<div class="container" *ngIf="!loading && !showCreateCompany">
  <div class="box-content">
    <!-- Logo -->
    <img class="image" [src]="'./assets/siloapp_logo.svg'" alt="" />

    <!-- Message -->
    <div>
      <p class="general-text">Enter your credentials below</p>
    </div>
    <!-- Sign-in form -->
    <form
      class="sigin-form"
      [formGroup]="signinForm"
      (ngSubmit)="onSubmit(signinForm)"
    >
      <mat-form-field class="signin-input" appearance="outline">
        <mat-label class="form-label">Username</mat-label>
        <input matInput formControlName="username" />
      </mat-form-field>
      <mat-form-field class="signin-input" appearance="outline">
        <mat-label class="form-label">Password</mat-label>
        <input
          matInput
          [type]="showPassword ? 'text' : 'password'"
          formControlName="password"
        />
        <label mat-icon-button matSuffix (click)="togglePasswordVisibility()">
          <mat-icon>{{
            showPassword ? "visibility_off" : "visibility"
          }}</mat-icon>
        </label>
      </mat-form-field>
      <button
        mat-button
        type="submit"
        class="button-default-dimension button-access"
        [disabled]="!signinForm.valid"
      >
        Sign in
      </button>
    </form>
    <div
      *ngIf="error && error.length > 0 && signinForm.touched"
      style="display: flex; flex-direction: column; gap: 4px; margin-top: 1rem"
    >
      <label *ngFor="let item of error" style="color: red; text-align: center">
        {{ item }}
      </label>
    </div>
    <div>
      <p class="general-black-text">
        Don't have an account?
        <a class="general-text" href="" routerLink="/signup"> Sign up</a>
      </p>
    </div>
  </div>
</div>
<app-createcompany [showCreateCompany]="showCreateCompany"></app-createcompany>
<app-spinner [loading]="loading"></app-spinner>
