export const environment = {
  production: false,
  baseUrl: '/api/v1/',
  env: 'localhost',
  port: '3000',
  endpoint: {
    baseUrl: 'https://staging-api.thesiloapp.com/api/v1/',
    users: 'https://staging-api.thesiloapp.com/api/v1/users',
    userById: 'https://staging-api.thesiloapp.com/api/v1/users/:id',
    activate: 'https://staging-api.thesiloapp.com/api/v1/users/activate',
    authenticate:
      'https://staging-api.thesiloapp.com/api/v1/users/authenticate',
    changePassword:
      'https://staging-api.thesiloapp.com/api/v1/users/:id/change-password',
    contactUs: 'https://staging-api.thesiloapp.com/api/v1/users/contactus',
    company: 'https://staging-api.thesiloapp.com/api/v1/company',
    companyById: 'https://staging-api.thesiloapp.com/api/v1/company/:id',
    projects: 'https://staging-api.thesiloapp.com/api/v1/projects',
    projectById: 'https://staging-api.thesiloapp.com/api/v1/projects/:id',
    projectFileById:
      'https://staging-api.thesiloapp.com/api/v1/projects/file/:id',
    projectFilesById: 'https://staging-api.thesiloapp.com/api/v1/projects/files/:id',
    sendProjectFilesEmail:
      'https://staging-api.thesiloapp.com/api/v1/projects/send-email/:id',
    countProjectsByUserId:
      'https://staging-api.thesiloapp.com/api/v1/projects/count',
    buildTree2D:
      'https://staging-api.thesiloapp.com/api/v1/projects/build-tree-2d',
    buildFlow2D:
      'https://staging-api.thesiloapp.com/api/v1/projects/build-flow-2d',
    quotations: 'https://staging-api.thesiloapp.com/api/v1/quotations',
    quotationById: 'https://staging-api.thesiloapp.com/api/v1/quotations/:id',
    quotationByProjectId:
      'https://staging-api.thesiloapp.com/api/v1/quotations/project/:id',
    acceptQuotationById:
      'https://staging-api.thesiloapp.com/api/v1/quotations/:id/accept',
    rejectQuotationById:
      'https://staging-api.thesiloapp.com/api/v1/quotations/:id/reject',
    countQuotations:
      'https://staging-api.thesiloapp.com/api/v1/quotations/count',
    reminderQuotationById:
      'https://staging-api.thesiloapp.com/api/v1/quotations/:id/reminder',
  },
  localStorageKeys: {
    currentProject: 'current-project-config',
    currentProjectId: 'current-project-id',
    userJwt: 'user-jwt',
    userFirstName: 'user-first-name',
    userLastName: 'user-last-name',
    userId: 'user-id',
    userIsAdmin: 'user-is-admin',
    buttonClick: 'current-button-click',
    quotationId: 'quotation-id',
    userType: 'user-type',
    companyType: 'company-type',
    companyId: 'company-id',
  },
  routes: {
    admin: '/admin',
    projects: '/projects',
    project: '/projects/:id',
    flowDiagram: '/flow-diagram',
    templates: '/templates',
    sendMail: '/projects/:id/send-mail',
    editTemplateFlowDiagram: '/templates/edit/flow-diagram',
    quotations: '/quotations',
    submitQuotationRequest: '/projects/:id/submit-quotation',
    viewQuotation: '/quotations/:id',
    viewQuotationFlowDiagram: '/quotations/:id/flow-diagram',
    account: '/account',
    contact: '/contact',
    consultingRequest: '/consulting-request',
    help: '/help',
    signIn: '/sign-in',
    paymentApproval: 'quotations/payment-approval',
    activationPending: '/activation-pending',
  },
};
