import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../shared/services/api.service';
import { UserSignInRequest, UserSignInResponse } from '../shared/models/user';
import { AuthService } from '../shared/services/auth.service';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  error: any[];
  loading = false;
  signinForm: FormGroup;
  showPassword: boolean = false;
  showCreateCompany = false;
  private routes = environment.routes;
  private endpoint = environment.endpoint;
  constructor(
    private router: Router,
    private http: HttpClient,
    private api: ApiService,
    private auth: AuthService
  ) {
    this.api.http = this.http;
  }

  ngOnInit(): void {
    this.auth.redirect();
    this.signinForm = new FormGroup({
      username: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required]),
    });
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  onSubmit(form: FormGroup): void {
    const formValue = form.value;
    const userSignInRequest: UserSignInRequest = {
      email: formValue.username.toLocaleLowerCase(),
      password: formValue.password,
    };
    this.api
      .post<UserSignInRequest, UserSignInResponse>(
        this.endpoint.authenticate,
        userSignInRequest
      )
      .subscribe(
        (r: UserSignInResponse) => {
          if (
            (r.company === undefined ||
            r.company === null ||
            r.company._id === undefined) && !r.isSuperAdmin
          ) {
            this.auth.setUserId(r._id);
            this.auth.setToken(r.token);
            this.showCreateCompany = true;
            return;
          } else {
            this.auth.setUserId(r._id);
            this.auth.setToken(r.token);
            this.auth.setLastName(r.lastName);
            this.auth.setAdminFlag(r.isSuperAdmin);
            this.auth.setFirstName(r.firstName);
            if(r.company) {
              this.auth.setCompanyId(r?.company?._id);
              this.auth.setCompanyType(r?.company?.type);
            } else {
              this.showCreateCompany = true;
              return;
            }
            
            let route = this.auth.specificRoute();

            this.router.navigate([route]).then(() => {
              window.location.reload();
            });
          }
        },
        (e) => {
          if (e.error.message instanceof Array) {
            this.error = e.error.message;
          } else {
            const nl = [];
            nl.push(e.error.message);
            this.error = nl;
          }
          if (this.error && e.status === 403) {
            this.router.navigate([this.routes.activationPending]);
          }
          if (this.error && e.status === 401) {
            this.router.navigate([this.routes.activationPending]);
          }
        }
      );
  }
}
