<div *ngIf="downloading" class="notification_wrapper">
  <div class="notification_box">
    <mat-spinner></mat-spinner>
    <span>Project files are downloading...</span>
    <span>{{ downloadedFiles }} / {{ maxFiles }}</span>
  </div>
</div>
<div *ngIf="count" class="notification_wrapper">
  <div class="notification_box">
    <mat-spinner></mat-spinner>
    <span>Checking if the project has files generated...</span>
  </div>
</div>

