import { GrainsEnum } from './enums';

export enum ProjectFileType {
  CSV,
  FLOW_DIAGRAM,
  STL_FILE,
  PROJECT_SNAPSHOT,
}

export const filesType: ProjectFileType[] = [
  ProjectFileType.CSV,
  ProjectFileType.FLOW_DIAGRAM,
  ProjectFileType.STL_FILE,
];

export enum ProjectSnapshotType {
  TOP,
  BOTTOM,
  DEGREE40,
}

export class ProjectFile {
  type: ProjectFileType;
  data: any;
  fileName?: any;
}

export class SendProjectEmail {
  message: string;
  recipients: string[];
  mainProductType: GrainsEnum;
  otherProductTypes: GrainsEnum[];
}
