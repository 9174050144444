<div class="spinner-loading">
  <app-spinner [loading]="loading"></app-spinner>
</div>
<div class="project-name">
  <mat-form-field>
    <label>
      <input matInput placeholder="Project Name" [(ngModel)]="projectName" />
    </label>
  </mat-form-field>
</div>
<div class="container">
  <div class="head-container">
    <div class="content-title">
      <h2>Design</h2>
    </div>
    <div class="equipment-list-header">
      <span *ngIf="showMoveEquipmentDialog" class="equipment-list-header-child"
        >Total number of equipments {{ currentProject.nodes.length }}</span
      >
    </div>
    <div class="content-top-buttons">
      <button
        mat-flat-button
        class="green-button"
        [matMenuTriggerFor]="actionMenu"
      >
        Actions
      </button>
      <mat-menu #actionMenu="matMenu" yPosition="below">
        <button
          mat-menu-item
          class="button-menu"
          [matMenuTriggerFor]="customizeMenu"
        >
          Customize
        </button>
        <mat-menu #customizeMenu="matMenu" yPosition="below">
          <button
            mat-menu-item
            *ngFor="let element of menuOptions"
            (click)="customizeDialog(element)"
            class="button-menu"
          >
            {{ element }}
          </button>
        </mat-menu>
        <button mat-menu-item class="button-menu" (click)="showEquipment()">
          Show equipments
        </button>
        <!--
        <button
          mat-menu-item
          class="button-menu"
          (click)="submitQuotationRequest()"
          [hidden]="disableSubmitQuotationRequest"
        >
          + Quotation
        </button>
        -->
        <button
          mat-menu-item
          class="button-menu"
          (click)="downloadProjectSpecs()"
        >
          Download project specs
        </button>
        <button mat-menu-item class="button-menu" (click)="saveProgress(true)">
          Save
        </button>
        <!---
        <button
          mat-menu-item
          class="button-menu"
          (click)="acceptQuotation()"
          *ngIf="!disableSubmitQuotationRequest"
        >
          Accept Offer
        </button>
        <button
          mat-menu-item
          class="button-menu"
          (click)="rejectQuotation()"
          *ngIf="!disableSubmitQuotationRequest"
        >
          Reject Offer
        </button>-->
      </mat-menu>
    </div>
  </div>
  <div #designContainer class="parent-grid">
    <canvas
      #canvas3dDesign
      id="silo3dDesign"
      class="layout-grid"
      height="564"
      width="844"
      (click)="
        addEquipmentsStepper.selected === step2 ? addEquipment($event) : null
      "
    ></canvas>
    <!-- Equipment list -->
    <div id="eq-list" class="equipment-list" *ngIf="showEquipmentList">
      <div class="equipment-list-header">
        <span class="equipment-list-header-child"
          >Total number of equipments
          {{ this.currentProject.nodes.length }}</span
        >
        <div class="button-spacer"></div>
        <button
          class="button-space-left secondary-button"
          (click)="saveChanges(false)"
        >
          Apply changes
        </button>
        <button
          class="button-space-left secondary-button"
          (click)="customizeClosed()"
        >
          Close
        </button>
      </div>
      <div class="mat-elevation-z2">
        <mat-accordion multi>
          <app-equipment-card
            *ngFor="let equipment of equipmentListToShow()"
            [equipment]="equipment"
            [editEquipmentCard]="false"
            [deleteEquipmentCard]="true"
            (deleteEquipment)="deleteEquipment($event)"
            (mouseOn)="mouseOn($event)"
            (mouseOff)="mouseOff($event)"
            (valueChangedEvent)="handleValueChanges($event)"
          >
          </app-equipment-card>
        </mat-accordion>
      </div>
    </div>
    <!-- Add new equipment -->
    <div class="equipment-list" [hidden]="!showAddEquipmentDialog">
      <div class="equipment-list-header">
        <span class="equipment-list-header-child"
          >Total number of equipments
          {{ currentProject.nodes.length - 1 }}</span
        >
      </div>
      <mat-horizontal-stepper #addEquipmentsStepper>
        <mat-step #step1>
          <ng-template matStepLabel>Choose equipment parameters</ng-template>
          <ng-container
            *ngIf="
              addEquipmentsStepper.selected === null ||
              addEquipmentsStepper.selected === step1
            "
          >
          <div class="add-equipment-container">
            <app-add-equipment-dialog *ngIf = "showAddEquipmentDialog"
              [equipmentParams]="addEquipmentParams"
              [part3DNodes]="currentProject.nodes"
              (closeClicked)="customizeClosed()"
              (addClicked)="addEquipment($event)"
              (mouseOn)="mouseOn($event)"
              (mouseOff)="mouseOff($event)"
            >
            </app-add-equipment-dialog>
          </div>
          </ng-container>
        </mat-step>

        <mat-step #step2>
          <ng-template matStepLabel>Position the equipment</ng-template>
          <ng-container *ngIf="addEquipmentsStepper.selected === step2">
            <p>Position the equipment on the silo plant.</p>
            <button
              mat-button
              class="white-button"
              (click)="closeAddEquipments()"
            >
              Close
            </button>
            <button
              mat-button
              class="green-button"
              (click)="addEquipmentsStepper.previous()"
            >
              Back to parameters
            </button>
            <button
              mat-button
              class="green-button"
              (click)="finishAddingEquipment()"
            >
              Finish
            </button>
          </ng-container>
        </mat-step>
      </mat-horizontal-stepper>
    </div>

    <!-- Delete equipments -->
    <div class="equipment-list" *ngIf="showDeleteEquipmentDialog">
      <div class="equipment-list-header">
        <span class="equipment-list-header-child"
          >Total number of equipments
          {{ currentProject.nodes.length - 1 }}</span
        >
      </div>
      <app-delete-equipment-dialog
        [part3DNodes]="equipmentListShow()"
        (closeClicked)="closeDeleteEquipment()"
        (deleteClicked)="deleteEquipment($event)"
        (mouseOn)="mouseOn($event)"
        (mouseOff)="mouseOff($event)"
      >
      </app-delete-equipment-dialog>
    </div>
    <!--Equipment specifications-->
    <div class="equipment-list" *ngIf="showEquipmentSpecificationsDialog">
      <div class="equipment-list-header">
        <span class="equipment-list-header-child"
          >Total number of equipments
          {{ currentProject.nodes.length - 1 }}</span
        >
      </div>
      <app-equipment-specifications-dialog
        [part3DNodes]="equipmentListShow()"
        (closeClicked)="customizeClosed()"
        (editClick)="editEquipmentSpecifications($event)"
        (mouseOn)="mouseOn($event)"
        (mouseOff)="mouseOff($event)"
      >
      </app-equipment-specifications-dialog>
    </div>
    <!-- Move equipment dialog-->
      <app-move-equipment-dialog *ngIf="showMoveEquipmentDialog"
      [part3DNodes]="currentProject.nodes"
      [isActive]="showMoveEquipmentDialog"
      (closeClicked)="closeMoveEquipment()"
      (rotateClicked)="rotateEquipment($event)"
      (mouseOn)="mouseOn($event)"
      (moveComponent)="onMoveComponent($event)"
      (mouseOff)="mouseOff($event)"
      (toggleBoundinxBoxEvent)="toggleBoundingBox($event)"
    >
    </app-move-equipment-dialog>

    <div class="layout-grid-button-item">
      <button
        mat-button
        (click)="back()"
        *ngIf="!isFromQuotations"
        class="white-button button-margin-left"
        [disabled]="disableBackButton"
      >
        Reconfigure
      </button>
      <button
        *ngIf="stepper"
        mat-button
        class="white-button button-space-left"
        (click)="cancel()"
      >
        Cancel
      </button>
      <div class="button-spacer"></div>
      <button
        mat-button
        class="green-button button-spacer-continue-left"
        (click)="next()"
        [disabled]="loading || !canContinue"
      >
        Continue
      </button>
    </div>
  </div>
  <app-notify-download></app-notify-download>
</div>
