// todo: extend this to work with multiple intakes
// todo: extend this to work with one intake and two groups of bins
// todo: extend this to work with multiple intakes and two groups of bins
import { Injectable } from '@angular/core';
import * as THREE from 'three';
import {
  Bin3DParams,
  Cleaner3DParams,
  Conveyor3DParams,
  Delivery3DParams,
  Dryer3DParams,
  Elevator3DParams,
  Intake3DParams,
  OverlappingEquipmentsPair,
  Part3D,
  Part3DDetails,
  Part3DNode,
  SceneGeneratorEvent,
  SiloGroupAxis,
  SiloGroupOrientation,
} from '../models/part';
import {
  AMBIENT_LIGHT_INTENSITY,
  ASPECT_RATIO,
  CAMERA_POSX,
  CAMERA_POSY,
  CAMERA_POSZ,
  DEFAULT_TILE_SIZE,
  DIRECTIONAL_LIGHT_INTENSITY,
  FAR_FIELD,
  FIELD_OF_VIEW,
  LIGHT_COLOR,
  NEAR_FIELD,
  NTILES,
  X0,
  Y0,
  Z0,
} from '../constants/scene-defaults';
import {
  PartAreaEnum,
  PartSubTypeEnum,
  PartTypeEnum,
  SceneGeneratorEnum,
  SyncStatesEnum,
} from '../models/enums';
import {
  BELT_CONVEYOR_INTAKE_LENGTH,
  BELT_CONVEYOR_PARAMS,
  BELT_CONVEYOR_TENSIONING_LENGTH,
  BIN_PARAMS,
  BUFFER_BIN_PARAMS,
  CHAIN_CONVEYOR_PARAMS,
  CHAIN_CONVEYOR_TENSIONING_STATION_LENGTH,
  CLEANER_ELEVATOR_PARAMS,
  CLEANER_PARAMS,
  CONVEYOR_DEFAULT_ORIENTATION,
  DELIVERY_AREA_PARAMS,
  DELIVERY_LOADING_CONVEYOR_PARAMS,
  DRYER_DEFAULT_ORIENTATION,
  DRYER_ELEVATOR_PARAMS,
  DRYER_INTAKE_OFFSET,
  DRYER_LENGTH,
  DRYER_LOADING_CONVEYOR_PARAMS,
  DRYER_PARAMS,
  ELEVATOR_BASE_LENGTH,
  ELEVATOR_DEFAULT_ORIENTATION,
  ELEVATOR_MOTOR_HEIGHT,
  ELEVATOR_PARAMS,
  INTAKE_AREA_PARAMS,
  MAIN_ELEVATOR_PARAMS,
} from '../constants/equipment-parameters-default';
import { EquipmentGraphService } from './equipment-graph.service';
import { EquipmentGeneratorService } from './equipment-generator.service';
import { Subject } from 'rxjs';
import { SceneParams } from '../models/scene-params';
import { VectorService } from './vector.service';
import { SiloPlantHelperService } from './silo-plant-helper.service';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {
  ConveyorLocation,
  SingleSiloBinBlockMultipleIntakeConfig,
  SingleSiloBinBlockSingleIntakeConfig,
} from '../models/silo-config';

@Injectable({
  providedIn: 'root',
})
export class SiloPlantGeneratorService {
  siloPlantConfig;

  constructor(
    private equipmentGraph: EquipmentGraphService,
    private equipmentGenerator: EquipmentGeneratorService,
    private geometry: VectorService,
    public helper: SiloPlantHelperService
  ) {}
  // === SCENE PREPARTION HELPER METHODS === //
  initializeScene(
    canvasSelector: string,
    cameraPosX: number = CAMERA_POSX,
    cameraPosY: number = CAMERA_POSY,
    cameraPosZ: number = CAMERA_POSZ,
    fieldOfView: number = FIELD_OF_VIEW,
    aspectRatio: number = ASPECT_RATIO,
    nearField: number = NEAR_FIELD,
    farField: number = FAR_FIELD
  ): SceneParams {
    // Get HTML element where to display the scene
    const canvas: HTMLCanvasElement = document.querySelector(canvasSelector);
    // Create a render
    const renderer = new THREE.WebGLRenderer({
      canvas,
      antialias: true,
      preserveDrawingBuffer: true,
    });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.shadowMap.enabled = false;
    // Create a camera
    const camera = new THREE.PerspectiveCamera(
      fieldOfView,
      aspectRatio,
      nearField,
      farField
    );
    camera.position.x = cameraPosX;
    camera.position.y = cameraPosY;
    camera.position.z = cameraPosZ;
    camera.lookAt(new THREE.Vector3(X0, Y0, Z0));
    // Create a scene
    const scene = new THREE.Scene();
    scene.background = new THREE.Color('lightgray');
    // Add axis helper
    const axesHelper = new THREE.AxesHelper(100);
    scene.add(axesHelper);
    // Add light to the scene
    const ambientLight = new THREE.AmbientLight(LIGHT_COLOR, AMBIENT_LIGHT_INTENSITY);
    const directionalLight = new THREE.DirectionalLight(LIGHT_COLOR, DIRECTIONAL_LIGHT_INTENSITY);
    directionalLight.position.set(
     (NTILES - 1) *  DEFAULT_TILE_SIZE,
     (NTILES - 1) *  DEFAULT_TILE_SIZE,
     (NTILES - 1) *  DEFAULT_TILE_SIZE);
    scene.add(ambientLight, directionalLight);

    // Create orbit controls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.minDistance = 1;
    controls.maxDistance = 900.0;
    return {
      scene,
      renderer,
      camera,
      light: directionalLight,
      controls,
      raycaster: new THREE.Raycaster(),
      pointer: new THREE.Vector3(),
    };
  }

  updateScene(
    renderer: THREE.Renderer,
    scene: THREE.Scene,
    camera: THREE.PerspectiveCamera,
    controls: OrbitControls,
  ): void {
    const canvas = renderer.domElement;
    camera.aspect = canvas.clientWidth / canvas.clientHeight;
    camera.updateProjectionMatrix();
    renderer.render(scene, camera);
    controls.update();
    requestAnimationFrame(() => {
      this.updateScene(renderer, scene, camera, controls);
    });
  }

  initScene3D(canvasSelector: string, nTiles: number): SceneParams {
    const sceneComponents = this.initializeScene(canvasSelector);
    this.updateScene(
      sceneComponents.renderer,
      sceneComponents.scene,
      sceneComponents.camera,
      sceneComponents.controls
    );
    sceneComponents.scene = this.equipmentGenerator.addPlaneTilesToScene(
      sceneComponents.scene,
      DEFAULT_TILE_SIZE,
      nTiles
    );
    return sceneComponents;
  }

  updateScene3D(
    sceneComponents?: SceneParams,
    cavasSelector?: string,
    nTiles?: number,
  ): void {
    if (!sceneComponents) {
      sceneComponents = this.initScene3D(cavasSelector, nTiles);
    }
    this.updateScene(
      sceneComponents.renderer,
      sceneComponents.scene,
      sceneComponents.camera,
      sceneComponents.controls,
    );
  }

  // === SILO PLANT CONSTRUCTION METHODS === //
  addSiloBinNodesSelection(
    nodes: Part3DNode[],
    tiles: THREE.Mesh[] | THREE.Group[],
    part3D?: Part3D,
    scene?: any
  ): Part3DNode[] {
    const binOrientation = new THREE.Vector3();
    const binId = this.equipmentGraph._generateRandomId(nodes);
    for (const [i, tile] of tiles.entries()) {
      const binPosition = new THREE.Vector3(
        tile.position.x,
        0,
        tile.position.z
      );
      const binParams = new Bin3DParams(
        `Storage bin #${binId}`,
        BIN_PARAMS.color,
        BIN_PARAMS.dx,
        BIN_PARAMS.padding
      );
      const bin = this.equipmentGraph.createNode(
        PartTypeEnum.SILO_BIN,
        PartAreaEnum.STORAGE,
        [],
        [],
        binPosition,
        binOrientation,
        binParams,
        binId
      );

      const existingBin = nodes.find(
        (n) =>
          n.position.x === bin.position.x &&
          n.position.y === bin.position.y &&
          n.position.z === bin.position.z
      );

      if (!existingBin) {
        if (part3D) {
          bin.part3D = part3D;
          bin.part3D.part.name = bin.partDetails3D.params.name;
          bin.part3D.part.position.x = bin.position.x;
          bin.part3D.part.position.y = bin.position.y;
          bin.part3D.part.position.z = bin.position.z;
        }

        if (scene) {
          scene.add(bin.part3D.part);
        }
        bin.tileName = tile.name;
        nodes.push(bin);
      }
    }

    return nodes;
  }

  addElevatorNodesSelection(
    nodes: Part3DNode[],
    tiles: THREE.Mesh[] | THREE.Group[],
    part3D?: Part3D,
    scene?: any
  ): Part3DNode[] {
    const elevatorOrientation = new THREE.Vector3();
    const elevatorId = this.equipmentGraph._generateRandomId(nodes);
    for (const [i, tile] of tiles.entries()) {
      const elevatorPosition = new THREE.Vector3(
        tile.position.x,
        0,
        tile.position.z
      );
      const elevatorParams = new Elevator3DParams(
        `Main Elevator #${elevatorId}`,
        MAIN_ELEVATOR_PARAMS.color,
        MAIN_ELEVATOR_PARAMS.dx,
        MAIN_ELEVATOR_PARAMS.padding,
        MAIN_ELEVATOR_PARAMS.capacity,
        MAIN_ELEVATOR_PARAMS.depth,
        true
      );
      const elevator = this.equipmentGraph.createNode(
        PartTypeEnum.ELEVATOR,
        PartAreaEnum.STORAGE,
        [],
        [],
        elevatorPosition,
        elevatorOrientation,
        elevatorParams,
        elevatorId
      );

      const existingBin = nodes.find(
        (n) =>
          n.position.x === elevator.position.x &&
          n.position.y === elevator.position.y &&
          n.position.z === elevator.position.z
      );

      if (!existingBin) {
        if (part3D) {
          elevator.part3D = part3D;
          elevator.part3D.part.name = elevator.partDetails3D.params.name;
          elevator.part3D.part.position.x = elevator.position.x;
          elevator.part3D.part.position.y = elevator.position.y;
          elevator.part3D.part.position.z = elevator.position.z;
        }

        if (scene) {
          scene.add(elevator.part3D.part);
        }
        elevator.tileName = tile.name;
        nodes.push(elevator);
      }
    }

    return nodes;
  }

  addIntakeNodesSelection(
    nodes: Part3DNode[],
    tiles: THREE.Mesh[] | THREE.Group[],
    part3D?: Part3D,
    scene?: any
  ): Part3DNode[] {
    const intakeId = this.equipmentGraph._generateRandomId(nodes);
    const orientation = new THREE.Vector3();
    for (const [i, tile] of tiles.entries()) {
      const intake = new Part3DNode();
      intake.partDetails3D = new Part3DDetails();
      intake.id = intakeId;
      intake.area = PartAreaEnum.INTAKE;
      intake.type = PartTypeEnum.INTAKE;
      intake.position = new THREE.Vector3(tile.position.x, 0, tile.position.z);
      intake.orientation = orientation;
      intake.partDetails3D.params = new Intake3DParams(
        `Intake #${intakeId}`,
        INTAKE_AREA_PARAMS.color,
        INTAKE_AREA_PARAMS.dx,
        INTAKE_AREA_PARAMS.padding,
        (INTAKE_AREA_PARAMS as Intake3DParams).capacity
      );

      const existingIntake = nodes.find(
        (n) =>
          n.position.x === intake.position.x &&
          n.position.y === intake.position.y &&
          n.position.z === intake.position.z
      );

      if (!existingIntake) {
        if (part3D) {
          intake.part3D = part3D;
          intake.part3D.part.name = intake.partDetails3D.params.name;
          intake.part3D.part.position.x = intake.position.x;
          intake.part3D.part.position.y = intake.position.y;
          intake.part3D.part.position.z = intake.position.z;
        }

        if (scene) {
          scene.add(intake.part3D.part);
        }
        intake.tileName = tile.name;
        nodes.push(intake);
      }
    }
    return nodes;
  }

  initSiloPlantConfig(nodes: Part3DNode[]): void {
    const nIntakes = nodes.filter(
      (n) => n.area === PartAreaEnum.INTAKE && n.type === PartTypeEnum.INTAKE
    ).length;
    if (nIntakes === 1) {
      this.siloPlantConfig = new SingleSiloBinBlockSingleIntakeConfig(nodes);
      this.siloPlantConfig.buildRelations();
    } else {
      this.siloPlantConfig = new SingleSiloBinBlockMultipleIntakeConfig(nodes);
    }
  }

  buildSiloPlant(sceneObjects$: Subject<SceneGeneratorEvent>): void {
    sceneObjects$.next({
      part3DNode: undefined,
      next: SceneGeneratorEnum.POSITION_SILO_BINS,
    });
  }

  appendEquipmentRelations(nodes: Part3DNode[], sceneObjects$: Subject<SceneGeneratorEvent>): void {
    for (let ix = 0; ix < nodes.length; ix++) {
      nodes[ix].children = [];
      const children = this.siloPlantConfig.equipmentGraph.getChildren(
        nodes[ix].id
      );
      if (children) {
        for (const c of children) {
          if (!nodes[ix].children.includes(c.toString())) {
            nodes[ix].children.push(c.toString());
          }
        }
      }
      nodes[ix].parents = [];
      const parents = this.siloPlantConfig.equipmentGraph.getParents(
        nodes[ix].id
      );
      if (parents) {
        for (const p of parents) {
          if (!nodes[ix].parents.includes(p.toString())) {
            nodes[ix].parents.push(p.toString());
          }
        }
      }
    }
    sceneObjects$.next({ part3DNode: undefined, next: SceneGeneratorEnum.COMPLETE });

  }

  numberSiloBins(
    bins: Part3DNode[],
    cornerBin: Part3DNode,
    axis: SiloGroupAxis,
    tileSize: number,
    numberOfBinRows?: number,
    numberOfBinsPerRow?: number
  ): Part3DNode[] {
    if (!numberOfBinRows) {
      numberOfBinRows = this.helper.getNumberOfSiloBinRows(bins, axis);
    }

    if (!numberOfBinsPerRow) {
      numberOfBinsPerRow = this.helper.getNumberOfSiloBinsPerRow(bins, axis);
    }
    let position: number;
    let startBin: Part3DNode;
    let binRowNum = 0;
    let binNumPerRow: number;
    let rowIdx = 1;
    let restartRowCounter = false;
    for (let i = 0; i < numberOfBinRows; i++) {
      binNumPerRow = 0;
      if (axis.T.x) {
        if (restartRowCounter) {
          position = cornerBin.position.x - axis.T.x * rowIdx * tileSize;
        } else {
          position = cornerBin.position.x + axis.T.x * i * tileSize;
        }
        startBin = this.helper.findEquipmentWithinRadius(
          bins,
          new THREE.Vector3(position, 0, cornerBin.position.z)
        );
        if (!startBin && !restartRowCounter) {
          restartRowCounter = true;
          position = cornerBin.position.x - axis.T.x * rowIdx * tileSize;
          startBin = this.helper.findEquipmentWithinRadius(
            bins,
            new THREE.Vector3(position, 0, cornerBin.position.z)
          );
        }
      } else {
        if (restartRowCounter) {
          position = cornerBin.position.z - axis.T.z * rowIdx * tileSize;
        } else {
          position = cornerBin.position.z + axis.T.z * i * tileSize;
        }
        startBin = this.helper.findEquipmentWithinRadius(
          bins,
          new THREE.Vector3(cornerBin.position.x, 0, position)
        );
        if (!startBin && !restartRowCounter) {
          restartRowCounter = true;
          position = cornerBin.position.z - axis.T.z * rowIdx * tileSize;
          startBin = this.helper.findEquipmentWithinRadius(
            bins,
            new THREE.Vector3(cornerBin.position.x, 0, position)
          );
        }
      }
      if (!startBin) {
        return null;
      }
      for (let j = 0; j < numberOfBinsPerRow; j++) {
        const bin = this.helper.findEquipmentAlongAxis(
          bins,
          startBin,
          axis.L,
          j * tileSize
        );
        if (bin) {
          bin.partDetails3D.binRowNum = binRowNum;
          bin.partDetails3D.binNumberPerRow = binNumPerRow;
          bin.partDetails3D.isLeftMainAxis = !restartRowCounter;
          (bin.partDetails3D.params as Bin3DParams).group = 1;
          binNumPerRow += 1;
        }
      }
      if (restartRowCounter) {
        rowIdx += 1;
      }
      binRowNum += 1;
    }
    return bins;
  }

  positionSiloBins(
    bins: Part3DNode[],
    orientation: SiloGroupOrientation,
    sceneObjects$: Subject<SceneGeneratorEvent>,
    numberOfBinRows?: number,
    numberOfBinsPerRow?: number
  ): void {
    orientation.bin.position = new THREE.Vector3(
      orientation.bin.position.x,
      orientation.bin.position.y,
      orientation.bin.position.z
    );
    let numberOfTransversalBins = numberOfBinRows;
    let numberOfLongitudinalBins = numberOfBinsPerRow;

    if (!numberOfTransversalBins) {
      numberOfTransversalBins = this.helper.getNumberOfSiloBinRows(
        bins,
        orientation.axis
      );
    }
    if (!numberOfLongitudinalBins) {
      numberOfLongitudinalBins = this.helper.getNumberOfSiloBinsPerRow(
        bins,
        orientation.axis
      );
    }
    // position reference row (binRowNum = 0)
    const referenceBins = bins.filter((b) => b.partDetails3D.binRowNum === 0);
    let previousCellRadius = 0;
    let distanceToCurrentBin = 0;
    if (numberOfLongitudinalBins <= 0) {
      numberOfLongitudinalBins = 1;
    }
    for (let j = 0; j < numberOfLongitudinalBins; j++) {
      const currentBin = referenceBins.find(
        (b) => b.partDetails3D.binNumberPerRow === j
      );
      if (!currentBin) {
        continue;
      }
      const padding = orientation.axis.L.x
        ? currentBin.partDetails3D.params.padding.x
        : currentBin.partDetails3D.params.padding.z;
      const position = orientation.axis.L.x
        ? currentBin.position.x
        : currentBin.position.z;
      if (j === 0) {
        distanceToCurrentBin += position + previousCellRadius;
      } else {
        if (orientation.axis.L.x) {
          distanceToCurrentBin +=
            (padding + previousCellRadius) * orientation.axis.L.x;
        } else {
          distanceToCurrentBin +=
            (padding + previousCellRadius) * orientation.axis.L.z;
        }
      }
      // test only. uncomment lines only if you want to test with having a "fat" bin
      // if (j === 1) {
      //   currentBin.partDetails3D.params.dx.x *= 2;
      //   currentBin.partDetails3D.params.dx.z *= 2;
      // }
      if (j > 0) {
        distanceToCurrentBin += orientation.axis.L.x
          ? (currentBin.partDetails3D.params.dx.x / 2 + padding) *
            orientation.axis.L.x
          : (currentBin.partDetails3D.params.dx.z / 2 + padding) *
            orientation.axis.L.z;
        currentBin.position = orientation.axis.L.x
          ? new THREE.Vector3(
              distanceToCurrentBin,
              currentBin.position.y,
              currentBin.position.z
            )
          : new THREE.Vector3(
              currentBin.position.x,
              currentBin.position.y,
              distanceToCurrentBin
            );
      }
      previousCellRadius = orientation.axis.L.x
        ? currentBin.partDetails3D.params.dx.x / 2
        : currentBin.partDetails3D.params.dx.z / 2;
      this.equipmentGenerator
        .getSiloBin(
          currentBin.partDetails3D.params.dx,
          currentBin.partDetails3D.params.color
        )
        .subscribe((b) => {
          b.part.name = currentBin.partDetails3D.params.name;
          b.part.position.x = currentBin.position.x;
          b.part.position.y = currentBin.position.y;
          b.part.position.z = currentBin.position.z;
          /** UPDATE ELEVATOR PARAMS */
          currentBin.part3D = b;
          currentBin.partDetails3D.params.dx.x = b.params.dx.x;
          currentBin.partDetails3D.params.dx.y = b.params.dx.y;
          currentBin.partDetails3D.params.dx.z = b.params.dx.z;

          if (
            numberOfTransversalBins === 1 &&
            j === numberOfLongitudinalBins - 1
          ) {
            sceneObjects$.next({
              part3DNode: currentBin,
              next: SceneGeneratorEnum.POSITION_MAIN_ELEVATOR,
            });
          } else {
            sceneObjects$.next({ part3DNode: currentBin, next: undefined });
          }
        });
    }
    // position bins on all other rows (with respect to binRowNum = 0)
    let previousBins: Part3DNode[];
    for (let i = 1; i < numberOfTransversalBins; i++) {
      // find current start bin to find out the maximum Z displacement

      const currentStartBin = bins.find(
        (b) =>
          b.partDetails3D.binRowNum === i &&
          b.partDetails3D.binNumberPerRow === 0
      );

      previousBins = bins.filter((b) => b.partDetails3D.binRowNum === i - 1);
      if (
        !currentStartBin.partDetails3D.isLeftMainAxis &&
        previousBins[0].partDetails3D.isLeftMainAxis
      ) {
        previousBins = bins.filter((b) => b.partDetails3D.binRowNum === 0);
      }

      // find out if you need to go + or - along Z based on the position of the bin wrt the reference row
      const isAlongT = currentStartBin.partDetails3D.isLeftMainAxis ? 1 : -1;

      // compute maximum displacement along Z
      const maxDx0 = Math.max(
        ...previousBins.map((b) => b.partDetails3D.params.dx.x / 2)
      );
      const maxDz0 = Math.max(
        ...previousBins.map((b) => b.partDetails3D.params.dx.z / 2)
      );
      const maxDx1 = Math.max(
        ...bins
          .filter((b) => b.partDetails3D.binRowNum === i)
          .map((b) => b.partDetails3D.params.dx.x / 2)
      );
      const maxDz1 = Math.max(
        ...bins
          .filter((b) => b.partDetails3D.binRowNum === i)
          .map((b) => b.partDetails3D.params.dx.z / 2)
      );

      // todo: compute padding along Z (maximum)? at the moment it uses the padding of the start bin.
      const paddingZ = orientation.axis.L.x
        ? currentStartBin.partDetails3D.params.padding.z
        : currentStartBin.partDetails3D.params.padding.x;

      // compute Z position of the bins on the current row
      const positionZ = orientation.axis.L.x
        ? previousBins[0].position.z +
          orientation.axis.T.z * isAlongT * (2 * paddingZ + maxDz0 + maxDz1)
        : previousBins[0].position.x +
          orientation.axis.T.x * isAlongT * (2 * paddingZ + maxDx0 + maxDx1);

      // for each row, initialize previous distance and cell radius
      distanceToCurrentBin = 0;
      previousCellRadius = 0;

      // position bins on the current row
      for (let j = 0; j < numberOfLongitudinalBins; j++) {
        const currentBin = bins.find(
          (b) =>
            b.partDetails3D.binRowNum === i &&
            b.partDetails3D.binNumberPerRow === j
        );
        if (!currentBin) {
          continue;
        }
        const padding = orientation.axis.L.x
          ? currentBin.partDetails3D.params.padding.x
          : currentBin.partDetails3D.params.padding.z;

        const positionX = orientation.axis.L.x
          ? currentBin.position.x
          : currentBin.position.z;

        if (j === 0) {
          distanceToCurrentBin += positionX + previousCellRadius;
        } else {
          if (orientation.axis.L.x) {
            distanceToCurrentBin +=
              (padding + previousCellRadius) * orientation.axis.L.x;
          } else {
            distanceToCurrentBin +=
              (padding + previousCellRadius) * orientation.axis.L.z;
          }
        }

        if (j > 0) {
          distanceToCurrentBin += orientation.axis.L.x
            ? (currentBin.partDetails3D.params.dx.x / 2 + padding) *
              orientation.axis.L.x
            : (currentBin.partDetails3D.params.dx.z / 2 + padding) *
              orientation.axis.L.z;
        }
        currentBin.position = orientation.axis.L.x
          ? new THREE.Vector3(
              distanceToCurrentBin,
              currentBin.position.y,
              positionZ
            )
          : new THREE.Vector3(
              positionZ,
              currentBin.position.y,
              distanceToCurrentBin
            );
        previousCellRadius = orientation.axis.L.x
          ? currentBin.partDetails3D.params.dx.x / 2
          : currentBin.partDetails3D.params.dx.z / 2;

        this.equipmentGenerator
          .getSiloBin(
            currentBin.partDetails3D.params.dx,
            currentBin.partDetails3D.params.color
          )
          .subscribe((b: Part3D) => {
            b.part.name = currentBin.partDetails3D.params.name;
            b.part.position.x = currentBin.position.x;
            b.part.position.y = currentBin.position.y;
            b.part.position.z = currentBin.position.z;
            /** UPDATE ELEVATOR PARAMS */
            currentBin.part3D = b;
            currentBin.partDetails3D.params.dx.x = b.params.dx.x;
            currentBin.partDetails3D.params.dx.y = b.params.dx.y;
            currentBin.partDetails3D.params.dx.z = b.params.dx.z;

            if (
              i === numberOfTransversalBins - 1 &&
              j === numberOfLongitudinalBins - 1
            ) {
              sceneObjects$.next({
                part3DNode: currentBin,
                next: SceneGeneratorEnum.POSITION_MAIN_ELEVATOR,
              });
            } else {
              sceneObjects$.next({ part3DNode: currentBin, next: undefined });
            }
          });
      }
    }
  }

  positionSingleBin(
    sceneObjects$: Subject<SceneGeneratorEvent>,
    orientation: SiloGroupOrientation
  ): void {
    this.equipmentGenerator
      .getSiloBin(
        orientation.bin.partDetails3D.params.dx,
        orientation.bin.partDetails3D.params.color
      )
      .subscribe((b) => {
        b.part.name = orientation.bin.partDetails3D.params.name;
        b.part.position.x = orientation.bin.position.x;
        b.part.position.y = orientation.bin.position.y;
        b.part.position.z = orientation.bin.position.z;
        /** UPDATE BIN PARAMS */
        orientation.bin.part3D = b;
        orientation.bin.partDetails3D.params.dx.x = b.params.dx.x;
        orientation.bin.partDetails3D.params.dx.y = b.params.dx.y;
        orientation.bin.partDetails3D.params.dx.z = b.params.dx.z;
        orientation.bin.partDetails3D.binNumberPerRow = 0;
        orientation.bin.partDetails3D.binRowNum = 0;
        // TODO: Change this. I don't think it is computed correctly
        if (orientation.bin.partDetails3D.isLeftMainAxis === undefined) {
          orientation.bin.partDetails3D.isLeftMainAxis =
            orientation.axis.T.x > 0 || orientation.axis.T.z > 0;
        }
        sceneObjects$.next({
          part3DNode: orientation.bin,
          next: SceneGeneratorEnum.POSITION_MAIN_ELEVATOR,
        });
      });
  }

  positionIntake(
    intake: Part3DNode,
    orientation: SiloGroupOrientation,
    sceneObjects$: Subject<SceneGeneratorEvent>,
    referenceRowSize?: THREE.Vector3
  ): void {
    intake.position = new THREE.Vector3(
      intake.position.x,
      intake.position.y,
      intake.position.z
    );
    orientation.bin.position = new THREE.Vector3(
      orientation.bin.position.x,
      orientation.bin.position.y,
      orientation.bin.position.z
    );

    const delta = new THREE.Vector3(
      intake.position.x - orientation.bin.position.x,
      intake.position.y - orientation.bin.position.y,
      intake.position.z - orientation.bin.position.z
    );
    const deltaSign = new THREE.Vector3(
      Math.sign(delta.x),
      Math.sign(delta.y),
      Math.sign(delta.z)
    );

    // Reposition away from selected position only if the main elevator and
    // intake are along the logitudinal axis.
    const isIntakeAlongLongitudinalAxis = this.geometry.alongSameAxis(
      this.geometry.subtractVectors(intake.position, orientation.bin.position),
      this.geometry.multiplyScalarVector(orientation.axis.L, -1)
    );
    if (0) {
      intake.position = new THREE.Vector3(
        orientation.bin.position.x +
          delta.x +
          (intake.partDetails3D.params.padding.x +
            orientation.bin.partDetails3D.params.padding.x) *
            deltaSign.x,
        orientation.bin.position.y +
          delta.y +
          (intake.partDetails3D.params.padding.y +
            orientation.bin.partDetails3D.params.padding.y) *
            deltaSign.y,
        orientation.bin.position.z +
          delta.z +
          (intake.partDetails3D.params.padding.z +
            orientation.bin.partDetails3D.params.padding.z) *
            deltaSign.z
      );

      if (referenceRowSize) {
        if (orientation.axis.T.x) {
          intake.position.x +=
            (referenceRowSize.x +
              orientation.bin.partDetails3D.params.dx.x / 2) *
            deltaSign.x;
          intake.position.z += referenceRowSize.z * deltaSign.z;
        } else {
          intake.position.x += referenceRowSize.x * deltaSign.x;
          intake.position.z +=
            (referenceRowSize.z +
              orientation.bin.partDetails3D.params.dx.z / 2) *
            deltaSign.z;
        }
      }
    }

    if (!intake.orientation) {
      intake.orientation = new THREE.Vector3(0, 0, 0);
    }
    this.equipmentGenerator
      .getEquipmentMetallicHousing(
        intake.partDetails3D.params.dx,
        intake.partDetails3D.params.color,
        intake.partDetails3D.params.name
      )
      .subscribe((i) => {
        i.part.name = intake.partDetails3D.params.name;
        i.part.position.x = intake.position.x;
        i.part.position.y = intake.position.y;
        i.part.position.z = intake.position.z;
        i.part.rotateY(intake.orientation.y);
        intake.part3D = i;
        sceneObjects$.next({
          part3DNode: intake,
          next: SceneGeneratorEnum.ADJUST_INTAKE_POSITION,
        });
      });
  }

  adjustPositionIntake(
    intake: Part3DNode,
    intakeCollisions:OverlappingEquipmentsPair[],
    nodes: Part3DNode[],
    tiles: any[],
    orientation: SiloGroupOrientation,
    sceneObjects$: Subject<SceneGeneratorEvent>
  ): void {
    const needsAdjusting = 0;
    //     this.helper.intakeNeedsAdjusting(
    //   orientation,
    //   intake
    // );

    /**
     * The needsAdjust check can be replaced by taking into account only the colisions...
     * Colision handling for intake:
     *  1. SILO_BIN => move either transversal/opposite transversal depending on the position of the intake
     *  2. BUFFER_BIN => move transversal (after the BUFFER_BIN)
     *  3. CLEANER/DELIVERY => move opposite longitudinal (after the delivery)
     */
    if (needsAdjusting || intakeCollisions.length > 0) {

      const position = new THREE.Vector3(
        intake.position.x,
        intake.position.y,
        intake.position.z
      );
        if(needsAdjusting){
          const deliveryBin = nodes.find(
            (n) =>
              n.area === PartAreaEnum.DELIVERY && n.type === PartTypeEnum.DELIVERY
          );
          // recompute intake position
          position.x = deliveryBin.position.x;
          position.y = deliveryBin.position.y;
          position.z = deliveryBin.position.z;
          if (orientation.axis.L.x) {
            position.x +=
              -orientation.axis.L.x *
              ((deliveryBin.partDetails3D.params as Delivery3DParams).support.x +
                intake.partDetails3D.params.dx.x / 2 +
                intake.partDetails3D.params.padding.x);
            position.z =
              position.z +
              2 *
                this.geometry.dotProductVectors(
                  intake.partDetails3D.params.padding,
                  orientation.axis.T
                );
          } else {
            position.x =
              position.x +
              2 *
                this.geometry.dotProductVectors(
                  intake.partDetails3D.params.padding,
                  orientation.axis.T
                );
            position.z +=
              -orientation.axis.L.z *
              ((deliveryBin.partDetails3D.params as Delivery3DParams).support.z +
                intake.partDetails3D.params.dx.z / 2 +
                intake.partDetails3D.params.padding.z);
          }

          const angle = new THREE.Vector3(1, 0, 0).angleTo(orientation.axis.T);
          intake.orientation = new THREE.Vector3(0, angle, 0);
        }
        else  if(intakeCollisions.length > 0) {
          intakeCollisions.forEach((colision) => {
            let colidingEquipment;
            if(colision.firstEquipment.type === PartTypeEnum.INTAKE){
              colidingEquipment = colision.secondEquipment;
            }
            else{
              colidingEquipment = colision.firstEquipment;
            }

            switch(colidingEquipment.type){
              case PartTypeEnum.BUFFER_BIN: {
                  position.x += (DEFAULT_TILE_SIZE * orientation.axis.T.x) - (DEFAULT_TILE_SIZE / 4 * orientation.axis.L.x);
                  position.z += (DEFAULT_TILE_SIZE * orientation.axis.T.z) - (DEFAULT_TILE_SIZE / 4 * orientation.axis.L.z);
                 break;
              }
              case PartTypeEnum.SILO_BIN: {
                const mainElevator = nodes.find((n) => n.type === PartTypeEnum.ELEVATOR && n.area === PartAreaEnum.STORAGE);
                const positionRelativeToElevator =
                  this.geometry.multiplyVectors(
                    orientation.axis.T,
                    new THREE.Vector3().copy(
                      this.geometry.subtractVectors(intake.position, mainElevator.position))
                  );

                position.x = colidingEquipment.position.x +
                (Math.sign(positionRelativeToElevator.x) *
                    (
                      + colidingEquipment.partDetails3D.params.dx.x / 2
                      + DEFAULT_TILE_SIZE / 2
                    )
                );
                position.z = colidingEquipment.position.z +
                      (Math.sign(positionRelativeToElevator.z) *
                          (
                            + colidingEquipment.partDetails3D.params.dx.z / 2
                            + DEFAULT_TILE_SIZE / 2
                          )
                      );
                break;
              }
              case PartTypeEnum.CLEANER:{}
              case PartTypeEnum.DELIVERY:{
                const deliveryBin = nodes.find((n) => n.type === PartTypeEnum.DELIVERY);
                const offSet = this.geometry.addVectors(deliveryBin.position,
                  this.geometry.multiplyScalarVector(orientation.axis.L, -DEFAULT_TILE_SIZE));

                orientation.axis.L.x ? position.x = offSet.x : position.z = offSet.z;
                break;
              }
            }
          })
        }


      // recompute intake orientation

      intake.position = position;
      this.equipmentGenerator
        .getEquipmentMetallicHousing(
          intake.partDetails3D.params.dx,
          intake.partDetails3D.params.color,
          intake.partDetails3D.params.name
        )
        .subscribe((i: Part3D) => {
          i.part.name = intake.partDetails3D.params.name;
          i.part.position.x = intake.position.x;
          i.part.position.y = intake.position.y;
          i.part.position.z = intake.position.z;
          i.part.rotateY(intake.orientation.y);
          intake.part3D = i;

          // TODO: sometimes it doesn't find a closest tile => tile.name is undefined. CHECK THIS ASAP!
          const tile = this.helper.findClosestTile(tiles, intake);
          intake.tileName = tile.name;
          sceneObjects$.next({
            part3DNode: intake,
            next: SceneGeneratorEnum.POSITION_INTAKE_RECLAIMING_CONVEYORS,
          });
        });
    } else {
      // TODO: sometimes it doesn't find a closest tile => tile.name is undefined. CHECK THIS ASAP!
      const tile = this.helper.findClosestTile(tiles, intake);
      intake.tileName = tile.name;
      sceneObjects$.next({
        part3DNode: undefined,
        next: SceneGeneratorEnum.POSITION_INTAKE_RECLAIMING_CONVEYORS,
      });
    }
  }

  positionIntakeReclaimingConveyors(
    intake: Part3DNode,
    nodes: Part3DNode[],
    orientation: SiloGroupOrientation,
    sceneObjects$: Subject<SceneGeneratorEvent>,
    nextAction?: SceneGeneratorEnum
  ): void {
    const RADIUS = 3;
    const initialConveyorOrientation = new THREE.Vector3(1, 0, 0);

    // find main elevator
    const mainElevator = nodes.find(
      (n) => n.area === PartAreaEnum.STORAGE && n.type === PartTypeEnum.ELEVATOR
    );
    const mainElevatorPosition = new THREE.Vector3(
      mainElevator.position.x +
        orientation.axis.L.x * (ELEVATOR_BASE_LENGTH / 2),
      0,
      mainElevator.position.z +
        orientation.axis.L.z * (ELEVATOR_BASE_LENGTH / 2)
    );

    // position intake reclaiming conveyor beneath the intake (longitudinal wrt the intake)
    const intakePosition = new THREE.Vector3(
      intake.position.x,
      0,
      intake.position.z
    );
    const longitudinalDistance = this.geometry.dotProductVectors(
      intake.partDetails3D.params.dx,
      orientation.axis.L
    );
    const transversalDistance = this.geometry.dotProductVectors(
      intake.partDetails3D.params.dx,
      orientation.axis.T
    );
    let lengthConveyor1 = 0;
    let angleConveyor1 = 0;
    let startingPosition: THREE.Vector3;
    let d3: number;
    let d4: number;
    let v2: THREE.Vector3;

    d3 = this.geometry.dotProductVectors(
      CHAIN_CONVEYOR_PARAMS.padding,
      orientation.axis.T
    );
    d4 = this.geometry.dotProductVectors(
      intake.partDetails3D.params.padding,
      orientation.axis.T
    );
    v2 = this.geometry.multiplyScalarVector(
      orientation.axis.T,
      longitudinalDistance
    );
    startingPosition = this.geometry.addVectors(intakePosition, v2);
    lengthConveyor1 =
      Math.abs(longitudinalDistance) + Math.abs(d3) + Math.abs(d4);
    // compute conveyor orientation
    const v00 = this.geometry.subtractVectors(startingPosition, intakePosition);
    const v03 = new THREE.Vector3(1, 0, 0);
    angleConveyor1 = this.geometry.compute2DAngle(v00, v03);

    // compute conveyor height
    const h1 =
      intake.position.y -
      CHAIN_CONVEYOR_PARAMS.padding.y -
      0.1;

    let conveyorStartPosition: number;
    const relativeBinIntakePosition = this.geometry.subtractVectors(
      intake.position,
      orientation.bin.position
    );
    if (relativeBinIntakePosition.x < 0) {
      conveyorStartPosition = this.geometry.subtractVectors(
        intake.position,
        new THREE.Vector3(0.75 * lengthConveyor1, 0, 0)
      );
    } else {
      conveyorStartPosition = this.geometry.addVectors(
        intake.position,
        new THREE.Vector3(0.75 * lengthConveyor1, 0, 0)
      );
      angleConveyor1 = Math.PI;
    }
    // create reclaiming conveyor
    this.createChainConveyor(
      this.siloPlantConfig.intakeTransversalConveyorId,
      'Intake reclaiming conveyor #1',
      nodes,
      conveyorStartPosition,
      lengthConveyor1,
      h1,
      angleConveyor1,
      1,
      [0],
      0,
      PartAreaEnum.INTAKE,
      sceneObjects$
    );

    // position intake reclaiming conveyors from intake to main elevator
    // get virtual node used to create a corner connection via two conveyors (if needed)
    const corner = this.geometry.computeIntermediaryCorner(
      mainElevatorPosition,
      intakePosition,
      orientation.axis.L
    );
    const intakeToElevatorDirection = this.geometry.normalize(
      this.geometry.subtractVectors(mainElevator.position, intake.position)
    );
    if (
      this.geometry.isPointWithinRadius(mainElevatorPosition, corner, RADIUS) ||
      this.geometry.alongSameAxis(intakeToElevatorDirection, orientation.axis.L, true)
    ) {
      // compute conveyor length
      const lengthConveyor2 = intakePosition.distanceTo(mainElevatorPosition) -
                              Math.max(mainElevator.partDetails3D.params.dx.x, mainElevator.partDetails3D.params.dx.z) -
                              CHAIN_CONVEYOR_PARAMS.padding.y;

      // compute conveyor orientation
      const v20 = this.geometry.subtractVectors(mainElevatorPosition, intakePosition);
      v20.y = 0;
      const v21 = this.geometry.multiplyScalarVector(
        v20,
        this.geometry.getNonZeroComponentVector(orientation.axis.L)
      );
      const v22 = this.geometry.multiplyScalarVector(
        v21,
        Math.sign(this.geometry.getNonZeroComponentVector(v20))
      );
      const v23 = new THREE.Vector3(1, 0, 0);
      const angleConveyor2 = this.geometry.compute2DAngle(v22, v23);

      // compute conveyor height
      const h2 = h1 - BELT_CONVEYOR_PARAMS.padding.y;

      // add conveyor
      if (nextAction === undefined) {
        nextAction = SceneGeneratorEnum.CONNECT_EQUIPMENTS;
      }
      this.createBeltConveyor(
        this.siloPlantConfig.intakeLongitudinalConveyorId,
        'Intake reclaiming conveyor #2',
        nodes,
        intakePosition,
        lengthConveyor2,
        h2,
        angleConveyor2,
        0,
        PartAreaEnum.INTAKE,
        sceneObjects$,
        nextAction
      );
    } else {
      // position conveyor to corner point
      // compute conveyor length
      const lengthConveyor2 = intakePosition.distanceTo(corner);

      // compute conveyor orientation
      const v20 = this.geometry.subtractVectors(corner, intakePosition);
      v20.y = 0;
      const angleConveyor2 = this.geometry.compute2DAngle(
        v20,
        initialConveyorOrientation
      );

      // compute conveyor height
      const h2 = h1 - BELT_CONVEYOR_PARAMS.padding.y;

      // add conveyor
      const intermediaryConveyorId =
        this.equipmentGraph._generateRandomId(nodes);
      this.siloPlantConfig.equipmentGraph.addRelation(
        this.siloPlantConfig.intakeTransversalConveyorId,
        intermediaryConveyorId
      );
      this.siloPlantConfig.equipmentGraph.addRelation(
        intermediaryConveyorId,
        this.siloPlantConfig.intakeLongitudinalConveyorId
      );
      this.siloPlantConfig.equipmentGraph.removeRelation(
        this.siloPlantConfig.intakeTransversalConveyorId,
        this.siloPlantConfig.intakeLongitudinalConveyorId
      );
      this.createBeltConveyor(
        intermediaryConveyorId,
        'Intake reclaiming conveyor #2 (intermediary)',
        nodes,
        intakePosition,
        lengthConveyor2,
        h2,
        angleConveyor2,
        0,
        PartAreaEnum.INTAKE,
        sceneObjects$
      );
      // position conveyor from corner to main elevator
      // compute conveyor length
      let l3 = corner.distanceTo(mainElevatorPosition) - 0.5;
      if(orientation.axis.L.x){
        if(mainElevator.position.x < intake.position.x){
          l3 -= (Math.max(mainElevator.partDetails3D.params.dx.x, mainElevator.partDetails3D.params.dx.z) + CHAIN_CONVEYOR_PARAMS.padding.y / 2);
        }
        else{
          //do nothing
        }
      }
      else{
        if(mainElevator.position.z < intake.position.z){
          l3 -= (Math.max(mainElevator.partDetails3D.params.dx.x, mainElevator.partDetails3D.params.dx.z) + CHAIN_CONVEYOR_PARAMS.padding.y / 2);
        }
        else{
          //do nothing
        }
      }

      // compute conveyor orientation
      const v30 = this.geometry.subtractVectors(mainElevatorPosition, corner);
      v30.y = 0;
      const angleConveyor3 = this.geometry.compute2DAngle(
        v30,
        initialConveyorOrientation
      );

      // compute conveyor height
      const h3 = ELEVATOR_PARAMS.depth.y + ELEVATOR_MOTOR_HEIGHT;

      // add conveyor
      if (nextAction === undefined) {
        nextAction = SceneGeneratorEnum.CONNECT_EQUIPMENTS;
      }
      this.createBeltConveyor(
        this.siloPlantConfig.intakeLongitudinalConveyorId,
        'Intake reclaiming conveyor #3',
        nodes,
        corner,
        l3,
        h3,
        angleConveyor3,
        0,
        PartAreaEnum.INTAKE,
        sceneObjects$,
        nextAction
      );
    }
  }

  positionMainElevator(
    nodes: Part3DNode[],
    orientation: SiloGroupOrientation,
    sceneObjects$: Subject<SceneGeneratorEvent>
  ): void {
    const mainElevator = nodes.filter(
      (n) => n.type === PartTypeEnum.ELEVATOR
    )[0] as Part3DNode;

    mainElevator.position = new THREE.Vector3().copy(mainElevator.position);
    let distanceToRefBin =
      mainElevator.position.distanceTo(orientation.bin.position) / 2;
    distanceToRefBin -=
      orientation.bin.partDetails3D.params.dx.x / 2 - distanceToRefBin;

    // compute elevator orientation wrt OY
    const angle = -this.geometry.compute2DAngle(ELEVATOR_DEFAULT_ORIENTATION, orientation.axis.L);

    mainElevator.position.y = MAIN_ELEVATOR_PARAMS.depth.y;
    if (orientation.axis.L.x) {
      mainElevator.position.x =
        mainElevator.position.x +
        orientation.axis.L.x *
          (distanceToRefBin - MAIN_ELEVATOR_PARAMS.padding.x);
    } else {
      mainElevator.position.z =
        mainElevator.position.z +
        orientation.axis.L.z *
          (distanceToRefBin - MAIN_ELEVATOR_PARAMS.padding.z);
    }

    /** elevator height depends on silo bins that have different params now => update needed */
    // get bins
    const bins = nodes.filter((n) => n.type === PartTypeEnum.SILO_BIN);

    // get reference bin

    mainElevator.partDetails3D.params.dx.y =
      this.getBinsMaxHeight(bins) +
      ELEVATOR_PARAMS.padding.y +
      2 * ELEVATOR_MOTOR_HEIGHT;

    mainElevator.orientation.y = angle;
    this.equipmentGenerator
      .getElevator(mainElevator.partDetails3D.params as Elevator3DParams)
      .subscribe((e) => {
        // position elevator

        e.part.position.x = mainElevator.position.x;
        e.part.position.y = mainElevator.position.y;
        e.part.position.z = mainElevator.position.z;

        // rotate elevator
        // const quaternion = new THREE.Quaternion();
        // quaternion.setFromAxisAngle(new THREE.Vector3(0, 1, 0), angle);
        // e.part.quaternion.multiply(quaternion);
        e.part.rotation.y = angle;

        // update elevator params
        mainElevator.partDetails3D.params.dx = new THREE.Vector3(
          e.params.dx.x,
          e.params.dx.y,
          e.params.dx.z
        );

        // rename part
        e.part.name = mainElevator.partDetails3D.params.name;

        mainElevator.part3D = e;
        mainElevator.part3D.part.updateWorldMatrix(true, true);
        if(mainElevator.physicsBody !== undefined){
          mainElevator.sync = SyncStatesEnum.SYNC_NEEDED;
        }
        sceneObjects$.next({
          part3DNode: mainElevator,
          next: SceneGeneratorEnum.POSITION_CLEANER_ELEVATOR,
        });
      });
  }

  getBinsMaxHeight(bins: Array<Part3DNode>): number{

    const maxHeight = bins.reduce((max, bin) => {
      return bin.partDetails3D.params.dx.y > max ? bin.partDetails3D.params.dx.y : max;
    }, bins[0].partDetails3D.params.dx.y);

    return maxHeight
  }
  // todo: fix this to work with silo plant config
  positionStorageLoadingConveyors(
    nodes: Part3DNode[],
    orientation: SiloGroupOrientation,
    sceneObjects$: Subject<SceneGeneratorEvent>,
    nextAction?: SceneGeneratorEnum
  ): void {
    let lastBinRowLeft = 0;
    let lastBinRowRight = 0;

    // get bins
    const bins = nodes.filter((n) => n.type === PartTypeEnum.SILO_BIN);
    const mainElevator = nodes.filter(
      (n) => n.type === PartTypeEnum.ELEVATOR
    )[0];

    const transversalHeight =
          mainElevator.partDetails3D.params.dx.y +
          mainElevator.position.y -
          2 * CHAIN_CONVEYOR_PARAMS.padding.y -
          ELEVATOR_MOTOR_HEIGHT;
    const longitudinalHeight = transversalHeight - 2 * CHAIN_CONVEYOR_PARAMS.padding.y;
    const maxPerRow = Math.max(
      ...bins.map((b) => b.partDetails3D.binNumberPerRow)
    );
    const maxRows = Math.max(...bins.map((b) => b.partDetails3D.binRowNum));

    // get reference bin
    const referenceBin = orientation.bin;

    referenceBin.position = new THREE.Vector3(
      referenceBin.position.x,
      referenceBin.position.y,
      referenceBin.position.z
    );

    const padding = orientation.axis.L.x
      ? CHAIN_CONVEYOR_PARAMS.padding.x
      : CHAIN_CONVEYOR_PARAMS.padding.z;

    // get last bin to the left of the reference bin
    let transversalBins = [];
    let oppositeTransversalBins = [];

    if (bins.length > 1) {
      transversalBins = bins.filter(
        (b) => b.partDetails3D.isLeftMainAxis === true
      );
      oppositeTransversalBins = bins.filter(
        /* != 0 Only when elevator is placed in the middle */
        (b) => b.partDetails3D.isLeftMainAxis === false
      );
    }

    if (transversalBins.length > 0 && maxRows !== 0) {
      //when only 1 row of bins dont add transversal
      lastBinRowLeft = Math.max(
        ...transversalBins.map((b) => b.partDetails3D.binRowNum)
      );
      const lastBinLeft = bins.find(
        (b) =>
          b.partDetails3D.binRowNum === lastBinRowLeft &&
          b.partDetails3D.binNumberPerRow === 0
      );

      // add transversal conveyor from reference to the last bin on the left
      if (lastBinLeft) {
        lastBinLeft.position = new THREE.Vector3(
          lastBinLeft.position.x,
          lastBinLeft.position.y,
          lastBinLeft.position.z
        );

        // determine conveyor orientation wrt to Y
        const transversalAngle: number = -this.geometry.compute2DAngle(CONVEYOR_DEFAULT_ORIENTATION, orientation.axis.T);

        // determine conveyor length
        const length =
          lastBinLeft.position.distanceTo(referenceBin.position) +
          padding +
          BELT_CONVEYOR_INTAKE_LENGTH;
        // determine conveyor height


        // determine number of outlets
        const nOutlets = this.getNumberOfTransversalOutlets(transversalBins);

        // determine distance between outlets
        const outletDistances: Array<number> = new Array(nOutlets).fill(0);
        for (let i = 0; i < nOutlets - 1; i++) {
          outletDistances[i] = length / nOutlets; // needs updates
        }
        outletDistances[nOutlets - 1] =
          length - outletDistances.reduce((total, num) => total + num, 0);

        // get start position
        let conveyorPosition = new THREE.Vector3(
          mainElevator.position.x,
          0,
          mainElevator.position.z
        );
        const displacement = this.helper.positionEquipmentByAngle(
          mainElevator.partDetails3D.params.dx.y +
            mainElevator.position.y -
            transversalHeight -
            ELEVATOR_MOTOR_HEIGHT,
          45
        );

        if (orientation.axis.L.x) {
          conveyorPosition.x +=
            orientation.axis.L.x * (displacement + ELEVATOR_BASE_LENGTH / 2);
        } else {
          conveyorPosition.z +=
            orientation.axis.L.z * (displacement + ELEVATOR_BASE_LENGTH / 2);
        }

        if (orientation.axis.T.x) {
          conveyorPosition.x +=
            -orientation.axis.T.x *
            (CHAIN_CONVEYOR_TENSIONING_STATION_LENGTH +
              BELT_CONVEYOR_INTAKE_LENGTH);
        } else {
          conveyorPosition.z +=
            -orientation.axis.T.z *
            (CHAIN_CONVEYOR_TENSIONING_STATION_LENGTH +
              BELT_CONVEYOR_INTAKE_LENGTH);
        }
        this.createChainConveyor(
          this.siloPlantConfig.siloLoadingTransversalConveyorIds[0],
          'Storage loading transversal conveyor #1',
          nodes,
          conveyorPosition,
          length,
          transversalHeight,
          transversalAngle,
          nOutlets,
          outletDistances,
          0,
          PartAreaEnum.STORAGE,
          sceneObjects$
        );
      }
    }

    // add transversal conveyor from reference to the last bin on the right
    // get last bin to the right of the reference bin

    if (oppositeTransversalBins.length > 1) {
      lastBinRowRight = Math.max(
        ...oppositeTransversalBins.map((b) => b.partDetails3D.binRowNum)
      );

      let lastBinRight: Part3DNode;
      if (bins.length > 1) {
        lastBinRight = bins.find(
          (b) =>
            b.partDetails3D.binRowNum === lastBinRowRight &&
            b.partDetails3D.binNumberPerRow === 0
        );
      } else {
        lastBinRight = orientation.bin;
      }

      if (lastBinRight) {
        lastBinRight.position = new THREE.Vector3(
          lastBinRight.position.x,
          lastBinRight.position.y,
          lastBinRight.position.z
        );

        // determine conveyor orientation
        const transversalAngle: number = this.geometry.compute2DAngle(CONVEYOR_DEFAULT_ORIENTATION, orientation.axis.T);

        // determine conveyor length
        const length =
          lastBinRight.position.distanceTo(referenceBin.position) + padding;

        // determine number of outlets
        const nOutlets = this.getNumberOfTransversalOutlets(
          oppositeTransversalBins
        );

        // determine distance between outlets
        const outletDistances: Array<number> = new Array(nOutlets).fill(0);
        for (let i = 0; i < nOutlets - 1; i++) {
          outletDistances[i] = length / nOutlets;
        }
        outletDistances[nOutlets - 1] =
          length - outletDistances.reduce((total, num) => total + num, 0);
        // get start position
        const position = new THREE.Vector3(
          referenceBin.position.x,
          referenceBin.position.y,
          referenceBin.position.z
        );
        if (orientation.axis.L.x) {
          position.x +=
            (-orientation.axis.L.x * referenceBin.partDetails3D.params.dx.x) /
            2;
        } else {
          position.z +=
            (-orientation.axis.L.z * referenceBin.partDetails3D.params.dx.z) /
            2;
        }
        this.createChainConveyor(
          this.siloPlantConfig.siloLoadingTransversalConveyorIds[1],
          'Storage loading transversal conveyor #2',
          nodes,
          position,
          length,
          transversalHeight,
          transversalAngle,
          nOutlets,
          outletDistances,
          0,
          PartAreaEnum.STORAGE,
          sceneObjects$
        );
      }
    }

    // add longitudinal conveyors
    const numberOfBinRows = Math.max(lastBinRowLeft, lastBinRowRight) + 1;
    for (let i = 0; i < numberOfBinRows; i++) {
      // get reference bin
      let startBin: Part3DNode;
      let lastBinPerRow: Part3DNode;
      let nOutlets = 0;
      let angle: number;

      if (bins.length > 1) {
        startBin = bins.find(
          (b) =>
            b.partDetails3D.binRowNum === i &&
            b.partDetails3D.binNumberPerRow === 0
        );
        const binsPerRow = bins.filter((b) => b.partDetails3D.binRowNum === i);
        const lastBinPerRowNumber = Math.max(
          ...binsPerRow.map((b) => b.partDetails3D.binNumberPerRow)
        );
        lastBinPerRow = bins.find(
          (b) =>
            b.partDetails3D.binRowNum === i &&
            b.partDetails3D.binNumberPerRow === lastBinPerRowNumber
        );
        nOutlets = binsPerRow.length;

        // determine conveyor orientation wrt to Y
        if (orientation.axis.L.x) {
          angle = Math.atan2(
            lastBinPerRow.position.z - startBin.position.z,
            lastBinPerRow.position.x - startBin.position.x
          );
        } else {
          angle = Math.atan2(
            -lastBinPerRow.position.z + startBin.position.z,
            -lastBinPerRow.position.x + startBin.position.x
          );
        }
      } else {
        angle = this.geometry.compute2DAngle(
          orientation.axis.L,
          CONVEYOR_DEFAULT_ORIENTATION
        );
        startBin = lastBinPerRow = orientation.bin;
        nOutlets = 1;
      }
      const longitudinalAngle: number = -this.geometry.compute2DAngle(CONVEYOR_DEFAULT_ORIENTATION, orientation.axis.L);

      startBin.position = new THREE.Vector3(
        startBin.position.x,
        startBin.position.y,
        startBin.position.z
      );

      // get last bin to the left of the reference bin

      lastBinPerRow.position = new THREE.Vector3(
        lastBinPerRow.position.x,
        lastBinPerRow.position.y,
        lastBinPerRow.position.z
      );

      // determine conveyor length
      const length =
        lastBinPerRow.position.distanceTo(startBin.position) +
        lastBinPerRow.part3D.params.dx.x / 2 +
        padding;

      // determine distance between outlets
      const outletDistances: Array<number> = new Array(nOutlets).fill(0);
      outletDistances[0] = startBin.partDetails3D.params.dx.x / 2; // add only the radius of the first bin for the first outlet
      for (let i = 1; i < nOutlets - 1; i++) {
        outletDistances[i] = padding + startBin.partDetails3D.params.dx.x;
      }
      if (nOutlets > 1)
        outletDistances[nOutlets - 1] =
          length - outletDistances.reduce((total, num) => total + num, 0); // last outlet distance from the previous outlet

      // get start position
      const position = new THREE.Vector3(
        startBin.position.x,
        startBin.position.y,
        startBin.position.z
      );
      if (orientation.axis.L.x) {
        position.x +=
          -orientation.axis.L.x *
          (startBin.partDetails3D.params.dx.x / 2 +
            0.5*CHAIN_CONVEYOR_TENSIONING_STATION_LENGTH);
      } else {
        position.z +=
          -orientation.axis.L.z *
          (startBin.partDetails3D.params.dx.z / 2 +
            0.5*CHAIN_CONVEYOR_TENSIONING_STATION_LENGTH);
      }

      // add longitudinal conveyor
      const cmap = this.siloPlantConfig.siloConveyorMapping.find(
        (m) =>
          m.location === ConveyorLocation.LOADING &&
          m.binRowNum === startBin.partDetails3D.binRowNum
      );
      let conveyorId = this.equipmentGraph._generateRandomId(nodes);
      if (cmap) {
        conveyorId = cmap.conveyorId;
      }
      if (i === numberOfBinRows - 1) {
        if (nextAction === undefined) {
          nextAction = SceneGeneratorEnum.POSITION_STORAGE_RECLAIMING_CONVEYORS;
        }
        this.createChainConveyor(
          conveyorId,
          `Storage loading longitudinal conveyor #${i + 1}`,
          nodes,
          position,
          length,
          longitudinalHeight,
          longitudinalAngle,
          nOutlets,
          outletDistances,
          0,
          PartAreaEnum.STORAGE,
          sceneObjects$,
          nextAction
        );
      } else {
        this.createChainConveyor(
          conveyorId,
          `Storage loading longitudinal conveyor #${i + 1}`,
          nodes,
          position,
          length,
          longitudinalHeight,
          angle,
          nOutlets,
          outletDistances,
          0,
          PartAreaEnum.STORAGE,
          sceneObjects$
        );
      }
    }
  }

  getNumberOfTransversalOutlets(bins: Part3DNode[]): number {
    let numberOfOutlets = 0;
    let binRowNumber = -1;
    const binsSortedByRowNum = bins.sort(
      (bin1, bin2) =>
        bin1.partDetails3D.binRowNum - bin2.partDetails3D.binRowNum
    );
    for (const bin of binsSortedByRowNum) {
      if (binRowNumber !== bin.partDetails3D.binRowNum) {
        binRowNumber = bin.partDetails3D.binRowNum;
        numberOfOutlets += 1;
      }
    }
    return numberOfOutlets;
  }

  // todo: fix this to work with silo plant config
  positionStorageReclaimingConveyors(
    nodes: Part3DNode[],
    orientation: SiloGroupOrientation,
    sceneObjects$: Subject<SceneGeneratorEvent>,
    nextAction?: SceneGeneratorEnum
  ): void {
    let lastBinRowLeft = 0;
    let lastBinRowRight = 0;

    // get bins
    const bins = nodes.filter((n) => n.type === PartTypeEnum.SILO_BIN);
    /**
     * MAXPERROW = how many silo's on the row
     * MARROWS = how many rows
     */
    const maxPerRow = Math.max(
      ...bins.map((b) => b.partDetails3D.binNumberPerRow)
    );
    const maxRows = Math.max(...bins.map((b) => b.partDetails3D.binRowNum));
    // get reference bin
    const referenceBin = orientation.bin;

    referenceBin.position = new THREE.Vector3(
      referenceBin.position.x,
      referenceBin.position.y,
      referenceBin.position.z
    );

    const padding = orientation.axis.L.x
      ? 2 * BELT_CONVEYOR_PARAMS.padding.x
      : 2 * BELT_CONVEYOR_PARAMS.padding.z;

    // get last bin to the left of the reference bin
    let transversalBins = [];
    let oppositeTransversalBins = [];

    if (bins.length > 1) {
      transversalBins = bins.filter(
        (b) => b.partDetails3D.isLeftMainAxis === true
      );
      oppositeTransversalBins = bins.filter(
        (b) => b.partDetails3D.isLeftMainAxis === false
      );
    }
    if (transversalBins.length > 1 && maxRows !== 0) {
      //when only 1 row of bins dont add transversal
      lastBinRowLeft = Math.max(
        ...transversalBins.map((b) => b.partDetails3D.binRowNum)
      );
      const lastBinLeft = bins.find(
        (b) =>
          b.partDetails3D.binRowNum === lastBinRowLeft &&
          b.partDetails3D.binNumberPerRow === 0
      );

      // add transversal conveyor from reference to the last bin on the left
      if (lastBinLeft) {

        // determine conveyor length
        const length =
          referenceBin.position.distanceTo(lastBinLeft.position) +
          referenceBin.partDetails3D.params.dx.x / 4 -
          padding / 2;

        // determine conveyor height
        const height =
          lastBinLeft.position.y -
          2 * BELT_CONVEYOR_PARAMS.padding.y;

        // determine conveyor orientation
        const transversalAngle: number = -this.geometry.compute2DAngle(CONVEYOR_DEFAULT_ORIENTATION, this.geometry.inverseVector(orientation.axis.T));


        // get start position
        const position = new THREE.Vector3().copy(this.geometry.addMultipleVectors([lastBinLeft.position,
          this.geometry.multiplyScalarVector(orientation.axis.T,
            BELT_CONVEYOR_TENSIONING_LENGTH + BELT_CONVEYOR_INTAKE_LENGTH / 2),
            this.geometry.multiplyScalarVector(orientation.axis.L, 0.1)]));
        if (orientation.axis.L.x) {
          position.x +=
            (-orientation.axis.L.x * lastBinLeft.partDetails3D.params.dx.x) / 2;
        } else {
          position.z +=
            (-orientation.axis.L.z * lastBinLeft.partDetails3D.params.dx.z) / 2;
        }
        this.createBeltConveyor(
          this.siloPlantConfig.siloReclaimingTransversalConveyorIds[0],
          'Storage reclaiming transversal conveyor #1',
          nodes,
          position,
          length,
          height,
          transversalAngle,
          0,
          PartAreaEnum.STORAGE,
          sceneObjects$
        );
      }
    }

    // add transversal conveyor from reference to the last bin on the right
    // get last bin to the right of the reference bin
    if (oppositeTransversalBins.length > 1) {
      lastBinRowRight = Math.max(
        ...oppositeTransversalBins.map((b) => b.partDetails3D.binRowNum)
      );
      const lastBinRight = bins.find(
        (b) =>
          b.partDetails3D.binRowNum === lastBinRowRight &&
          b.partDetails3D.binNumberPerRow === 0
      );

      if (lastBinRight) {
        lastBinRight.position = new THREE.Vector3(
          lastBinRight.position.x,
          lastBinRight.position.y,
          lastBinRight.position.z
        );

        // determine conveyor length
        const length =
          referenceBin.position.distanceTo(lastBinRight.position) +
          referenceBin.partDetails3D.params.dx.x / 4 -
          padding / 2;

        // determine conveyor height
        const height =
          lastBinRight.position.y -
          2 * BELT_CONVEYOR_PARAMS.padding.y;

        // determine conveyor orientation
        const transversalAngle: number = -this.geometry.compute2DAngle(CONVEYOR_DEFAULT_ORIENTATION, orientation.axis.T);


        // get start position
        const position = new THREE.Vector3(
          lastBinRight.position.x,
          lastBinRight.position.y,
          lastBinRight.position.z
        );
        if (orientation.axis.L.x) {
          position.x +=
            (-orientation.axis.L.x * lastBinRight.partDetails3D.params.dx.x) /
            2;
        } else {
          position.z +=
            (-orientation.axis.L.z * lastBinRight.partDetails3D.params.dx.z) /
            2;
        }
        this.createBeltConveyor(
          this.siloPlantConfig.siloReclaimingTransversalConveyorIds[1],
          'Storage reclaiming transversal conveyor #2',
          nodes,
          position,
          length,
          height,
          transversalAngle,
          0,
          PartAreaEnum.STORAGE,
          sceneObjects$
        );
      }
    }

    // add longitudinal conveyors
    const numberOfBinRows = Math.max(lastBinRowLeft, lastBinRowRight) + 1;
    for (let i = 0; i < numberOfBinRows; i++) {
      // get reference bin
      let startBin: Part3DNode;
      let lastBinPerRow: Part3DNode;
      let nOutlets = 0;
      let longitudinalAngle: number;

      if (bins.length > 1) {
        startBin = bins.find(
          (b) =>
            b.partDetails3D.binRowNum === i &&
            b.partDetails3D.binNumberPerRow === 0
        );
        const binsPerRow = bins.filter((b) => b.partDetails3D.binRowNum === i);
        const lastBinPerRowNumber = Math.max(
          ...binsPerRow.map((b) => b.partDetails3D.binNumberPerRow)
        );
        lastBinPerRow = bins.find(
          (b) =>
            b.partDetails3D.binRowNum === i &&
            b.partDetails3D.binNumberPerRow === lastBinPerRowNumber
        );
        nOutlets = binsPerRow.length;
      } else {
        startBin = lastBinPerRow = orientation.bin;
        nOutlets = 1;
      }


      longitudinalAngle = Math.PI;
      if (orientation.axis.L.x === -1) {
        longitudinalAngle = 0;
      } else if (orientation.axis.L.z) {
        longitudinalAngle /= orientation.axis.L.z * 2;
      }
      startBin.position = new THREE.Vector3(
        startBin.position.x,
        startBin.position.y,
        startBin.position.z
      );

      // get last bin to the left of the reference bin
      lastBinPerRow.position = new THREE.Vector3(
        lastBinPerRow.position.x,
        lastBinPerRow.position.y,
        lastBinPerRow.position.z
      );

      // determine conveyor length
      const length =
        startBin.position.distanceTo(lastBinPerRow.position) +
        startBin.partDetails3D.params.dx.x / 2 +
        BELT_CONVEYOR_TENSIONING_LENGTH +
        BELT_CONVEYOR_INTAKE_LENGTH;

      // determine conveyor height
      // const height = lastBinPerRow.position.y - BELT_CONVEYOR_PARAMS.padding.y + BELT_CONVEYOR_PARAMS.depth.y;
      const height = lastBinPerRow.position.y - BELT_CONVEYOR_PARAMS.padding.y;

      // get start position
      let position = new THREE.Vector3(
        lastBinPerRow.position.x,
        lastBinPerRow.position.y,
        lastBinPerRow.position.z
      );
      if (orientation.axis.L.x) {
        position.x += orientation.axis.L.x * BELT_CONVEYOR_TENSIONING_LENGTH;
      } else {
        position.z += orientation.axis.L.z * BELT_CONVEYOR_TENSIONING_LENGTH;
      }

      // add longitudinal conveyor
      const cmap = this.siloPlantConfig.siloConveyorMapping.find(
        (m) =>
          m.location === ConveyorLocation.RECLAIMING &&
          m.binRowNum === startBin.partDetails3D.binRowNum
      );
      let conveyorId = this.equipmentGraph._generateRandomId(nodes);
      if (cmap) {
        conveyorId = cmap.conveyorId;
      }
      if (i === numberOfBinRows - 1) {
        if (nextAction === undefined) {
          nextAction = SceneGeneratorEnum.POSITION_DELIVERY_LOADING_CONVEYORS;
        }
        this.createBeltConveyor(
          conveyorId,
          `Storage reclaiming longitudinal conveyor #${i + 1}`,
          nodes,
          position,
          length,
          height,
          longitudinalAngle,
          0,
          PartAreaEnum.STORAGE,
          sceneObjects$,
          nextAction
        );
      } else {
        this.createBeltConveyor(
          conveyorId,
          `Storage reclaiming longitudinal conveyor #${i + 1}`,
          nodes,
          position,
          length,
          height,
          longitudinalAngle,
          0,
          PartAreaEnum.STORAGE,
          sceneObjects$
        );
      }
    }
  }

  positionDryerElevator(
    nodes: Part3DNode[],
    orientation: SiloGroupOrientation,
    sceneObjects$: Subject<SceneGeneratorEvent>
  ): void {
    // compute elevator position
    let dryerElevator = nodes.find((node) => node.type === PartTypeEnum.ELEVATOR && node.area === PartAreaEnum.DRYER);
    if(dryerElevator === undefined){
      dryerElevator = new Part3DNode(this.siloPlantConfig.dryerElevatorId);
    }

    // get main elevator
    const mainElevator = nodes.filter(
      (n) => n.type === PartTypeEnum.ELEVATOR
    )[0];

    const angle = mainElevator.orientation.y + Math.PI;

    dryerElevator.orientation = new THREE.Vector3(0, angle, 0);
    dryerElevator.type = PartTypeEnum.ELEVATOR;
    dryerElevator.area = PartAreaEnum.DRYER;
    dryerElevator.partDetails3D = new Part3DDetails();
    dryerElevator.partDetails3D.params = new Elevator3DParams(
      'Drying elevator',
      DRYER_ELEVATOR_PARAMS.color,
      DRYER_ELEVATOR_PARAMS.dx,
      DRYER_ELEVATOR_PARAMS.padding,
      DRYER_ELEVATOR_PARAMS.capacity,
      DRYER_ELEVATOR_PARAMS.depth
    );

    const bufferBin = nodes.find((node) => node.type === PartTypeEnum.BUFFER_BIN);
    const dryer = nodes.find((node) => node.type === PartTypeEnum.DRYER);

    if(bufferBin !== undefined && dryer !== undefined){
      const  maxHeight = Math.max(bufferBin.partDetails3D.params.dx.y, dryer.partDetails3D.params.dx.y);
      dryerElevator.partDetails3D.params.dx.y = maxHeight + ELEVATOR_PARAMS.padding.y  + ELEVATOR_MOTOR_HEIGHT;
    }
    else if(bufferBin !== undefined){
      dryerElevator.partDetails3D.params.dx.y = bufferBin.partDetails3D.params.dx.y + ELEVATOR_PARAMS.padding.y  + ELEVATOR_MOTOR_HEIGHT;
    }
    else if(dryer !== undefined){
      dryerElevator.partDetails3D.params.dx.y = dryer.partDetails3D.params.dx.y + ELEVATOR_PARAMS.padding.y  + ELEVATOR_MOTOR_HEIGHT;
    }

    this.equipmentGenerator
      .getElevator(dryerElevator.partDetails3D.params as Elevator3DParams)
      .subscribe((e) => {
        // position elevator

        e.part.position.copy(mainElevator.position);

        e.part.position.add(
          this.geometry.multiplyScalarVector(
            orientation.axis.T,
            DRYER_ELEVATOR_PARAMS.padding.x
          )
        );

        dryerElevator.position = e.part.position;

        //rotate elevator
        e.part.rotation.y = angle;

        // update elevator params
        dryerElevator.partDetails3D.params.dx = new THREE.Vector3(
          e.params.dx.x,
          e.params.dx.y,
          e.params.dx.z
        );

        // rename part
        e.part.name = dryerElevator.partDetails3D.params.name;

        dryerElevator.part3D = e;
        if(dryerElevator.physicsBody !== undefined){
          dryerElevator.sync = SyncStatesEnum.SYNC_NEEDED;
        }
        sceneObjects$.next({
          part3DNode: dryerElevator,
          next: SceneGeneratorEnum.POSITION_DRYER,
        });
      });
  }

  positionDryer(
    nodes: Part3DNode[],
    orientation: SiloGroupOrientation,
    sceneObjects$: Subject<SceneGeneratorEvent>
  ): void {
    let dryer = nodes.find((node) => node.type === PartTypeEnum.DRYER);
    if(dryer === undefined){
      dryer = new Part3DNode(this.siloPlantConfig.dryerId);
    }

    const dryerElevator = nodes.find(
      (n) => n.area === PartAreaEnum.DRYER && n.type === PartTypeEnum.ELEVATOR
    );

    let distance = new THREE.Vector3(0, 0, 0);

    distance = this.geometry.addMultipleVectors([distance,
      this.geometry.multiplyScalarVector(orientation.axis.L,
        (-DRYER_LENGTH / 1.75)),
      this.geometry.multiplyScalarVector(orientation.axis.T,
        (dryerElevator.partDetails3D.params.dx.x + DRYER_PARAMS.dx.z / 2 + DRYER_PARAMS.padding.z))
    ]);

    dryer.position.y = 0;
    dryer.position.x = dryerElevator.position.x + distance.x;
    dryer.position.z = dryerElevator.position.z + distance.z;

    const angle = -this.geometry.compute2DAngle(DRYER_DEFAULT_ORIENTATION, orientation.axis.L);
    // create dryer
    dryer.orientation = new THREE.Vector3(0, angle, 0);
    dryer.area = PartAreaEnum.DRYER;
    dryer.type = PartTypeEnum.DRYER;
    dryer.partDetails3D = new Part3DDetails();
    dryer.partDetails3D.params = new Dryer3DParams(
      'Dryer',
      DRYER_PARAMS.color,
      DRYER_PARAMS.dx,
      DRYER_PARAMS.padding,
      DRYER_PARAMS.capacity,
      DRYER_PARAMS.moistureReduction
    );

    this.equipmentGenerator
      .getDryer(dryer.partDetails3D.params as Dryer3DParams)
      .subscribe((p: Part3D) => {
         // rotate part
        p.part.rotation.y = dryer.orientation.y;

        // position part
        p.part.position.copy(dryer.position);

        // rename part
        p.part.name = dryer.partDetails3D.params.name;

        dryer.part3D = p;

        // if(dryer.physicsBody !== undefined){
        //   dryer.sync = SyncStatesEnum.SYNC_NEEDED;
        // }
        sceneObjects$.next({
          part3DNode: dryer,
          next: SceneGeneratorEnum.POSITION_BUFFER_BIN,
        });
      });
  }

  positionDryerBufferBins(
    nodes: Part3DNode[],
    orientation: SiloGroupOrientation,
    sceneObjects$: Subject<SceneGeneratorEvent>
  ): void {
    let bbin = nodes.find((node) => node.type === PartTypeEnum.BUFFER_BIN);
    if(bbin === undefined){
      bbin = new Part3DNode(this.siloPlantConfig.bufferBinId);
    }
    if(bbin.sync === SyncStatesEnum.SYNC_DONE){
      sceneObjects$.next({
        part3DNode: undefined,
        next: SceneGeneratorEnum.POSITION_STORAGE_LOADING_CONVEYORS,
      });
      return;
    }

    // find dryer
    const dryer = nodes.find(
      (n) => n.area === PartAreaEnum.DRYER && n.type === PartTypeEnum.DRYER
    );

    // compute distance between dryer and buffer bin
    // const distance = Math.min(dryer.partDetails3D.params.dx.x, dryer.partDetails3D.params.dx.z) +
    //                   mainElevator.position.distanceTo(dryer.position);


    // create buffer bin
    bbin.area = PartAreaEnum.DRYER;
    bbin.type = PartTypeEnum.BUFFER_BIN;
    bbin.orientation = new THREE.Vector3();
    bbin.partDetails3D = new Part3DDetails();
    bbin.partDetails3D.params = new Bin3DParams(
      'Buffer bin',
      BUFFER_BIN_PARAMS.color,
      BUFFER_BIN_PARAMS.dx,
      BUFFER_BIN_PARAMS.padding,
      undefined,
      1
    );
    this.equipmentGenerator
      .getBufferBin(bbin.partDetails3D.params as Bin3DParams)
      .subscribe((p) => {

        bbin.position.copy(this.geometry.addMultipleVectors([dryer.position,
          this.geometry.multiplyScalarVector(orientation.axis.L,
            (DRYER_INTAKE_OFFSET)),
          this.geometry.multiplyScalarVector(orientation.axis.T,
            (dryer.partDetails3D.params.dx.z + bbin.partDetails3D.params.dx.z / 2
            + BUFFER_BIN_PARAMS.padding.z))
        ]));
        // position part
        p.part.position.copy(bbin.position);

        // rename part
        p.part.name = bbin.partDetails3D.params.name;
        /** UPDATE BUFFER BIN PARAMS */
        bbin.part3D = p;
        bbin.partDetails3D.params.dx.x = p.params.dx.x;
        bbin.partDetails3D.params.dx.y = p.params.dx.y;
        bbin.partDetails3D.params.dx.z = p.params.dx.z;

        sceneObjects$.next({
          part3DNode: bbin,
          next: SceneGeneratorEnum.POSITION_STORAGE_LOADING_CONVEYORS,
        });
      });
  }

  positionDryerLoadingConveyors(
    nodes: Part3DNode[],
    orientation: SiloGroupOrientation,
    sceneObjects$: Subject<SceneGeneratorEvent>,
    nOutlets?: number,
    nextAction?: SceneGeneratorEnum
  ): void {
    const N_OUTLETS = 2;
    if (!nOutlets) {
      nOutlets = N_OUTLETS;
    }

    // find elevator
    const elevator = nodes.find(
      (n) => n.area === PartAreaEnum.DRYER && n.type === PartTypeEnum.ELEVATOR
    );
    elevator.position = new THREE.Vector3(
      elevator.position.x,
      elevator.position.y,
      elevator.position.z
    );

    // find buffer bin
    const bbin = nodes.find(
      (n) => n.area === PartAreaEnum.DRYER && n.type === PartTypeEnum.BUFFER_BIN
    );
    bbin.position = new THREE.Vector3(
      bbin.position.x,
      bbin.position.y,
      bbin.position.z
    );

    // find dryer
    const dryer = nodes.find(
      (n) => n.area === PartAreaEnum.DRYER && n.type === PartTypeEnum.DRYER
    );
    dryer.position = new THREE.Vector3(
      dryer.position.x,
      dryer.position.y,
      dryer.position.z
    );

    // compute start position
    const position = new THREE.Vector3(
      elevator.position.x,
      elevator.position.y,
      elevator.position.z
    );
    if (orientation.axis.L.x) {
      position.x = bbin.position.x;
    } else {
      position.z = bbin.position.z;
    }

    // compute conveyor length
    const length =
      position.distanceTo(bbin.position) +
      CHAIN_CONVEYOR_TENSIONING_STATION_LENGTH;

    // compute conveyor height
    const height =
      elevator.partDetails3D.params.dx.y +
      (elevator.partDetails3D.params as Elevator3DParams).depth.y -
      DRYER_LOADING_CONVEYOR_PARAMS.padding.y -
      ELEVATOR_MOTOR_HEIGHT;

    // compute conveyor angle
    const v00 = this.geometry.subtractVectors(bbin.position, position);
    v00.y = 0;
    const v01 = this.geometry.multiplyScalarVector(
      v00,
      this.geometry.getNonZeroComponentVector(orientation.axis.T)
    );
    const v02 = this.geometry.multiplyScalarVector(
      v01,
      Math.sign(this.geometry.getNonZeroComponentVector(v00))
    );
    const v03 = new THREE.Vector3(1, 0, 0);
    const angle = this.geometry.compute2DAngle(v02, v03); // Math.atan2(v03.z * v02.x - v03.x * v02.z, v03.x * v02.x + v03.z * v02.z);
    // distance between outlets
    const outletDistances: Array<number> = new Array(nOutlets).fill(0);
    outletDistances[0] = position.distanceTo(dryer.position);
    for (let i = 1; i < nOutlets - 1; i++) {
      outletDistances[i] = position.distanceTo(dryer.position); // NEEDS UPDATE - NOW THERE ARE ONLY 2 OUTLETS
    }
    outletDistances[nOutlets - 1] =
      length - outletDistances.reduce((total, num) => total + num, 0);

    // create conveyor
    if (nextAction === undefined) {
      nextAction = SceneGeneratorEnum.POSITION_DRYER_RECLAIMING_CONVEYORS;
    }
    this.createChainConveyor(
      this.siloPlantConfig.dryerLoadingConveyorId,
      'Dryer loading conveyor',
      nodes,
      position,
      length,
      height,
      angle,
      nOutlets,
      outletDistances,
      0,
      PartAreaEnum.DRYER,
      sceneObjects$,
      nextAction
    );
  }

  positionDryerReclaimingConveyors(
    nodes: Part3DNode[],
    orientation: SiloGroupOrientation,
    sceneObjects$: Subject<SceneGeneratorEvent>,
    nextAction?: SceneGeneratorEnum
  ): void {
    // find dryer
    const dryer = nodes.find(
      (n) => n.area === PartAreaEnum.DRYER && n.type === PartTypeEnum.DRYER
    );
    const startPositionDryerConveyor = new THREE.Vector3(
      dryer.position.x,
      0,
      dryer.position.z
    );

    // find elevator
    const elevator = nodes.find(
      (n) => n.area === PartAreaEnum.DRYER && n.type === PartTypeEnum.ELEVATOR
    );
    const endPositionDryerConveyor = new THREE.Vector3(
      elevator.position.x,
      0,
      elevator.position.z
    );

    // adjust end position to match that of the dryer (i.e. make conveyor straight)
    if (orientation.axis.L.x) {
      endPositionDryerConveyor.x = dryer.position.x;
    } else {
      endPositionDryerConveyor.z = dryer.position.z;
    }
    if (orientation.axis.T.x) {
      startPositionDryerConveyor.x +=
        orientation.axis.T.x *
        (CHAIN_CONVEYOR_TENSIONING_STATION_LENGTH +
          BELT_CONVEYOR_INTAKE_LENGTH);
    } else {
      startPositionDryerConveyor.z +=
        orientation.axis.T.z *
        (CHAIN_CONVEYOR_TENSIONING_STATION_LENGTH +
          BELT_CONVEYOR_INTAKE_LENGTH);
    }

    // compute conveyor length
    const dryerConveyorLength = startPositionDryerConveyor.distanceTo(
      endPositionDryerConveyor
    );

    // compute conveyor height
    const dryerConveyorHeight =
      elevator.position.y +
      0.5 * (elevator.partDetails3D.params as Elevator3DParams).depth.y +
      2 * DRYER_LOADING_CONVEYOR_PARAMS.padding.y;

    // compute dryer conveyor angle
    const v00 = this.geometry.subtractVectors(
      endPositionDryerConveyor,
      startPositionDryerConveyor
    );
    v00.y = 0;
    const v01 = this.geometry.multiplyScalarVector(
      v00,
      -this.geometry.getNonZeroComponentVector(orientation.axis.T)
    );
    const v02 = this.geometry.multiplyScalarVector(
      v01,
      Math.sign(this.geometry.getNonZeroComponentVector(v00))
    );
    const v03 = new THREE.Vector3(1, 0, 0);
    const dryerConveyorAngle = this.geometry.compute2DAngle(v02, v03); // Math.atan2(v03.z * v02.x - v03.x * v02.z, v03.x * v02.x + v03.z * v02.z);

    this.createChainConveyor(
      this.siloPlantConfig.dryerReclaimingConveyorId,
      'Dryer reclaiming conveyor',
      nodes,
      startPositionDryerConveyor,
      dryerConveyorLength,
      dryerConveyorHeight,
      dryerConveyorAngle,
      1,
      [0],
      0,
      PartAreaEnum.DRYER,
      sceneObjects$
    );

    // find buffer bin
    const bbin = nodes.find(
      (n) => n.area === PartAreaEnum.DRYER && n.type === PartTypeEnum.BUFFER_BIN
    );

    const startPositionBBinConveyorPadding = this.geometry.multiplyVectors(
      DRYER_LOADING_CONVEYOR_PARAMS.padding,
      orientation.axis.L
    );
    let startPositionBBinConveyor = this.geometry.subtractVectors(
      new THREE.Vector3(bbin.position.x, 0, bbin.position.z),
      startPositionBBinConveyorPadding
    );
    let endPositionBBinConveyor = new THREE.Vector3(
      elevator.position.x,
      0,
      elevator.position.z
    );
    if (orientation.axis.T.x) {
      startPositionBBinConveyor.x +=
        orientation.axis.T.x *
        (CHAIN_CONVEYOR_TENSIONING_STATION_LENGTH +
          BELT_CONVEYOR_INTAKE_LENGTH);
      // endPositionBBinConveyor.x += (-orientation.axis.T.x * (CHAIN_CONVEYOR_TENSIONING_STATION_LENGTH + BELT_CONVEYOR_INTAKE_LENGTH));
    } else {
      startPositionBBinConveyor.z +=
        orientation.axis.T.z *
        (CHAIN_CONVEYOR_TENSIONING_STATION_LENGTH +
          BELT_CONVEYOR_INTAKE_LENGTH);
      // endPositionBBinConveyor.z += (-orientation.axis.T.z * (CHAIN_CONVEYOR_TENSIONING_STATION_LENGTH + BELT_CONVEYOR_INTAKE_LENGTH));
    }

    // adjust end position to match that of the bbin (i.e. make conveyor straight)

    if (orientation.axis.L.x) {
      endPositionBBinConveyor.x = bbin.position.x;
    } else {
      endPositionBBinConveyor.z = bbin.position.z;
    }
    endPositionBBinConveyor = this.geometry.subtractVectors(
      endPositionBBinConveyor,
      startPositionBBinConveyorPadding
    );

    // compute conveyor length
    const bbinConveyorLength = startPositionBBinConveyor.distanceTo(
      endPositionBBinConveyor
    );

    // compute bbin conveyor angle
    const v10 = this.geometry.subtractVectors(
      endPositionDryerConveyor,
      startPositionDryerConveyor
    );
    v10.y = 0;
    const v11 = this.geometry.multiplyScalarVector(
      v10,
      -this.geometry.getNonZeroComponentVector(orientation.axis.T)
    );
    const v12 = this.geometry.multiplyScalarVector(
      v11,
      Math.sign(this.geometry.getNonZeroComponentVector(v10))
    );
    const v13 = new THREE.Vector3(1, 0, 0);
    const bbinConveyorAngle = this.geometry.compute2DAngle(v12, v13); // Math.atan2(v13.z * v12.x - v13.x * v12.z, v13.x * v12.x + v13.z * v12.z);

    // create reclaiming conveyor from buffer bin to elevator
    if (nextAction === undefined) {
      nextAction = SceneGeneratorEnum.POSITION_INTAKE;
    }
    this.createChainConveyor(
      this.siloPlantConfig.bufferBinReclaimingConveyorId,
      'Buffer bin reclaiming conveyor',
      nodes,
      startPositionBBinConveyor,
      bbinConveyorLength,
      dryerConveyorHeight,
      bbinConveyorAngle,
      1,
      [0],
      0,
      PartAreaEnum.DRYER,
      sceneObjects$,
      nextAction
    );
  }

  positionCleanerElevator(
    nodes: Part3DNode[],
    orientation: SiloGroupOrientation,
    sceneObjects$: Subject<SceneGeneratorEvent>
  ): void {
    let cleanerElevator = nodes.find((node) => node.type === PartTypeEnum.ELEVATOR && node.area === PartAreaEnum.CLEANER);
    if(cleanerElevator === undefined){
      cleanerElevator = new Part3DNode(this.siloPlantConfig.cleanerElevatorId);
    }
    // get main elevator
    const mainElevator = nodes.filter(
      (n) => n.type === PartTypeEnum.ELEVATOR
    )[0];
    const oppositeTransversalAxis = this.geometry.multiplyScalarVector(
      orientation.axis.T,
      -1
    );
    const angle = mainElevator.orientation.y + Math.PI;

    cleanerElevator.position = this.geometry.addVectors(mainElevator.position,(
      this.geometry.multiplyScalarVector(
        oppositeTransversalAxis,
        CLEANER_ELEVATOR_PARAMS.padding.x
      )
    ));
    cleanerElevator.orientation = new THREE.Vector3(0, angle, 0);
    cleanerElevator.type = PartTypeEnum.ELEVATOR;
    cleanerElevator.area = PartAreaEnum.CLEANER;
    cleanerElevator.partDetails3D = new Part3DDetails();
    cleanerElevator.partDetails3D.params = new Elevator3DParams(
      'Cleaner elevator',
      CLEANER_ELEVATOR_PARAMS.color,
      CLEANER_ELEVATOR_PARAMS.dx,
      CLEANER_ELEVATOR_PARAMS.padding,
      CLEANER_ELEVATOR_PARAMS.capacity,
      CLEANER_ELEVATOR_PARAMS.depth
    );

    this.equipmentGenerator
      .getElevator(cleanerElevator.partDetails3D.params as Elevator3DParams)
      .subscribe((e: Part3D) => {
        // position elevator

        e.part.position.x = cleanerElevator.position.x;
        e.part.position.y = cleanerElevator.position.y;
        e.part.position.z = cleanerElevator.position.z;

        // rotate elevator
        e.part.rotation.y = angle;
        // update elevator params
        cleanerElevator.partDetails3D.params.dx = new THREE.Vector3(
          e.params.dx.x,
          e.params.dx.y,
          e.params.dx.z
        );

        // rename part
        e.part.name = cleanerElevator.partDetails3D.params.name;
        cleanerElevator.part3D = e;
        if(cleanerElevator.physicsBody !== undefined){
          cleanerElevator.sync = SyncStatesEnum.SYNC_NEEDED;
        }
        sceneObjects$.next({
          part3DNode: cleanerElevator,
          next: SceneGeneratorEnum.POSITION_CLEANER,
        });
      });
  }

  positionCleaner(
    nodes: Part3DNode[],
    longitudinalAxis: THREE.Vector3,
    sceneObjects$: Subject<SceneGeneratorEvent>
  ): void {
    let cleaner = nodes.find((node) => node.type === PartTypeEnum.CLEANER);
    if(cleaner === undefined){
      cleaner = new Part3DNode(this.siloPlantConfig.cleanerId);
    }

    const cleanerElevator = nodes.find(
      (n) => n.area === PartAreaEnum.CLEANER && n.type === PartTypeEnum.ELEVATOR
    );
    const oppositeLongitudinalAxis = this.geometry.multiplyScalarVector(
      longitudinalAxis,
      -1
    );
    const v0 = this.geometry.addMultipleVectors([
      cleanerElevator.partDetails3D.params.dx,
      cleanerElevator.partDetails3D.params.padding,
      CLEANER_PARAMS.support,
      CLEANER_PARAMS.padding,
    ]);
    const v1 = this.geometry.multiplyVectors(oppositeLongitudinalAxis, v0);

    cleaner.position.y = 0;
    if (oppositeLongitudinalAxis.x) {
      cleaner.position.z = cleanerElevator.position.z; //same z axis => change x
      cleaner.position.x = cleanerElevator.position.x + v1.x;
    } else {
      cleaner.position.x = cleanerElevator.position.x; //same x axis => change z
      cleaner.position.z = cleanerElevator.position.z + v1.z;
    }

    let angle = Math.PI;
    if (longitudinalAxis.z === 1) {
      angle = 0;
    } else if (longitudinalAxis.x) {
      angle /= longitudinalAxis.x * 2;
    }
    cleaner.orientation.y = angle;
    cleaner.type = PartTypeEnum.CLEANER;
    cleaner.subType = PartSubTypeEnum.AIR_SIFTER;
    cleaner.area = PartAreaEnum.CLEANER;
    cleaner.partDetails3D = new Part3DDetails();
    cleaner.partDetails3D.params = new Cleaner3DParams(
      'Conditioning',
      CLEANER_PARAMS.color,
      CLEANER_PARAMS.dx,
      CLEANER_PARAMS.support,
      CLEANER_PARAMS.cover,
      CLEANER_PARAMS.padding,
      MAIN_ELEVATOR_PARAMS.capacity
    );
    this.equipmentGenerator
      .getCleaner(cleaner.partDetails3D.params as Cleaner3DParams)
      .subscribe((c: Part3D) => {
        // position cleaner
        c.part.position.x = cleaner.position.x;
        c.part.position.y = cleaner.position.y;
        c.part.position.z = cleaner.position.z;
        // rotate cleaner
        c.part.rotateY(angle);
        // rename part
        c.part.name = cleaner.partDetails3D.params.name;
        cleaner.part3D = c;
        // if(cleaner.physicsBody !== undefined){
        //   cleaner.sync = SyncStatesEnum.SYNC_NEEDED;
        // }
        sceneObjects$.next({
          part3DNode: cleaner,
          next: SceneGeneratorEnum.POSITION_DELIVERY_BINS,
        });
      });
  }

  positionDeliveryBins(
    nodes: Part3DNode[],
    longitudinalAxis: THREE.Vector3,
    sceneObjects$: Subject<SceneGeneratorEvent>
  ): void {
    let delivery = nodes.find((node) => node.type === PartTypeEnum.DELIVERY);
    if(delivery === undefined){
      delivery = new Part3DNode(this.siloPlantConfig.deliveryId);
    }

    const cleaner = nodes.find(
      (n) => n.area === PartAreaEnum.CLEANER && n.type === PartTypeEnum.CLEANER
    );
    const oppositeLongitudinalAxis = this.geometry.multiplyScalarVector(
      longitudinalAxis,
      -1
    );
    const v0 = this.geometry.addMultipleVectors([
      cleaner.partDetails3D.params.dx,
      cleaner.partDetails3D.params.padding,
      DELIVERY_AREA_PARAMS.support,
      DELIVERY_AREA_PARAMS.padding,
    ]);
    const v1 = this.geometry.multiplyVectors(oppositeLongitudinalAxis, v0);

    if (oppositeLongitudinalAxis.x) {
      delivery.position.z = cleaner.position.z; //same z axis => change x
      delivery.position.x = cleaner.position.x + v1.x;
    } else {
      delivery.position.x = cleaner.position.x; //same x axis => change z
      delivery.position.z = cleaner.position.z + v1.z;
    }

    delivery.orientation = new THREE.Vector3();
    delivery.type = PartTypeEnum.DELIVERY;
    delivery.area = PartAreaEnum.DELIVERY;
    delivery.partDetails3D = new Part3DDetails();
    delivery.partDetails3D.params = new Delivery3DParams(
      'Delivery bin',
      DELIVERY_AREA_PARAMS.color,
      DELIVERY_AREA_PARAMS.dx,
      DELIVERY_AREA_PARAMS.support,
      DELIVERY_AREA_PARAMS.padding
    );

    this.equipmentGenerator
      .getAutoDeliveryBin(delivery.partDetails3D.params as Delivery3DParams)
      .subscribe((p) => {
        // position part
        p.part.position.x = delivery.position.x;
        p.part.position.y = delivery.position.y;
        p.part.position.z = delivery.position.z;
        // rotate part
        p.part.rotateX(delivery.orientation.x);
        p.part.rotateY(delivery.orientation.y);
        p.part.rotateZ(delivery.orientation.z);
        // rename part
        p.part.name = delivery.partDetails3D.params.name;

        /** UPDATE DELIVERY BIN PARAMS */
        delivery.part3D = p;
        delivery.partDetails3D.params.dx.x = p.params.dx.x;
        delivery.partDetails3D.params.dx.y = p.params.dx.y;
        delivery.partDetails3D.params.dx.z = p.params.dx.z;

        if(delivery.physicsBody !== undefined){
          delivery.sync = SyncStatesEnum.SYNC_NEEDED;
        }
        sceneObjects$.next({
          part3DNode: delivery,
          next: SceneGeneratorEnum.POSITION_DRYER_ELEVATOR,
        });
      });
  }

  positionDeliveryLoadingConveyors(
    nodes: Part3DNode[],
    orientation: SiloGroupOrientation,
    sceneObjects$: Subject<SceneGeneratorEvent>,
    nOutlets?: number,
    nextAction?: SceneGeneratorEnum
  ): void {
    const N_OUTLETS = 2;
    if (!nOutlets) {
      nOutlets = N_OUTLETS;
    }

    // get start and end equipments
    const startEquipment = nodes.find(
      (n) => n.area === PartAreaEnum.CLEANER && n.type === PartTypeEnum.ELEVATOR
    );
    startEquipment.position = new THREE.Vector3(
      startEquipment.position.x,
      startEquipment.position.y,
      startEquipment.position.z
    );

    const endEquipment = nodes.find(
      (n) =>
        n.area === PartAreaEnum.DELIVERY && n.type === PartTypeEnum.DELIVERY
    );
    endEquipment.position = new THREE.Vector3(
      endEquipment.position.x,
      endEquipment.position.y,
      endEquipment.position.z
    );

    // get cleaner
    const cleaner = nodes.find(
      (n) => n.area === PartAreaEnum.CLEANER && n.type === PartTypeEnum.CLEANER
    );
    cleaner.position = new THREE.Vector3(
      cleaner.position.x,
      cleaner.position.y,
      cleaner.position.z
    );

    // compute conveyor length
    const padding = this.geometry.addMultipleVectors([
      DELIVERY_LOADING_CONVEYOR_PARAMS.padding,
      (cleaner.partDetails3D.params as Cleaner3DParams).support,
      cleaner.partDetails3D.params.padding,
      endEquipment.partDetails3D.params.padding,
      this.geometry.multiplyScalarVector(
        (endEquipment.partDetails3D.params as Delivery3DParams).support,
        1
      ),
    ]);
    let lengthVector = new THREE.Vector3(
      endEquipment.position.x - startEquipment.position.x,
      0,
      endEquipment.position.z - startEquipment.position.z
    );
    lengthVector = this.geometry.multiplyVectors(
      lengthVector,
      orientation.axis.L
    );
    const length = Math.abs(
      this.geometry.getNonZeroComponentVector(lengthVector)
    );

    // compute conveyor height
    const height =
      startEquipment.partDetails3D.params.dx.y +
      (startEquipment.partDetails3D.params as Elevator3DParams).depth.y -
      DELIVERY_LOADING_CONVEYOR_PARAMS.padding.y;

    // compute outlet distance
    const outletDistances: Array<number> = new Array(nOutlets).fill(0);
    outletDistances[0] = cleaner.position.distanceTo(startEquipment.position);
    for (let i = 1; i < nOutlets - 1; i++) {
      outletDistances[i] = cleaner.position.distanceTo(endEquipment.position);
    }
    outletDistances[nOutlets - 1] =
      length - outletDistances.reduce((total, num) => total + num, 0);

    // compute start position

    let conveyorPosition = new THREE.Vector3(
      startEquipment.position.x,
      0,
      startEquipment.position.z
    );
    const displacement = this.helper.positionEquipmentByAngle(
      startEquipment.partDetails3D.params.dx.y +
        startEquipment.position.y -
        height -
        ELEVATOR_MOTOR_HEIGHT,
      45
    );

    if (orientation.axis.L.x) {
      conveyorPosition.x +=
        -orientation.axis.L.x * (displacement - BELT_CONVEYOR_INTAKE_LENGTH);
    } else {
      conveyorPosition.z +=
        -orientation.axis.L.z * (displacement - BELT_CONVEYOR_INTAKE_LENGTH);
    }

    const elevatorToDeliveryOrientation = new THREE.Vector3().copy(
      this.geometry.subtractVectors(
        endEquipment.position,
        startEquipment.position
      )
    ).normalize();
    elevatorToDeliveryOrientation.y = 0;
    const angle = -this.geometry.compute2DAngle(CONVEYOR_DEFAULT_ORIENTATION, elevatorToDeliveryOrientation);
    if (nextAction === undefined) {
      nextAction = SceneGeneratorEnum.POSITION_DRYER_LOADING_CONVEYORS;
    }
    this.createChainConveyor(
      this.siloPlantConfig.deliveryLoadingConveyorId,
      `Delivery & cleaning loading conveyor`,
      nodes,
      conveyorPosition,
      length,
      height,
      angle,
      nOutlets,
      outletDistances,
      0,
      PartAreaEnum.DELIVERY,
      sceneObjects$,
      nextAction
    );
  }

  createChainConveyor(
    id: number,
    name: string,
    nodes: Part3DNode[],
    position: THREE.Vector3,
    length: number,
    height: number,
    angle: number,
    nOutlets: number,
    outputDistances: Array<number>,
    capacity: number,
    area: PartAreaEnum,
    sceneObjects$: Subject<SceneGeneratorEvent>,
    nextAction?: SceneGeneratorEnum
  ): void {
    // build conveyor
    const conveyor = new Part3DNode(id);
    conveyor.area = area;
    conveyor.type = PartTypeEnum.CHAIN_CONVEYOR;
    conveyor.position = new THREE.Vector3(position.x, height, position.z);
    conveyor.orientation = new THREE.Vector3(0, angle, 0);
    conveyor.partDetails3D = new Part3DDetails();
    conveyor.partDetails3D.params = new Conveyor3DParams(
      name,
      CHAIN_CONVEYOR_PARAMS.color,
      new THREE.Vector3(length, 0, 0),
      nOutlets,
      capacity,
      CHAIN_CONVEYOR_PARAMS.depth,
      outputDistances,
      CHAIN_CONVEYOR_PARAMS.padding
    );

    this.equipmentGenerator
      .getChainConveyor(conveyor.partDetails3D.params as Conveyor3DParams)
      .subscribe((c: Part3D) => {
        // position conveyor
        c.part.position.x = conveyor.position.x;
        c.part.position.y = conveyor.position.y;
        c.part.position.z = conveyor.position.z;

        // rotate conveyor
        c.part.rotateY(conveyor.orientation.y);

        // update conveyor params
        conveyor.partDetails3D.params.dx = new THREE.Vector3(
          c.params.dx.x,
          c.params.dx.y,
          c.params.dx.z
        );

        // rename part
        c.part.name = conveyor.partDetails3D.params.name;

        conveyor.part3D = c;

        sceneObjects$.next({ part3DNode: conveyor, next: nextAction });
      });
  }

  createBeltConveyor(
    id: number,
    name: string,
    nodes: Part3DNode[],
    position: THREE.Vector3,
    length: number,
    height: number,
    angle: number,
    capacity: number,
    area: PartAreaEnum,
    sceneObjects$: Subject<SceneGeneratorEvent>,
    nextAction?: SceneGeneratorEnum
  ): void {
    // build conveyor
    const conveyor = new Part3DNode(id);
    conveyor.area = area;
    conveyor.type = PartTypeEnum.BELT_CONVEYOR;
    conveyor.position = new THREE.Vector3(position.x, height, position.z);
    conveyor.orientation = new THREE.Vector3(0, angle, 0);
    conveyor.partDetails3D = new Part3DDetails();
    conveyor.partDetails3D.params = new Conveyor3DParams(
      name,
      BELT_CONVEYOR_PARAMS.color,
      new THREE.Vector3(length, 0, 0),
      undefined,
      capacity,
      BELT_CONVEYOR_PARAMS.depth,
      BELT_CONVEYOR_PARAMS.outputDistances,
      BELT_CONVEYOR_PARAMS.padding
    );
    this.equipmentGenerator
      .getBeltConveyor(conveyor.partDetails3D.params as Conveyor3DParams)
      .subscribe((c: Part3D) => {
        // position conveyor
        c.part.position.x = conveyor.position.x;
        c.part.position.y = conveyor.position.y;
        c.part.position.z = conveyor.position.z;
        // rotate conveyor
        c.part.rotateX(conveyor.orientation.x);
        c.part.rotateY(conveyor.orientation.y);
        c.part.rotateZ(conveyor.orientation.z);
        // update conveyor params
        conveyor.partDetails3D.params.dx = new THREE.Vector3(
          c.params.dx.x,
          c.params.dx.y,
          c.params.dx.z
        );
        // rename part
        c.part.name = conveyor.partDetails3D.params.name;
        conveyor.part3D = c;
        sceneObjects$.next({ part3DNode: conveyor, next: nextAction });
      });
  }
}
