import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Project } from '../../shared/models/project';
import { environment } from '../../../environments/environment';
import {
  Bin3DParams,
  Cleaner3DParams,
  Conveyor3DParams,
  Delivery3DParams,
  Dryer3DParams,
  Elevator3DParams,
  Part3DDetails,
  Part3DNode,
  Part3DParamsBase,
  PartDetails3DEditsEvent,
} from '../../shared/models/part';
import { ApiService } from '../../shared/services/api.service';
import { HttpClient } from '@angular/common/http';
import * as THREE from 'three';
import {
  PartAreaEnum,
  PartTypeEnum,
  ProjectHelperServiceEnum,
} from '../../shared/models/enums';
import { GenericSnackBarService } from '../../shared/services/generic-snack-bar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import {
  BELT_CONVEYOR_PARAMS,
  BUFFER_BIN_PARAMS,
  CHAIN_CONVEYOR_PARAMS,
  CLEANER_PARAMS,
  DELIVERY_AREA_PARAMS,
  DRYER_PARAMS,
  INTAKE_AREA_PARAMS,
  MAIN_ELEVATOR_PARAMS,
  SILO_BIN_PARAMS,
} from 'src/app/shared/constants/equipment-parameters-default';
import { EquipmentGraphService } from 'src/app/shared/services/equipment-graph.service';
import { Part2DNode } from '../../shared/models/flow-diagram';
import { Subject } from 'rxjs';
import { OperationResult } from '../../shared/models/helper-service-events';
import { QuotationHelperService } from '../../shared/services/quotation-helper.service';
import { ProjectHelperService } from '../../shared/services/project-helper.service';
import { ProjectFilesService } from 'src/app/shared/services/projectfiles.service';
import { EquipmentCardComponent } from 'src/app/shared/modules/misc/equipment-card/equipment-card.component';
import { NotifyService } from 'src/app/shared/services/notify.service';

@Component({
  selector: 'app-flow-diagram',
  templateUrl: './flow-diagram.component.html',
  styleUrls: ['./flow-diagram.component.scss'],
})
export class FlowDiagramComponent implements OnInit, OnDestroy {
  @Input() submitProposalRequestFlag = true;
  @Input() stepper?: MatStepper;
  @ViewChildren(EquipmentCardComponent)
  equipmentCards: QueryList<EquipmentCardComponent>;

  menuOptions = [
    'Equipment specifications',
    'Add equipment',
    'Delete equipment',
  ];
  public currentProject: Project;
  showEquipmentList = false;
  showAddEquipmentDialog = false;
  showDeleteEquipmentDialog = false;
  showEquipmentSpecificationsDialog = false;
  showAcceptReject = false;
  showQuotation = true;
  public loading = true;
  public isSmall = true;
  public progress = 0;
  private canvasSelector = '#flow-diagram';
  private localStorageKeys = environment.localStorageKeys;
  private endpoint = environment.endpoint;
  private routes = environment.routes;
  private part2DNodes: Part2DNode[] = [];
  private ctx: CanvasRenderingContext2D;
  private canvas: HTMLCanvasElement;
  private fetchController = new AbortController();
  private projectId: string;
  private projectService$: Subject<OperationResult> =
    new Subject<OperationResult>();
  private uploadService$: Subject<OperationResult> =
    new Subject<OperationResult>();
  private downloadService$: Subject<OperationResult> =
    new Subject<OperationResult>();

  constructor(
    private api: ApiService,
    private http: HttpClient,
    private snackBar: GenericSnackBarService,
    private router: Router,
    private route: ActivatedRoute,
    private equipmentGraph: EquipmentGraphService,
    private quotationHelper: QuotationHelperService,
    private projectFiles: ProjectFilesService,
    private projectHelper: ProjectHelperService
  ) {
    this.api.http = this.http;
  }
  ngOnInit(): void {
    setTimeout(this.initTimedOutFunction.bind(this), 0);
    this.currentProject = new Project();
  }
  initTimedOutFunction(): void {
    this.canvas = document.querySelector(this.canvasSelector);
    this.canvas.width = 3 * window.innerWidth;
    this.canvas.height = 3 * window.innerHeight;
    this.ctx = this.canvas.getContext('2d');
    this.ctx.fillStyle = 'white';
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    this.projectService$.subscribe((event: OperationResult) => {
      if (event.status === ProjectHelperServiceEnum.EDITED) {
        if (event.data.hasOwnProperty('navigateTo')) {
          this.router.navigate(event.data.navigateTo);
        }
        this.loading = false;
        this.snackBar.showSuccess(
          `Project ` + this.currentProject._id + ' have been saved.'
        );
      }
      if (event.status === ProjectHelperServiceEnum.ERROR) {
        this.loading = false;
      }
      if (event.status === ProjectHelperServiceEnum.LOADED) {
        this.currentProject = event.data;
        this.currentProject.nodes = [...event.data.nodes] as Part3DNode[];
        this.generate2DFlow(this.ctx);
      }
    });
    this.downloadService$.subscribe((event) => {
      if (event.status === ProjectHelperServiceEnum.SUCCESS) {
        this.snackBar.showSuccess(event.data);
      }
    });
    this.uploadService$.subscribe((event) => {
      if (event.status === ProjectHelperServiceEnum.SUCCESS) {
        this.snackBar.showSuccess(event.data);
      }
    });
    this.route.params.subscribe((param) => {
      this.projectHelper.getProject(this.projectService$, param.id);
    });
    this.route.queryParams.subscribe((param) => {
      const view = param.view;
      if (view && view === 'quotation') {
        this.showAcceptReject = true;
      } else {
        this.showAcceptReject = false;
      }
    });
  }

  async downloadProjectSpecs(): Promise<void> {
    await this.projectFiles.downloadProjectFiles(
      this.currentProject,
      this.downloadService$,
      false
    );
  }
  // ------ METHODS TO GENERATE FLOW DIAGRAM ------ //
  generate2DFlow(ctx: CanvasRenderingContext2D, redraw: boolean = false): void {
    this.loading = true;
    if (redraw) {
      this.part2DNodes = [];
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }
    this.currentProject.nodes.forEach((x) => (x.part3D = undefined));
    const part2DNodesData = {
      part3DNodes: [...this.currentProject.nodes],
      nSiloBinsPerRow: [],
    };
    // build 2D parts tree
    this.api
      .post(this.endpoint.buildTree2D, part2DNodesData, {
        signal: this.fetchController.signal,
      })
      .subscribe(
        (part2DNodes) => {
          this.part2DNodes = [...(part2DNodes as any).part2DNodes];
          const flow2DData = {
            part2DNodes: this.part2DNodes,
            canvasWidth: this.canvas.width,
            canvasHeight: this.canvas.height,
          };
          const steps =
            this.part2DNodes.length +
            this.part2DNodes.reduce((s, x) => s + x.edges.length, 0);
          const progressStepPercentage = steps / 100;
          for (const node of this.part2DNodes) {
            this.drawPart2D(this.ctx, node);
            let startPos;
            let endPos;
            if (node && node.edges && node.edges.length) {
              const deviationBoxStartX = [];
              const deviationBoxStartY = [];
              let nDeviationBoxes = 0;
              let nOutlets = 0;
              if (node.type === PartTypeEnum.CHAIN_CONVEYOR) {
                nOutlets = node.edges.length - 1;
                let addDeviationBoxes = false;
                for (const e of node.edges) {
                  const child = this.part2DNodes.find((n) => n.id === e[1]);
                  if (child.type === PartTypeEnum.ELEVATOR) {
                    addDeviationBoxes = true;
                  }
                }
                if (addDeviationBoxes) {
                  nDeviationBoxes = node.edges.length - 1;
                }
              } else {
                nDeviationBoxes = node.edges.length - 1;
              }
              const startNode = this.part2DNodes.find(
                (x) => x.id === node.edges[0][0]
              );
              for (const [k, edge] of node.edges.entries()) {
                const endNode = this.part2DNodes.find((x) => x.id === edge[1]);
                if (!endNode) {
                  this.snackBar.showError(
                    `Unable to generate flow diagram. Cause: end node for ${startNode.name} is missing.`
                  );
                }
                // define start position for current connection based on eq type + conditions
                switch (startNode.type) {
                  case PartTypeEnum.INTAKE: {
                    startPos = {
                      x: startNode.x + startNode.dx - 10,
                      y: startNode.y + 6,
                    };
                    break;
                  }
                  case PartTypeEnum.BELT_CONVEYOR: {
                    if (!startNode.orientation) {
                      startPos = {
                        x: startNode.x + startNode.dx,
                        y: startNode.y + 16,
                      };
                    } else {
                      startPos = {
                        x: startNode.x,
                        y: startNode.y + 16,
                      };
                    }
                    break;
                  }
                  case PartTypeEnum.ELEVATOR: {
                    startPos = {
                      x: startNode.x + startNode.dx + 16,
                      y: startNode.y - startNode.dy + 36,
                    };
                    break;
                  }
                  case PartTypeEnum.CLEANER: {
                    startPos = {
                      x: startNode.x + startNode.dx - 10,
                      y: startNode.y + 11,
                    };
                    break;
                  }
                  case PartTypeEnum.CHAIN_CONVEYOR: {
                    if (
                      endNode.type === PartTypeEnum.SILO_BIN ||
                      endNode.type === PartTypeEnum.BUFFER_BIN ||
                      endNode.type === PartTypeEnum.DRYER
                    ) {
                      if (
                        !startNode.orientation &&
                        endNode.x + endNode.dx / 2 >
                          startNode.x + startNode.dx - 50
                      ) {
                        startPos = {
                          x: startNode.x + startNode.dx - 5,
                          y: startNode.y + 16,
                        };
                      } else if (
                        !startNode.orientation &&
                        endNode.x + endNode.dx / 2 <=
                          startNode.x + startNode.dx - 50
                      ) {
                        this.drawChainConveyorOutlet(
                          ctx,
                          endNode.x + endNode.dx / 2,
                          startNode.y,
                          10
                        );
                        startPos = {
                          x: endNode.x + endNode.dx / 2,
                          y: startNode.y + 16,
                        };
                      } else if (
                        !startNode.orientation &&
                        endNode.x - endNode.dx / 2 < startNode.x - 10
                      ) {
                        startPos = {
                          x: startNode.x + 5,
                          y: startNode.y + 16,
                        };
                      } else if (
                        !startNode.orientation &&
                        endNode.x - endNode.dx / 2 >= startNode.x + 10
                      ) {
                        this.drawChainConveyorOutlet(
                          ctx,
                          endNode.x + endNode.dx / 2,
                          startNode.y,
                          10
                        );
                        startPos = {
                          x: endNode.x + endNode.dx / 2,
                          y: startNode.y + 16,
                        };
                      } else {
                        this.drawChainConveyorOutlet(
                          ctx,
                          endNode.x + endNode.dx / 2,
                          startNode.y,
                          10
                        );
                        startPos = {
                          x: endNode.x + endNode.dx / 2,
                          y: startNode.y + 16,
                        };
                      }
                    } else if (
                      endNode.type === PartTypeEnum.BELT_CONVEYOR ||
                      endNode.type === PartTypeEnum.CHAIN_CONVEYOR
                    ) {
                      if (!startNode.orientation) {
                        startPos = {
                          x: startNode.x + startNode.dx - 5 - k * 40,
                          y: startNode.y + 16,
                        };
                        if (startPos.x < startNode.x + startNode.dx - 10) {
                          this.drawChainConveyorOutlet(
                            ctx,
                            startPos.x,
                            startNode.y,
                            10
                          );
                        }
                      } else {
                        startPos = {
                          x: startNode.x + 5 + k * 40,
                          y: startNode.y + 16,
                        };
                        if (startPos.x > startNode.x + startNode.dx + 10) {
                          this.drawChainConveyorOutlet(
                            ctx,
                            startPos.x,
                            startNode.y,
                            10
                          );
                        }
                      }
                    } else {
                      if (!startNode.orientation) {
                        startPos = {
                          x: startNode.x + startNode.dx - 5,
                          y: startNode.y + 16,
                        };
                      } else {
                        startPos = {
                          x: startNode.x + 5,
                          y: startNode.y + 16,
                        };
                      }
                    }
                    break;
                  }
                  case PartTypeEnum.DRYER: {
                    startPos = {
                      x: startNode.x + startNode.dx / 2,
                      y: startNode.y + 11,
                    };
                    break;
                  }
                  case PartTypeEnum.BUFFER_BIN: {
                    startPos = {
                      x: startNode.x + startNode.dx / 2,
                      y: startNode.y + 11,
                    };
                    break;
                  }
                  case PartTypeEnum.DELIVERY: {
                    startPos = {
                      x: startNode.x + startNode.dx / 2,
                      y: startNode.y + 11,
                    };
                    break;
                  }
                  case PartTypeEnum.SILO_BIN: {
                    startPos = {
                      x: startNode.x + startNode.dx / 2,
                      y: startNode.y + 11,
                    };
                    break;
                  }
                  default: {
                    startPos = {
                      x: startNode.x,
                      y: startNode.y,
                    };
                    break;
                  }
                }
                // draw a straight line leaving the equipment
                this.drawLine(ctx, startPos.x, startPos.y, 0, 10);
                startPos.y += 10;
                // define end position for current connection based on eq type + conditions
                switch (endNode?.type) {
                  case PartTypeEnum.INTAKE: {
                    endPos = {
                      x: endNode.x,
                      y: endNode.y - 21,
                    };
                    break;
                  }
                  case PartTypeEnum.BELT_CONVEYOR: {
                    if (startNode.type === PartTypeEnum.SILO_BIN) {
                      if (!endNode.orientation) {
                        endPos = {
                          x: startNode.x + startNode.dx / 2,
                          y: endNode.y - 6,
                        };
                      } else {
                        endPos = {
                          x: startNode.x + startNode.dx / 2,
                          y: endNode.y - 6,
                        };
                      }
                    } else {
                      if (!endNode.orientation) {
                        endPos = {
                          x: endNode.x,
                          y: endNode.y - 6,
                        };
                      } else {
                        endPos = {
                          x: endNode.x + endNode.dx,
                          y: endNode.y - 6,
                        };
                      }
                    }
                    break;
                  }
                  case PartTypeEnum.ELEVATOR: {
                    endPos = {
                      x: endNode.x - 16,
                      y: endNode.y - 41,
                    };
                    break;
                  }
                  case PartTypeEnum.CLEANER: {
                    endPos = {
                      x: endNode.x + endNode.dx / 2,
                      y: endNode.y - endNode.dy - 6,
                    };
                    break;
                  }
                  case PartTypeEnum.CHAIN_CONVEYOR: {
                    if (!endNode.orientation) {
                      endPos = {
                        x: endNode.x,
                        y: endNode.y - endNode.dy - 6,
                      };
                    } else {
                      endPos = {
                        x: endNode.x + endNode.dx,
                        y: endNode.y - endNode.dy - 6,
                      };
                    }
                    break;
                  }
                  case PartTypeEnum.DRYER: {
                    endPos = {
                      x: endNode.x + endNode.dx / 2,
                      y: endNode.y - endNode.dy - 6,
                    };
                    break;
                  }
                  case PartTypeEnum.BUFFER_BIN: {
                    endPos = {
                      x: endNode.x + endNode.dx / 2,
                      y: endNode.y - endNode.dy - 6,
                    };
                    break;
                  }
                  case PartTypeEnum.DELIVERY: {
                    endPos = {
                      x: endNode.x + endNode.dx / 2,
                      y: endNode.y - endNode.dy - 6,
                    };
                    break;
                  }
                  case PartTypeEnum.SILO_BIN: {
                    endPos = {
                      x: endNode.x + endNode.dx / 2,
                      y: endNode.y - endNode.dy - 6,
                    };
                    break;
                  }
                  default: {
                    endPos = {
                      x: endNode.x,
                      y: endNode.y,
                    };
                    break;
                  }
                }
                // draw a straight line entering the equipment
                this.drawLine(ctx, endPos.x, endPos.y, 0, -10);
                endPos.y -= 10;
                // draw deviation boxes (if needed)
                if (nDeviationBoxes) {
                  if (k === 0) {
                    for (let j = 0; j < nDeviationBoxes; j++) {
                      deviationBoxStartX.push(startPos.x);
                      deviationBoxStartY.push(
                        startPos.y + (j + 1) * 7.5 + j * 10
                      );
                      if (j) {
                        this.drawLine(
                          ctx,
                          deviationBoxStartX[j],
                          deviationBoxStartY[j] - 7.5,
                          0,
                          10
                        );
                        deviationBoxStartY[j] =
                          startPos.y + (j + 1) * 7.5 + (j + 1) * 10;
                      }
                      this.drawDeviationBox(
                        ctx,
                        deviationBoxStartX[j],
                        deviationBoxStartY[j]
                      );
                    }
                  }
                  if (k < deviationBoxStartY.length) {
                    startPos.x = deviationBoxStartX[k] + 7.5;
                    startPos.y = deviationBoxStartY[k];
                  } else {
                    startPos.y =
                      deviationBoxStartY[deviationBoxStartY.length - 1] + 7.5;
                  }
                }
              }
            }
            this.progress += progressStepPercentage;
          }
          // draw paths between 2D parts
          this.api
            .post(this.endpoint.buildFlow2D, flow2DData, {
              signal: this.fetchController.signal,
            })
            .subscribe(
              async (flow2D) => {
                for (const path of (flow2D as any).paths) {
                  if (path) {
                    this.drawPath(this.ctx, path);
                  }
                  this.progress += progressStepPercentage;
                }
                await this.projectFiles.uploadProjectFilesFlowDiagramStep(
                  this.currentProject,
                  this.canvasSelector,
                  this.uploadService$
                );
                this.loading = false;
              },
              () => {
                this.snackBar.showError(
                  'Failed to generate flow diagram. Please go back and try again..'
                );
              }
            );
        },
        () => {
          this.snackBar.showError(
            'Failed to generate flow diagram. Please go back and try again...'
          );
        }
      );
  }

  drawLine(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    dx: number,
    dy: number
  ): void {
    ctx.lineWidth = 0.5;
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x + dx, y + dy);
    ctx.stroke();
    ctx.lineWidth = 1;
  }

  drawPath(ctx: CanvasRenderingContext2D, path): void {
    if (path && path.length) {
      ctx.lineWidth = 0.5;
      ctx.beginPath();
      ctx.strokeStyle = this.getMyColor();
      ctx.moveTo(path[0][0], path[0][1]);
      for (let i = 1; i < path.length; i++) {
        ctx.lineTo(path[i][0], path[i][1]);
      }

      ctx.stroke();

      this.drawArrowHead(
        ctx,
        path[path.length - 1][0],
        path[path.length - 1][1] + 10
      );
      ctx.lineWidth = 1;
    }
  }

  getMyColor = () => {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return '#' + n.slice(0, 6);
  };

  drawPart2D(ctx: CanvasRenderingContext2D, node: Part2DNode): void {
    ctx.font = '14px Roboto';
    ctx.fillText(node.id.toString(), node.x, node.y - node.dy - 10);
    ctx.fillStyle = 'green';
    switch (node.type) {
      case PartTypeEnum.INTAKE: {
        this.drawIntake(ctx, node.x, node.y, node.dx);
        break;
      }
      case PartTypeEnum.CLEANER: {
        this.drawCleaner(ctx, node.x, node.y, node.dx, node.dy);
        break;
      }
      case PartTypeEnum.DRYER: {
        this.drawDryer(ctx, node.x, node.y, node.dx, node.dy);
        break;
      }
      case PartTypeEnum.ELEVATOR: {
        this.drawElevator(
          ctx,
          node.x,
          node.y,
          node.dx,
          node.dy,
          node.orientation
        );
        break;
      }
      case PartTypeEnum.BUFFER_BIN: {
        this.drawBufferBin(ctx, node.x, node.y, node.dx, node.dy);
        break;
      }
      case PartTypeEnum.DELIVERY: {
        this.drawTruckDelivery(ctx, node.x, node.y, node.dx, node.dy);
        break;
      }
      case PartTypeEnum.BELT_CONVEYOR: {
        this.drawBeltConveyor(
          ctx,
          node.x,
          node.y,
          node.dx,
          node.dy,
          node.orientation
        );
        break;
      }
      case PartTypeEnum.CHAIN_CONVEYOR: {
        this.drawChainConveyor(
          ctx,
          node.x,
          node.y,
          node.dx,
          node.dy,
          node.orientation
        );
        break;
      }
      case PartTypeEnum.SILO_BIN: {
        this.drawSiloBin(ctx, node.x, node.y, node.dx, node.dy);
        break;
      }
    }
  }

  drawArrowHead(ctx: CanvasRenderingContext2D, x: number, y: number): void {
    ctx.lineWidth = 0.5;
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x + 3, y - 5);
    ctx.moveTo(x, y);
    ctx.lineTo(x - 3, y - 5);
    ctx.stroke();
    ctx.lineWidth = 1;
  }

  drawDeviationBox(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    r?: number
  ): void {
    r = r !== undefined ? r : 7.5;
    // add deviation box
    ctx.lineWidth = 0.5;
    ctx.beginPath();
    ctx.moveTo(x - (r * Math.PI) / 4, y - (r * Math.PI) / 2);
    ctx.lineTo(x + (r * Math.PI) / 4, y + (r * Math.PI) / 2);
    ctx.stroke();
    ctx.beginPath();
    ctx.arc(x, y, r, 0, 2 * Math.PI);
    ctx.stroke();
    ctx.lineWidth = 1;
  }

  drawElevator(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number,
    orientation: number
  ): void {
    ctx.lineWidth = 0.5;
    // draw elevator leg
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x, y - 1.5 * w);
    ctx.lineTo(x + w, y - 1.5 * w);
    ctx.lineTo(x + w, y);
    ctx.lineTo(x, y);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(x - 15, y - 1.5 * w + 2);
    ctx.lineTo(x, y - 1.5 * w + 2);
    ctx.moveTo(x - 15, y - 1.5 * w + 2);
    ctx.lineTo(x, y - 18);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(x + w + 15, y - 1.5 * w + 2);
    ctx.lineTo(x + w, y - 1.5 * w + 2);
    ctx.moveTo(x + w + 15, y - 1.5 * w + 2);
    ctx.lineTo(x + w, y - 18);
    ctx.stroke();

    // draw elevator body
    ctx.beginPath();
    ctx.moveTo(x, y - 1.5 * w);
    ctx.lineTo(x, y - h + w);
    ctx.lineTo(x + 10, y - h + w);
    ctx.lineTo(x + 10, y - 1.5 * w);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(x + w - 10, y - 1.5 * w);
    ctx.lineTo(x + w - 10, y - h + w);
    ctx.lineTo(x + w, y - h + w);
    ctx.lineTo(x + w, y - 1.5 * w);
    ctx.stroke();

    // draw elevator head
    switch (orientation) {
      case 0: {
        ctx.beginPath();
        ctx.moveTo(x, y - h + w);
        ctx.lineTo(x, y - h);
        ctx.lineTo(x + w - 5, y - h);
        ctx.lineTo(x + w + 10, y - h + 0.5 * w);
        ctx.lineTo(x + w + 10, y - h + w);
        ctx.lineTo(x, y - h + w);
        ctx.stroke();
        break;
      }
      case Math.PI: {
        ctx.beginPath();
        ctx.moveTo(x + w, y - h + w);
        ctx.lineTo(x + w, y - h);
        ctx.lineTo(x, y - h);
        ctx.lineTo(x - 10, y - h + 0.5 * w);
        ctx.lineTo(x - 10, y - h + w);
        ctx.lineTo(x + w, y - h + w);
        ctx.stroke();
        break;
      }
    }
    ctx.lineWidth = 1;
  }

  drawBeltConveyor(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    r: number,
    orientation: number
  ): void {
    ctx.lineWidth = 0.5;
    // draw conveyor
    ctx.beginPath();
    ctx.moveTo(x, y - r);
    ctx.lineTo(x + w, y - r);
    ctx.moveTo(x, y + r);
    ctx.lineTo(x + w, y + r);
    ctx.stroke();
    ctx.closePath();

    ctx.beginPath();
    ctx.arc(x, y, r, 0, 2 * Math.PI);
    ctx.stroke();
    ctx.closePath();

    ctx.beginPath();
    ctx.arc(x + w, y, r, 0, 2 * Math.PI);
    ctx.stroke();
    ctx.closePath();

    // draw conveyor ends
    // ctx.lineWidth = 0.25;
    // switch (orientation) {
    //   case 0: {
    //     // add start arrow
    //     ctx.beginPath();
    //     ctx.moveTo(x + 3, y - r - 20);
    //     ctx.lineTo(x + 3, y - r - 5);
    //     ctx.moveTo(x + 1, y - r - 8);
    //     ctx.lineTo(x + 3, y - r - 5);
    //     ctx.moveTo(x + 5, y - r - 8);
    //     ctx.lineTo(x + 3, y - r - 5);
    //     ctx.stroke();
    //
    //     // add end arrow
    //     ctx.beginPath();
    //     ctx.moveTo(x + w - 3, y - r - 5);
    //     ctx.lineTo(x + w + 8, y - r - 5);
    //     ctx.lineTo(x + w + 8, y + r + 5);
    //     ctx.stroke();
    //
    //     ctx.moveTo(x + w + 10, y + r - 1);
    //     ctx.lineTo(x + w + 8, y + r + 5);
    //     ctx.moveTo(x + w + 6, y + r - 1);
    //     ctx.lineTo(x + w + 8, y + r + 5);
    //     ctx.stroke();
    //     break;
    //   }
    //   case Math.PI: {
    //     // add start arrow
    //     ctx.beginPath();
    //     ctx.moveTo(x + w - 3, y - r - 20);
    //     ctx.lineTo(x + w - 3, y - r - 5);
    //     ctx.moveTo(x + w - 1, y - r - 8);
    //     ctx.lineTo(x + w - 3, y - r - 5);
    //     ctx.moveTo(x + w - 5, y - r - 8);
    //     ctx.lineTo(x + w - 3, y - r - 5);
    //     ctx.stroke();
    //
    //     // add end arrow
    //     ctx.beginPath();
    //     ctx.moveTo(x + 3, y - r - 5);
    //     ctx.lineTo(x - 8, y - r - 5);
    //     ctx.lineTo(x - 8, y + r + 5);
    //     ctx.stroke();
    //
    //     ctx.moveTo(x - 10, y + r - 1);
    //     ctx.lineTo(x - 8, y + r + 5);
    //     ctx.moveTo(x - 6, y + r - 1);
    //     ctx.lineTo(x - 8, y + r + 5);
    //     ctx.stroke();
    //     break;
    //   }
    // }
    ctx.lineWidth = 1;
  }

  drawChainConveyor(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number,
    orientation: number
  ): void {
    ctx.lineWidth = 0.5;
    // draw conveyor body
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x + w, y);
    ctx.lineTo(x + w, y - h);
    ctx.lineTo(x, y - h);
    ctx.closePath();
    ctx.stroke();

    // add conveyor start + end
    switch (orientation) {
      case 0: {
        ctx.beginPath();
        ctx.moveTo(x + w - 20, y);
        ctx.lineTo(x + w - 20, y - h);
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(x, y - h / 3);
        ctx.lineTo(x + 20, y - h / 3);
        ctx.moveTo(x, y - (2 * h) / 3);
        ctx.lineTo(x + 20, y - (2 * h) / 3);
        ctx.moveTo(x + 20, y);
        ctx.lineTo(x + 20, y - h);
        ctx.stroke();

        this.drawChainConveyorOutlet(ctx, x + w - 10, y, h);
        break;
      }
      case Math.PI: {
        ctx.beginPath();
        ctx.moveTo(x + 20, y);
        ctx.lineTo(x + 20, y - h);
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(x + w, y - h / 3);
        ctx.lineTo(x + w - 20, y - h / 3);
        ctx.moveTo(x + w, y - (2 * h) / 3);
        ctx.lineTo(x + w - 20, y - (2 * h) / 3);
        ctx.moveTo(x + w - 20, y);
        ctx.lineTo(x + w - 20, y - h);
        ctx.stroke();

        this.drawChainConveyorOutlet(ctx, x + 10, y, h);
        break;
      }
    }
    ctx.lineWidth = 1;
  }

  drawChainConveyorOutlet(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    h: number
  ): void {
    ctx.lineWidth = 0.5;
    ctx.beginPath();
    ctx.moveTo(x - 10, y);
    ctx.lineTo(x, y + h);
    ctx.lineTo(x + 10, y);
    ctx.stroke();
    ctx.lineWidth = 1;
  }

  drawSiloBin(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number
  ): void {
    ctx.lineWidth = 0.5;
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x + w, y);
    ctx.lineTo(x + w, y - 10);
    ctx.lineTo(x + w - 10, y - 10);
    ctx.lineTo(x + w - 10, y - h + 20);
    ctx.lineTo(x + w / 2, y - h);
    ctx.lineTo(x + 10, y - h + 20);
    ctx.lineTo(x + 10, y - 10);
    ctx.lineTo(x, y - 10);
    ctx.closePath();
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + 10, y - 10);
    ctx.lineTo(x + w - 10, y - 10);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + w / 2, y - 10);
    ctx.lineTo(x + w / 2, y - 20);
    ctx.lineTo(x + w - 10, y - 20);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + w / 2 + w / 10, y - 12);
    ctx.lineTo(x + w / 2 + w / 10 + 5, y - 17);
    ctx.lineTo(x + w / 2 + w / 10 + 10, y - 12);
    ctx.lineTo(x + w / 2 + w / 10 + 15, y - 17);
    ctx.lineTo(x + w / 2 + w / 10 + 20, y - 12);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + 10, y - h + 20);
    ctx.lineTo(x + w - 10, y - h + 20);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + w / 2, y - h);
    ctx.lineTo(x + w / 2, y - 25);
    ctx.stroke();
    ctx.lineWidth = 1;
  }

  drawDryer(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number
  ): void {
    ctx.lineWidth = 0.5;
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x + w, y);
    ctx.lineTo(x + w, y - 10);
    ctx.lineTo(x + w - 10, y - 10);
    ctx.lineTo(x + w - 10, y - h + 20);
    ctx.lineTo(x + 10, y - h + 20);
    ctx.lineTo(x + 10, y - 10);
    ctx.lineTo(x, y - 10);
    ctx.lineTo(x, y);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + w / 2 - 30, y - 10);
    ctx.lineTo(x + w / 2 - 30, y - h + 10);
    ctx.lineTo(x + w / 2 + 30, y - h + 10);
    ctx.lineTo(x + w / 2 + 30, y - 10);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + w / 2 - 30, y - h / 3);
    ctx.lineTo(x + w / 2 + 30, y - h / 3);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + w / 2 - 30, y - (2 * h) / 3);
    ctx.lineTo(x + w / 2 + 30, y - (2 * h) / 3);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + w / 2 - 30, y - h + 10);
    ctx.lineTo(x + w / 2, y - h);
    ctx.lineTo(x + w / 2 + 30, y - h + 10);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + w / 2 - 30, y - 30);
    ctx.lineTo(x + w / 2, y - 15);
    ctx.lineTo(x + w / 2 + 30, y - 30);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + 10, y - 10);
    ctx.lineTo(x + w - 10, y - 10);
    ctx.stroke();
    ctx.lineWidth = 1;
  }

  drawCleaner(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number
  ): void {
    ctx.lineWidth = 0.5;
    ctx.beginPath();
    ctx.moveTo(x, y - 10);
    ctx.lineTo(x + w, y - 10);
    ctx.lineTo(x + w, y - h);
    ctx.lineTo(x, y - h);
    ctx.lineTo(x, y - 10);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + w - 30, y - 10);
    ctx.lineTo(x + w - 30, y - h);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + w - 15, y - 15);
    ctx.lineTo(x + w - 15, y - h + 5);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x, y - h + 15);
    ctx.lineTo(x + w - 30, y - h + 15);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x, y - h + 20);
    ctx.lineTo(x + w - 30, y - h + 20);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x, y - h + 30);
    ctx.lineTo(x + w - 30, y - h + 40);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x, y - h + 35);
    ctx.lineTo(x + w - 30, y - h + 45);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x, y - h + 50);
    ctx.lineTo(x + w - 30, y - h + 60);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x, y - h + 55);
    ctx.lineTo(x + w - 30, y - h + 65);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + w / 2 - 15, y - 10);
    ctx.lineTo(x + w / 2, y);
    ctx.lineTo(x + w / 2 + 15, y - 10);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + w - 30, y - 10);
    ctx.lineTo(x + w - 15, y);
    ctx.lineTo(x + w, y - 10);
    ctx.stroke();
    ctx.lineWidth = 1;
  }

  drawIntake(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    w: number
  ): void {
    ctx.lineWidth = 0.5;
    ctx.beginPath();
    ctx.moveTo(x, y - 10);
    ctx.lineTo(x + w, y - 10);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + 20, y - 10);
    ctx.lineTo(x + 30, y);
    ctx.lineTo(x + 40, y - 10);
    ctx.lineTo(x + 50, y);
    ctx.lineTo(x + 60, y - 10);
    ctx.lineTo(x + 70, y);
    ctx.lineTo(x + 80, y - 10);
    ctx.lineTo(x + 90, y);
    ctx.lineTo(x + 100, y - 10);
    ctx.stroke();
    ctx.lineWidth = 1;
  }

  drawBufferBin(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number
  ): void {
    ctx.lineWidth = 0.5;
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x + w, y);
    ctx.lineTo(x + w, y - 10);
    ctx.lineTo(x, y - 10);
    ctx.lineTo(x, y);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + 10, y - 10);
    ctx.lineTo(x + 10, y - h + 20);
    ctx.lineTo(x + w - 10, y - h + 20);
    ctx.lineTo(x + w - 10, y - 10);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + 13, y - 10);
    ctx.lineTo(x + 13, y - 0.3 * h);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + w - 13, y - 10);
    ctx.lineTo(x + w - 13, y - 0.3 * h);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + 10, y - 0.3 * h);
    ctx.lineTo(x + w - 10, y - 0.3 * h);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + 10, y - h + 20);
    ctx.lineTo(x + w / 2, y - h);
    ctx.lineTo(x + w - 10, y - h + 20);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + w / 2, y - h);
    ctx.lineTo(x + w / 2, y - 0.3 * h - 30);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + 10, y - 0.3 * h);
    ctx.lineTo(x + w / 2, y - 0.15 * h);
    ctx.lineTo(x + w - 10, y - 0.3 * h);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + w / 2 - 5, y - 0.15 * h);
    ctx.lineTo(x + w / 2 + 5, y - 0.15 * h);
    ctx.lineTo(x + w / 2 + 5, y - 0.15 * h + 5);
    ctx.stroke();
    ctx.lineWidth = 1;
  }

  drawTruckDelivery(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number
  ): void {
    ctx.lineWidth = 0.5;
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x, y - 0.3 * h);
    ctx.lineTo(x + w, y - 0.3 * h);
    ctx.lineTo(x + w, y);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x, y - 0.3 * h);
    ctx.lineTo(x + 0.15 * w, y - 0.45 * h);
    ctx.lineTo(x + 0.85 * w, y - 0.45 * h);
    ctx.lineTo(x + w, y - 0.3 * h);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + 0.15 * w, y - 0.45 * h);
    ctx.lineTo(x + w / 2, y - 0.3 * h);
    ctx.lineTo(x + 0.85 * w, y - 0.45 * h);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + 0.15 * w, y - 0.45 * h);
    ctx.lineTo(x + 0.15 * w, y - h + 20);
    ctx.lineTo(x + 0.85 * w, y - h + 20);
    ctx.lineTo(x + 0.85 * w, y - 0.45 * h);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x + 0.15 * w, y - h + 20);
    ctx.lineTo(x + w / 2, y - h);
    ctx.lineTo(x + 0.85 * w, y - h + 20);
    ctx.stroke();
    ctx.lineWidth = 1;
  }
  // ------ ACTIONS ------ //
  customizeDialog(menuOption: string): void {
    this.showEquipmentList = false;
    this.showDeleteEquipmentDialog = false;
    this.showAddEquipmentDialog = false;
    this.showEquipmentSpecificationsDialog = false;
    switch (menuOption) {
      case 'Add equipment': {
        this.showAddEquipmentDialog = true;
        break;
      }
      case 'Delete equipment': {
        this.showDeleteEquipmentDialog = true;
        break;
      }
      case 'Equipment specifications': {
        this.showEquipmentSpecificationsDialog = true;
        break;
      }
    }
  }

  // TODO: FIX THIS!
  // handleCapacityChange($event): void {
  //   const value = $event.value;
  //   const dryer = this.currentProject.nodes.find(
  //     (n) => n.type === PartTypeEnum.DRYER
  //   );
  //   const excludedConveyors = this.currentProject.nodes.filter(
  //     (n) =>
  //       n.area === PartAreaEnum.DRYER &&
  //       (n.type === PartTypeEnum.CHAIN_CONVEYOR ||
  //         n.type === PartTypeEnum.BELT_CONVEYOR) &&
  //       n.parents.includes(dryer.id.toString())
  //   );
  //   const includedConveyors = this.currentProject.nodes.filter(
  //     (n) =>
  //       (n.type === PartTypeEnum.CHAIN_CONVEYOR ||
  //         n.type === PartTypeEnum.BELT_CONVEYOR) &&
  //       !excludedConveyors.includes(n)
  //   );
  //   for (let element of includedConveyors) {
  //     let card = this.equipmentCards.filter(
  //       (c) => c.equipment.id === element.id
  //     )[0];
  //     let param = element.partDetails3D.params as Dryer3DParams;
  //     param.capacity = value;
  //     card.InputRef.nativeElement.value = value;
  //   }
  // }

  customizeClosed(): void {
    this.showDeleteEquipmentDialog = false;
    this.showAddEquipmentDialog = false;
    this.showEquipmentList = false;
    this.showEquipmentSpecificationsDialog = false;
  }

  addEquipment(event: any): void {
    const nodeId = Math.max(...this.currentProject.nodes.map((x) => x.id)) + 1;
    const node = new Part3DNode(nodeId);
    node.area = event.partArea;
    if (event.parents && event.parents.length) {
      node.parents = event.parents.map((x) => x.toString());
    } else {
      node.parents.push(
        this.currentProject.nodes
          .find((x) => x.partDetails3D.params.name === 'Root')
          .id.toString()
      );
    }
    if (event.children && event.children.length) {
      node.children = event.children.map((x) => x.toString());
    }
    node.partDetails3D = new Part3DDetails();
    node.area = event.partArea;
    node.type = event.partType;
    node.position = new THREE.Vector3(20, 0, 20);
    node.orientation = new THREE.Vector3(0, 0, 0);
    let newIntakeConveyor: Part3DNode; // USED ONLY FOR INTAKES, NEEDS REFACTORING.
    switch (node.type) {
      case PartTypeEnum.INTAKE: {
        node.partDetails3D.params = event.params as Part3DParamsBase;
        node.partDetails3D.params.name = event.name;
        node.partDetails3D.params.color = node.partDetails3D.params.color
          ? node.partDetails3D.params.color
          : INTAKE_AREA_PARAMS.color;
        node.partDetails3D.params.dx = node.partDetails3D.params.dx
          ? new THREE.Vector3().copy(node.partDetails3D.params.dx)
          : new THREE.Vector3().copy(INTAKE_AREA_PARAMS.dx);
        node.partDetails3D.params.padding = node.partDetails3D.params.padding
          ? new THREE.Vector3().copy(node.partDetails3D.params.padding)
          : new THREE.Vector3().copy(INTAKE_AREA_PARAMS.padding);

        // add intake conveyor beneath the intake
        let intakeGrandChild: Part3DNode;
        const existingIntake = this.currentProject.nodes.find(
          (x) => x.type === PartTypeEnum.INTAKE
        );
        if (existingIntake) {
          const existingIntakeChild = this.currentProject.nodes.find(
            (x) => x.id === parseInt(existingIntake.children[0], 0)
          );
          intakeGrandChild = this.currentProject.nodes.find(
            (x) => x.id === parseInt(existingIntakeChild.children[0], 0)
          );
        }
        if (intakeGrandChild) {
          newIntakeConveyor = new Part3DNode();
          newIntakeConveyor.id =
            Math.max(...this.currentProject.nodes.map((x) => x.id)) + 2;
          newIntakeConveyor.area = PartAreaEnum.INTAKE;
          newIntakeConveyor.type = PartTypeEnum.BELT_CONVEYOR;
          newIntakeConveyor.parents.push(node.id.toString());
          newIntakeConveyor.children.push(intakeGrandChild.id.toString());
          newIntakeConveyor.partDetails3D = new Part3DDetails();
          newIntakeConveyor.position = new THREE.Vector3(
            node.position.x - node.partDetails3D.params.dx.x / 2,
            BELT_CONVEYOR_PARAMS.depth,
            node.position.dx.z
          );
          newIntakeConveyor.orientation = new THREE.Vector3(0, 0, 0);
          newIntakeConveyor.partDetails3D.params = JSON.parse(
            JSON.stringify(BELT_CONVEYOR_PARAMS)
          ) as Conveyor3DParams;
          newIntakeConveyor.partDetails3D.params.name = `Conveyor ${node.partDetails3D.params.name}`;
          newIntakeConveyor.partDetails3D.params.dx.x =
            node.partDetails3D.params.dx.x;
          // establish relationships between new equipments
          newIntakeConveyor.children = newIntakeConveyor.children.concat(
            ...node.children
          );
          newIntakeConveyor.children = newIntakeConveyor.children.filter(
            (item, i, a) => a.indexOf(item) === i
          );
          node.children = [newIntakeConveyor.id.toString()];
          intakeGrandChild.parents.push(newIntakeConveyor.id.toString());
        }
        break;
      }
      case PartTypeEnum.DELIVERY: {
        node.partDetails3D.params = event.params as Delivery3DParams;
        node.partDetails3D.params.dx.x = event.params.diameter;
        node.partDetails3D.params.dx.z = event.params.diameter;
        node.partDetails3D.params.dx.y =
          event.params.volume /
          (Math.PI * Math.pow(event.params.diameter / 2, 2));
        node.partDetails3D.params.name = event.name;
        node.partDetails3D.params.color = node.partDetails3D.params.color
          ? node.partDetails3D.params.color
          : DELIVERY_AREA_PARAMS.color;
        node.partDetails3D.params.dx = node.partDetails3D.params.dx
          ? new THREE.Vector3().copy(node.partDetails3D.params.dx)
          : new THREE.Vector3().copy(DELIVERY_AREA_PARAMS.dx);
        node.partDetails3D.params.padding = node.partDetails3D.params.padding
          ? new THREE.Vector3().copy(node.partDetails3D.params.padding)
          : new THREE.Vector3().copy(DELIVERY_AREA_PARAMS.padding);
        (node.partDetails3D.params as Delivery3DParams).support = (
          node.partDetails3D.params as Delivery3DParams
        ).support
          ? new THREE.Vector3().copy(
              (node.partDetails3D.params as Delivery3DParams).support
            )
          : new THREE.Vector3().copy(DELIVERY_AREA_PARAMS.support);
        break;
      }
      case PartTypeEnum.CLEANER: {
        node.partDetails3D.params = event.params as Cleaner3DParams;
        node.partDetails3D.params.name = event.name;
        node.partDetails3D.params.color = node.partDetails3D.params.color
          ? node.partDetails3D.params.color
          : CLEANER_PARAMS.color;
        node.partDetails3D.params.dx = node.partDetails3D.params.dx
          ? new THREE.Vector3().copy(node.partDetails3D.params.dx)
          : new THREE.Vector3().copy(CLEANER_PARAMS.dx);
        node.partDetails3D.params.padding = node.partDetails3D.params.padding
          ? new THREE.Vector3().copy(node.partDetails3D.params.padding)
          : new THREE.Vector3().copy(CLEANER_PARAMS.padding);
        (node.partDetails3D.params as Cleaner3DParams).support = (
          node.partDetails3D.params as Cleaner3DParams
        ).support
          ? new THREE.Vector3().copy(
              (node.partDetails3D.params as Cleaner3DParams).support
            )
          : new THREE.Vector3().copy(CLEANER_PARAMS.support);
        (node.partDetails3D.params as Cleaner3DParams).cover = (
          node.partDetails3D.params as Cleaner3DParams
        ).cover
          ? new THREE.Vector3().copy(
              (node.partDetails3D.params as Cleaner3DParams).cover
            )
          : new THREE.Vector3().copy(CLEANER_PARAMS.cover);
        (node.partDetails3D.params as Cleaner3DParams).capacity = (
          node.partDetails3D.params as Cleaner3DParams
        ).capacity
          ? (node.partDetails3D.params as Cleaner3DParams).capacity
          : CLEANER_PARAMS.capacity;
        break;
      }
      case PartTypeEnum.DRYER: {
        node.partDetails3D.params = event.params as Dryer3DParams;
        node.partDetails3D.params.name = event.name;
        node.partDetails3D.params.color = node.partDetails3D.params.color
          ? node.partDetails3D.params.color
          : DRYER_PARAMS.color;
        node.partDetails3D.params.dx = node.partDetails3D.params.dx
          ? new THREE.Vector3().copy(node.partDetails3D.params.dx)
          : new THREE.Vector3().copy(DRYER_PARAMS.dx);
        node.partDetails3D.params.padding = node.partDetails3D.params.padding
          ? new THREE.Vector3().copy(node.partDetails3D.params.padding)
          : new THREE.Vector3().copy(DRYER_PARAMS.padding);
        (node.partDetails3D.params as Dryer3DParams).capacity = (
          node.partDetails3D.params as Dryer3DParams
        ).capacity
          ? (node.partDetails3D.params as Dryer3DParams).capacity
          : DRYER_PARAMS.capacity;
        (node.partDetails3D.params as Dryer3DParams).moistureReduction = (
          node.partDetails3D.params as Dryer3DParams
        ).moistureReduction
          ? (node.partDetails3D.params as Dryer3DParams).moistureReduction
          : DRYER_PARAMS.moistureReduction;
        break;
      }
      case PartTypeEnum.BUFFER_BIN: {
        node.partDetails3D.params = event.params as Bin3DParams;
        node.partDetails3D.params.dx.x = event.params.diameter;
        node.partDetails3D.params.dx.z = event.params.diameter;
        node.partDetails3D.params.dx.y =
          event.params.volume /
          (Math.PI * Math.pow(event.params.diameter / 2, 2));
        node.partDetails3D.params.name = event.name;
        node.partDetails3D.params.color = node.partDetails3D.params.color
          ? node.partDetails3D.params.color
          : BUFFER_BIN_PARAMS.color;
        node.partDetails3D.params.dx = node.partDetails3D.params.dx
          ? new THREE.Vector3().copy(node.partDetails3D.params.dx)
          : new THREE.Vector3().copy(BUFFER_BIN_PARAMS.dx);
        node.partDetails3D.params.padding = node.partDetails3D.params.padding
          ? new THREE.Vector3().copy(node.partDetails3D.params.padding)
          : new THREE.Vector3().copy(BUFFER_BIN_PARAMS.padding);
        break;
      }
      case PartTypeEnum.SILO_BIN: {
        node.partDetails3D.params = event.params as Bin3DParams;
        node.partDetails3D.params.dx.x = event.params.diameter;
        node.partDetails3D.params.dx.z = event.params.diameter;
        node.partDetails3D.params.dx.y =
          event.params.volume /
          (Math.PI * Math.pow(event.params.diameter / 2, 2));
        node.partDetails3D.params.name = event.name;
        node.partDetails3D.params.color = node.partDetails3D.params.color
          ? node.partDetails3D.params.color
          : SILO_BIN_PARAMS.color;
        node.partDetails3D.params.dx = node.partDetails3D.params.dx
          ? new THREE.Vector3().copy(node.partDetails3D.params.dx)
          : new THREE.Vector3().copy(SILO_BIN_PARAMS.dx);
        node.partDetails3D.params.padding = node.partDetails3D.params.padding
          ? new THREE.Vector3().copy(node.partDetails3D.params.padding)
          : new THREE.Vector3().copy(SILO_BIN_PARAMS.padding);
        const siblings = this.currentProject.nodes.filter((x) =>
          x.children.includes(node.children[0])
        );
        let minCellPositionX = 1000;
        let minCellPositionZ = 1000;
        let cellPositionX = 0;
        let cellPositionZ = 0;
        for (const sibling of siblings) {
          if (sibling.position.x > cellPositionX) {
            cellPositionX = sibling.position.x;
          }
          if (sibling.position.z > cellPositionZ) {
            cellPositionZ = sibling.position.z;
          }
          if (minCellPositionX > sibling.position.x) {
            minCellPositionX = sibling.position.x;
          }
          if (minCellPositionZ > sibling.position.z) {
            minCellPositionZ = sibling.position.z;
          }
        }
        const orientation = new THREE.Vector3(
          cellPositionX - minCellPositionX,
          0,
          cellPositionZ - minCellPositionZ
        ).angleTo(new THREE.Vector3(0, 0, 1));
        switch (orientation) {
          case 0: {
            node.position.x = cellPositionX;
            node.position.z =
              cellPositionZ +
              node.partDetails3D.params.dx.z +
              node.partDetails3D.params.padding.z;
            break;
          }
          case Math.PI: {
            node.position.x = cellPositionX;
            node.position.z =
              cellPositionZ -
              node.partDetails3D.params.dx.z +
              node.partDetails3D.params.padding.z;
            break;
          }
          case Math.PI / 2: {
            node.position.x =
              cellPositionX +
              node.partDetails3D.params.dx.z +
              node.partDetails3D.params.padding.x;
            node.position.z = cellPositionZ;
            break;
          }
          default: {
            throw Error('Unknown orientation.');
          }
        }
        break;
      }
      case PartTypeEnum.ELEVATOR: {
        if (node.area === PartAreaEnum.STORAGE) {
          node.partDetails3D.params = event.params as Elevator3DParams;
          node.partDetails3D.params.name = event.name;
          node.partDetails3D.params.color = node.partDetails3D.params.color
            ? node.partDetails3D.params.color
            : MAIN_ELEVATOR_PARAMS.color;
          node.partDetails3D.params.dx = node.partDetails3D.params.dx
            ? new THREE.Vector3().copy(node.partDetails3D.params.dx)
            : new THREE.Vector3().copy(MAIN_ELEVATOR_PARAMS.dx);
          node.partDetails3D.params.padding = node.partDetails3D.params.padding
            ? new THREE.Vector3().copy(node.partDetails3D.params.padding)
            : new THREE.Vector3().copy(MAIN_ELEVATOR_PARAMS.padding);
          (node.partDetails3D.params as Elevator3DParams).capacity = (
            node.partDetails3D.params as Elevator3DParams
          ).capacity
            ? (node.partDetails3D.params as Elevator3DParams).capacity
            : MAIN_ELEVATOR_PARAMS.capacity;
        } else if (
          node.area === PartAreaEnum.CLEANER ||
          node.area === PartAreaEnum.DRYER ||
          node.area === PartAreaEnum.DELIVERY
        ) {
          node.partDetails3D.params = event.params as Elevator3DParams;
          node.partDetails3D.params.name = event.name;
          node.partDetails3D.params.color = node.partDetails3D.params.color
            ? node.partDetails3D.params.color
            : MAIN_ELEVATOR_PARAMS.color;
          node.partDetails3D.params.dx = node.partDetails3D.params.dx
            ? new THREE.Vector3().copy(node.partDetails3D.params.dx)
            : new THREE.Vector3().copy(MAIN_ELEVATOR_PARAMS.dx);
          node.partDetails3D.params.padding = node.partDetails3D.params.padding
            ? new THREE.Vector3().copy(node.partDetails3D.params.padding)
            : new THREE.Vector3().copy(MAIN_ELEVATOR_PARAMS.padding);
          (node.partDetails3D.params as Elevator3DParams).capacity = (
            node.partDetails3D.params as Elevator3DParams
          ).capacity
            ? (node.partDetails3D.params as Elevator3DParams).capacity
            : MAIN_ELEVATOR_PARAMS.capacity;
        } else {
          node.partDetails3D.params = event.params as Elevator3DParams;
          node.partDetails3D.params.name = event.name;
          node.partDetails3D.params.color = node.partDetails3D.params.color
            ? node.partDetails3D.params.color
            : MAIN_ELEVATOR_PARAMS.color;
          node.partDetails3D.params.dx = node.partDetails3D.params.dx
            ? new THREE.Vector3().copy(node.partDetails3D.params.dx)
            : new THREE.Vector3().copy(MAIN_ELEVATOR_PARAMS.dx);
          node.partDetails3D.params.padding = node.partDetails3D.params.padding
            ? new THREE.Vector3().copy(node.partDetails3D.params.padding)
            : new THREE.Vector3().copy(MAIN_ELEVATOR_PARAMS.padding);
          (node.partDetails3D.params as Elevator3DParams).capacity = (
            node.partDetails3D.params as Elevator3DParams
          ).capacity
            ? (node.partDetails3D.params as Elevator3DParams).capacity
            : MAIN_ELEVATOR_PARAMS.capacity;
        }
        break;
      }
      case PartTypeEnum.BELT_CONVEYOR: {
        node.partDetails3D.params = event.params as Conveyor3DParams;
        node.partDetails3D.params.name = event.name;
        node.partDetails3D.params.color = node.partDetails3D.params.color
          ? node.partDetails3D.params.color
          : BELT_CONVEYOR_PARAMS.color;
        node.partDetails3D.params.dx = node.partDetails3D.params.dx
          ? new THREE.Vector3().copy(node.partDetails3D.params.dx)
          : new THREE.Vector3().copy(BELT_CONVEYOR_PARAMS.dx);
        node.partDetails3D.params.padding = node.partDetails3D.params.padding
          ? new THREE.Vector3().copy(node.partDetails3D.params.padding)
          : new THREE.Vector3().copy(BELT_CONVEYOR_PARAMS.padding);
        (node.partDetails3D.params as Conveyor3DParams).capacity = (
          node.partDetails3D.params as Conveyor3DParams
        ).capacity
          ? (node.partDetails3D.params as Conveyor3DParams).capacity
          : BELT_CONVEYOR_PARAMS.capacity;
        (node.partDetails3D.params as Conveyor3DParams).depth = (
          node.partDetails3D.params as Conveyor3DParams
        ).depth
          ? new THREE.Vector3().copy(
              (node.partDetails3D.params as Conveyor3DParams).depth
            )
          : new THREE.Vector3(0, 0, 0);
        // set conveyor length based on longest distance between parent and child nodes
        const parentPosition = new THREE.Vector3();
        const childPosition = new THREE.Vector3();
        if (!node.partDetails3D.params.dx.x) {
          let longestDistance = 0;
          for (const parent of node.parents) {
            const parentNode = this.equipmentGraph.findNodeById(
              this.currentProject.nodes,
              parseInt(parent, 0)
            );
            for (const child of node.children) {
              const childNode = this.equipmentGraph.findNodeById(
                this.currentProject.nodes,
                parseInt(child, 0)
              );
              const distance = parentNode.position.distanceTo(
                childNode.position
              );
              if (distance > longestDistance) {
                parentPosition.copy(parentNode.part3D.position);
                childPosition.copy(childNode.part3D.position);
                longestDistance = distance;
              }
            }
          }
          node.partDetails3D.params.dx.x = longestDistance;
          node.orientation.y = new THREE.Vector3(1, 0, 0).angleTo(
            new THREE.Vector3(
              parentPosition.x - childPosition.x,
              0,
              parentPosition.z - childPosition.z
            )
          );
        }
        break;
      }
      case PartTypeEnum.CHAIN_CONVEYOR: {
        node.partDetails3D.params = event.params as Conveyor3DParams;
        node.partDetails3D.params.name = event.name;
        node.partDetails3D.params.color = node.partDetails3D.params.color
          ? node.partDetails3D.params.color
          : CHAIN_CONVEYOR_PARAMS.color;
        node.partDetails3D.params.dx = node.partDetails3D.params.dx
          ? new THREE.Vector3().copy(node.partDetails3D.params.dx)
          : new THREE.Vector3().copy(CHAIN_CONVEYOR_PARAMS.dx);
        node.partDetails3D.params.padding = node.partDetails3D.params.padding
          ? new THREE.Vector3().copy(node.partDetails3D.params.padding)
          : new THREE.Vector3().copy(CHAIN_CONVEYOR_PARAMS.padding);
        (node.partDetails3D.params as Elevator3DParams).capacity = (
          node.partDetails3D.params as Elevator3DParams
        ).capacity
          ? (node.partDetails3D.params as Elevator3DParams).capacity
          : CHAIN_CONVEYOR_PARAMS.capacity;
        (node.partDetails3D.params as Conveyor3DParams).nOutputs = (
          node.partDetails3D.params as Conveyor3DParams
        ).nOutputs
          ? (node.partDetails3D.params as Conveyor3DParams).nOutputs
          : CHAIN_CONVEYOR_PARAMS.nOutputs;
        (node.partDetails3D.params as Conveyor3DParams).depth = (
          node.partDetails3D.params as Conveyor3DParams
        ).depth
          ? new THREE.Vector3().copy(
              (node.partDetails3D.params as Conveyor3DParams).depth
            )
          : new THREE.Vector3(0, 0, 0);
        // set conveyor length based on longest distance between parent and child nodes
        const parentPosition = new THREE.Vector3();
        const childPosition = new THREE.Vector3();
        if (!node.partDetails3D.params.dx.x) {
          let longestDistance = 0;
          for (const parent of node.parents) {
            const parentNode = this.equipmentGraph.findNodeById(
              this.currentProject.nodes,
              parseInt(parent, 0)
            );
            for (const child of node.children) {
              const childNode = this.equipmentGraph.findNodeById(
                this.currentProject.nodes,
                parseInt(child, 0)
              );
              const distance = parentNode.position.distanceTo(
                childNode.position
              );
              if (distance > longestDistance) {
                parentPosition.copy(parentNode.part3D.position);
                childPosition.copy(childNode.part3D.position);
                longestDistance = distance;
              }
            }
          }
          node.partDetails3D.params.dx.x = longestDistance;
          node.orientation.y = new THREE.Vector3(1, 0, 0).angleTo(
            new THREE.Vector3(
              parentPosition.x - childPosition.x,
              0,
              parentPosition.z - childPosition.z
            )
          );
        }
        break;
      }
    }
    this.currentProject.nodes = this.equipmentGraph.addNode(
      this.currentProject.nodes,
      node
    );
    if (newIntakeConveyor) {
      this.currentProject.nodes = this.equipmentGraph.addNode(
        this.currentProject.nodes,
        newIntakeConveyor
      );
    }
    this.generate2DFlow(this.ctx, true);
    this.projectHelper.editProject(
      this.projectService$,
      this.currentProject,
      {}
    );
  }
  deleteEquipment(event: any): void {
    const existingNodes = [...this.currentProject.nodes];
    for (const nodeId of event) {
      const deletedNode = this.equipmentGraph.findNodeById(
        this.currentProject.nodes,
        nodeId
      );
      if (deletedNode) {
        this.currentProject.nodes = this.equipmentGraph.deleteNode(
          this.currentProject.nodes,
          deletedNode.id
        );
      }
    }
    try {
      this.generate2DFlow(this.ctx, true);
      this.projectHelper.editProject(
        this.projectService$,
        this.currentProject,
        {}
      );
    } catch (err) {
      this.snackBar.showError(`Failed to delete equipments. Cause ${err}.`);
      this.currentProject.nodes = [...existingNodes];
    }
  }
  showEquipment(): void {
    this.loading = false;
    this.showEquipmentList = true;
    this.showAddEquipmentDialog = false;
    this.showDeleteEquipmentDialog = false;
    this.showEquipmentSpecificationsDialog = false;
  }
  editEquipmentSpecifications(event: PartDetails3DEditsEvent): void {
    if (this.loading) {
      this.snackBar.showError(
        `Please wait for the flow diagram to finish generating...`
      );
      return;
    }
    if (event) {
      let generate2DFlow = false;
      const node = this.currentProject.nodes.find(
        (x) => x.partDetails3D.params.name === event.name
      );
      if (node) {
        const index = this.currentProject.nodes.indexOf(node);
        const originalPartType = node.type;
        if (this.currentProject.nodes[index].children !== event.children) {
          generate2DFlow = true;
        }
        this.currentProject.nodes[index].children = [...event.children];
        if (this.currentProject.nodes[index].parents !== event.parents) {
          generate2DFlow = true;
        }
        this.currentProject.nodes[index].parents = [...event.parents];
        this.currentProject.nodes[index].area = event.area;
        this.currentProject.nodes[index].type = event.type;
        this.currentProject.nodes[index].partDetails3D.params = event.params;
        if (
          originalPartType === PartTypeEnum.CHAIN_CONVEYOR &&
          event.type === PartTypeEnum.BELT_CONVEYOR
        ) {
          (
            this.currentProject.nodes[index].partDetails3D
              .params as Conveyor3DParams
          ).color = BELT_CONVEYOR_PARAMS.color;
          (
            this.currentProject.nodes[index].partDetails3D
              .params as Conveyor3DParams
          ).nOutputs = undefined;
        } else if (
          originalPartType === PartTypeEnum.BELT_CONVEYOR &&
          event.type === PartTypeEnum.CHAIN_CONVEYOR
        ) {
          (
            this.currentProject.nodes[index].partDetails3D
              .params as Conveyor3DParams
          ).color = CHAIN_CONVEYOR_PARAMS.color;
          (
            this.currentProject.nodes[index].partDetails3D
              .params as Conveyor3DParams
          ).nOutputs = (event.params as Conveyor3DParams).nOutputs;
        }
        if (generate2DFlow) {
          this.generate2DFlow(this.ctx, true);
        }
        this.projectHelper.editProject(
          this.projectService$,
          this.currentProject,
          {}
        );
      }
    }
  }
  mouseOn($event): void {}
  mouseOff($event): void {}

  cancel(): void {
    if (this.submitProposalRequestFlag) {
      localStorage.removeItem(this.localStorageKeys.currentProjectId);
      this.stepper.reset();
      this.router.navigate([this.routes.projects]);
    } else {
      this.router.navigate([this.routes.quotations]);
    }
  }

  back(): void {
    this.loading = true;
    this.saveProgress();
    this.stepper.previous();
    this.loading = false;
  }

  sendProjectFiles(): void {
    const url = this.routes.sendMail.replace(':id', this.currentProject._id);
    this.router.navigate([url]);
  }

  submitQuotationRequest(): void {
    this.loading = false;
    this.projectHelper.editProject(this.projectService$, this.currentProject, {
      name: this.currentProject.name,
      plantCapacity: true,
      navigateTo: [
        this.routes.submitQuotationRequest.replace(
          ':id',
          this.currentProject._id
        ),
      ],
    });
  }

  acceptRfp(): void {
    this.quotationHelper.acceptQuotationByProjectId(this.currentProject._id);
  }

  rejectRfp(): void {
    this.quotationHelper.rejectQuotationByProjectId(this.currentProject._id);
  }

  async saveProgress(): Promise<void> {
    this.loading = true;
    await this.projectFiles.uploadProjectFilesFlowDiagramStep(
      this.currentProject,
      this.canvasSelector,
      this.uploadService$
    );
    this.projectHelper.editProject(
      this.projectService$,
      this.currentProject,
      {}
    );
  }

  ngOnDestroy(): void {
    if (this.projectService$) {
      this.projectService$.unsubscribe();
    }
    if (this.uploadService$) {
      this.uploadService$.unsubscribe();
    }
    if (this.downloadService$) {
      this.downloadService$.unsubscribe();
    }
  }
}
