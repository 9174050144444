import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChildren,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../shared/services/api.service';
import { AuthService } from '../../shared/services/auth.service';
import { Project } from '../../shared/models/project';
import { GRAIN_TYPES } from '../../shared/constants/grain-types';
import { GenericSnackBarService } from '../../shared/services/generic-snack-bar.service';
import { Producer } from '../../shared/models/producer';
import { Quotation } from 'src/app/shared/models/quotation';
import {
  CompanyTypeEnum,
  PartAreaEnum,
  PartTypeEnum,
  ProjectHelperServiceEnum,
  QuotationHelperServiceEnum,
  QuotationStatusEnum,
  QuotationTypeEnum,
  UserHelperServiceEnum,
  UserStatusEnum,
  UserTypeEnum,
} from '../../shared/models/enums';
import { User } from 'src/app/shared/models/user';
import { SiloPlantHelperService } from 'src/app/shared/services/silo-plant-helper.service';
import {
  Bin3DParams,
  Cleaner3DParams,
  Conveyor3DParams,
  Dryer3DParams,
  Elevator3DParams,
  Intake3DParams,
  Part3DNode,
} from 'src/app/shared/models/part';
import {
  INDIVIDUAL_EQUIPMENTS_GROUPS,
  INDIVIDUAL_EQUIPMENTS_LIST,
} from 'src/app/shared/constants/individual-equipment-list';
import { Subject } from 'rxjs';
import { CloneObjectService } from 'src/app/shared/services/clone-object.service';
import { OperationResult } from '../../shared/models/helper-service-events';
import { QuotationHelperService } from '../../shared/services/quotation-helper.service';
import { ProjectHelperService } from '../../shared/services/project-helper.service';
import { UserHelperService } from '../../shared/services/user-helper.service';
import { EquipmentCardComponent } from 'src/app/shared/modules/misc/equipment-card/equipment-card.component';

@Component({
  selector: 'app-submit-quotation',
  templateUrl: './submit-quotation.component.html',
  styleUrls: ['./submit-quotation.component.scss'],
})
export class SubmitQuotationComponent implements OnInit, OnDestroy {
  @ViewChildren(EquipmentCardComponent)
  equipmentCards: QueryList<EquipmentCardComponent>;
  selectedProducers: FormControl = new FormControl(null, Validators.required);
  selectedProductTypes: FormControl = new FormControl(
    null,
    Validators.required
  );
  selectedMainProductTypes: FormControl = new FormControl(
    null,
    Validators.required
  );

  selectedIndividualEquipmentsList: Part3DNode[] = [];
  selectedIndividualEquipmentsList$: Subject<Part3DNode> =
    new Subject<Part3DNode>();

  individualEquipmentsList: Part3DNode[] = INDIVIDUAL_EQUIPMENTS_LIST;
  individualEquipmentsGroups = INDIVIDUAL_EQUIPMENTS_GROUPS;
  canSeeMoreProducers = false;
  showMoreProducers = false;
  currentProject: Project = new Project();
  quotationType: QuotationTypeEnum;
  singleMachinesQuotationType = QuotationTypeEnum.SINGLE_MACHINES;
  siloPlantQuotationType = QuotationTypeEnum.SILO_PLANT;

  message: FormControl = new FormControl(null);
  availableProducers: Producer[];
  filterProducers: Producer[];
  availableProductTypes = GRAIN_TYPES;
  loading = true;
  mainProdType = GRAIN_TYPES;
  otherProdType = GRAIN_TYPES;

  private endpoint = environment.endpoint;
  private routes = environment.routes;
  private user: User;
  private projectId: string;
  private quotation: Quotation = undefined;

  private quotationService$: Subject<OperationResult> =
    new Subject<OperationResult>();
  private projectsService$: Subject<OperationResult> =
    new Subject<OperationResult>();
  private userService$: Subject<OperationResult> =
    new Subject<OperationResult>();
  private formFieldElement;
  private formFieldElement1;
  private formFieldElement2;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private api: ApiService,
    private auth: AuthService,
    private cloner: CloneObjectService,
    private siloPlantHelper: SiloPlantHelperService,
    private quotationHelper: QuotationHelperService,
    private projectHelper: ProjectHelperService,
    private userHelper: UserHelperService,
    private snackBar: GenericSnackBarService,
    private elementRef: ElementRef
  ) {
    this.api.http = this.http;
  }

  ngOnInit(): void {
    this.quotationService$.subscribe((event: OperationResult) => {
      if (event.status === QuotationHelperServiceEnum.CREATED) {
        if (
          this.quotationType &&
          this.quotationType == QuotationTypeEnum.SINGLE_MACHINES
        ) {
          this.router.navigate([this.routes.quotations]);
        } else {
          this.save(this.routes.projects, QuotationStatusEnum.PENDING);
        }
      }
      if (event.status === QuotationHelperServiceEnum.ERROR) {
        this.loading = false;
      }
    });
    this.projectsService$.subscribe((event: OperationResult) => {
      if (event.status === ProjectHelperServiceEnum.CREATED) {
        let quoat = event.data.quotation;
        quoat.projectId = event.data.id;
        this.quotationHelper.createQuotation(quoat, this.quotationService$);
      }
      if (event.status === ProjectHelperServiceEnum.EDITED) {
        this.loading = false;
        this.router.navigate([event.data.navigateTo]);
      }
      if (event.status === ProjectHelperServiceEnum.ERROR) {
        this.loading = false;
      }
      if (event.status === ProjectHelperServiceEnum.LEAVE_PAGE) {
        this.loading = false;
        this.router.navigate(event.data.navigateTo);
      }
      if (event.status === ProjectHelperServiceEnum.LOADED) {
        this.loading = false;
      }
    });
    this.userService$.subscribe((event: OperationResult) => {
      if (event.status === UserHelperServiceEnum.LOADED) {
        if (event.data.hasOwnProperty('users')) {
          let country;
          if (this.user && this.user.company.country !== undefined) {
            country = this.user.company.country;
            this.canSeeMoreProducers = true;
          }
          this.availableProducers = event.data.users;
          if (this.canSeeMoreProducers) {
            this.filterProducers = this.availableProducers.filter(
              (producer: Producer) => producer.company.country === country
            );
            if (this.filterProducers.length <= 1) {
              this.canSeeMoreProducers = false;
            }
          }
        }
        if (event.data.hasOwnProperty('user')) {
          this.user = event.data.user;
        }
        this.loading = false;
      }
      if (event.status === UserHelperServiceEnum.ERROR) {
        this.loading = false;
      }
    });
    this.initialize();
  }

  initialize(): void {
    this.route.queryParams.subscribe((params) => {
      this.quotationType = params.quotationType;
      if (
        this.quotationType &&
        this.quotationType == QuotationTypeEnum.SINGLE_MACHINES
      ) {
        this.selectedIndividualEquipmentsList$.subscribe((equipment) => {
          this.selectedIndividualEquipmentsList.push(equipment);
        });
      } else {
        this.loading = true;
        this.quotationType = QuotationTypeEnum.SILO_PLANT;
        this.route.params.subscribe((params) => {
          this.projectId = params.id;
          if (this.projectId) {
            this.projectHelper.getProject(
              this.projectsService$,
              this.projectId
            );
            this.api
              .getById<Project>(this.endpoint.projectById, this.projectId)
              .subscribe(
                (project) => {
                  if (!project) {
                    this.snackBar.showError(
                      'Empty equipment list. Please return to the design section before submitting an offer request.'
                    );
                  } else {
                    this.currentProject = project;
                    this.loading = false;
                  }
                },
                (err) => {
                  this.snackBar.showError(
                    `Failed to load project details. Cause ${err.message}.`
                  );
                  this.loading = false;
                }
              );
          }
        });
      }
    });
    this.userHelper.getUserById(this.userService$, this.auth.getUserId());
    this.userHelper.getProducers(this.userService$, {
      status: UserStatusEnum.ACTIVE,
    });
  }

  // ====== INDIVIDUAL EQUIPMENTS INTERACTION ====== //
  addEquipment(equipmentId: number, groupId: number): void {
    for (const group of this.individualEquipmentsGroups) {
      if (group.id === groupId) {
        const selectedGroup = this.individualEquipmentsList.filter(
          (eq) => eq.partDetails3D.params.group === group.id
        );
        for (const equipment of selectedGroup) {
          if (equipment.id === equipmentId) {
            this.selectedIndividualEquipmentsList$.next(equipment);
            return;
          }
        }
      }
    }
  }

  private onChangeSelection($event): any[] {
    let list = [];
    const selected = $event.value;
    if (selected instanceof Array) {
      selected.forEach((id) => {
        list = GRAIN_TYPES.filter((v) => v.id !== id);
      });
      if (selected.length <= 0) {
        list = GRAIN_TYPES;
      }
    } else {
      list = GRAIN_TYPES.filter((v) => v.id !== selected);
    }
    return list;
  }

  onOtherProductTypesChange($event): void {
    this.mainProdType = this.onChangeSelection($event);
  }

  onMainProductTypesChange($event): void {
    this.otherProdType = this.onChangeSelection($event);
    this.formFieldElement1 = this.elementRef.nativeElement.querySelector(
      '.rfp-details-half-input-mainprodtype .mat-form-field-wrapper .mat-form-field-flex'
    );
    const v = $event.value;
    if (v) {
      this.formFieldElement1.style.border = '1px solid #dee5f6';
    }
  }

  onProducersChange($event): void {
    const selectedOptions = $event.value;
    this.formFieldElement = this.elementRef.nativeElement.querySelector(
      '.rfp-details-half-input-producers .mat-form-field-wrapper .mat-form-field-flex'
    );
    if (selectedOptions.length > 0) {
      this.formFieldElement.style.border = '1px solid #dee5f6';
    } else {
      this.formFieldElement.style.border = '1px solid red';
    }
  }

  producersList(): Producer[] {
    if (this.showMoreProducers) {
      return this.availableProducers;
    } else {
      return this.canSeeMoreProducers
        ? this.filterProducers
        : this.availableProducers;
    }
  }

  showMoreProducersBtn(): void {
    this.showMoreProducers = !this.showMoreProducers;
  }

  deleteEquipment(event: number): void {
    if (event) {
      this.selectedIndividualEquipmentsList.forEach((x, index) => {
        if (x.id === event) {
          this.selectedIndividualEquipmentsList.splice(index, 1);
        }
      });
    }
  }

  findEquipmentsInGroup(groupId: number): Part3DNode[] {
    return this.individualEquipmentsList.filter(
      (eq) => eq.partDetails3D.params.group === groupId
    );
  }

  isProducersEmpty() {
    if (!this.selectedProducers.value) {
      return true;
    }
    if (this.selectedProducers.value.length === 0) {
      return true;
    }
    return false;
  }

  // handleCapacityChange($event): void {
  //   const value = $event.value;
  //   const dryer = this.currentProject.nodes.find(
  //     (n) => n.type === PartTypeEnum.DRYER
  //   );
  //   const excludedConveyors = this.currentProject.nodes.filter(
  //     (n) =>
  //       n.area === PartAreaEnum.DRYER &&
  //       (n.type === PartTypeEnum.CHAIN_CONVEYOR ||
  //         n.type === PartTypeEnum.BELT_CONVEYOR) &&
  //       n.parents.includes(dryer.id.toString())
  //   );
  //   const includedConveyors = this.currentProject.nodes.filter(
  //     (n) =>
  //       (n.type === PartTypeEnum.CHAIN_CONVEYOR ||
  //         n.type === PartTypeEnum.BELT_CONVEYOR) &&
  //       !excludedConveyors.includes(n)
  //   );
  //   for (let element of includedConveyors) {
  //     let card = this.equipmentCards.filter(
  //       (c) => c.equipment.id === element.id
  //     )[0];
  //     let param = element.partDetails3D.params as Dryer3DParams;
  //     param.capacity = value;
  //     card.InputRef.nativeElement.value = value;
  //   }
  // }

  isValidNumber(value: number): boolean {
    if (value === undefined || value === null) {
      return true;
    }
    if (value <= 0) {
      return true;
    }
    return false;
  }

  submitQuotation(): void {
    this.formFieldElement = this.elementRef.nativeElement.querySelector(
      '.rfp-details-half-input-producers .mat-form-field-wrapper .mat-form-field-flex'
    );
    this.formFieldElement1 = this.elementRef.nativeElement.querySelector(
      '.rfp-details-half-input-mainprodtype .mat-form-field-wrapper .mat-form-field-flex'
    );
    if (this.isProducersEmpty()) {
      this.formFieldElement.style.border = '1px solid red';
    } else {
      this.formFieldElement.style.border = 'none';
    }
    if (!this.selectedMainProductTypes.value) {
      this.formFieldElement1.style.border = '1px solid red';
    }
    if (this.selectedProducers.errors || this.selectedMainProductTypes.errors) {
      this.loading = false;
      this.snackBar.showError(
        'Missing required information. Please complete the missing fields and try again.'
      );
      return;
    }
    // get main product type selected
    const mainProductType = this.availableProductTypes.find(
      (product) => product.id === this.selectedMainProductTypes.value
    ).name;
    // get other product types selected
    const otherProductTypes = [];
    if (
      this.selectedProductTypes.value &&
      this.selectedProductTypes.value.length
    ) {
      if (this.selectedProductTypes.value instanceof Array) {
        for (const pt of this.selectedProductTypes.value) {
          const productType = this.availableProductTypes.find(
            (p) => p.id === pt
          );
          if (productType) {
            otherProductTypes.push(productType.name);
          }
        }
      } else {
        const productType = this.availableProductTypes.find(
          (p) => p.id === this.selectedProductTypes.value
        );
        if (productType) {
          otherProductTypes.push(productType.name);
        }
      }
    }
    // get user country
    let companyCountry;
    if (this.user?.company.country) {
      companyCountry = this.user.company.country;
    }
    // get user message
    let userMessage;
    if (this.message.value) {
      userMessage = this.message.value;
    }
    // get silo plant capacity
    if (this.quotationType === QuotationTypeEnum.SILO_PLANT) {
      this.currentProject = this.siloPlantHelper.getPlantCapacityDetails(
        this.currentProject
      );
    }
    // build a quotation per producer
    for (const producerId of this.selectedProducers.value) {
      // get producer details
      const currentProducer = this.availableProducers.find(
        (producer) => producer._id === producerId
      );
      if (currentProducer) {
        this.quotation = new Quotation();
        this.quotation.quotationType = this.quotationType;
        // add equipments and project name and id
        if (this.quotationType === QuotationTypeEnum.SILO_PLANT) {
          this.quotation.equipments = this.currentProject.nodes;
          this.quotation.projectName = this.currentProject.name;
          this.quotation.dryingCapacity = this.currentProject.dryingCapacity;
          this.quotation.storageCapacity = this.currentProject.storageCapacity;
          this.quotation.handlingCapacity =
            this.currentProject.handlingCapacity;
          this.quotation.mainElevatorCapacity =
            this.currentProject.mainElevatorCapacity;
        } else {
          this.quotation.equipments = [
            ...this.selectedIndividualEquipmentsList,
          ];
          const size = this.quotation.equipments.length;
          if (size <= 0) {
            this.snackBar.showError('Please add equipments in the list.');
            return;
          }
        }
        let valid = true;
        this.quotation.equipments.forEach((equipment) => {
          const eqt = equipment.type;
          if (
            eqt === PartTypeEnum.BUFFER_BIN ||
            eqt === PartTypeEnum.SILO_BIN ||
            eqt === PartTypeEnum.DELIVERY
          ) {
            let param: Bin3DParams = equipment.partDetails3D
              .params as Bin3DParams;
            let diameter = param.dx.x;
            let volume = param.volume;
            if (this.isValidNumber(diameter) && this.isValidNumber(volume)) {
              valid = false;
              this.snackBar.showError(
                'Missing required information. Please check your bins configuration. Make sure the values are above 0.'
              );
              return;
            }
          } else if (eqt === PartTypeEnum.INTAKE) {
            let param = equipment.partDetails3D.params as Intake3DParams;
            let length = param.dx.x;
            let width = param.dx.z;
            let capacity = param.capacity;
            if (
              this.isValidNumber(length) &&
              this.isValidNumber(capacity) &&
              this.isValidNumber(width)
            ) {
              valid = false;
              this.snackBar.showError(
                'Missing required information. Please check your intake configuration. Make sure the values are above 0.'
              );
              return;
            }
          } else if (eqt === PartTypeEnum.ELEVATOR) {
            let param = equipment.partDetails3D.params as Elevator3DParams;
            let capacity = param.capacity;
            let h = param.dx.y;
            if (this.isValidNumber(capacity) && this.isValidNumber(h)) {
              valid = false;
              this.snackBar.showError(
                'Missing required information. Please check your elevator configuration. Make sure the values are above 0.'
              );
              return;
            }
          } else if (eqt === PartTypeEnum.CLEANER) {
            let param = equipment.partDetails3D.params as Cleaner3DParams;
            let capacity = param.capacity;
            if (this.isValidNumber(capacity)) {
              valid = false;
              this.snackBar.showError(
                'Missing required information. Please check your cleaner configuration. Make sure the values are above 0.'
              );
              return;
            }
          } else if (
            eqt === PartTypeEnum.BELT_CONVEYOR ||
            eqt === PartTypeEnum.CHAIN_CONVEYOR
          ) {
            let param = equipment.partDetails3D.params as Conveyor3DParams;
            let capacity = param.capacity;
            let length = param.dx.x;
            if (this.isValidNumber(length) && this.isValidNumber(capacity)) {
              valid = false;
              this.snackBar.showError(
                'Missing required information. Please check your conveyors configuration. Make sure the values are above 0.'
              );
              return;
            }
          } else if (eqt === PartTypeEnum.DRYER) {
            let param = equipment.partDetails3D.params as Dryer3DParams;
            let moisture = param.moistureReduction;
            let capacity = param.capacity;
            if (this.isValidNumber(capacity) && this.isValidNumber(moisture)) {
              valid = false;
              this.snackBar.showError(
                'Missing required information. Please check your dryers configuration. Make sure the values are above 0.'
              );
              return;
            }
          }
        });
        if (!valid) return;
        // add min and max handling capacity details
        this.quotation.minHandlingCapacity =
          this.siloPlantHelper.getMinHandlingCapacity(
            this.quotation.equipments
          );
        this.quotation.maxHandlingCapacity =
          this.siloPlantHelper.getMaxHandlingCapacity(
            this.quotation.equipments
          );
        // add product types
        this.quotation.mainProductType = mainProductType;
        this.quotation.otherProductTypes = otherProductTypes;
        // add user details
        this.quotation.userId = this.auth.getUserId();
        this.quotation.userFirstName = this.auth.getFirstName();
        this.quotation.userLastName = this.auth.getLastName();
        this.quotation.userCountry = companyCountry;
        this.quotation.country = currentProducer.company.country;
        this.quotation.producerCompany = currentProducer.company.name;
        this.quotation.producerId = producerId;
        this.quotation.projectId = this.projectId;
        this.quotation.status = QuotationStatusEnum.PENDING;
        this.quotation.companyId = this.auth.getCompanyId();
        this.quotation.message = userMessage;
        this.quotation.paymentAccepted = undefined;
        if (this.quotationType === QuotationTypeEnum.SILO_PLANT) {
          // create project clone and attach it to current project
          // clone current project
          const projectClone = this.cloner.deepCopyProject(this.currentProject, this.currentProject.name, false);
          projectClone._id = undefined;
          projectClone.parentId = this.currentProject._id;
          projectClone.name = this.currentProject.name;
          projectClone.producerId = this.quotation.producerId;
          // create child project
          this.projectHelper.insertProject(
            projectClone,
            this.quotation,
            this.projectsService$
          );
        } else {
          // save quotation to database
          this.quotationHelper.createQuotation(
            this.quotation,
            this.quotationService$
          );
        }
      }
    }
  }

  save(navigateTo: string, status?: QuotationStatusEnum): void {
    this.loading = true;
    this.projectHelper.editProject(this.projectsService$, this.currentProject, {
      plantCapacity: true,
      navigateTo,
      status,
    });
  }

  back(): void {
    if (this.quotationType === QuotationTypeEnum.SILO_PLANT) {
      this.save(this.routes.projects);
    } else {
      this.save(this.routes.quotations);
    }
  }

  ngOnDestroy(): void {
    this.quotationService$.unsubscribe();
    this.projectsService$.unsubscribe();
  }
}
