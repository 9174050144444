import { Injectable} from '@angular/core';
import * as CANNON from 'cannon-es'
import * as THREE from 'three';
import CannonDebugger from 'cannon-es-debugger';
import { Debug3DHelperService } from './3d-debug-helper.service';
import { Part3DNode, SiloGroupOrientation } from '../models/part';
import { PartTypeEnum, SyncStatesEnum } from '../models/enums';
import { DEFAULT_TILE_SIZE, NTILES } from '../constants/scene-defaults';
import { VectorService } from './vector.service';
@Injectable({
  providedIn: 'root'
})
export class PhysicsService {

  private debuggerView: boolean = false;
  private guiDebugger:any;
  private defaultMaterial: CANNON.Material = new CANNON.Material('default');
  private contactMaterial: CANNON.ContactMaterial = new CANNON.ContactMaterial(
    this.defaultMaterial,
    this.defaultMaterial,
    {
      friction: 1,
      restitution: 0,
      contactEquationRelaxation:10,

    });
  private cannonDebugger:any;
  private world: CANNON.World;

  constructor(private threejsDebugger: Debug3DHelperService,
    private vectorService: VectorService){

  }

  initPhysicsWorld(scene: THREE.Scene): any{

    this.world = new CANNON.World();
    this.world.gravity.set(0, -9.82, 0);
    this.world.allowSleep = true;
    this.world.broadphase = new CANNON.SAPBroadphase(this.world);
    this.world.defaultContactMaterial = this.contactMaterial;

    const floorShape = new CANNON.Plane();
    const floorBody = new CANNON.Body();
    floorBody.mass = 0;
    floorBody.material = this.defaultMaterial;
    floorBody.addShape(floorShape);
    floorBody.quaternion.setFromAxisAngle(
      new CANNON.Vec3(-1, 0 ,0),
      Math.PI / 2
    )

    this.world.addBody(floorBody);


    return[this.world]
  }
  createCannonDebugger(scene: any){
      this.cannonDebugger = CannonDebugger(scene, this.world,{
      color: 0xff0000,
    });
    this.guiDebugger = this.threejsDebugger.getDebuggerInstance();

    this.guiDebugger.add(this.world.gravity, 'y').name('Gravity');
    this.guiDebugger.add(this.world, "allowSleep");
    this.guiDebugger.add(this.contactMaterial, 'friction');
  }

  createPhysicsBodies(nodes: Part3DNode[], scene: any): Part3DNode[]{

    /**
     * 1. Find the bounding box of the node
     * 2. Create the physics body from the bbox ( the cube will have half of the dimensions of the box in CANNON )
     * 3. Add body to world
     */

    // Cannon.js body
    nodes.forEach((node) => {
      if(node.physicsBody !== undefined){
        const bodyId = this.world.bodies.indexOf(node.physicsBody);
        this.world.bodies.splice(bodyId, 1);
        node.physicsBody = undefined;
      }
      node = this.createPhysicsBody(nodes, node, scene);
      node.sync = SyncStatesEnum.SYNC_NOT_NEEDED;
    })
    return nodes;
  }

  createPhysicsBody(nodes: Part3DNode[], sceneObject: Part3DNode, scene: any): Part3DNode{
    const node = nodes.find((node) => node.id === sceneObject.id);

    if(node.type !== PartTypeEnum.BELT_CONVEYOR
      && node.type !== PartTypeEnum.CHAIN_CONVEYOR){

        const boxHelper = scene.children.find(c => c.type === 'Box3Helper' && c.name === node.partDetails3D.params.name);
        const dimensions: CANNON.Vec3 = new CANNON.Vec3(
          (boxHelper.box.max.x - boxHelper.box.min.x) / 2,
          (boxHelper.box.max.y - boxHelper.box.min.y) / 2,
          (boxHelper.box.max.z - boxHelper.box.min.z) / 2,
        )

        const shape = new CANNON.Box(
            new CANNON.Vec3(
              /*
                Apply half of the padding only for the silo bins, + sign used for conversion from true/false to 0/1
              */
                dimensions.x + ((0.5 * node.partDetails3D.params.padding.x)),// +(node.type === PartTypeEnum.SILO_BIN)),
                dimensions.y,
                dimensions.z + ((0.5 * node.partDetails3D.params.padding.z))))// * +(node.type === PartTypeEnum.SILO_BIN))));
        const body = new CANNON.Body({
          mass: node.type === PartTypeEnum.ELEVATOR ? 0 : 1,
          shape,
          material: this.defaultMaterial,
          collisionFilterGroup: 1, // Put the object in group 1
          collisionFilterMask: 1, // It can only collide with group 1 objects
        });
        body.position.copy(boxHelper.box.getCenter());
        // body.quaternion.copy(node.part3D.part.quaternion);
        /**
         * Bins must not rotate on Y
         */
        body.angularFactor.set(
          0,
          0,//+(node.type !== PartTypeEnum.SILO_BIN && node.type !== PartTypeEnum.BUFFER_BIN && node.type !== PartTypeEnum.DELIVERY),
          0);
        // body.quaternion.copy(node.part3D.part.quaternion);
        // body.addEventListener('collide', () => console.log('colision'));
        this.world.addBody(body);

        node.physicsBody = body;
      }
      else{
        node.physicsBody = null;
      }
    return node;
  }
  moveBody(nodes: Part3DNode[], node, axis: string, value: number): Part3DNode[]{
    node.physicsBody.position = new CANNON.Vec3(
      axis === 'x' ? node.physicsBody.position.x + value : node.physicsBody.position.x,
      axis === 'y' ? node.physicsBody.position.y + value : node.physicsBody.position.y,
      axis === 'z' ? node.physicsBody.position.z + value : node.physicsBody.position.z
    );

    return nodes;
  }

  isolateNode(node: Part3DNode, nodes: Part3DNode[], orientation: SiloGroupOrientation): Promise<boolean>{

    return new Promise((resolve, reject) => {
      const newNodes = nodes.filter((n) => n.id !== node.id && n.type !== PartTypeEnum.CHAIN_CONVEYOR && n.type !== PartTypeEnum.BELT_CONVEYOR && n.type !== PartTypeEnum.SILO_BIN && n.type !== PartTypeEnum.INTAKE);
      this.addBinsSeparation(orientation);
        for(let i = 0; i < newNodes.length; i++){
          for(let j = i + 1; j < newNodes.length; j++){
            this.createConstraint(newNodes[i], newNodes[j], "LockConstraint");
          }
        }
        for(let i = 0; i < orientation.binsMatrix[0].length; i++){
          orientation.binsMatrix[0][i].physicsBody.linearFactor.set(Math.abs(orientation.axis.L.x), 0, Math.abs(orientation.axis.L.z));
        }
      setTimeout(() => {
        resolve(true);
      }, 100);
    })

  }

  addBinsSeparation(orientation:SiloGroupOrientation) : void{

    let halfExtentX, halfExtentY, halfExtentZ;
    if(orientation.axis.T.x){
      halfExtentX = (NTILES * DEFAULT_TILE_SIZE);
      halfExtentY = DEFAULT_TILE_SIZE / 2;
      halfExtentZ = 0.01 / 2;
    }
    else{
      halfExtentX = 0.01 / 2;
      halfExtentY = DEFAULT_TILE_SIZE / 2;
      halfExtentZ = (NTILES * DEFAULT_TILE_SIZE);
    }

    const position: CANNON.Vec3 = new CANNON.Vec3();

    if(orientation.axis.L.x + orientation.axis.L.z === -1){
      position.copy(
        this.vectorService.multiplyVectors(
          orientation.axis.L,
          new CANNON.Vec3(
            orientation.bin.physicsBody.aabb.upperBound.x,
            0,
            orientation.bin.physicsBody.aabb.upperBound.z
          )
        )
      )
    }
    else{
      position.copy(
        this.vectorService.multiplyVectors(
          orientation.axis.L,
          new CANNON.Vec3(
            orientation.bin.physicsBody.aabb.lowerBound.x,
            0,
            orientation.bin.physicsBody.aabb.lowerBound.z
          )
        )
      )
    }

    const shape = new CANNON.Box(
      new CANNON.Vec3(
        halfExtentX,
        halfExtentY,
        halfExtentZ
      )
    )
    const body = new CANNON.Body({
      mass: 0,
      shape,
      material: this.defaultMaterial,
      collisionFilterGroup: 1, // Put the object in group 1
      collisionFilterMask: 1, // It can only collide with group 1 objects
    });
    body.position.set(Math.abs(position.x), halfExtentY, Math.abs(position.z));
    this.world.addBody(body);
    setTimeout(() => {
      this.world.removeBody(body);
    }, 1000 * 2);
  }

  createConstraint(nodeA: Part3DNode, nodeB: Part3DNode, constraintType:Constraints): void{

    let lockConstraint:CANNON.Constraint;

    switch(constraintType){
      case "DistanceConstraint": {
        const distance = nodeA.part3D.params.dx.x / 2
        + nodeA.partDetails3D.params.padding.x
        + nodeB.part3D.params.dx.x / 2;

        lockConstraint = new CANNON.DistanceConstraint(nodeA.physicsBody, nodeB.physicsBody, distance);
        break;
      }
      case "LockConstraint": {
        lockConstraint = new CANNON.LockConstraint(nodeA.physicsBody, nodeB.physicsBody);
        break;
      }
    }
    if(lockConstraint !== undefined){
      this.world.addConstraint(lockConstraint)
    }
  }

  lockSiloBins(nodes: Array<Part3DNode>, binsMatrix: Array<Array<Part3DNode>>): void{

    const bins = nodes.find((node) => node.type === PartTypeEnum.SILO_BIN);

    for(let i = 0; i < binsMatrix.length; i++){
      for(let j = 0; j < binsMatrix[i].length; j++){
        if(i < binsMatrix.length - 1){
          this.createConstraint(binsMatrix[i][j], binsMatrix[i + 1][j], "DistanceConstraint");
        }
        if(j < binsMatrix[i].length - 1){
          this.createConstraint(binsMatrix[i][j], binsMatrix[i][j + 1], "DistanceConstraint");
        }
      }
    }

  }
  removeNodeIsolation(): void{
    this.world.constraints = [];
  }
  updatePhysicsBody(node: Part3DNode, scene): Promise<boolean>{
    return new Promise<boolean>((resolve, reject) => {
      if(node.physicsBody !== null){
        /**
         * Using linearFactor restricts the movement of the body to specific axis
         * Using angularVector restricts the rotation of the body to specific axis
         */
        // node.physicsBody.linearFactor.set(0,0,1); // Only allow movement along Z
        const shape = node.physicsBody.shapes[0] as CANNON.Box;
        const box3Helper = scene.children.find(c => c.type === 'Box3Helper' && c.name === node.partDetails3D.params.name);

        shape.halfExtents.set(
          Math.abs(box3Helper.scale.x) + (node.partDetails3D.params.padding.x * 0.5),// * +(node.type === PartTypeEnum.SILO_BIN),
          Math.abs(box3Helper.scale.y),
          Math.abs(box3Helper.scale.z) + (node.partDetails3D.params.padding.z * 0.5)// * +(node.type === PartTypeEnum.SILO_BIN)
          )

          node.physicsBody.position.copy(box3Helper.box.getCenter());

          shape.updateConvexPolyhedronRepresentation();
          node.physicsBody.updateBoundingRadius();
          node.physicsBody.updateMassProperties();
          setTimeout(() => {
            resolve(true);
          }, 1000);
      }
    });
  }

  allowSleep(value: boolean): void{

    this.world.allowSleep = value;
  }

  updatePhysicsWorld(nodes: Part3DNode[], scene):void{
    // const elapsedTime = this.clock.getElapsedTime();
    // const deltaTime = elapsedTime - this.oldElapsedTime;
    // this.oldElapsedTime = elapsedTime;

    for(const node of nodes){
      if(node.physicsBody !== undefined && node.physicsBody !== null){

        node.physicsBody.velocity.set(0, 0, 0);
        node.physicsBody.vlambda.set(0, 0, 0);
        node.part3D.part.position.x = node.position.x = node.physicsBody.position.x;
        node.part3D.part.position.z = node.position.z = node.physicsBody.position.z;

        // node.part3D.part.quaternion.copy(node.physicsBody.quaternion);

        const sceneEquipment =
              scene.children.find(
                (e) => e.name === node.partDetails3D.params.name && e.type !== 'Box3Helper'
              );
        if(sceneEquipment !== undefined){
          sceneEquipment.position.copy(node.part3D.part.position);
          // sceneEquipment.quaternion.copy(node.physicsBody.quaternion);
        }
      }
    }
    // Update physics world
    this.world.step(1 / 60);

    // Cannon debugger
    if(this.cannonDebugger !== undefined){
      this.cannonDebugger.update() // Update the CannonDebugger meshes
    }

    requestAnimationFrame(() => {
      this.updatePhysicsWorld(nodes, scene)
    })
}
}

export type Constraints = "LockConstraint" | "DistanceConstraint";
