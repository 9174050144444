import { Injectable } from '@angular/core';
import { ExportToCsv } from 'export-to-csv';

@Injectable({
  providedIn: 'root',
})
export class DownloadCsvService {
  constructor() {}

  download(
    title: string,
    filename: string,
    data: string[][],
    header?: string[]
  ): void {
    if (!filename) {
      filename = 'Untitled';
    }
    let options;
    if (!header || !header.length) {
      options = {
        fieldSeparator: ',',
        filename: filename,
        quoteStrings: '"',
        decimalSeparator: 'locale',
        showLabels: true,
        showTitle: true,
        title: title,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      };
    } else {
      options = {
        fieldSeparator: ',',
        filename: filename,
        quoteStrings: '"',
        decimalSeparator: 'locale',
        showLabels: true,
        showTitle: true,
        title: title,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        headers: header,
      };
    }
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  }
}
