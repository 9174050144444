<!--
  Legend:
    partDetails3D.params.dx.x === length
    partDetails3D.params.dx.y === height
    partDetails3D.params.dx.z === width
-->
<div class="container">
  <h3>Equipment specifications</h3>
  
  <app-dropdown-search [options]="updatedPart3DNodes" label="Equipments" [_formControl]="equipmentName" 
    [formFieldClass]="'full-input'"
    (onMouseOverEvent)="mouseOverOn($event)" (onMouseOutEvent)="mouseOverOff($event)" (onSelectionChangeEvent)="getNode($event)">
  </app-dropdown-search>
  
  <div *ngIf="isSelected === true">
    <div class="input-container">
      <app-dropdown-search [options]="partAreas" label="Area" [_formControl]="equipmentArea" 
      [formFieldClass]="'first-input'">
      </app-dropdown-search>

      <app-dropdown-search *ngIf="!isConveyor" 
        [options]="partTypes" label="Type" [_formControl]="equipmentType" 
        [formFieldClass]="'second-input'">
      </app-dropdown-search>

      <app-dropdown-search *ngIf="isConveyor" 
        [options]="partTypeConveyor" label="Conveyor type" [_formControl]="equipmentTypeConveyor" 
        [formFieldClass]="'second-input'">
      </app-dropdown-search>
    </div>

    <div class="input-container">
      <app-dropdown-search [options]="updatedPart3DNodes" label="Inputs" [_formControl]="equipmentParents" 
        [formFieldClass]="'first-input'"
        (onMouseOverEvent)="mouseOverOn($event)" (onMouseOutEvent)="mouseOverOff($event)"
        [multipleOption]="true">
      </app-dropdown-search>

      <app-dropdown-search [options]="updatedPart3DNodes" label="Outputs" [_formControl]="equipmentChildren" 
        [formFieldClass]="'second-input'"
        (onMouseOverEvent)="mouseOverOn($event)" (onMouseOutEvent)="mouseOverOff($event)"
        [multipleOption]="true">
      </app-dropdown-search>
    </div>

  </div>

  <!-- Equipment parameters -->
  <!-- Silo bin -->
  <div *ngIf="equipmentType.value == partTypeEnum.SILO_BIN">
    <mat-form-field appearance="outline" class="first-input">
      <mat-label>Volume (m^3)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="equipment.partDetails3D.params.volume" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="second-input">
      <mat-label>Diameter (m)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="equipment.partDetails3D.params.dx.x" />
    </mat-form-field>
  </div>

  <!-- Buffere bin -->
  <div *ngIf="equipmentType.value === partTypeEnum.BUFFER_BIN">
    <mat-form-field appearance="outline" class="first-input">
      <mat-label>Volume (m^3)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="equipment.partDetails3D.params.volume" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="second-input">
      <mat-label>Diameter (m)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="equipment.partDetails3D.params.dx.x" />
    </mat-form-field>
  </div>

  <!-- Delivery bin -->
  <div *ngIf="equipmentType.value === partTypeEnum.DELIVERY">
    <mat-form-field appearance="outline" class="first-input">
      <mat-label>Volume (m^3)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="equipment.partDetails3D.params.volume" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="second-input">
      <mat-label>Diameter (m)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="equipment.partDetails3D.params.dx.x" />
    </mat-form-field>
  </div>

  <!-- Intake -->
  <!-- <div *ngIf="equipmentType.value === partTypeEnum.INTAKE">
    <mat-form-field appearance="outline" class="first-input">
      <mat-label>Capacity (t/h)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="equipment.partDetails3D.params.capacity" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="second-input">
      <mat-label>Length (m)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="equipment.partDetails3D.params.dx.x" />
    </mat-form-field>
  </div> -->

  <!-- Elevator -->
  <div *ngIf="equipmentType.value === partTypeEnum.ELEVATOR">
    <mat-form-field appearance="outline" class="first-input">
      <mat-label>Capacity (t/h)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="equipment.partDetails3D.params.capacity" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="second-input">
      <mat-label>Height (m)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="equipment.partDetails3D.params.dx.y" />
    </mat-form-field>
  </div>

  <!-- Cleaner -->
  <div *ngIf="equipmentType.value === partTypeEnum.CLEANER">
    <mat-form-field appearance="fill" class="first-input">
      <mat-label>Subtype</mat-label>
      <mat-select [formControl]="equipmentSubType" required>
        <mat-option *ngFor="let type of subTypes" [value]="type.id">
          {{ type.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="second-input">
      <mat-label>Capacity (t/h)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="equipment.partDetails3D.params.capacity" />
    </mat-form-field>
  </div>

  <!-- Chain conveyor -->
  <div
    *ngIf="equipmentTypeConveyor.value === partTypeEnum.CHAIN_CONVEYOR || equipmentType.value === partTypeEnum.CHAIN_CONVEYOR">
    <mat-form-field appearance="outline" class="first-input">
      <mat-label>Capacity (t/h)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="equipment.partDetails3D.params.capacity" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="second-input">
      <mat-label>Length (m)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="equipment.partDetails3D.params.dx.x" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="first-input">
      <mat-label>Number of outlets</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="equipment.partDetails3D.params.nOutputs" />
    </mat-form-field>
  </div>

  <!-- Belt conveyor -->
  <div
    *ngIf="equipmentTypeConveyor.value === partTypeEnum.BELT_CONVEYOR || equipmentType.value === partTypeEnum.BELT_CONVEYOR">
    <mat-form-field floatLabel="auto" appearance="outline" class="first-input">
      <mat-label>Capacity (t/h)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="equipment.partDetails3D.params.capacity" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="second-input">
      <mat-label>Length (m)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="equipment.partDetails3D.params.dx.x" />
    </mat-form-field>
  </div>

  <!-- Dryer -->
  <div *ngIf="equipmentType.value === partTypeEnum.DRYER">
    <mat-form-field appearance="outline" class="first-input">
      <mat-label>Height (m)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="equipment.partDetails3D.params.dx.y"
        [disabled]="true" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="second-input">
      <mat-label>Base width (m)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="equipment.partDetails3D.params.dx.z"
        [disabled]="true" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="first-input">
      <mat-label>Base length (m)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="equipment.partDetails3D.params.dx.x"
        [disabled]="true" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="second-input">
      <mat-label>Capacity (t/h)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="equipment.partDetails3D.params.capacity" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="first-input">
      <mat-label>Moisture Reduction (%)</mat-label>
      <input matInput class="input-content" type="number"
        [(ngModel)]="equipment.partDetails3D.params.moistureReduction" />
    </mat-form-field>
  </div>
  <div class="layout-button">
    <button mat-button class="white-button" (click)="closeDialog()">Close</button>
    <button mat-button class="green-button" (click)="applyChanges()">Apply</button>
  </div>
</div>
