import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProjectsComponent } from './projects.component';
import { ProjectsRoutingModule } from './projects-routing.module';
import { AllProjectsComponent } from './all-projects/all-projects.component';
import { SiloLayoutComponent } from './silo-layout/silo-layout.component';
import { SiloProjectComponent } from './silo-project/silo-project.component';
import { SiloPrototypeComponent } from './silo-prototype/silo-prototype.component';

// Material design modules
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { FlowDiagramComponent } from './flow-diagram/flow-diagram.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MiscModule } from '../shared/modules/misc/misc.module';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [
    ProjectsComponent,
    AllProjectsComponent,
    SiloLayoutComponent,
    SiloProjectComponent,
    SiloPrototypeComponent,
    FlowDiagramComponent,
  ],
  imports: [
    CommonModule,
    ProjectsRoutingModule,
    // Material
    MatTabsModule,
    MatButtonModule,
    MatStepperModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    FormsModule,
    MatInputModule,
    MatMenuModule,
    MatProgressBarModule,
    MatFormFieldModule,
    // SiloApp Modules
    MiscModule,
    MatExpansionModule,
    FlexModule,
  ],
  exports: [SiloPrototypeComponent],
  providers: [],
})
export class ProjectsModule {}
