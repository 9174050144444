import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  OnDestroy,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PartTypeEnum } from 'src/app/shared/models/enums';
import { Part3DNode } from 'src/app/shared/models/part';
import { GenericSnackBarService } from 'src/app/shared/services/generic-snack-bar.service';

@Component({
  selector: 'app-move-equipment-dialog',
  templateUrl: './move-equipment-dialog.component.html',
  styleUrls: ['./move-equipment-dialog.component.scss'],
})
export class MoveEquipmentDialogComponent implements OnInit {
  @Input() part3DNodes: Part3DNode[];
  @Input() isActive: boolean;
  @Output() closeClicked = new EventEmitter();
  @Output() rotateClicked = new EventEmitter();
  @Output() mouseOn = new EventEmitter();
  @Output() mouseOff = new EventEmitter();
  @Output() moveComponent = new EventEmitter();
  @Output() toggleBoundinxBoxEvent = new EventEmitter();
  equipmentsForm: FormControl = new FormControl('');
  angleForm: FormControl = new FormControl(0);
  elevationForm: FormControl = new FormControl(0);

  equipmentsSubscription: Subscription = new Subscription();
  angleSubscription: Subscription = new Subscription();
  elevationSubscription: Subscription = new Subscription();
  incrementValue = 0.2;
  angle: number = 0;
  elevation = undefined;
  selectedItems = [];

  constructor(private snackBar: GenericSnackBarService) {}

  ngOnInit(): void {
    if (this.part3DNodes.length > 0) {
      this.equipmentsForm.setValue([
        this.part3DNodes[this.part3DNodes.length - 1].id,
      ]);
    }

    this.angleSubscription = this.angleForm.valueChanges.subscribe((a) => {
      if (a !== null) {
        this.selectIntakeChildConveyor();

        const rotateEvent: RotateEvent = {
          nodeIds: this.equipmentsForm.value,
          angle: a - this.angle,
        };
        this.angle = a;
        this.rotateClicked.emit(rotateEvent);
      }
    });

    this.elevationSubscription = this.elevationForm.valueChanges.subscribe(
      (e) => {
        if (e !== null) {
          if (this.elevation == null) {
            this.elevation = 0;
          }
          const moveEvent: MoveEvent = {
            nodeIds: this.equipmentsForm.value,
            axis: 'y',
            value: e - this.elevation,
          };
          this.elevation = e;
          this.moveComponent.emit(moveEvent);
        }
      }
    );

    this.equipmentsSubscription = this.equipmentsForm.statusChanges.subscribe(
      (status) => {
        if (status === 'INVALID') {
          this.elevation = undefined;
          this.angle = 0;
        }
      }
    );
  }

  // [TODO] - Angular Hostlisteners can't be easily removed on component destoy,
  // thus the exception. Desired outcome => refactor components & routing so that component lifecyle ends when it is inactive.
  @HostListener('window:keydown.w', ['$event'])
  moveEquipmentUp(event: KeyboardEvent): void {
    if (this.isActive && document.activeElement.id !== 'mat-input-2') {
      event.preventDefault();
      this.movePerAxis('y', false);
    }
  }

  @HostListener('window:keydown.s', ['$event'])
  moveEquipmentDown(event: KeyboardEvent): void {
    if (this.isActive && document.activeElement.id !== 'mat-input-2') {
      event.preventDefault();
      this.movePerAxis('y', true);
    }
  }

  @HostListener('window:keydown.a', ['$event'])
  moveEquipmentLeft(event: KeyboardEvent): void {
    if (this.isActive && document.activeElement.id !== 'mat-input-2') {
      this.movePerAxis('x', true);
    }
  }

  @HostListener('window:keydown.d', ['$event'])
  moveEquipmentRight(event: KeyboardEvent): void {
    if (this.isActive && document.activeElement.id !== 'mat-input-2') {
      this.movePerAxis('x', false);
    }
  }

  @HostListener('window:keydown.q', ['$event'])
  moveEquipmentDepth(event: KeyboardEvent): void {
    if (this.isActive && document.activeElement.id !== 'mat-input-2') {
      this.movePerAxis('z', false);
    }
  }

  @HostListener('window:keydown.e', ['$event'])
  moveEquipmentForward(event: KeyboardEvent): void {
    if (this.isActive && document.activeElement.id !== 'mat-input-2') {
      this.movePerAxis('z', true);
    }
  }

  @HostListener('window:keydown.r', ['$event'])
  rotateEquimpentOnKeyR(event: KeyboardEvent): void {
    if (this.isActive && document.activeElement.id !== 'mat-input-2') {
      this.angleForm.setValue(this.angleForm.value - 1);
    }
  }

  @HostListener('window:keydown.t', ['$event'])
  rotateEquimpentOnKeyT(event: KeyboardEvent): void {
    if (this.isActive && document.activeElement.id !== 'mat-input-2') {
      this.angleForm.setValue(this.angleForm.value + 1);
    }
  }

  selectIntakeChildConveyor(): void {
    const selectedEquipments = [...this.equipmentsForm.value];
    for (const nodeId of selectedEquipments) {
      const equipment = this.part3DNodes.find(
        (x) => x.id === parseInt(nodeId, 0)
      );
      if (
        equipment &&
        equipment.type === PartTypeEnum.INTAKE &&
        !this.equipmentsForm.value.includes(equipment.children[0])
      ) {
        this.equipmentsForm.value.push(parseInt(equipment.children[0], 0));
        break;
      } else if (
        equipment &&
        equipment.type === PartTypeEnum.INTAKE &&
        this.equipmentsForm.value.includes(equipment.children[0])
      ) {
        break;
      } else if (
        equipment &&
        (equipment.type === PartTypeEnum.BELT_CONVEYOR ||
          equipment.type === PartTypeEnum.CHAIN_CONVEYOR) &&
        !this.equipmentsForm.value.includes(equipment.parents[0])
      ) {
        const firstParent = this.part3DNodes.find(
          (x) => x.id === parseInt(equipment.parents[0], 0)
        );
        if (firstParent && firstParent.type === PartTypeEnum.INTAKE) {
          this.equipmentsForm.value.push(parseInt(equipment.parents[0], 0));
        }
        break;
      } else if (
        equipment &&
        (equipment.type === PartTypeEnum.BELT_CONVEYOR ||
          equipment.type === PartTypeEnum.CHAIN_CONVEYOR) &&
        this.equipmentsForm.value.includes(equipment.parents[0])
      ) {
        break;
      }
    }
    this.equipmentsForm.setValue(
      this.equipmentsForm.value.filter((item, i, a) => a.indexOf(item) === i)
    );
  }

  movePerAxis(axis: string, opposite: boolean): void {
    const sameSelection =
      this.selectedItems.sort().join(',') ===
      this.equipmentsForm.value.sort().join(',');
    if (!sameSelection) {
      this.selectedItems = [...this.equipmentsForm.value];
      this.elevation = undefined;
    }

    this.selectIntakeChildConveyor();
    const moveEvent: MoveEvent = {
      nodeIds: this.equipmentsForm.value,
      axis,
      value: opposite ? -this.incrementValue : this.incrementValue,
    };
    if (axis === 'y') {
      if (this.equipmentsForm.value.length > 1) {
        this.snackBar.showWarning(
          'Multiple equipments selected. Elevation reported to the lowest equipment.'
        );
        let elevations = [];
        for (const nodeId of this.equipmentsForm.value) {
          const selectedNode = this.part3DNodes.find((n) => n.id === nodeId);
          if (selectedNode) {
            const currentElevation = selectedNode.position.y;
            if (currentElevation) {
              elevations.push(currentElevation);
            }
          }
        }
        if (elevations && elevations.length) {
          this.elevation = Math.min(...elevations);
        } else {
          this.elevation = 0;
        }
      } else {
        if (this.elevation === undefined) {
          const selectedNode = this.part3DNodes.find(
            (n) => n.id === this.equipmentsForm.value[0]
          );
          if (selectedNode) {
            this.elevation = selectedNode.position.y;
          }
          if (this.elevation === undefined) {
            this.elevation = 0;
          }
        }
      }
      const elevationChange = opposite
        ? -this.incrementValue
        : this.incrementValue;
      this.elevationForm.setValue(this.elevation + elevationChange);
    }

    if (moveEvent.axis !== 'y') {
      this.moveComponent.emit(moveEvent);
    }
  }

  closeDialog(): void {
    this.angleForm.reset();
    this.closeClicked.emit();
    this.equipmentsForm.reset();
  }

  mouseOverOn($event: any): void {
    let element = $event.target.textContent;

    if (element && element !== '') {
      try {
        element = element.split('.')[1].trim();
      } catch (err) {
        element = element.trim();
      }
      this.mouseOn.emit({ nodeName: element });
    }
  }

  mouseOverOff($event: any): void {
    let element = $event.target.textContent;
    if (element && element !== '') {
      try {
        element = element.split('.')[1].trim();
      } catch (err) {
        element = element.trim();
      }
      this.mouseOff.emit({ nodeName: element });
    }
  }

  toggleBoundingBox(): void {
    this.toggleBoundinxBoxEvent.emit();
  }

  ngOnDestroy(): void {
    if (this.angleSubscription) {
      this.angleSubscription.unsubscribe();
    }
    if (this.elevationSubscription) {
      this.elevationSubscription.unsubscribe();
    }
    if (this.equipmentsSubscription) {
      this.equipmentsSubscription.unsubscribe();
    }
  }
}

interface MoveEvent {
  nodeIds: number[];
  axis: string;
  value: number;
}

interface RotateEvent {
  nodeIds: number[];
  angle: number;
}
