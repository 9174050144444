import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { MatStepper } from '@angular/material/stepper';
import {
  PartAreaEnum,
  PartTypeEnum,
  ProjectHelperServiceEnum,
  ProjectTypeEnum,
  SiloPlantStepperEnum,
} from '../../shared/models/enums';
import { Project } from '../../shared/models/project';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { EquipmentGeneratorService } from '../../shared/services/equipment-generator.service';
import * as THREE from 'three';
import { Elevator3DParams, Part3D, Part3DNode } from '../../shared/models/part';
import { SiloPlantGeneratorService } from '../../shared/services/silo-plant-generator.service';
import {
  BIN_PARAMS,
  INTAKE_AREA_PARAMS,
  MAIN_ELEVATOR_PARAMS,
  SILO_PLANT_THUMBNAIL,
} from '../../shared/constants/equipment-parameters-default';
import {
  DEFAULT_TILE_SIZE,
  MOUSE_DRAG_TRESHOLD,
} from 'src/app/shared/constants/scene-defaults';
import { Subject } from 'rxjs';
import { CloneObjectService } from 'src/app/shared/services/clone-object.service';
import { ProjectHelperService } from 'src/app/shared/services/project-helper.service';
import { OperationResult } from 'src/app/shared/models/helper-service-events';
import { GenericSnackBarService } from 'src/app/shared/services/generic-snack-bar.service';

@Component({
  selector: 'app-silo-layout',
  templateUrl: './silo-layout.component.html',
  styleUrls: ['./silo-layout.component.scss'],
})
export class SiloLayoutComponent implements OnInit, OnDestroy {
  @Input() stepper: MatStepper;
  loading = false;
  public progress = 0;
  private nextStep = false;
  private changed = false;
  public projectName: string;
  public showEnterName: boolean;
  private routes = environment.routes;
  private currentProject: Project;
  private projectService$: Subject<OperationResult> =
    new Subject<OperationResult>();
  // 3D Scene parameters + constants
  private N_TILES = 9;
  private canvasSelector = '#silo3dDesign';
  private sceneComponents;
  private canvas;
  public selectedTiles = [];
  public currentXY: any = {};
  private invalidElevatorTile: number = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private equipmentGenerator: EquipmentGeneratorService,
    private siloPlantGenerator: SiloPlantGeneratorService,
    private projectHelper: ProjectHelperService,
    private clonerService: CloneObjectService,
    private snackBar: GenericSnackBarService
  ) {}
  ngOnInit(): void {
    setTimeout(this.initTimedOutFunction.bind(this), 0);
  }
  initTimedOutFunction(): void {
    this.initializeScene();
    this.projectService$.subscribe((r: OperationResult) => {
      if (r.status === ProjectHelperServiceEnum.CREATED) {
        this.projectCreated(r.data);
      }
      if (r.status === ProjectHelperServiceEnum.EDITED) {
        this.loading = false;
        if (this.nextStep) {
          this.stepper.next();
        }
      }
      if (r.status === ProjectHelperServiceEnum.ERROR) {
        this.loading = false;
      }
      if (r.status === ProjectHelperServiceEnum.LOADED) {
        this.projectLoaded(r.data);
        this.reinitPlantConfig();
      }
      if (r.status === ProjectHelperServiceEnum.SUCCESS) {
        this.router.navigate([this.routes.projects]).then(() => {});
      }
      if (
        r.status === ProjectHelperServiceEnum.NO_DATA &&
        this.currentProject === undefined
      ) {
        this.createBlankProject();
      }
    });
    this.route.params.subscribe((param) => {
      this.projectHelper.getProject(this.projectService$, param.id);
    });
    this.route.queryParams.subscribe((param) => {
      const view = param.view;
      if (view && (view === 'plant' || view === 'quotation')) {
        this.projectService$.unsubscribe();
        this.stepper.next();
      }
    });
  }
  initializeScene(): void {
    this.loading = true;
    this.canvas = document.querySelector(this.canvasSelector);
    this.canvas.style.width = '100%';
    this.canvas.style.height = '100%';
    this.canvas.width = this.canvas.offsetWidth;
    this.canvas.height = this.canvas.offsetHeight;
    this.sceneComponents = this.siloPlantGenerator.initScene3D(
      this.canvasSelector,
      this.N_TILES
    );
    this.siloPlantGenerator.updateScene3D(
      this.sceneComponents,
      this.canvasSelector,
      this.N_TILES
    );
  }

  projectCreated(project: Project): void {
    this.currentProject = this.clonerService.deepCopyProject(project, project.name, true);
    this.loading = false;
    this.showEnterName = true;
  }

  projectLoaded(project: Project): void {
    this.currentProject = this.clonerService.deepCopyProject(project, project.name, true);
    this.projectName = this.currentProject.name;
    const name = this.projectName.trim().replace(' ', '').toLowerCase();
    if (name.startsWith('untitledproject')) {
      this.showEnterName = true;
    }
    const nodes = [];
    for (const node of this.currentProject.nodes) {
      if (
        node.type === PartTypeEnum.SILO_BIN ||
        node.type === PartTypeEnum.INTAKE
      ) {
        nodes.push(node);
      }
      if (node.type === PartTypeEnum.ELEVATOR) {
        if ((node.partDetails3D.params as Elevator3DParams).isMain) {
          nodes.push(node);
        }
      }
    }
    if (
      !this.sceneComponents ||
      this.stepper.selectedIndex === SiloPlantStepperEnum.MAIN_ELEVATOR ||
      this.stepper.selectedIndex === SiloPlantStepperEnum.INTAKE
    ) {
      this.canvas = document.getElementById(
        'silo3dDesign'
      ) as HTMLCanvasElement;
      if (!this.sceneComponents) {
        this.sceneComponents = this.siloPlantGenerator.initScene3D(
          this.canvasSelector,
          this.N_TILES
        );
      }
      this.siloPlantGenerator.updateScene3D(
        this.sceneComponents,
        this.canvasSelector,
        this.N_TILES
      );
    }
    // this.highlightSelectedTiles(this.sceneComponents.scene.children, nodes);
    if (this.stepper.selectedIndex === SiloPlantStepperEnum.MAIN_ELEVATOR) {
      this.invalidElevatorTile = this.validateElevatorPosition(
        this.currentProject.nodes
      );
    }
    this.preselectTiles(this.currentProject.nodes);
    this.loading = false;
  }

  createBlankProject(): void {
    this.loading = true;
    const gridSnapshot = this.takeGridScreenshot();
    this.projectHelper.createProject(
      this.projectService$,
      ProjectTypeEnum.PROJECT,
      gridSnapshot
    );
  }

  keyPressed(event): void {
    const keyCode = event.key;
    if (keyCode == 'Backspace') {
      const nameT = this.projectName.trim().replace(' ', '').toLowerCase();
      if (nameT.length <= 0 || nameT.startsWith('untitledp')) {
        this.projectName = '';
      }
    }
  }

  nameValid(name: string): boolean {
    const nameT = name.trim().replace(' ', '').toLowerCase();
    if (nameT.length <= 0 || nameT.startsWith('untitledproject')) {
      return false;
    }
    return true;
  }
  saveProjectName(): void {
    if (!this.nameValid(this.projectName)) {
      this.snackBar.showError(
        'Invalid project name! Please specify a correct name...'
      );
      return;
    }
    const editData = {
      name: this.projectName,
    };
    this.nextStep = false;
    this.showEnterName = false;
    this.projectHelper.editProject(
      this.projectService$,
      this.currentProject,
      editData
    );
  }
  // ====== USER ACTIONS ====== //
  next(): void {
    let currentStep = this.stepper.selectedIndex;
    if (!this.nameValid(this.projectName)) {
      this.showEnterName = true;
      this.snackBar.showError(
        'Invalid project name! Please specify a correct name...'
      );
      return;
    }
    if (currentStep === SiloPlantStepperEnum.SILO_BINS) {
      const bins = this.currentProject.nodes.find(
        (n) => n.type === PartTypeEnum.SILO_BIN
      );
      if (bins === undefined) {
        this.snackBar.showSuccess('Please place at least one silo bin.');
        return;
      }
    }
    if (currentStep === SiloPlantStepperEnum.MAIN_ELEVATOR) {
      const mainElevator = this.currentProject.nodes.find(
        (n) =>
          n.type === PartTypeEnum.ELEVATOR && n.area === PartAreaEnum.STORAGE
      );
      if (mainElevator === undefined) {
        this.snackBar.showSuccess('Please place the main elevator.');
        return;
      }
      if (this.invalidElevatorTile !== 0) {
        this.snackBar.showSuccess(
          'Elevator position is not valid for current silo bins configuration.'
        );
        return;
      }
    }
    if (currentStep === SiloPlantStepperEnum.INTAKE) {
      const intake = this.currentProject.nodes.find(
        (n) => n.type === PartTypeEnum.INTAKE
      );
      if (intake === undefined) {
        this.snackBar.showSuccess('Please place the intake.');
        return;
      }
    }
    if (this.changed) {
      this.reinitPlantConfig();
    }
    const editData = {
      name: this.projectName,
      imageUrl: this.takeGridScreenshot(),
    };
    this.nextStep = true;
    this.projectHelper.editProject(
      this.projectService$,
      this.currentProject,
      editData
    );
  }

  reinitPlantConfig(): void {
    this.currentProject.completed = false;
    const equipments = this.currentProject.nodes.filter(
      (n) =>
        n.type === PartTypeEnum.INTAKE ||
        n.type === PartTypeEnum.SILO_BIN ||
        (n.type === PartTypeEnum.ELEVATOR && n.area === PartAreaEnum.STORAGE)
    );
    this.currentProject.nodes = [...equipments];
    for (let i = 0; i < this.currentProject.nodes.length; i++) {
      const equipmentTile = this.sceneComponents.scene.children.filter(
        (c) => c.name === this.currentProject.nodes[i].tileName
      )[0];
      this.currentProject.nodes[i].position.x = equipmentTile.position.x;
      this.currentProject.nodes[i].position.z = equipmentTile.position.z;
      if (this.currentProject.nodes[i].type === PartTypeEnum.SILO_BIN) {
        this.currentProject.nodes[i].partDetails3D.binNumberPerRow = undefined;
        this.currentProject.nodes[i].partDetails3D.binRowNum = undefined;
      }
    }
  }
  back(): void {
    this.stepper.previous();
  }
  cancel(): void {
    this.stepper.reset();
    if (this.currentProject.nodes.length <= 0) {
      this.projectHelper.deleteProject(
        this.projectService$,
        this.currentProject._id
      );
    } else {
      this.router.navigate([this.routes.projects]).then(() => {});
    }
  }
  // ====== 3D INTERACTION ====== //
  preselectTiles(nodes: Part3DNode[]): void {
    const maxDistance = 0.75 * DEFAULT_TILE_SIZE;
    const tiles = this.sceneComponents.scene.children.filter(
      (c) => c.hasOwnProperty('isMesh') || c.name.includes('Tile')
    );
    for (const node of nodes) {
      if (
        node.type === PartTypeEnum.SILO_BIN ||
        node.type === PartTypeEnum.INTAKE ||
        node.type === PartTypeEnum.ELEVATOR
      ) {
        /*const tile = this.sceneComponents.scene.children.find(
          (t) =>
            t.position.x === node.position.x && t.position.z === node.position.z
        );*/
        const tile = this.siloPlantGenerator.helper.findClosestTile(
          tiles,
          node,
          maxDistance
        );
        if (tile) {
          tile.material.transparent = true;
          tile.material.opacity = 0.3;
          tile.material.color = new THREE.Color('lightgray');
          this.selectedTiles.push(tile);
          switch (node.type) {
            case PartTypeEnum.SILO_BIN: {
              this.equipmentGenerator
                .getSiloBin(
                  node.partDetails3D.params.dx,
                  node.partDetails3D.params.color,
                  node.partDetails3D.params.name
                )
                .subscribe((p) => {
                  node.part3D = p;
                  node.part3D.part.position.x = node.position.x;
                  node.part3D.part.position.y = node.position.y;
                  node.part3D.part.position.z = node.position.z;
                  this.sceneComponents.scene.add(node.part3D.part);
                });
              break;
            }
            case PartTypeEnum.INTAKE: {
              this.equipmentGenerator
                .getEquipmentMetallicHousing(
                  node.partDetails3D.params.dx,
                  node.partDetails3D.params.color,
                  node.partDetails3D.params.name
                )
                .subscribe((p: Part3D) => {
                  node.part3D = p;
                  node.part3D.part.position.x = node.position.x;
                  node.part3D.part.position.y = node.position.y;
                  node.part3D.part.position.z = node.position.z;
                  this.sceneComponents.scene.add(node.part3D.part);
                });
              break;
            }
            case PartTypeEnum.ELEVATOR: {
              if ((node.partDetails3D.params as Elevator3DParams).isMain) {
                this.equipmentGenerator
                  .getElevator(node.partDetails3D.params as Elevator3DParams)
                  .subscribe((p: Part3D) => {
                    node.part3D = p;
                    node.part3D.part.position.x = node.position.x;
                    node.part3D.part.position.y = node.position.y;
                    node.part3D.part.position.z = node.position.z;
                    this.sceneComponents.scene.add(node.part3D.part);
                  });
              }
              break;
            }
          }
        }
      }
    }
  }
  takeGridScreenshot(): string {
    let imageUrl;
    if (this.sceneComponents.renderer) {
      imageUrl = this.sceneComponents.renderer.domElement.toDataURL(
        'image/png',
        0.0000001
      );
    } else {
      imageUrl = SILO_PLANT_THUMBNAIL;
    }
    return imageUrl;
  }
  onMouseMove(event: MouseEvent): void {
    this.currentXY = { x: event.clientX, y: event.clientY };
  }
  onMouseDown(event: MouseEvent): void {
    if (
      Math.abs(event.clientX - this.currentXY.x) > MOUSE_DRAG_TRESHOLD ||
      Math.abs(event.clientY - this.currentXY.Y) > MOUSE_DRAG_TRESHOLD
    ) {
      return;
    }
    // get current selection. this should be loaded from currentProject (if not a new project).
    const INTERSECTED = [...this.selectedTiles];
    // get mouse position wrt the canvas
    const rect = this.canvas.getBoundingClientRect();
    this.sceneComponents.pointer.x =
      ((event.clientX - rect.left) / (rect.right - rect.left)) * 2 - 1;
    this.sceneComponents.pointer.y =
      -((event.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;

    // create a ray caster
    this.sceneComponents.raycaster.setFromCamera(
      this.sceneComponents.pointer,
      this.sceneComponents.camera
    );

    // find out the intersection between rays and plane
    let intersects = this.sceneComponents.raycaster.intersectObjects(
      this.sceneComponents.scene.children,
      false
    );
    if (intersects.length > 0) {
      if (this.stepper.selectedIndex === SiloPlantStepperEnum.MAIN_ELEVATOR) {
        this.invalidElevatorTile = this.validateElevatorPosition(
          this.currentProject.nodes
        );
      }
      intersects = intersects.filter((x) => {
        return (
          x.object.name.includes('Tile') &&
          (x.object.valid === true || x.object.id === this.invalidElevatorTile)
        );
      });
      const intersectedObjects = intersects.map((x) => x.object);
      if (intersectedObjects.length > 0) {
        for (const intersectedObject of intersectedObjects) {
          if (INTERSECTED.includes(intersectedObject)) {
            this.changed = true;
            const selectedSceneTile = this.sceneComponents.scene.children.find(
              (x) => x === intersectedObject
            );

            INTERSECTED.forEach((element, index) => {
              if (element === intersectedObject) {
                INTERSECTED.splice(index, 1);
                // remove deselected element
                // 1. find element in nodes
                const selectedNode = this.currentProject.nodes.find(
                  (n) =>
                    (n.type === PartTypeEnum.SILO_BIN ||
                      n.type === PartTypeEnum.INTAKE ||
                      n.type === PartTypeEnum.ELEVATOR) &&
                    n.tileName === selectedSceneTile.name
                );

                // if node found
                // 2. remove element from nodes
                let removeNode = false;
                if (selectedNode) {
                  switch (this.stepper.selectedIndex) {
                    case SiloPlantStepperEnum.SILO_BINS: {
                      removeNode = true;
                      break;
                    }
                    case SiloPlantStepperEnum.MAIN_ELEVATOR: {
                      if (
                        selectedNode.type === 'elevator' ||
                        selectedNode.type === 'intake'
                      ) {
                        removeNode = true;
                      }
                      break;
                    }
                    case SiloPlantStepperEnum.INTAKE: {
                      if (selectedNode.type === 'intake') {
                        removeNode = true;
                      }
                      break;
                    }
                  }
                }
                if (removeNode) {
                  selectedSceneTile.material.color = new THREE.Color('green');
                  selectedSceneTile.material.transparent = true;
                  selectedSceneTile.material.opacity = 0.3;

                  const nodeIx =
                    this.currentProject.nodes.indexOf(selectedNode);
                  if (nodeIx > -1) {
                    this.currentProject.nodes.splice(nodeIx, 1);
                  }

                  // 3. find element in scene
                  // 4. remove element from scene
                  const sceneElement = this.sceneComponents.scene.children.find(
                    (n) => n.name === selectedNode.partDetails3D.params.name
                  );
                  const sceneElementIx =
                    this.sceneComponents.scene.children.indexOf(sceneElement);
                  if (sceneElementIx > -1) {
                    this.sceneComponents.scene.children.splice(
                      sceneElementIx,
                      1
                    );
                  }
                  if (selectedSceneTile.id === this.invalidElevatorTile) {
                    selectedSceneTile.material.color = new THREE.Color('red');
                    selectedSceneTile.material.transparent = true;
                    selectedSceneTile.material.opacity = 0.3;
                    selectedSceneTile.valid = false;
                  }
                }
              }
            });
          } else {
            this.changed = true;
            const selectedSceneTile = this.sceneComponents.scene.children.find(
              (x) => x === intersectedObject
            );
            if (INTERSECTED.includes(selectedSceneTile)) {
              this.snackBar.showError(
                'Unable to place equipment. You already have an equipment at the same position.'
              );
            } else if (selectedSceneTile) {
              selectedSceneTile.material.color =
                this.stepper.selectedIndex === 0
                  ? new THREE.Color(BIN_PARAMS.color)
                  : new THREE.Color(INTAKE_AREA_PARAMS.color);
              selectedSceneTile.material.transparent = true;
              selectedSceneTile.material.opacity = 0.3;
              INTERSECTED.push(intersectedObject);
              // add silo bin or intake 3D on the selected tile
              switch (this.stepper.selectedIndex) {
                case SiloPlantStepperEnum.SILO_BINS: {
                  this.equipmentGenerator
                    .getSiloBin(BIN_PARAMS.dx, BIN_PARAMS.color)
                    .subscribe((p: Part3D) => {
                      const updatedNodes =
                        this.siloPlantGenerator.addSiloBinNodesSelection(
                          this.currentProject.nodes,
                          [selectedSceneTile],
                          p,
                          this.sceneComponents.scene
                        );
                      this.currentProject.nodes = [...updatedNodes];
                    });
                  break;
                }
                case SiloPlantStepperEnum.MAIN_ELEVATOR: {
                  const elevators = this.currentProject.nodes.filter(
                    (n) => n.type === PartTypeEnum.ELEVATOR
                  );
                  if (elevators && elevators.length === 0) {
                    this.equipmentGenerator
                      .getElevator(MAIN_ELEVATOR_PARAMS)
                      .subscribe((p: Part3D) => {
                        const updatedNodes =
                          this.siloPlantGenerator.addElevatorNodesSelection(
                            this.currentProject.nodes,
                            [selectedSceneTile],
                            p,
                            this.sceneComponents.scene
                          );
                        this.currentProject.nodes = [...updatedNodes];
                      });
                  } else {
                    selectedSceneTile.material.color = new THREE.Color('green');
                    selectedSceneTile.material.transparent = true;
                    selectedSceneTile.material.opacity = 0.3;
                    this.snackBar.showError(
                      'Only one main elevator can be selected. Please remove the existing elevator and try again.'
                    );
                  }
                  break;
                }
                case SiloPlantStepperEnum.INTAKE: {
                  const intakes = this.currentProject.nodes.filter(
                    (n) => n.type === PartTypeEnum.INTAKE
                  );
                  if (intakes && intakes.length === 0) {
                    this.equipmentGenerator
                      .getEquipmentMetallicHousing(
                        INTAKE_AREA_PARAMS.dx,
                        INTAKE_AREA_PARAMS.color,
                        INTAKE_AREA_PARAMS.name
                      )
                      .subscribe((p: Part3D) => {
                        const updatedNodes =
                          this.siloPlantGenerator.addIntakeNodesSelection(
                            this.currentProject.nodes,
                            [selectedSceneTile],
                            p,
                            this.sceneComponents.scene
                          );
                        this.currentProject.nodes = [...updatedNodes];
                      });
                  } else {
                    selectedSceneTile.material.color = new THREE.Color('green');
                    selectedSceneTile.material.transparent = true;
                    selectedSceneTile.material.opacity = 0.3;
                    this.snackBar.showError(
                      'Only one intake can be selected. Please remove the existing intake and try again.'
                    );
                  }
                  break;
                }
              }
            }
          }
        }
      }
    }
    this.selectedTiles = [...INTERSECTED];
  }

  validateElevatorPosition(nodes: Part3DNode[]): number {
    const bins = nodes.filter((n) => n.type === PartTypeEnum.SILO_BIN);
    const mainElevator = nodes.filter(
      (n) => n.type === PartTypeEnum.ELEVATOR
    )[0];

    let invalidTiles = [];
    if (bins.length > 1) {
      const tiles = this.sceneComponents.scene.children.filter(
        (c) => c.hasOwnProperty('isMesh') || c.name.includes('Tile')
      );
      const binsAxis = this.siloPlantGenerator.helper.binsOneRowConfig(bins);
      if (binsAxis.x !== 0 || binsAxis.z !== 0) {
        // all bins are on the same axis
        invalidTiles = this.siloPlantGenerator.helper.findInvalidTiles(
          bins,
          tiles,
          binsAxis
        );

        invalidTiles.forEach((tile) => {
          tile.material.color = new THREE.Color('red');
          tile.material.transparent = true;
          tile.material.opacity = 0.3;
          tile.valid = false;
        });
      }
      if (mainElevator !== undefined) {
        const mainElevatorTile = this.siloPlantGenerator.helper.findClosestTile(
          tiles,
          mainElevator
        );
        const invalidElevator = invalidTiles.filter(
          (tile) => tile.id === mainElevatorTile.id
        )[0];
        return invalidElevator === undefined ? 0 : invalidElevator.id;
      }
    }
    return 0;
  }

  highlightSelectedTiles(
    tiles: THREE.Group | THREE.Mesh,
    nodes: Part3DNode[]
  ): void {
    if (nodes) {
      const maxDistance = 0.75 * DEFAULT_TILE_SIZE;
      for (const node of nodes) {
        const tile = this.siloPlantGenerator.helper.findClosestTile(
          tiles,
          node,
          maxDistance
        );
        if (tile) {
          tile.material.color = new THREE.Color('orange');
          tile.material.transparent = true;
          tile.material.opacity = 0.3;
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.projectService$) {
      this.projectService$.unsubscribe();
    }
  }
}
