import { Injectable } from '@angular/core';
import {
  OverlappingEquipmentsPair,
  Part3D,
  Part3DDetails,
  Part3DDetailsParams,
  Part3DNode,
} from '../models/part';
import { PartAreaEnum, PartTypeEnum } from '../models/enums';
import * as THREE from 'three';
import { CloneObjectService } from './clone-object.service';
import { PhysicsService } from './physics.service';
import { CollisionsService } from './collisions.service';

@Injectable({
  providedIn: 'root',
})
export class EquipmentGraphService {
  private ROOT_NODE_ID = 0;

  constructor(private duplicator: CloneObjectService,
    private physicsService: PhysicsService,
    private collisionsService: CollisionsService) { }

  // ------ Helper methods to modify the equipment graph ------ //
  addNode(nodes: Part3DNode[], node: Part3DNode): Part3DNode[] {
    const parentsIds = node.parents;
    const childrenIds = node.children;
    // link node as child to all it's parents
    for (const parentId of parentsIds) {
      const parentNode = this.findNodeById(nodes, parseInt(parentId, 0));
      if (parentNode) {
        const parentNodeIndex = nodes.indexOf(parentNode);
        nodes[parentNodeIndex].children.push(node.id.toString());
      }
    }
    // link all children to the current node
    for (const childId of childrenIds) {
      const childNode = this.findNodeById(nodes, parseInt(childId, 0));
      if (childNode) {
        const childNodeIndex = nodes.indexOf(childNode);
        nodes[childNodeIndex].parents.push(childId);
        childNode.parents.push(node.id.toString());
      }
    }
    nodes.push(node);
    return nodes;
  }

  deleteNode(nodes: Part3DNode[], nodeId: number): Part3DNode[] {
    const node = this.findNodeById(nodes, nodeId);
    const parentsIds = node.parents;
    const childrenIds = node.children;
    const nodeIndex = nodes.findIndex((x) => x.id === node.id);
    nodes.splice(nodeIndex, 1);
    // remove node from parents list
    for (const parentId of parentsIds) {
      const parentNode = this.findNodeById(nodes, parseInt(parentId, 0));
      if (parentNode) {
        parentNode.children = parentNode.children.filter(
          (x) => x !== nodeId.toString() && x !== this.ROOT_NODE_ID.toString()
        );
      }
    }
    // remove node from children list
    if (node.area === PartAreaEnum.INTAKE) {
      for (const childId of childrenIds) {
        const childNode = this.findNodeById(nodes, parseInt(childId, 0));
        if (
          childNode.type === PartTypeEnum.CHAIN_CONVEYOR ||
          childNode.type === PartTypeEnum.BELT_CONVEYOR
        ) {
          this.deleteNode(nodes, parseInt(childId, 0));
        }
      }
    } else {
      for (const childId of childrenIds) {
        const childNode = this.findNodeById(nodes, parseInt(childId, 0));
        if (childNode && childNode.type !== PartTypeEnum.SILO_BIN) {
          childNode.parents = childNode.parents.filter(
            (x) => x !== nodeId.toString() && x !== this.ROOT_NODE_ID.toString()
          );
        }
      }
    }
    return nodes;
  }

  duplicateNode(nodes: Part3DNode[], nodeId: number): Part3DNode[] {
    const newNodeId = this._generateRandomId(nodes);
    const currentNode = this.findNodeById(nodes, nodeId);
    const duplicatedNode = this.duplicator.deepCopyPart3DNode(currentNode, undefined, true);
    duplicatedNode.id = newNodeId;
    nodes = this.addNode(nodes, duplicatedNode);
    return nodes;
  }

  rotateNode(
    nodes: Part3DNode[],
    nodeId: number,
    angle: THREE.Vector3
  ): Part3DNode[] {
    const node = this.findNodeById(nodes, nodeId);
    node.orientation = new THREE.Vector3(
      angle.x,
      node.orientation.y + angle.y,
      angle.z
    );
    return nodes;
  }

  moveNode(
    nodes: Part3DNode[],
    nodeId: number,
    axis: string,
    value: number,
    scene: THREE.Scene,
    collisions: Array<OverlappingEquipmentsPair>,
  ): OverlappingEquipmentsPair[] {
    const node = this.findNodeById(nodes, nodeId);
    node.position = new THREE.Vector3(
      axis === 'x' ? node.position.x + value : node.position.x,
      axis === 'y' ? node.position.y + value : node.position.y,
      axis === 'z' ? node.position.z + value : node.position.z
    );
    if(node.physicsBody === null || node.physicsBody === undefined){
      const sceneEquipment = scene.children.find((c) => c.name === node.partDetails3D.params.name && c.type !== 'Box3Helper');
      switch (axis) {
        case 'y': {
          sceneEquipment.position.setY(node.position.y);
          break;
        }
        case 'x': {
          sceneEquipment.position.setX(node.position.x);
          break;
        }
        case 'z': {
          sceneEquipment.position.setZ(node.position.z);
          break;
        }
      }
    }
    else{

      this.physicsService.moveBody(nodes, node, axis, value);
    }

      this.collisionsService.updateBoundingBox(
        node,
        scene
      );

      collisions = this.collisionsService.checkColisions(
        nodes,
        collisions,
        scene
      );
    return collisions;
  }

  updateBoundingBox(node: Part3DNode, scene: THREE.Scene): void {
    const boxHelper = scene.children.find(
      (c) =>
        c.type === 'Box3Helper' &&
        c.name === node.partDetails3D.params.name
    );
    if (boxHelper) {
      node.part3D.part.position.copy(node.position);
      boxHelper.box.setFromObject(node.part3D.part);
      if (
        node.type === PartTypeEnum.INTAKE &&
        node.tileName !== undefined
      ) {
        boxHelper.box.min.y = node.part3D.part.position.y;
        boxHelper.box.max.y *= 2;
      }
      boxHelper.material.color.set(0x00ff00);
    }
  }

  setPosition(node: Part3DNode, newPosition: THREE.Vector3): void{
    if(node.physicsBody !== undefined){
      node.physicsBody.position.copy(newPosition);
    }
    else{
      node.part3D.part.position.copy(newPosition);
      node.position.copy(newPosition);
    }
  }

  findNodeById(nodes: Part3DNode[], nodeId: number): Part3DNode {
    return nodes.find((x) => x.id === nodeId);
  }

  findNodeByName(nodes: Part3DNode[], name: string): Part3DNode {
    return nodes.find((x) => x.partDetails3D.params.name === name);
  }

  findNodeByPosition(nodes: Part3DNode[], position: THREE.Vector3): Part3DNode {
    return nodes.find(
      (x: Part3DNode) =>
        x.position.x === position.x &&
        x.position.y === position.y &&
        x.position.z === position.z
    );
  }

  createNode(
    partType: PartTypeEnum,
    partArea: PartAreaEnum,
    parents?: number[],
    children?: number[],
    x0?: THREE.Vector3,
    theta?: THREE.Vector3,
    params?: Part3DDetailsParams,
    id?: number,
    part?: Part3D
  ): Part3DNode {
    const node = new Part3DNode(id);
    // fill in node part details 3D
    node.partDetails3D = new Part3DDetails();
    node.type = partType;
    node.area = partArea;
    node.partDetails3D.params = params;
    node.position = new THREE.Vector3();
    if (x0) {
      node.position.x = x0.x;
      node.position.y = x0.y;
      node.position.z = x0.z;
    }
    node.orientation = new THREE.Vector3();
    if (theta) {
      node.orientation.x = theta.x;
      node.orientation.y = theta.y;
      node.orientation.z = theta.z;
    }
    // fill in node meta information
    node.area = partArea;
    node.parents = parents.map((x) => x.toString());
    node.children = children.map((x) => x.toString());

    // create part
    if (part) {
      part.part.position.x = node.position.x;
      part.part.position.y = node.position.y;
      part.part.position.z = node.position.z;
      part.part.rotateX(node.orientation.x);
      part.part.rotateY(node.orientation.y);
      part.part.rotateZ(node.orientation.z);
      part.part.name = node.partDetails3D.params.name;
      node.part3D = part.part;
    }
    return node;
  }

  addNodePart3D(node: Part3DNode, part: Part3D): Part3DNode {
    part.part.position.x = node.position.x;
    part.part.position.y = node.position.y;
    part.part.position.z = node.position.z;
    part.part.rotateX(node.orientation.x);
    part.part.rotateY(node.orientation.y);
    part.part.rotateZ(node.orientation.z);
    part.part.name = node.partDetails3D.params.name;
    node.part3D = part.part;
    return node;
  }

  _generateRandomId(nodes: Part3DNode[]): number {
    let newId: number;
    let ok = true;
    while (ok) {
      newId = Math.floor(Math.random() * 100000) + 1;
      if (nodes.find((x: Part3DNode) => x.id === newId) === undefined) {
        ok = false;
      }
    }
    return newId;
  }

  getEquipmentNumberByArea(
    equipmentType: PartTypeEnum,
    nodes: Part3DNode[],
    area: PartAreaEnum
  ): number {
    const equipments = nodes.filter(
      (x) => x.area === area && x.type === equipmentType
    );
    if (equipments) {
      return equipments.length;
    } else {
      return 0;
    }
  }

  getBinsConfiguration(nodes: Array<Part3DNode>): any {

    const bins = nodes.filter((n) => n.type === PartTypeEnum.SILO_BIN && n.tileName !== undefined);
    let maxRow = 0;
    let maxColumn = 0;

    bins.forEach((bin) => {
      if(bin.partDetails3D.binRowNum > maxRow){
        maxRow = bin.partDetails3D.binRowNum;
      }
      if(bin.partDetails3D.binNumberPerRow > maxColumn){
        maxColumn = bin.partDetails3D.binNumberPerRow;
      }
    })

    const binsMatrix: any[][] = [];
    for (let i = 0; i <= maxRow; i++) {
      binsMatrix[i] = Array(maxColumn).fill(undefined);
    }
    bins.forEach((bin) => {
      binsMatrix[bin.partDetails3D.binRowNum][bin.partDetails3D.binNumberPerRow] = bin;
    })

    return binsMatrix;
  }
}
