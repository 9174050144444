import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquipmentCardComponent } from './equipment-card/equipment-card.component';
import { GenericCardComponent } from './generic-card/generic-card.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { SpinnerComponent } from './spinner/spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IndividualEquipmentCardComponent } from './individual-equipment-card/individual-equipment-card.component';
import { AddEquipmentDialogComponent } from './add-equipment-dialog/add-equipment-dialog.component';
import { DeleteEquipmentDialogComponent } from './delete-equipment-dialog/delete-equipment-dialog.component';
import { DuplicateEquipmentDialogComponent } from './duplicate-equipment-dialog/duplicate-equipment-dialog.component';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MoveEquipmentDialogComponent } from './move-equipment-dialog/move-equipment-dialog.component';
import { PaymentApprovalComponent } from './payment-approval/payment-approval.component';
import { EquipmentSpecificationsDialogComponent } from './equipment-specifications-dialog/equipment-specifications-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { PendingAccountComponent } from './pending-account/pending-account.component';
import { ComingsoonComponent } from './comingsoon/comingsoon.component';
import { DropdownSearchComponent } from './dropdown-search/dropdown-search.component';
import { CreateCompanyComponent } from './createcompany-card/createcompany.component';
import { MatRadioModule } from '@angular/material/radio';
import { SendMailComponent } from './send-mail/send-mail.component';
import { MatMenuModule } from '@angular/material/menu';
import { NotifyDownloadComponent } from './notify-download/notify-download.component';

@NgModule({
  declarations: [
    EquipmentCardComponent,
    GenericCardComponent,
    SpinnerComponent,
    SendMailComponent,
    NotifyDownloadComponent,
    CreateCompanyComponent,
    IndividualEquipmentCardComponent,
    AddEquipmentDialogComponent,
    DeleteEquipmentDialogComponent,
    DuplicateEquipmentDialogComponent,
    MoveEquipmentDialogComponent,
    PaymentApprovalComponent,
    EquipmentSpecificationsDialogComponent,
    PendingAccountComponent,
    ComingsoonComponent,
    DropdownSearchComponent,
    NotifyDownloadComponent,
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatDialogModule,
    MatRadioModule,
    MatFormFieldModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatInputModule,
    FormsModule,
    MatExpansionModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSliderModule,
    MatIconModule,
    MatAutocompleteModule,
    MatTooltipModule,
  ],
  exports: [
    DropdownSearchComponent,
    ComingsoonComponent,
    EquipmentCardComponent,
    GenericCardComponent,
    SendMailComponent,
    SpinnerComponent,
    NotifyDownloadComponent,
    CreateCompanyComponent,
    AddEquipmentDialogComponent,
    DeleteEquipmentDialogComponent,
    DuplicateEquipmentDialogComponent,
    IndividualEquipmentCardComponent,
    MoveEquipmentDialogComponent,
    EquipmentSpecificationsDialogComponent,
    PaymentApprovalComponent,
    PendingAccountComponent,
  ],
  entryComponents: [],
})
export class MiscModule {}
