import { NgModule, InjectionToken } from '@angular/core';
import { MatSliderModule } from '@angular/material/slider';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Material design imports
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressBarModule } from '@angular/material/progress-bar';

// Services
import { GenericDialogService } from './shared/services/generic-dialog.service';
import { ApiService } from './shared/services/api.service';
import { AuthService } from './shared/services/auth.service';
import { EquipmentGeneratorService } from './shared/services/equipment-generator.service';
import { CloneObjectService } from './shared/services/clone-object.service';
import { SiloPlantGeneratorService } from './shared/services/silo-plant-generator.service';
import { EquipmentGraphService } from './shared/services/equipment-graph.service';
import { ProjectHelperService } from './shared/services/project-helper.service';
import { CollisionsService } from './shared/services/collisions.service';

// App modules
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth.guard';
import { AccountModule } from './account/account.module';
import { ProjectsModule } from './projects/projects.module';
import { MainNavModule } from './main-nav/main-nav.module';
import { QuotationsModule } from './quotations/quotations.module';

// App components
import { SignInComponent } from './sign-in/sign-in.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { MiscModule } from './shared/modules/misc/misc.module';
import { MatSelectModule } from '@angular/material/select';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AdminModule } from './admin/admin.module';
import { TeamComponent } from './team/team.component';
import { BillingComponent } from './billing/billing.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { WINDOW } from '@ng-toolkit/universal';
import { ActivateComponent } from './activate/activate.component';
import { SignUpModule } from './signup/signup.module';
import { DisableScrollDirective } from './shared/constants/disable-scroll.directive';
import { SignupComponent } from './signup/signup.component';
export const WINDOW_TOKEN = new InjectionToken<Window>('Window object');

@NgModule({
  declarations: [
    AppComponent,
    // Functional components
    MainNavComponent,
    SignInComponent,
    ActivateComponent,
    ContactUsComponent,
    TeamComponent,
    BillingComponent,
    TutorialComponent,
    DisableScrollDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    HttpClientModule,
    // Material design modules
    MatCardModule,
    MatToolbarModule,
    MatButtonModule,
    MatRadioModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatIconModule,
    MatSidenavModule,
    MatMenuModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    FormsModule,
    MatSnackBarModule,
    MatSelectModule,
    MatSliderModule,
    MatRadioModule,
    MatProgressBarModule,
    // Functional modules
    AccountModule,
    ProjectsModule,
    MainNavModule,
    QuotationsModule,
    SignUpModule,
    MiscModule,
    AdminModule,
  ],
  providers: [
    GenericDialogService,
    ApiService,
    AuthService,
    AuthGuard,
    EquipmentGeneratorService,
    EquipmentGraphService,
    CloneObjectService,
    SiloPlantGeneratorService,
    ProjectHelperService,
    CollisionsService,
    {
      provide: WINDOW_TOKEN,
      useFactory: () => window,
    },
    {
      provide: WINDOW,
      useExisting: WINDOW_TOKEN,
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
  entryComponents: [],
})
export class AppModule {}
