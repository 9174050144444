<div class="spinner-loading">
  <app-spinner [loading]="loading"></app-spinner>
</div>
<div class="container" [hidden]="loading">
  <div class="head-container">
    <div class="content-title">
      <h2>Projects</h2>
    </div>
    <div>
      <mat-form-field
        *ngFor="let filter of filterSelectObj"
        class="account-details-half-input"
        appearance="outline"
        style="margin-left: 15px"
      >
        <mat-label>Filter {{ filter.name }}</mat-label>
        <select
          matNativeControl
          name="{{ filter.columnProp }}"
          [(ngModel)]="filter.modelValue"
          (change)="filterChange(filter, $event)"
        >
          <option value="">Select {{ filter.name }}</option>
          <option [value]="item" *ngFor="let item of filter.options">
            {{ item }}
          </option>
        </select>
      </mat-form-field>
    </div>
  </div>
  <div class="parent-grid" [hidden]="loading">
    <div class="content-grid">
      <div *ngFor="let cardDetails of filterCardsDetails">
        <app-generic-card
          [cardDetails]="cardDetails"
          [isPlantSelect]="true"
          (editProjectClicked)="selectProject($event)"
        >
        </app-generic-card>
      </div>
    </div>
  </div>
</div>
