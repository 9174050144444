import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
} from '@angular/core';
// import * as THREE from 'node_modules/three';
import * as THREE from  'three';
import { MatStepper } from '@angular/material/stepper';
import { GenericSnackBarService } from '../../shared/services/generic-snack-bar.service';
import { HttpClient } from '@angular/common/http';
import { EquipmentGeneratorService } from '../../shared/services/equipment-generator.service';
import { EquipmentGraphService } from '../../shared/services/equipment-graph.service';
import { CloneObjectService } from '../../shared/services/clone-object.service';
import { Project } from '../../shared/models/project';
import { ApiService } from '../../shared/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Bin3DParams,
  Cleaner3DParams,
  Conveyor3DParams,
  Delivery3DParams,
  Dryer3DParams,
  Elevator3DParams,
  OverlappingEquipmentsPair,
  Part3DDetails,
  Part3DNode,
  Part3DParamsBase,
  PartDetails3DEditsEvent,
  PartDetails3DNew,
  SceneGeneratorEvent,
  SiloGroupOrientation,
} from '../../shared/models/part';
// import { Subject } from 'node_modules/rxjs';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  DEFAULT_TILE_SIZE,
  NTILES,
} from '../../shared/constants/scene-defaults';
import {
  AddEquipmentStepperEnum,
  CustomizeDialogOptionEnum,
  PartAreaEnum,
  PartTypeEnum,
  ProjectHelperServiceEnum,
  SceneGeneratorEnum,
  SyncStatesEnum,
} from '../../shared/models/enums';
import { SiloPlantGeneratorService } from '../../shared/services/silo-plant-generator.service';
import { SceneParams } from '../../shared/models/scene-params';
import { SiloPlantCustomizerService } from 'src/app/shared/services/silo-plant-customizer.service';
import { QuotationHelperService } from 'src/app/shared/services/quotation-helper.service';
import { OperationResult } from 'src/app/shared/models/helper-service-events';
import { ProjectHelperService } from 'src/app/shared/services/project-helper.service';
import { ProjectFilesService } from 'src/app/shared/services/projectfiles.service';
import { ProjectFileType } from 'src/app/shared/models/projectfile';
import {
  BELT_CONVEYOR_PARAMS,
  BUFFER_BIN_PARAMS,
  CHAIN_CONVEYOR_PARAMS,
  CLEANER_PARAMS,
  DELIVERY_AREA_PARAMS,
  DRYER_PARAMS,
  INTAKE_AREA_PARAMS,
  MAIN_ELEVATOR_PARAMS,
  SILO_BIN_PARAMS,
} from 'src/app/shared/constants/equipment-parameters-default';
import { EquipmentCardComponent } from 'src/app/shared/modules/misc/equipment-card/equipment-card.component';
import { Debug3DHelperService } from 'src/app/shared/services/3d-debug-helper.service';
import { PhysicsService } from 'src/app/shared/services/physics.service';
// import GUI from 'node_modules/lil-gui';
import GUI from 'lil-gui';
import { CollisionsService } from 'src/app/shared/services/collisions.service';
@Component({
  selector: 'app-silo-prototype',
  templateUrl: './silo-prototype.component.html',
  styleUrls: ['./silo-prototype.component.scss'],
})
export class SiloPrototypeComponent implements OnInit, OnDestroy {
  @ViewChildren(EquipmentCardComponent)
  equipmentCards: QueryList<EquipmentCardComponent>;
  @Input() stepper?: MatStepper;
  @Input() downloadProjectFilesFlag = false;
  @ViewChild('designContainer') designContainer: ElementRef;
  @ViewChild('addEquipmentsStepper') addEquipmentsStepper: MatStepper;

  isViewPlant = false;
  isFromQuotations = false;
  quotationId = '';
  public canContinue = false;
  public disableBackButton = false;
  public disableSubmitQuotationRequest = false;
  public loading = true;
  public addEquipmentsLoading = false;
  public menuOptions = [
    'Equipment specifications',
    'Add equipment',
    'Delete equipment',
    'Move equipment',
  ];
  private clickedNext = false;
  public showEquipmentList = false;
  public showAddEquipmentDialog = false;
  public showDeleteEquipmentDialog = false;
  public showRotateEquipmentDialog = false;
  public showMoveEquipmentDialog = false;
  public showEquipmentSpecificationsDialog = false;
  public currentProject: Project = new Project();
  public projectName: string;
  public collisions: OverlappingEquipmentsPair[] = [];
  private orientation: SiloGroupOrientation;
  private canvasSelector = '#silo3dDesign';
  private sceneComponents: SceneParams;
  private canvas: HTMLCanvasElement;
  private routes = environment.routes;
  private currentlyAddingNodes: Part3DNode[];
  /**
   * THREEjs debugging properties
   */
  private debugger: GUI;
  private debuggingModeActivated: boolean = false;
  /********************************************** */
  private addEquipmentParams: PartDetails3DNew;
  /**
   * CANNONjs properties
   */
  private world: any;
  /********************************************** */

  // Subjects & Observables
  private sceneObjects$: Subject<SceneGeneratorEvent> =
    new Subject<SceneGeneratorEvent>();
  private sceneGenerator$: Subject<SceneGeneratorEnum> =
    new Subject<SceneGeneratorEnum>();
  private projectsService$: Subject<OperationResult> =
    new Subject<OperationResult>();
  private uploadService$: Subject<OperationResult> =
    new Subject<OperationResult>();
  private downloadService$: Subject<OperationResult> =
    new Subject<OperationResult>();

  constructor(
    private api: ApiService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private projectFiles: ProjectFilesService,
    private equipmentGenerator: EquipmentGeneratorService,
    private equipmentGraph: EquipmentGraphService,
    private snackBar: GenericSnackBarService,
    private cloner: CloneObjectService,
    private siloPlantGenerator: SiloPlantGeneratorService,
    private siloPlantCustomizer: SiloPlantCustomizerService,
    private projectHelper: ProjectHelperService,
    public quotationHelper: QuotationHelperService,
    public debuggerHelper: Debug3DHelperService,
    public physicsHelper: PhysicsService,
    public collisionsService: CollisionsService
  ) {
    this.api.http = this.http;
  }
  ngOnInit(): void {
    setTimeout(this.initTimedOutFunction.bind(this), 0);
  }
  initializeScene(): void {
    this.canvas = document.querySelector(this.canvasSelector);
    this.canvas.style.width = '100%';
    this.canvas.style.height = '100%';
    this.canvas.width = this.canvas.offsetWidth;
    this.canvas.height = this.canvas.offsetHeight;
    this.sceneComponents = this.siloPlantGenerator.initScene3D(
      this.canvasSelector,
      NTILES
    );
    this.physicsHelper.initPhysicsWorld(this.sceneComponents.scene);

    this.siloPlantGenerator.updateScene3D(
      this.sceneComponents,
      this.canvasSelector,
      NTILES
    );
  }

  initTimedOutFunction(): void {
    this.loading = true;
    this.initializeScene();

    const canvas = document.querySelector(this.canvasSelector);
    /*
    setInterval(() => {
      console.log(this.sceneComponents.camera);
    }, 1000);*/
    // stop propagation of clicks
    canvas.addEventListener('click', (e) => {
      e.stopPropagation();
    });
    // subscribe to the projects helper service
    this.projectsService$.subscribe((event) => {
      if (event.status === ProjectHelperServiceEnum.LOADED) {
        this.initializeProject(event.data);
      }
      if (event.status === ProjectHelperServiceEnum.EDITED) {
        if (event.data.hasOwnProperty('navigateTo')) {
          this.router.navigate(event.data.navigateTo);
          if (this.clickedNext) {
            this.stepper.next();
            this.clickedNext = false;
          }
        } else {
          if (this.clickedNext) {
            this.stepper.next();
            this.clickedNext = false;
          }
        }
      }
      if (event.status === ProjectHelperServiceEnum.NEXT) {
        this.loading = false;
        this.stepper.next();
      }
    });
    this.downloadService$.subscribe((event) => {
      if (event.status === ProjectHelperServiceEnum.SUCCESS) {
        this.snackBar.showSuccess(event.data);
      }
    });
    this.uploadService$.subscribe((event) => {
      if (event.status === ProjectHelperServiceEnum.SUCCESS) {
        this.snackBar.showSuccess(event.data);
      }
    });
    this.route.params.subscribe((param) => {
      this.projectHelper.getProject(this.projectsService$, param.id);
    });
    this.route.queryParams.subscribe((param) => {
      const view = param.view;
      if (view && view === 'quotation') {
        this.disableSubmitQuotationRequest = false;
      } else {
        this.disableSubmitQuotationRequest = true;
      }
      if (view && view === 'plantDebug') {
        this.debuggerHelper.initDebug3DHelper();
        this.debugger = this.debuggerHelper.getDebuggerInstance();
        this.debuggerHelper.addSceneControls(this.sceneComponents);
        this.debugger.onFinishChange((e) => {
          this.sceneComponents.renderer.render(
            this.sceneComponents.scene,
            this.sceneComponents.camera
          );
        });

        this.debugger.show().close();
        this.debuggingModeActivated = true;
        this.physicsHelper.createCannonDebugger(this.sceneComponents.scene);
        console.log('Debugging mode ON');
      }
    });
  }

  async downloadProjectSpecs(): Promise<void> {
    await this.projectFiles.uploadProjectFile(
      this.currentProject,
      ProjectFileType.STL_FILE,
      this.uploadService$,
      null,
      null,
      this.sceneComponents
    );
    await this.projectFiles.downloadProjectFiles(
      this.currentProject,
      this.downloadService$,
      true,
      this.sceneComponents
    );
  }

  customizeDialog(menuOption: String): void {
    this.customizeClosed();
    switch (menuOption) {
      case "Add equipment": {
        this.showAddEquipmentDialog = true;
        break;
      }
      case "Delete equipment": {
        this.showDeleteEquipmentDialog = true;
        break;
      }
      case "Rotate wquipment": {
        this.showRotateEquipmentDialog = true;
        break;
      }
      case "Move equipment": {
        this.showMoveEquipmentDialog = true;
        this.snackBar.showSuccess(
          'You can also move equipment using keyboard. For Elevation: WS. For X axis: AD. For Z axis: QE. For Rotation: RT'
        );
        this.collisionsService.addEquipmentsBoxHelper(
          this.currentProject.nodes,
          this.sceneComponents.scene
        );

        this.collisions = this.collisionsService.checkColisions(
          this.currentProject.nodes,
          this.collisions,
          this.sceneComponents.scene
        );
        break;
      }
      case "Equipment specifications": {
        this.showEquipmentSpecificationsDialog = true;
        break;
      }
    }
    setTimeout(() => {
      this.designContainer.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }, 100);
  }

  customizeClosed(): void {
    this.showDeleteEquipmentDialog = false;
    this.showRotateEquipmentDialog = false;
    this.showAddEquipmentDialog = false;
    this.showEquipmentList = false;
    this.showMoveEquipmentDialog = false;
    this.showEquipmentSpecificationsDialog = false;
    this.toggleBoundingBox();
    this.addEquipmentsStepper.reset();
  }

  addEquipment(event: PartDetails3DNew | MouseEvent): void {
    const mouseEvent = event as MouseEvent;
    switch (this.addEquipmentsStepper.selectedIndex) {
      case AddEquipmentStepperEnum.SET_PARAMETERS: {
        event = event as PartDetails3DNew;
        event.params = event.params as Bin3DParams;
        const DEFAULT_POSITION = new THREE.Vector3(6, 0, 6);
        const DEFAULT_ORIENTATION = new THREE.Vector3(0, 0, 0);
        // this.siloGrid.canAddEquipment = true;
        const nodeId =
          Math.max(...this.currentProject.nodes.map((x) => x.id)) + 1;
        const node = new Part3DNode(nodeId);
        if (event.parents && event.parents.length) {
          node.parents = event.parents.map((x) => x.toString());
        } else {
          node.parents.push(
            this.currentProject.nodes
              .find((x) => x.partDetails3D.params.name === 'Root')
              .id.toString()
          );
        }
        if (event.children && event.children.length) {
          node.children = event.children.map((x) => x.toString());
        }
        node.partDetails3D = new Part3DDetails();
        node.area = event.area;
        node.type = event.type;
        node.position = DEFAULT_POSITION;
        node.orientation = DEFAULT_ORIENTATION;
        let newIntakeConveyor: Part3DNode = null; // USED ONLY FOR INTAKES, NEEDS REFACTORING.
        switch (node.type) {
          case PartTypeEnum.INTAKE: {
            node.partDetails3D.params = event.params as Part3DParamsBase;
            node.partDetails3D.params.name = event.name;
            node.partDetails3D.params.color = node.partDetails3D.params.color
              ? node.partDetails3D.params.color
              : INTAKE_AREA_PARAMS.color;
            node.partDetails3D.params.dx = node.partDetails3D.params.dx
              ? new THREE.Vector3().copy(node.partDetails3D.params.dx)
              : new THREE.Vector3().copy(INTAKE_AREA_PARAMS.dx);
            node.partDetails3D.params.padding = node.partDetails3D.params
              .padding
              ? new THREE.Vector3().copy(node.partDetails3D.params.padding)
              : new THREE.Vector3().copy(INTAKE_AREA_PARAMS.padding);

            // add intake conveyor beneath the intake
            let intakeGrandChild: Part3DNode;
            const existingIntake = this.currentProject.nodes.find(
              (x) => x.type === PartTypeEnum.INTAKE
            );
            if (existingIntake) {
              const existingIntakeChild = this.currentProject.nodes.find(
                (x) => x.id === parseInt(existingIntake.children[0], 0)
              );
              intakeGrandChild = this.currentProject.nodes.find(
                (x) => x.id === parseInt(existingIntakeChild.children[0], 0)
              );
            }
            if (intakeGrandChild) {
              newIntakeConveyor = new Part3DNode();
              newIntakeConveyor.id =
                Math.max(...this.currentProject.nodes.map((x) => x.id)) + 2;
              newIntakeConveyor.area = PartAreaEnum.INTAKE;
              newIntakeConveyor.type = PartTypeEnum.BELT_CONVEYOR;
              newIntakeConveyor.parents.push(node.id.toString());
              newIntakeConveyor.children.push(intakeGrandChild.id.toString());
              newIntakeConveyor.partDetails3D = new Part3DDetails();
              newIntakeConveyor.position = new THREE.Vector3(
                node.position.x - node.partDetails3D.params.dx.x / 2,
                BELT_CONVEYOR_PARAMS.depth.y,
                node.position.z
              );
              newIntakeConveyor.orientation = new THREE.Vector3(0, 0, 0);
              newIntakeConveyor.partDetails3D.params = JSON.parse(
                JSON.stringify(BELT_CONVEYOR_PARAMS)
              ) as Conveyor3DParams;
              newIntakeConveyor.partDetails3D.params.name = `Conveyor ${node.partDetails3D.params.name}`;
              newIntakeConveyor.partDetails3D.params.dx.x =
                node.partDetails3D.params.dx.x;
              // establish relationships between new equipments
              newIntakeConveyor.children = newIntakeConveyor.children.concat(
                ...node.children
              );
              newIntakeConveyor.children = newIntakeConveyor.children.filter(
                (item, i, a) => a.indexOf(item) === i
              );
              node.children = [newIntakeConveyor.id.toString()];
              intakeGrandChild.parents.push(newIntakeConveyor.id.toString());

              this.currentlyAddingNodes = [node, newIntakeConveyor];
            }
            break;
          }

          case PartTypeEnum.DELIVERY: {
            node.partDetails3D.params = event.params as Delivery3DParams;
            node.partDetails3D.params.name = event.name;
            node.partDetails3D.params.color = node.partDetails3D.params.color
              ? node.partDetails3D.params.color
              : DELIVERY_AREA_PARAMS.color;
            node.partDetails3D.params.dx = node.partDetails3D.params.dx
              ? new THREE.Vector3().copy(node.partDetails3D.params.dx)
              : new THREE.Vector3().copy(DELIVERY_AREA_PARAMS.dx);
            node.partDetails3D.params.padding = node.partDetails3D.params
              .padding
              ? new THREE.Vector3().copy(node.partDetails3D.params.padding)
              : new THREE.Vector3().copy(DELIVERY_AREA_PARAMS.padding);
            (node.partDetails3D.params as Delivery3DParams).support = (
              node.partDetails3D.params as Delivery3DParams
            ).support
              ? new THREE.Vector3().copy(
                  (node.partDetails3D.params as Delivery3DParams).support
                )
              : new THREE.Vector3().copy(DELIVERY_AREA_PARAMS.support);

            if ('diameter' in node.partDetails3D.params) {
              node.partDetails3D.params['dx']['x'] =
                node.partDetails3D.params['diameter'];
              node.partDetails3D.params['dx']['z'] =
                node.partDetails3D.params['diameter'];
            }
            if ('volume' in node.partDetails3D.params) {
              (<number>node.partDetails3D.params['dx']['y']) =
                <number>node.partDetails3D.params['volume'] /
                (Math.PI *
                  Math.pow(node.partDetails3D.params['dx']['x'] / 2, 2));
            }
            break;
          }

          case PartTypeEnum.CLEANER: {
            node.partDetails3D.params = event.params as Cleaner3DParams;
            node.partDetails3D.params.name = event.name;
            node.subType = event.subType;
            node.partDetails3D.params.color = node.partDetails3D.params.color
              ? node.partDetails3D.params.color
              : CLEANER_PARAMS.color;
            node.partDetails3D.params.dx = node.partDetails3D.params.dx
              ? new THREE.Vector3().copy(node.partDetails3D.params.dx)
              : new THREE.Vector3().copy(CLEANER_PARAMS.dx);
            node.partDetails3D.params.padding = node.partDetails3D.params
              .padding
              ? new THREE.Vector3().copy(node.partDetails3D.params.padding)
              : new THREE.Vector3().copy(CLEANER_PARAMS.padding);
            (node.partDetails3D.params as Cleaner3DParams).support = (
              node.partDetails3D.params as Cleaner3DParams
            ).support
              ? new THREE.Vector3().copy(
                  (node.partDetails3D.params as Cleaner3DParams).support
                )
              : new THREE.Vector3().copy(CLEANER_PARAMS.support);
            (node.partDetails3D.params as Cleaner3DParams).cover = (
              node.partDetails3D.params as Cleaner3DParams
            ).cover
              ? new THREE.Vector3().copy(
                  (node.partDetails3D.params as Cleaner3DParams).cover
                )
              : new THREE.Vector3().copy(CLEANER_PARAMS.cover);
            (node.partDetails3D.params as Cleaner3DParams).capacity = (
              node.partDetails3D.params as Cleaner3DParams
            ).capacity
              ? (node.partDetails3D.params as Cleaner3DParams).capacity
              : CLEANER_PARAMS.capacity;
            break;
          }
          case PartTypeEnum.DRYER: {
            node.partDetails3D.params = event.params as Dryer3DParams;
            node.partDetails3D.params.name = event.name;
            node.partDetails3D.params.color = node.partDetails3D.params.color
              ? node.partDetails3D.params.color
              : DRYER_PARAMS.color;
            node.partDetails3D.params.dx = node.partDetails3D.params.dx
              ? new THREE.Vector3().copy(node.partDetails3D.params.dx)
              : new THREE.Vector3().copy(DRYER_PARAMS.dx);
            node.partDetails3D.params.padding = node.partDetails3D.params
              .padding
              ? new THREE.Vector3().copy(node.partDetails3D.params.padding)
              : new THREE.Vector3().copy(DRYER_PARAMS.padding);
            (node.partDetails3D.params as Dryer3DParams).capacity = (
              node.partDetails3D.params as Dryer3DParams
            ).capacity
              ? (node.partDetails3D.params as Dryer3DParams).capacity
              : DRYER_PARAMS.capacity;
            (node.partDetails3D.params as Dryer3DParams).moistureReduction = (
              node.partDetails3D.params as Dryer3DParams
            ).moistureReduction
              ? (node.partDetails3D.params as Dryer3DParams).moistureReduction
              : DRYER_PARAMS.moistureReduction;
            break;
          }
          case PartTypeEnum.BUFFER_BIN: {
            node.partDetails3D.params = event.params as Bin3DParams;

            node.partDetails3D.params.name = event.name;
            node.partDetails3D.params.dx = node.partDetails3D.params.dx
              ? new THREE.Vector3().copy(node.partDetails3D.params.dx)
              : new THREE.Vector3().copy(BUFFER_BIN_PARAMS.dx);

            node.partDetails3D.params.color = node.partDetails3D.params.color
              ? node.partDetails3D.params.color
              : BUFFER_BIN_PARAMS.color;

            node.partDetails3D.params.padding = node.partDetails3D.params
              .padding
              ? new THREE.Vector3().copy(node.partDetails3D.params.padding)
              : new THREE.Vector3().copy(BUFFER_BIN_PARAMS.padding);

            if ('diameter' in node.partDetails3D.params) {
              node.partDetails3D.params['dx']['x'] =
                node.partDetails3D.params['diameter'];
              node.partDetails3D.params['dx']['z'] =
                node.partDetails3D.params['diameter'];
            }
            if ('volume' in node.partDetails3D.params) {
              (<number>node.partDetails3D.params['dx']['y']) =
                <number>node.partDetails3D.params['volume'] /
                (Math.PI *
                  Math.pow(node.partDetails3D.params['dx']['x'] / 2, 2));
            }

            break;
          }
          case PartTypeEnum.SILO_BIN: {
            node.partDetails3D.params = event.params as Bin3DParams;

            node.partDetails3D.params.name = event.name;
            node.partDetails3D.params.color = node.partDetails3D.params.color
              ? node.partDetails3D.params.color
              : SILO_BIN_PARAMS.color;
            node.partDetails3D.params.dx = node.partDetails3D.params.dx
              ? new THREE.Vector3().copy(node.partDetails3D.params.dx)
              : new THREE.Vector3().copy(SILO_BIN_PARAMS.dx);
            node.partDetails3D.params.padding = node.partDetails3D.params
              .padding
              ? new THREE.Vector3().copy(node.partDetails3D.params.padding)
              : new THREE.Vector3().copy(SILO_BIN_PARAMS.padding);

            if ('diameter' in node.partDetails3D.params) {
              node.partDetails3D.params['dx']['x'] =
                node.partDetails3D.params['diameter'];
              node.partDetails3D.params['dx']['z'] =
                node.partDetails3D.params['diameter'];
            }
            if ('volume' in node.partDetails3D.params) {
              (<number>node.partDetails3D.params['dx']['y']) =
                <number>node.partDetails3D.params['volume'] /
                (Math.PI *
                  Math.pow(node.partDetails3D.params['dx']['x'] / 2, 2));
            }

            break;
          }
          case PartTypeEnum.ELEVATOR: {
            if (node.area === PartAreaEnum.STORAGE) {
              node.partDetails3D.params = event.params as Elevator3DParams;
              node.partDetails3D.params.name = event.name;
              node.partDetails3D.params.color = node.partDetails3D.params.color
                ? node.partDetails3D.params.color
                : MAIN_ELEVATOR_PARAMS.color;
              node.partDetails3D.params.dx = node.partDetails3D.params.dx
                ? new THREE.Vector3().copy(node.partDetails3D.params.dx)
                : new THREE.Vector3().copy(MAIN_ELEVATOR_PARAMS.dx);
              node.partDetails3D.params.padding = node.partDetails3D.params
                .padding
                ? new THREE.Vector3().copy(node.partDetails3D.params.padding)
                : new THREE.Vector3().copy(MAIN_ELEVATOR_PARAMS.padding);
              (node.partDetails3D.params as Elevator3DParams).capacity = (
                node.partDetails3D.params as Elevator3DParams
              ).capacity
                ? (node.partDetails3D.params as Elevator3DParams).capacity
                : MAIN_ELEVATOR_PARAMS.capacity;
            } else if (
              node.area === PartAreaEnum.CLEANER ||
              node.area === PartAreaEnum.DRYER ||
              node.area === PartAreaEnum.DELIVERY
            ) {
              node.partDetails3D.params = event.params as Elevator3DParams;
              node.partDetails3D.params.name = event.name;
              node.partDetails3D.params.color = node.partDetails3D.params.color
                ? node.partDetails3D.params.color
                : MAIN_ELEVATOR_PARAMS.color;
              node.partDetails3D.params.dx = node.partDetails3D.params.dx
                ? new THREE.Vector3().copy(node.partDetails3D.params.dx)
                : new THREE.Vector3().copy(MAIN_ELEVATOR_PARAMS.dx);
              node.partDetails3D.params.padding = node.partDetails3D.params
                .padding
                ? new THREE.Vector3().copy(node.partDetails3D.params.padding)
                : new THREE.Vector3().copy(MAIN_ELEVATOR_PARAMS.padding);
              (node.partDetails3D.params as Elevator3DParams).capacity = (
                node.partDetails3D.params as Elevator3DParams
              ).capacity
                ? (node.partDetails3D.params as Elevator3DParams).capacity
                : MAIN_ELEVATOR_PARAMS.capacity;
            } else {
              node.partDetails3D.params = event.params as Elevator3DParams;
              node.partDetails3D.params.name = event.name;
              node.partDetails3D.params.color = node.partDetails3D.params.color
                ? node.partDetails3D.params.color
                : MAIN_ELEVATOR_PARAMS.color;
              node.partDetails3D.params.dx = node.partDetails3D.params.dx
                ? new THREE.Vector3().copy(node.partDetails3D.params.dx)
                : new THREE.Vector3().copy(MAIN_ELEVATOR_PARAMS.dx);
              node.partDetails3D.params.padding = node.partDetails3D.params
                .padding
                ? new THREE.Vector3().copy(node.partDetails3D.params.padding)
                : new THREE.Vector3().copy(MAIN_ELEVATOR_PARAMS.padding);
              (node.partDetails3D.params as Elevator3DParams).capacity = (
                node.partDetails3D.params as Elevator3DParams
              ).capacity
                ? (node.partDetails3D.params as Elevator3DParams).capacity
                : MAIN_ELEVATOR_PARAMS.capacity;
            }

            if ('height' in node.partDetails3D.params) {
              node.partDetails3D.params['dx']['y'] =
                node.partDetails3D.params['height'];
            }
            break;
          }
          case PartTypeEnum.BELT_CONVEYOR: {
            node.partDetails3D.params = event.params as Conveyor3DParams;

            node.partDetails3D.params.name = event.name;
            node.partDetails3D.params.color = node.partDetails3D.params.color
              ? node.partDetails3D.params.color
              : BELT_CONVEYOR_PARAMS.color;
            node.partDetails3D.params.dx = node.partDetails3D.params.dx
              ? new THREE.Vector3().copy(node.partDetails3D.params.dx)
              : new THREE.Vector3().copy(BELT_CONVEYOR_PARAMS.dx);
            node.partDetails3D.params.padding = node.partDetails3D.params
              .padding
              ? new THREE.Vector3().copy(node.partDetails3D.params.padding)
              : new THREE.Vector3().copy(BELT_CONVEYOR_PARAMS.padding);
            (node.partDetails3D.params as Conveyor3DParams).capacity = (
              node.partDetails3D.params as Conveyor3DParams
            ).capacity
              ? (node.partDetails3D.params as Conveyor3DParams).capacity
              : BELT_CONVEYOR_PARAMS.capacity;
            (node.partDetails3D.params as Conveyor3DParams).depth = (
              node.partDetails3D.params as Conveyor3DParams
            ).depth
              ? new THREE.Vector3().copy(
                  (node.partDetails3D.params as Conveyor3DParams).depth
                )
              : new THREE.Vector3(0, 0, 0);

            if ('length' in node.partDetails3D.params) {
              node.partDetails3D.params['dx']['x'] =
                node.partDetails3D.params['length'];
            }
            // set conveyor length based on longest distance between parent and child nodes
            const parentPosition = new THREE.Vector3();
            const childPosition = new THREE.Vector3();
            const parentPositionVector = new THREE.Vector3();
            let longestDistance = 0;
            for (const parent of node.parents) {
              const parentNode = this.equipmentGraph.findNodeById(
                this.currentProject.nodes,
                parseInt(parent, 0)
              );
              for (const child of node.children) {
                const childNode = this.equipmentGraph.findNodeById(
                  this.currentProject.nodes,
                  parseInt(child, 0)
                );
                parentPositionVector.copy(parentNode.position);
                const distance = parentPositionVector.distanceTo(
                  childNode.position
                );
                if (distance > longestDistance) {
                  parentPosition.copy(parentNode.position);
                  childPosition.copy(childNode.position);
                  longestDistance = distance;
                }
              }
            }
            if (node.partDetails3D.params.dx.x === 0) {
              node.partDetails3D.params.dx.x = longestDistance;
            }

            node.position.copy(parentPosition);

            node.orientation.y = new THREE.Vector3(1, 0, 0).angleTo(
              new THREE.Vector3(
                childPosition.x - parentPosition.x,
                0,
                childPosition.z - parentPosition.z
              )
            );
            if (parentPosition.z < childPosition.z) {
              node.orientation.y *= -1;
            }
            break;
          }
          case PartTypeEnum.CHAIN_CONVEYOR: {
            node.partDetails3D.params = event.params as Conveyor3DParams;

            node.partDetails3D.params.name = event.name;
            node.partDetails3D.params.color = node.partDetails3D.params.color
              ? node.partDetails3D.params.color
              : CHAIN_CONVEYOR_PARAMS.color;
            node.partDetails3D.params.dx = node.partDetails3D.params.dx
              ? new THREE.Vector3().copy(node.partDetails3D.params.dx)
              : new THREE.Vector3().copy(CHAIN_CONVEYOR_PARAMS.dx);
            node.partDetails3D.params.padding = node.partDetails3D.params
              .padding
              ? new THREE.Vector3().copy(node.partDetails3D.params.padding)
              : new THREE.Vector3().copy(CHAIN_CONVEYOR_PARAMS.padding);
            (node.partDetails3D.params as Elevator3DParams).capacity = (
              node.partDetails3D.params as Elevator3DParams
            ).capacity
              ? (node.partDetails3D.params as Elevator3DParams).capacity
              : CHAIN_CONVEYOR_PARAMS.capacity;
            (node.partDetails3D.params as Conveyor3DParams).nOutputs = (
              node.partDetails3D.params as Conveyor3DParams
            ).nOutputs
              ? (node.partDetails3D.params as Conveyor3DParams).nOutputs
              : CHAIN_CONVEYOR_PARAMS.nOutputs;
            (node.partDetails3D.params as Conveyor3DParams).depth = (
              node.partDetails3D.params as Conveyor3DParams
            ).depth
              ? new THREE.Vector3().copy(
                  (node.partDetails3D.params as Conveyor3DParams).depth
                )
              : new THREE.Vector3(0, 0, 0);

            if ('length' in node.partDetails3D.params) {
              node.partDetails3D.params['dx']['x'] =
                node.partDetails3D.params['length'];
            }
            // set conveyor length based on longest distance between parent and child nodes
            const parentPosition = new THREE.Vector3();
            const childPosition = new THREE.Vector3();
            const parentPositionVector = new THREE.Vector3();
            let longestDistance = 0;
            for (const parent of node.parents) {
              const parentNode = this.equipmentGraph.findNodeById(
                this.currentProject.nodes,
                parseInt(parent, 0)
              );
              parentPositionVector.set(
                parentNode.position.x,
                parentNode.position.y,
                parentNode.position.z
              );

              for (const child of node.children) {
                const childNode = this.equipmentGraph.findNodeById(
                  this.currentProject.nodes,
                  parseInt(child, 0)
                );
                const distance = parentPositionVector.distanceTo(
                  childNode.position
                );
                if (distance > longestDistance) {
                  parentPosition.copy(parentNode.position);
                  childPosition.copy(childNode.position);
                  longestDistance = distance;
                }
              }
            }
            if (node.partDetails3D.params.dx.x === 0) {
              node.partDetails3D.params.dx.x = longestDistance;
            }

            node.position.copy(parentPosition);

            node.orientation.y = new THREE.Vector3(1, 0, 0).angleTo(
              new THREE.Vector3(
                childPosition.x - parentPosition.x,
                0,
                childPosition.z - parentPosition.z
              )
            );
            if (parentPosition.z < childPosition.z) {
              node.orientation.y *= -1;
            }
            break;
          }
        }

        this.currentlyAddingNodes = [node];
        if (newIntakeConveyor !== null) {
          this.currentlyAddingNodes.push(newIntakeConveyor);
        }

        this.addEquipmentParams = event;
        this.currentlyAddingNodes.forEach((node) => {
          this.equipmentGenerator.getPartFactory(node, this.sceneObjects$);
        });
        this.addEquipmentsStepper.next();

        break;
      }
      case AddEquipmentStepperEnum.POSITION_EQUIPMENT: {
        const equipment = this.currentlyAddingNodes[0];
        const secondEquipment = this.currentlyAddingNodes[1];

        if (
          equipment.type !== PartTypeEnum.CHAIN_CONVEYOR &&
          equipment.type !== PartTypeEnum.BELT_CONVEYOR
        ) {
          event = event as MouseEvent;

          const rect = this.canvas.getBoundingClientRect();
          this.sceneComponents.pointer.x =
            ((event.clientX - rect.left) / (rect.right - rect.left)) * 2 - 1;
          this.sceneComponents.pointer.y =
            -((event.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;

          // create a ray caster
          this.sceneComponents.raycaster.setFromCamera(
            this.sceneComponents.pointer,
            this.sceneComponents.camera
          );

          // find out the intersection between rays and plane
          let intersects = this.sceneComponents.raycaster.intersectObjects(
            this.sceneComponents.scene.children,
            false
          );

          if (intersects.length > 0) {
            equipment.position = new THREE.Vector3(
              intersects[0].point.x,
              0,
              intersects[0].point.z
            );

            if (secondEquipment !== undefined) {
              this.currentlyAddingNodes[1].position =
                this.currentlyAddingNodes[0].position;
              this.currentlyAddingNodes[1].position.y =
                BELT_CONVEYOR_PARAMS.depth.y;
            }
          }
          this.currentlyAddingNodes.forEach((node) => {
            this.equipmentGenerator.getPartFactory(node, this.sceneObjects$);
          });
        }

        break;
      }
    }
  }

  deleteEquipment(event: any): void {
    const existingNodes = [...this.currentProject.nodes];
    try {
      for (const nodeId of event) {
        const deletedNode = this.equipmentGraph.findNodeById(
          this.currentProject.nodes,
          nodeId
        );
        if (deletedNode) {
          this.currentProject.nodes = this.equipmentGraph.deleteNode(
            this.currentProject.nodes,
            deletedNode.id
          );
          for (const node of this.sceneComponents.scene.children) {
            if (node.name !== '' && !node.name.includes('Tile')) {
              const existingNode = this.currentProject.nodes.find(
                (n) => n.partDetails3D.params.name === node.name
              );
              if (!existingNode) {
                this.sceneComponents.scene.remove(node);
              }
            }
          }
        }
      }
    } catch (err) {
      this.snackBar.showError(`Failed to delete equipments. Cause ${err}.`);
      this.currentProject.nodes = [...existingNodes];
    }
  }

  // ------ PROJECT INIT HELPER FUNCTIONS ------ //
  initializeProject(project: any): void {
    // subscribe to scene generator
    this.sceneGenerator$.subscribe((e) => {
      switch (e) {
        case SceneGeneratorEnum.POSITION_SILO_BINS: {
          const bins = this.currentProject.nodes.filter(
            (n) => n.type === PartTypeEnum.SILO_BIN
          );

          const elevator = this.currentProject.nodes.filter(
            (n) => n.type === PartTypeEnum.ELEVATOR
          )[0];
          this.orientation =
            this.siloPlantGenerator.helper.computeLongitudinalAndTransversalAxis(
              elevator,
              bins
            );

          // create silo bins
          let numberOfTransversalBins =
            this.siloPlantGenerator.helper.getNumberOfSiloBinRows(
              bins,
              this.orientation.axis
            );
          let numberOfLongitudinalBins =
            this.siloPlantGenerator.helper.getNumberOfSiloBinsPerRow(
              bins,
              this.orientation.axis
            );
          if (numberOfLongitudinalBins === 0 && bins.length > 1) {
            numberOfLongitudinalBins = 1;
          }
          let numberedBins = [];
          if (
            (bins && bins[0].partDetails3D.binNumberPerRow === undefined) ||
            bins[0].partDetails3D.binRowNum === undefined
          ) {
            numberedBins = this.siloPlantGenerator.numberSiloBins(
              bins,
              this.orientation.bin,
              this.orientation.axis,
              DEFAULT_TILE_SIZE,
              numberOfTransversalBins,
              numberOfLongitudinalBins
            );
            if (numberedBins === null) {
              this.snackBar.showError(
                'Something is not just right. Please revise the bins or intake placement.'
              );
            }
          } else {
            numberedBins = [...bins];
          }
          if (numberedBins.length > 1) {
            this.siloPlantGenerator.positionSiloBins(
              numberedBins,
              this.orientation,
              this.sceneObjects$,
              numberOfTransversalBins,
              numberOfLongitudinalBins
            );
          } else {
            this.siloPlantGenerator.positionSingleBin(
              this.sceneObjects$,
              this.orientation
            );
          }
          break;
        }

        case SceneGeneratorEnum.POSITION_MAIN_ELEVATOR: {
          // create main elevator
          // todo: position elevator can be 1 function with some more parameters.
          // todo: all these functions could be generalised more to be used for adding elements too.
          //  do this after the default config is done.
          this.siloPlantGenerator.initSiloPlantConfig(
            this.currentProject.nodes
          );

          this.siloPlantGenerator.positionMainElevator(
            this.currentProject.nodes,
            this.orientation,
            this.sceneObjects$
          );
          break;
        }
        case SceneGeneratorEnum.POSITION_CLEANER_ELEVATOR: {
          // create cleaner elevator
          this.siloPlantGenerator.positionCleanerElevator(
            this.currentProject.nodes,
            this.orientation,
            this.sceneObjects$
          );
          break;
        }
        case SceneGeneratorEnum.POSITION_CLEANER: {
          // create cleaner
          this.siloPlantGenerator.positionCleaner(
            this.currentProject.nodes,
            this.orientation.axis.L,
            this.sceneObjects$
          );
          break;
        }
        case SceneGeneratorEnum.POSITION_DELIVERY_BINS: {
          // create delivery bin
          this.siloPlantGenerator.positionDeliveryBins(
            this.currentProject.nodes,
            this.orientation.axis.L,
            this.sceneObjects$
          );
          break;
        }
        case SceneGeneratorEnum.POSITION_DRYER_ELEVATOR: {
          // create dryer elevator
          this.siloPlantGenerator.positionDryerElevator(
            this.currentProject.nodes,
            this.orientation,
            this.sceneObjects$
          );
          break;
        }
        case SceneGeneratorEnum.POSITION_DRYER: {
          // create dryer
          this.siloPlantGenerator.positionDryer(
            this.currentProject.nodes,
            this.orientation,
            this.sceneObjects$
          );
          break;
        }
        case SceneGeneratorEnum.POSITION_BUFFER_BIN: {
          // create buffer bin
          this.siloPlantGenerator.positionDryerBufferBins(
            this.currentProject.nodes,
            this.orientation,
            this.sceneObjects$
          );
          break;
        }
        case SceneGeneratorEnum.POSITION_STORAGE_LOADING_CONVEYORS: {
          // create storage loading conveyors
          this.siloPlantGenerator.positionStorageLoadingConveyors(
            this.currentProject.nodes,
            this.orientation,
            this.sceneObjects$
          );
          break;
        }
        case SceneGeneratorEnum.POSITION_STORAGE_RECLAIMING_CONVEYORS: {
          // create storage reclaiming conveyors
          this.siloPlantGenerator.positionStorageReclaimingConveyors(
            this.currentProject.nodes,
            this.orientation,
            this.sceneObjects$
          );
          break;
        }
        case SceneGeneratorEnum.POSITION_DELIVERY_LOADING_CONVEYORS: {
          // create delivery conveyor
          this.siloPlantGenerator.positionDeliveryLoadingConveyors(
            this.currentProject.nodes,
            this.orientation,
            this.sceneObjects$
          );
          break;
        }
        case SceneGeneratorEnum.POSITION_DRYER_LOADING_CONVEYORS: {
          // create dryer loading conveyor
          this.siloPlantGenerator.positionDryerLoadingConveyors(
            this.currentProject.nodes,
            this.orientation,
            this.sceneObjects$
          );
          break;
        }
        case SceneGeneratorEnum.POSITION_DRYER_RECLAIMING_CONVEYORS: {
          // create dryer reclaiming conveyor
          this.siloPlantGenerator.positionDryerReclaimingConveyors(
            this.currentProject.nodes,
            this.orientation,
            this.sceneObjects$
          );
          break;
        }
        case SceneGeneratorEnum.POSITION_INTAKE: {
          const intake = this.currentProject.nodes.find(
            (n) =>
              n.area === PartAreaEnum.INTAKE && n.type === PartTypeEnum.INTAKE
          );
          let referenceRowBins = this.currentProject.nodes.filter(
            (n) =>
              n.area === PartAreaEnum.STORAGE &&
              n.type === PartTypeEnum.SILO_BIN &&
              n.partDetails3D.binRowNum === 0
          );
          if (referenceRowBins.length === 0) {
            referenceRowBins.push(this.orientation.bin);
          }
          const maxX = Math.max(
            ...referenceRowBins.map((b) => b.partDetails3D.params.dx.x / 2)
          );
          const maxZ = Math.max(
            ...referenceRowBins.map((b) => b.partDetails3D.params.dx.z / 2)
          );
          const referenceRowSize = new THREE.Vector3(maxX, 0, maxZ);
          // create intake
          this.siloPlantGenerator.positionIntake(
            intake,
            this.orientation,
            this.sceneObjects$,
            referenceRowSize
          );
          break;
        }
        case SceneGeneratorEnum.ADJUST_INTAKE_POSITION: {
          // adjust intake position
          const intake = this.currentProject.nodes.find(
            (n) =>
              n.area === PartAreaEnum.INTAKE && n.type === PartTypeEnum.INTAKE
          );
          /**
           * 1.Add boundingBoxes
           * 2.Check for colisions
           * 3.Remove boundingBoxes
           */
          this.collisionsService.addEquipmentsBoxHelper(
            this.currentProject.nodes,
            this.sceneComponents.scene
          );
          this.collisions = this.collisionsService.checkColisions(
            this.currentProject.nodes,
            this.collisions,
            this.sceneComponents.scene
          );

          const intakeCollisions: OverlappingEquipmentsPair[] = [];
          this.collisions.forEach((colision) => {
            if (
              colision.firstEquipment.type === PartTypeEnum.INTAKE ||
              colision.secondEquipment.type === PartTypeEnum.INTAKE
            ) {
              intakeCollisions.push(colision);
            }
          });

          this.siloPlantGenerator.adjustPositionIntake(
            intake,
            intakeCollisions,
            this.currentProject.nodes,
            this.sceneComponents.scene.children.filter((n) =>
              n.name.includes('Tile')
            ),
            this.orientation,
            this.sceneObjects$
          );
          break;
        }
        case SceneGeneratorEnum.POSITION_INTAKE_RECLAIMING_CONVEYORS: {
          // create intake reclaiming conveyors
          const intake = this.currentProject.nodes.find(
            (n) =>
              n.area === PartAreaEnum.INTAKE && n.type === PartTypeEnum.INTAKE
          );
          this.siloPlantGenerator.positionIntakeReclaimingConveyors(
            intake,
            this.currentProject.nodes,
            this.orientation,
            this.sceneObjects$
          );
          break;
        }
        case SceneGeneratorEnum.CONNECT_EQUIPMENTS: {
          this.siloPlantGenerator.appendEquipmentRelations(
            this.currentProject.nodes,
            this.sceneObjects$
          );

          this.canContinue = true;
          break;
        }
        case SceneGeneratorEnum.COMPLETE: {
          if (this.currentProject.nodes[0].physicsBody === undefined) {
            this.orientation.binsMatrix =
              this.equipmentGraph.getBinsConfiguration(
                this.currentProject.nodes
              );
            this.collisionsService.addEquipmentsBoxHelper(
              this.currentProject.nodes,
              this.sceneComponents.scene
            );
            this.currentProject.nodes = this.physicsHelper.createPhysicsBodies(
              this.currentProject.nodes,
              this.sceneComponents.scene
            );
            this.physicsHelper.updatePhysicsWorld(
              this.currentProject.nodes,
              this.sceneComponents.scene
            );

            const orientationBin = this.currentProject.nodes.find(
              (node) => node.id === this.orientation.bin.id
            );
            if (orientationBin) {
              this.orientation.bin = orientationBin;
            }
            if (this.debuggingModeActivated === true) {
              this.currentProject.nodes.forEach((node) => {
                this.debuggerHelper.addEquipment(node);
              });
            }
          }

          this.physicsHelper.allowSleep(false);
        }
      }
    });
    // subscribe to receive objects which are displayed on the 3D canvas
    this.sceneObjects$.subscribe(
      async (so) => {
        if (so.part3DNode) {
          const existingElement = this.currentProject.nodes.find(
            (n) =>
              n.partDetails3D.params.name ===
              so.part3DNode.partDetails3D.params.name
          );
          const eIx = this.currentProject.nodes.indexOf(existingElement);
          if (eIx > -1) {
            try {
              this.currentProject.nodes[eIx] = this.cloner.deepCopyPart3DNode(
                so.part3DNode,
                so.part3DNode.partDetails3D.params.name,
                true
              );
            } catch (e) {
              console.log(e);
            }
            const currentSceneElement =
              this.sceneComponents.scene.children.find(
                (e) =>
                  e.name === so.part3DNode.partDetails3D.params.name &&
                  e.type !== 'Box3Helper'
              );
            const cseIx =
              this.sceneComponents.scene.children.indexOf(currentSceneElement);
            if (cseIx > -1) {
              this.sceneComponents.scene.children.splice(cseIx, 1);
            }
          } else {
            this.currentProject.nodes.push(so.part3DNode);
          }

          this.sceneComponents.scene.add(so.part3DNode.part3D.part);
          const node = this.currentProject.nodes.find(
            (n) => n.id === so.part3DNode.id
          );

          this.collisionsService.updateBoundingBox(
            node,
            this.sceneComponents.scene
          );

          if (node.sync === SyncStatesEnum.SYNC_NEEDED) {
            /**
             * 1. Get the new bounding box after the mesh was scaled
             * 2. Isolate the equipment from the others such that only it will move
             * 3. Update the physics body
             * 4. Remove isolation
             */
            this.physicsHelper.allowSleep(false);
            await this.physicsHelper.isolateNode(
              node,
              this.currentProject.nodes,
              this.orientation
            );
            await this.physicsHelper.updatePhysicsBody(
              node,
              this.sceneComponents.scene
            );
            if (node.type === PartTypeEnum.SILO_BIN) {
              this.siloPlantCustomizer.recenterSiloBins(
                this.currentProject.nodes,
                node,
                this.orientation
              );
            }
            this.physicsHelper.removeNodeIsolation();
            node.sync = SyncStatesEnum.SYNC_DONE;
          }
        }
        if (
          so.next !== undefined ||
          so.next !== SceneGeneratorEnum.DO_NOTHING
        ) {
          this.sceneGenerator$.next(so.next);
        }
      },
      (err) => console.log(err)
    );
    this.currentProject = this.cloner.deepCopyProject(
      project,
      project.name,
      true
    );

    this.projectName = this.currentProject.name;
    try {
      if (this.currentProject.completed === false) {
        this.currentProject.completed = true;
        this.siloPlantGenerator.buildSiloPlant(this.sceneObjects$);
      } else {
        for (const node of this.currentProject.nodes) {
          const isLastNode =
            node.id ===
            this.currentProject.nodes[this.currentProject.nodes.length - 1].id;
          this.equipmentGenerator.getPartFactory(
            node,
            this.sceneObjects$,
            isLastNode
          );
        }
      }

      this.canContinue = true;
    } catch (e) {
      this.snackBar.showError(e.message);
    }
    this.disableSubmitQuotationRequest = true;
    this.route.queryParams.subscribe((param) => {
      const view = param.view;
      if (view && view === 'plant') {
        this.isViewPlant = true;
      } else if (view && view === 'quotation') {
        this.isFromQuotations = true;
        this.quotationId = param.from;
      }
    });
    this.loading = false;
  }

  firstTimeInitialization(nodes: Part3DNode[]): boolean {
    const elevator = nodes.find((n) => n.type === PartTypeEnum.ELEVATOR);
    return elevator !== undefined;
  }

  // ------ OBJECT INTERACTIONS ------ //
  rotateEquipment(event: any): void {
    const angle = new THREE.Vector3(0, (event.angle * Math.PI) / 180, 0);
    for (const nodeId of event.nodeIds) {
      this.currentProject.nodes = this.equipmentGraph.rotateNode(
        this.currentProject.nodes,
        parseInt(nodeId, 0),
        angle
      );
      const equipment = this.currentProject.nodes.find(
        (x) => x.id === parseInt(nodeId, 0)
      );

      if (equipment.physicsBody !== null) {
        const eulerAngle = new THREE.Euler().setFromVector3(
          equipment.orientation
        );
        const quaternion = new THREE.Quaternion().setFromEuler(eulerAngle);
        equipment.physicsBody.quaternion.copy(quaternion);
      } else {
        const sceneEquipment = this.sceneComponents.scene.getObjectByName(
          equipment.partDetails3D.params.name
        );
        sceneEquipment.rotation.set(
          equipment.orientation.x,
          equipment.orientation.y,
          equipment.orientation.z
        );
      }
    }
  }

  onMoveComponent(event: any): void {
    for (const nodeId of event.nodeIds) {
      this.collisions = this.equipmentGraph.moveNode(
        this.currentProject.nodes,
        parseInt(nodeId, 0),
        event.axis,
        event.value,
        this.sceneComponents.scene,
        this.collisions
      );
    }
  }

  mouseOn(event: any): void {
    const color = 0xaaaaaa;
    if (event.hasOwnProperty('nodeName')) {
      this.equipmentGenerator.emitColor(
        this.sceneComponents,
        event.nodeName,
        color
      );
    } else {
      this.equipmentGenerator.emitColor(
        this.sceneComponents,
        event.partDetails3D.params.name,
        color
      );
    }
  }

  mouseOff(event: any): void {
    const color = 0x000000;
    if (event.hasOwnProperty('nodeName')) {
      this.equipmentGenerator.emitColor(
        this.sceneComponents,
        event.nodeName,
        color
      );
    } else {
      this.equipmentGenerator.emitColor(
        this.sceneComponents,
        event.partDetails3D.params.name,
        color
      );
    }
  }

  redrawPartByName(
    nodes: Part3DNode[],
    nodeName: string,
    sceneComponents: SceneParams,
    orientation: SiloGroupOrientation,
    sceneObjects$: Subject<SceneGeneratorEvent>
  ): SceneParams {
    // redraw equipment
    const node = nodes.find((x) => x.partDetails3D.params.name === nodeName);

    if (node) {
      const part3D = sceneComponents.scene.children.find(
        (x) => x.name === nodeName && x.type !== 'Box3Helper'
      );
      const redraw = this.siloPlantCustomizer.redrawPartFactory(
        node,
        nodes,
        orientation,
        sceneObjects$
      );
      if (redraw) {
        if (node.physicsBody !== undefined) {
          node.sync = SyncStatesEnum.SYNC_NEEDED;
        }
        const part3DIndex = sceneComponents.scene.children.indexOf(part3D);
        sceneComponents.scene.children.splice(part3DIndex, 1);
      }
    }
    return sceneComponents;
  }

  async showEquipment() {
    this.loading = false;
    if (this.showEquipmentList) {
      this.showEquipmentList = false;
      return;
    }
    this.showEquipmentList = true;
    this.showAddEquipmentDialog = false;
    this.showDeleteEquipmentDialog = false;
    this.showMoveEquipmentDialog = false;
    this.showEquipmentSpecificationsDialog = false;
    this.showRotateEquipmentDialog = false;
  }

  handleValueChanges($event): void {
    console.log($event);
    const equipment = this.currentProject.nodes.find((n) => n.id === $event.id);
    const crtEqIndx = this.currentProject.nodes.indexOf(equipment);
    if (
      equipment.type === PartTypeEnum.ELEVATOR &&
      equipment.area === PartAreaEnum.STORAGE
    ) {
      this.currentProject.nodes[crtEqIndx].partDetails3D.params['capacity'] =
        $event.firstParameterValue;
      this.currentProject.nodes[crtEqIndx].partDetails3D.params['dx']['y'] =
        $event.secondParameterValue;

      // Apply logic to change capacity for all conveyors that depend on
      // the main elevator conveyor
      const dryer = this.currentProject.nodes.find(
        (n) => n.type === PartTypeEnum.DRYER
      );
      const excludedConveyors = this.currentProject.nodes.filter(
        (n) =>
          n.area === PartAreaEnum.DRYER &&
          (n.type === PartTypeEnum.CHAIN_CONVEYOR ||
            n.type === PartTypeEnum.BELT_CONVEYOR) &&
          n.parents.includes(dryer.id.toString())
      );
      const includedConveyors = this.currentProject.nodes.filter(
        (n) =>
          (n.type === PartTypeEnum.CHAIN_CONVEYOR ||
            n.type === PartTypeEnum.BELT_CONVEYOR) &&
          !excludedConveyors.includes(n)
      );

      for (const conveyor of includedConveyors) {
        const indx = this.currentProject.nodes.indexOf(conveyor);
        this.currentProject.nodes[indx].partDetails3D.params['capacity'] =
          $event.firstParameterValue;
      }
    } else if (
      equipment.type === PartTypeEnum.ELEVATOR &&
      equipment.area !== PartAreaEnum.STORAGE
    ) {
      this.currentProject.nodes[crtEqIndx].partDetails3D.params['capacity'] =
        $event.firstParameterValue;
      this.currentProject.nodes[crtEqIndx].partDetails3D.params['dx']['y'] =
        $event.secondParameterValue;
    } else if (
      equipment.type === PartTypeEnum.CHAIN_CONVEYOR ||
      equipment.type === PartTypeEnum.BELT_CONVEYOR
    ) {
      this.currentProject.nodes[crtEqIndx].partDetails3D.params['capacity'] =
        $event.firstParameterValue;
      this.currentProject.nodes[crtEqIndx].partDetails3D.params['dx']['x'] =
        $event.secondParameterValue;
    } else if (
      equipment.type === PartTypeEnum.SILO_BIN ||
      equipment.type === PartTypeEnum.BUFFER_BIN ||
      equipment.type === PartTypeEnum.DELIVERY
    ) {
      this.currentProject.nodes[crtEqIndx].partDetails3D.params['volume'] =
        $event.firstParameterValue;
      this.currentProject.nodes[crtEqIndx].partDetails3D.params['dx']['x'] =
        $event.secondParameterValue;
    } else if (equipment.type === PartTypeEnum.DRYER) {
      this.currentProject.nodes[crtEqIndx].partDetails3D.params['capacity'] =
        $event.firstParameterValue;
      this.currentProject.nodes[crtEqIndx].partDetails3D.params[
        'moistureReduction'
      ] = $event.secondParameterValue;
    } else if (equipment.type === PartTypeEnum.CLEANER) {
      this.currentProject.nodes[crtEqIndx].partDetails3D.params['capacity'] =
        $event.firstParameterValue;
    }
  }

  equipmentListToShow(): any[] {
    return this.currentProject.nodes.filter(
      (e) => e.type !== PartTypeEnum.INTAKE
    );
  }

  editEquipmentSpecifications(event: PartDetails3DEditsEvent): void {
    this.currentProject.nodes =
      this.siloPlantCustomizer.editEquipmentSpecifications(
        this.currentProject.nodes,
        event
      );
    this.sceneComponents = this.redrawPartByName(
      this.currentProject.nodes,
      event.name,
      this.sceneComponents,
      this.orientation,
      this.sceneObjects$
    );
  }

  finishAddingEquipment(): void {
    // this.currentProject.nodes = this.equipmentGraph.addNode(
    //   this.currentProject.nodes,
    //   this.currentlyAddingNode
    // );
    this.addEquipmentParams = null;
    this.addEquipmentsStepper.reset();
    this.customizeDialog("Move equipment");
    this.saveProgress(true);
  }

  toggleBoundingBox(): void {
    const boxes = this.sceneComponents.scene.children.filter(
      (c) => c.type === 'Box3Helper'
    );
    boxes.forEach((box) => {
      if (this.showMoveEquipmentDialog) {
        box.visible = !box.visible;
      } else {
        box.visible = false;
      }
    });
  }

  closeAddEquipments(): void {
    const nodeElement = this.currentProject.nodes.find(
      (node) => node.id === this.currentlyAddingNodes[0].id
    );
    const nodeIdx = this.currentProject.nodes.indexOf(nodeElement);
    const sceneElement = this.sceneComponents.scene.children.find(
      (n) => n.name === this.currentlyAddingNodes[0].partDetails3D.params.name
    );
    const sceneElementIx =
      this.sceneComponents.scene.children.indexOf(sceneElement);

    if (nodeIdx > -1) {
      this.currentProject.nodes.splice(nodeIdx, 1);
    }

    if (sceneElementIx > -1) {
      this.sceneComponents.scene.children.splice(sceneElementIx, 1);
    }

    this.addEquipmentParams = null;
    this.addEquipmentsStepper.reset();
    this.customizeClosed();
  }

  closeDeleteEquipment(): void {
    this.saveProgress(true);
    this.customizeClosed();
  }

  closeMoveEquipment(): void {
    // TODO: Do we want to let them save with collisions?
    if (this.collisions.length > 0) {
      this.snackBar.showWarning(
        `You have ${this.collisions.length} unsolved collisions.`
      );
    }
    this.saveProgress(true);
    this.customizeClosed();
  }

  // ------ NAVIGATION & SAVE METHODS ------ //
  saveChanges(upload: boolean): void {
    this.saveProgress(upload);
    // for (const node of this.currentProject.nodes) {
    //   this.redrawPartByName(
    //     this.currentProject.nodes,
    //     node.partDetails3D.params.name,
    //     this.sceneComponents,
    //     this.orientation,
    //     this.sceneObjects$
    //   );
    // }
    this.loading = false;
  }

  async saveProgress(upload: boolean): Promise<void> {
    this.loading = true;
    this.projectHelper.editProject(this.projectsService$, this.currentProject, {
      name: this.projectName,
      plantCapacity: true,
    });
    if (upload) {
      await this.projectFiles.uploadProjectFiles3DDesignStep(
        this.currentProject,
        this.sceneComponents,
        this.uploadService$
      );
    }
    this.loading = false;
  }

  async next() {
    this.currentProject.name = this.projectName;
    if (!this.currentProject.nameValid()) {
      this.snackBar.showError(
        'Invalid project name! Please specify a correct name...'
      );
      return;
    }
    if (
      !this.projectHelper.isProjectEquipmentValid(
        this.currentProject,
        this.snackBar
      )
    ) {
      this.showEquipmentList = true;
      const eqList = document.getElementById('eq-list');
      setTimeout(() => {
        eqList.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 100);
      return;
    }
    this.loading = true;
    this.clickedNext = true;
    await this.projectFiles.uploadProjectFiles3DDesignStep(
      this.currentProject,
      this.sceneComponents,
      this.uploadService$
    );
    this.projectHelper.editProject(this.projectsService$, this.currentProject, {
      name: this.projectName,
      plantCapacity: true,
    });
    this.loading = false;
  }

  back(): void {
    if (!this.disableBackButton) {
      this.snackBar
        .showWarning(
          'Please be aware that all your changes will be lost if you return to a previous step.'
        )
        .afterDismissed()
        .subscribe((info) => {
          if (info.dismissedByAction === true) {
            this.currentProject.completed = false;
            const equipments = this.currentProject.nodes.filter(
              (n) =>
                (n.type === PartTypeEnum.INTAKE ||
                  n.type === PartTypeEnum.SILO_BIN ||
                  (n.type === PartTypeEnum.ELEVATOR &&
                    n.area === PartAreaEnum.STORAGE)) &&
                n.tileName !== undefined
            );
            this.currentProject.nodes = [...equipments];
            for (let i = 0; i < this.currentProject.nodes.length; i++) {
              const equipmentTile = this.sceneComponents.scene.children.find(
                (c) => c.name === this.currentProject.nodes[i].tileName
              );
              this.currentProject.nodes[i].position.x =
                equipmentTile.position.x;
              this.currentProject.nodes[i].position.z =
                equipmentTile.position.z;
              if (this.currentProject.nodes[i].type === PartTypeEnum.SILO_BIN) {
                this.currentProject.nodes[i].partDetails3D.params.dx.x =
                  SILO_BIN_PARAMS.dx.x;
                this.currentProject.nodes[i].partDetails3D.params.dx.y =
                  SILO_BIN_PARAMS.dx.y;
                this.currentProject.nodes[i].partDetails3D.params.dx.z =
                  SILO_BIN_PARAMS.dx.z;
              }
              if (this.currentProject.nodes[i].type === PartTypeEnum.INTAKE) {
                this.currentProject.nodes[i].partDetails3D.params.dx.x =
                  INTAKE_AREA_PARAMS.dx.x;
                this.currentProject.nodes[i].partDetails3D.params.dx.y =
                  INTAKE_AREA_PARAMS.dx.y;
                this.currentProject.nodes[i].partDetails3D.params.dx.z =
                  INTAKE_AREA_PARAMS.dx.z;
              }
            }
            this.saveProgress(false);
            this.stepper.selectedIndex = 2;
            this.router.navigate([
              this.routes.projects,
              this.currentProject._id,
            ]);
          }
        });
    }
  }

  cancel(): void {
    this.stepper.reset();
    this.router.navigate([this.routes.projects]);
  }

  // ------ QUOTATION METHODS ------ //
  submitQuotationRequest(): void {
    this.loading = false;
    this.projectHelper.editProject(this.projectsService$, this.currentProject, {
      name: this.projectName,
      plantCapacity: true,
      navigateTo: [
        this.routes.submitQuotationRequest.replace(
          ':id',
          this.currentProject._id
        ),
      ],
    });
  }

  acceptQuotation(): void {
    this.quotationHelper.acceptQuotationByProjectId(this.currentProject._id);
  }

  rejectQuotation(): void {
    this.quotationHelper.rejectQuotationByProjectId(this.currentProject._id);
  }

  ngOnDestroy(): void {
    if (this.sceneObjects$) {
      this.sceneObjects$.unsubscribe();
    }
    if (this.sceneGenerator$) {
      this.sceneGenerator$.unsubscribe();
    }
    if (this.projectsService$) {
      this.projectsService$.unsubscribe();
    }
    if (this.uploadService$) {
      this.uploadService$.unsubscribe();
    }
    if (this.downloadService$) {
      this.downloadService$.unsubscribe();
    }
    if (this.debugger !== undefined) {
      this.debuggerHelper.deleteDebugger();
    }
  }
}
