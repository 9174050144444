import { Injectable } from '@angular/core';
import { combineLatest, forkJoin, Observable, Subject } from 'rxjs';
import {
  Bin3DParams,
  BinIntakePair,
  BoundingBox,
  Cleaner3DParams,
  Conveyor3DParams,
  Delivery3DParams,
  Dryer3DParams,
  Elevator3DParams,
  Part3D,
  Part3DNode,
  SceneGeneratorEvent,
} from '../models/part';
import {
  CLEANER_3D_PATH,
  ROOF_ANGLE,
  SUPPORT_LEG_WIDTH,
  siloDiameters,
  ELEVATOR_3D_PATHS,
  CHAIN_CONVEYOR_PATHS,
  DRYER_PATHS,
  BELT_CONVEYOR_PATHS,
  STL_MODEL_SCALE_1_TO_1,
  BELT_CONVEYOR_INTAKE_LENGTH,
} from '../constants/equipment-parameters-default';
import { PartTypeEnum, SceneGeneratorEnum } from '../models/enums';

import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { SceneParams } from '../models/scene-params';
import { INTAKE_CONVEYOR_DX } from 'src/app/projects/flow-diagram/constants/equipment-position';

@Injectable({
  providedIn: 'root',
})
export class EquipmentGeneratorService {
  public isOneCell: boolean;
  public isOneRowConfig: boolean;
  public nSiloBinsPerRow: number[];
  public nSiloBinRows: number;
  public L: THREE.Vector3;
  public T: THREE.Vector3;
  public binsBoundingBox: BoundingBox;
  public closestBinIntakePair: BinIntakePair;
  public C0: THREE.Vector3;

  constructor() {}

  // ------ 3D ELEMENTS & EQUIPMENT HELPER FUNCTIONS ------ //
  addPlaneTilesToScene(
    scene: THREE.Scene,
    size: number,
    count: number
  ): THREE.Scene {
    for (let i = 0; i < count; i++) {
      for (let j = 0; j <= count; j++) {
        this.getPlaneTile(
          new THREE.Vector3(size, 0, size),
          'green',
          true
        ).subscribe((tile) => {
          tile.part.rotateX(Math.PI / 2);
          tile.part.position.x = i * (size + 0.05);
          tile.part.position.z = j * (size + 0.05);
          tile.part.name = `Tile ${i}${j}`;
          scene.add(tile.part);
        });
      }
    }
    return scene;
  }

  getPlaneTile(
    dx: THREE.Vector3,
    color: number | string = 'green',
    isTransparent: boolean = false
  ): Observable<Part3D> {
    const planeTile = new Part3D();
    return new Observable<Part3D>((observer) => {
      const geometry =
        dx.z === 0
          ? new THREE.PlaneGeometry(dx.x, dx.x)
          : new THREE.PlaneGeometry(dx.x, dx.z);
      const material = new THREE.MeshPhongMaterial({
        color: new THREE.Color(color),
        side: THREE.DoubleSide,
      });
      if (isTransparent) {
        material.transparent = true;
        material.opacity = 0.3;
      }
      planeTile.part = new THREE.Mesh(geometry, material);
      planeTile.part.receiveShadow = true;
      planeTile.part.valid = true;
      observer.next(planeTile);
      observer.complete();
    });
  }

  /** METHOD USED TO GET THE X,Y,Z DIMENSIONS OF A STL PART
   * RETURNS A VECTOR CONTAINING THE 3 DIMENSIONS ( MIGHT BE INVERSED - WHEN STL IS LOADED, INITIAL ORIENTATION MIGHT NOT BE THE ONE THAT IS GLOBAL )
   */
  getModelDimensions(object: any) {
    const boundingBox = new THREE.Box3();
    boundingBox.setFromObject(object);
    const dimensions = new THREE.Vector3();
    dimensions.subVectors(boundingBox.max, boundingBox.min);

    return dimensions;
  }

  /** FUNCTION USED TO GET THE SILOBIN DIAMETER CLOSEST TO THE ONE THAT IS REQUIRED */
  findClosestSiloBinDiameter(
    diameter: number,
    siloBinOptions: Array<number>
  ): number {
    const siloBinDiameter = siloBinOptions.sort((a, b) => {
      return Math.abs(diameter - a) - Math.abs(diameter - b);
    })[0];

    return siloBinDiameter;
  }

  /** METHOD USED TO GET THE PATHS TO THE SILOBIN PARTS */
  getSiloBinsPaths(type: string, diameter: number) {
    let siloDiameter: number | string;
    let path: string;

    switch (type) {
      case 'flatBottom':
        siloDiameter = this.findClosestSiloBinDiameter(
          diameter,
          siloDiameters.flatBottom
        );
        if (siloDiameter) {
          siloDiameter = siloDiameter.toFixed(2);
        }
        path = `../../assets/3d-designs/Silo/Flat-bottom-silo/SFP_${siloDiameter}/SFP${siloDiameter}_`;
        break;
      case 'conicalBottom':
        siloDiameter = this.findClosestSiloBinDiameter(
          diameter,
          siloDiameters.conicalBottom
        );
        if (siloDiameter) {
          siloDiameter = siloDiameter.toFixed(2);
        }
        path = `../../assets/3d-designs/Silo/Conical-bottom-silo/SFC_${siloDiameter}/SFC${siloDiameter}_`;
        break;
      case 'autoDelivery':
        siloDiameter = this.findClosestSiloBinDiameter(
          diameter,
          siloDiameters.autoDelivery
        );
        if (siloDiameter) {
          siloDiameter = siloDiameter.toFixed(2);
        }
        path = `../../assets/3d-designs/Silo/Auto-delivery-silo/SLA_${siloDiameter}/SLA${siloDiameter}_`;
        break;
    }
    return [path + 'Base.stl', path + 'Middle.stl', path + 'Roof.stl'];
  }

  getSiloBin(
    dx: THREE.Vector3,
    color: number | string,
    name?: string
  ): Observable<Part3D> {
    const siloBin = new Part3D();
    const material = new THREE.MeshPhongMaterial({
      color: color,
    });
    const siloBinPartsPaths: Array<string> = this.getSiloBinsPaths(
      'flatBottom',
      dx.x
    );

    return new Observable<Part3D>((observer) => {
      Promise.all(this.loadParts(siloBinPartsPaths))
        .then((loadedParts) => {
          const parts = {
            bottom: new THREE.Mesh(loadedParts[0], material),
            middle: new THREE.Mesh(loadedParts[1], material),
            roof: new THREE.Mesh(loadedParts[2], material),
          };
          parts.bottom.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );
          parts.middle.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );
          parts.roof.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );

          const bottomPartDimensions = this.getModelDimensions(parts.bottom);
          const middlePartDimensions = this.getModelDimensions(parts.middle);
          const roofPartDimensions = this.getModelDimensions(parts.roof);

          parts.bottom.geometry.rotateX(-Math.PI / 2);
          parts.middle.geometry.rotateX(-Math.PI / 2);
          parts.roof.geometry.rotateX(-Math.PI / 2);

          const midParts = Math.max(
            1,
            Math.round(
              (dx.y - bottomPartDimensions.z - roofPartDimensions.z) /
                middlePartDimensions.z
            )
          );

          const middleGroup = new THREE.Group();
          for (let i = 0; i < midParts; i++) {
            const meshCopy = parts.middle.clone();
            meshCopy.position.y = i * middlePartDimensions.z;
            middleGroup.add(meshCopy);
          }

          parts.bottom.position.y = 0;
          parts.bottom.position.x -= bottomPartDimensions.x / 2;
          parts.bottom.position.z += bottomPartDimensions.y / 2;

          middleGroup.position.y = 0;
          middleGroup.position.x -= middlePartDimensions.x / 2;
          middleGroup.position.z += middlePartDimensions.y / 2;

          parts.roof.position.y = (midParts - 1) * middlePartDimensions.z;
          parts.roof.position.x -= roofPartDimensions.x / 2;
          parts.roof.position.z += roofPartDimensions.y / 2;

          siloBin.part = new THREE.Group();
          siloBin.part.add(parts.bottom);
          siloBin.part.add(middleGroup);
          siloBin.part.add(parts.roof);
          const siloBinDimensions = this.getModelDimensions(siloBin.part);
          siloBin.part.castShadow = true;
          siloBin.part.name = name ? name : 'Unknown silo bin';
          siloBin.params.dx.x = siloBin.params.dx.z = parseFloat(middlePartDimensions.x.toFixed(2));
          siloBin.params.dx.y = parseFloat(siloBinDimensions.y.toFixed(2));

          // console.log(`${siloBin.part.name} wanted height:${dx.y} real:${siloBinDimensions.y}`);

          observer.next(siloBin);
          observer.complete();
        })
        .catch((err) => {
          throw new Error(err);
        });
    });
  }

  getBufferBin(bufferBinParams: Bin3DParams): Observable<Part3D> {
    const bufferBin = new Part3D();
    const material = new THREE.MeshPhongMaterial({
      color: bufferBinParams.color,
    });
    const bufferBinPartsPaths: Array<string> = this.getSiloBinsPaths(
      'conicalBottom',
      bufferBinParams.dx.x
    );

    return new Observable<Part3D>((observer) => {
      Promise.all(this.loadParts(bufferBinPartsPaths))
        .then((loadedParts) => {
          const parts = {
            bottom: new THREE.Mesh(loadedParts[0], material),
            middle: new THREE.Mesh(loadedParts[1], material),
            roof: new THREE.Mesh(loadedParts[2], material),
          };
          /** SCALE PARTS 1:1 */
          parts.bottom.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );
          parts.middle.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );
          parts.roof.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );

          const bottomPartDimensions = this.getModelDimensions(parts.bottom);
          const middlePartDimensions = this.getModelDimensions(parts.middle);
          const roofPartDimensions = this.getModelDimensions(parts.roof);

          parts.bottom.geometry.rotateX(-Math.PI / 2);
          parts.middle.geometry.rotateX(-Math.PI / 2);
          parts.roof.geometry.rotateX(-Math.PI / 2);

          /** CREATE MIDDLE PART DEPENDING ON THE DESIRED HEIGHT */
          const midParts = Math.max(
            1,
            Math.round(
              (bufferBinParams.dx.y -
                bottomPartDimensions.z -
                roofPartDimensions.z) /
                middlePartDimensions.z
            )
          ); // IF HEIGHT IS TOO SMALL ADD AT LEAST 1 MIDDLE PART
          const middleGroup = new THREE.Group();
          for (let i = 0; i < midParts; i++) {
            const meshCopy = parts.middle.clone();
            meshCopy.position.y = i * middlePartDimensions.z;
            middleGroup.add(meshCopy);
          }

          parts.bottom.position.y = 0;
          parts.bottom.position.x -= bottomPartDimensions.x / 2; //CENTER WRT OX
          parts.bottom.position.z += bottomPartDimensions.y / 2; //CENTER WRT OZ

          middleGroup.position.y = 0;
          middleGroup.position.x -= middlePartDimensions.x / 2;
          middleGroup.position.z += middlePartDimensions.y / 2;

          parts.roof.position.y = (midParts - 1) * middlePartDimensions.z;
          parts.roof.position.x -= roofPartDimensions.x / 2;
          parts.roof.position.z += roofPartDimensions.y / 2;

          bufferBin.part = new THREE.Group();
          bufferBin.part.add(parts.bottom);
          bufferBin.part.add(middleGroup);
          bufferBin.part.add(parts.roof);
          const bufferBinDimensions = this.getModelDimensions(bufferBin.part);

          bufferBin.part.castShadow = true;
          bufferBin.part.name = bufferBinParams.name
            ? bufferBinParams.name
            : 'Unknown silo bin';
          bufferBin.params.dx.x = bufferBin.params.dx.z = parseFloat(bufferBinDimensions.x.toFixed(2));
          bufferBin.params.dx.y = parseFloat(bufferBinDimensions.y.toFixed(2));
          // console.log(`${bufferBin.part.name} wanted height:${bufferBinParams.dx.y} real:${bufferBinDimensions.y}`);

          observer.next(bufferBin);
          observer.complete();
        })
        .catch((err) => {
          throw new Error(err);
        });
    });
  }

  getBox(
    dx: THREE.Vector3,
    materialColor: string | number
  ): Observable<Part3D> {
    const box = new Part3D();
    return new Observable<Part3D>((observer) => {
      const geometry = new THREE.BoxGeometry(dx.x, dx.y, dx.z);
      const material = new THREE.MeshPhongMaterial({ color: materialColor });
      box.part = new THREE.Mesh(geometry, material);
      observer.next(box);
      observer.complete();
    });
  }

  getEquipmentMetallicHousing(
    dx: THREE.Vector3,
    color: string | number,
    name: string,
    roofHeightBuffer: number = 0.7
  ): Observable<Part3D> {
    const WALL_WIDTH = 0.1;
    const ROOF_WIDTH_BUFFER = 0.75;

    const housing = new Part3D(new THREE.Group());

    return new Observable<Part3D>((observer) => {
      forkJoin([
        this.getBox(new THREE.Vector3(dx.z, dx.y, WALL_WIDTH), color),
        this.getBox(
          new THREE.Vector3(
            dx.z,
            dx.y + dx.x * Math.sin(ROOF_ANGLE),
            WALL_WIDTH
          ),
          color
        ),
        this.getBox(new THREE.Vector3(dx.z, WALL_WIDTH, dx.x), color),
      ]).subscribe((parts) => {
        parts[0].part.position.y = dx.y / 2;
        parts[0].part.rotateY(Math.PI / 2);
        parts[0].part.position.x = -dx.x / 2;

        parts[1].part.position.y = (dx.y + dx.x * Math.sin(ROOF_ANGLE)) / 2;
        parts[1].part.rotateY(Math.PI / 2);
        parts[1].part.position.x = dx.x / 2;

        parts[2].part.position.y = dx.y + (dx.x * Math.sin(ROOF_ANGLE)) / 2;
        parts[2].part.rotateY(Math.PI / 2);
        parts[2].part.rotateX(Math.PI / 2);
        parts[2].part.rotateX(-(Math.PI / 2 + ROOF_ANGLE));

        housing.part.add(parts[0].part);
        housing.part.add(parts[1].part);
        housing.part.add(parts[2].part);
        housing.part.rotateY(Math.PI / 2);
        housing.part.name = name;
        observer.next(housing);
        observer.complete();
      });
    });
  }

  getSupport(
    dx: THREE.Vector3,
    supportLegWidth: number = 0,
    color: number | string
  ): Observable<Part3D> {
    const support = new Part3D(new THREE.Group());
    supportLegWidth = supportLegWidth ? supportLegWidth : 0.25;
    const supportLegDimension = new THREE.Vector3(
      supportLegWidth,
      dx.y,
      supportLegWidth
    );

    return new Observable<Part3D>((observable) => {
      forkJoin([
        this.getPlaneTile(dx, color),
        this.getBox(supportLegDimension, color),
        this.getBox(supportLegDimension, color),
        this.getBox(supportLegDimension, color),
        this.getBox(supportLegDimension, color),
      ]).subscribe((parts) => {
        // support top
        parts[0].part.rotateX(Math.PI / 2);
        parts[0].part.position.y = dx.y;
        support.part.add(parts[0].part);

        // leg 1
        parts[1].part.position.x = dx.x / 2 - supportLegWidth;
        parts[1].part.position.z = dx.z / 2 - supportLegWidth;
        parts[1].part.position.y = dx.y / 2;
        support.part.add(parts[1].part);

        // leg 2
        parts[2].part.position.x = dx.x / 2 - supportLegWidth;
        parts[2].part.position.z = -dx.z / 2 + supportLegWidth;
        parts[2].part.position.y = dx.y / 2;
        support.part.add(parts[2].part);

        // leg 3
        parts[3].part.position.x = -dx.x / 2 + supportLegWidth;
        parts[3].part.position.z = dx.z / 2 - supportLegWidth;
        parts[3].part.position.y = dx.y / 2;
        support.part.add(parts[3].part);

        // leg 4
        parts[4].part.position.x = -dx.x / 2 + supportLegWidth;
        parts[4].part.position.z = -dx.z / 2 + supportLegWidth;
        parts[4].part.position.y = dx.y / 2;
        support.part.add(parts[4].part);
        support.part.rotateY(Math.PI / 2);
        observable.next(support);
        observable.complete();
      });
    });
  }

  getAutoDeliveryBin(deliveryAreaParams: Delivery3DParams): Observable<Part3D> {
    const autoDeliveryBin = new Part3D();
    const material = new THREE.MeshPhongMaterial({
      color: deliveryAreaParams.color,
    });
    const siloBinPartsPaths: Array<string> = this.getSiloBinsPaths(
      'autoDelivery',
      deliveryAreaParams.dx.x
    );

    return new Observable<Part3D>((observer) => {
      Promise.all(this.loadParts(siloBinPartsPaths))
        .then((loadedParts) => {
          const parts = {
            bottom: new THREE.Mesh(loadedParts[0], material),
            middle: new THREE.Mesh(loadedParts[1], material),
            roof: new THREE.Mesh(loadedParts[2], material),
          };
          parts.bottom.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );
          parts.middle.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );
          parts.roof.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );

          const bottomPartDimensions = this.getModelDimensions(parts.bottom);
          const middlePartDimensions = this.getModelDimensions(parts.middle);
          const roofPartDimensions = this.getModelDimensions(parts.roof);

          parts.bottom.geometry.rotateX(-Math.PI / 2);
          parts.middle.geometry.rotateX(-Math.PI / 2);
          parts.roof.geometry.rotateX(-Math.PI / 2);

          let midParts = Math.max(
            1,
            Math.round(
              (deliveryAreaParams.dx.y -
                bottomPartDimensions.z -
                roofPartDimensions.z) /
                middlePartDimensions.z
            )
          );
          const middleGroup = new THREE.Group();
          for (let i = 0; i < midParts; i++) {
            const meshCopy = parts.middle.clone();
            meshCopy.position.y = i * middlePartDimensions.z;
            middleGroup.add(meshCopy);
          }

          parts.bottom.position.y = 0;
          parts.bottom.position.x -= bottomPartDimensions.x / 2;
          parts.bottom.position.z += bottomPartDimensions.y / 2;

          middleGroup.position.y = 0;
          middleGroup.position.x -= middlePartDimensions.x / 2;
          middleGroup.position.z += middlePartDimensions.y / 2;

          parts.roof.position.y = (midParts - 1) * middlePartDimensions.z;
          parts.roof.position.x -= roofPartDimensions.x / 2;
          parts.roof.position.z += roofPartDimensions.y / 2;

          autoDeliveryBin.part = new THREE.Group();
          autoDeliveryBin.part.add(parts.bottom);
          autoDeliveryBin.part.add(middleGroup);
          autoDeliveryBin.part.add(parts.roof);
          const autoDeliveryBinDimensions = this.getModelDimensions(
            autoDeliveryBin.part
          );

          autoDeliveryBin.part.castShadow = true;
          autoDeliveryBin.part.name = deliveryAreaParams.name
            ? deliveryAreaParams.name
            : 'Unknown silo bin';
          autoDeliveryBin.params.dx.x = autoDeliveryBin.params.dx.z =
            parseFloat(autoDeliveryBinDimensions.x.toFixed(2));
          autoDeliveryBin.params.dx.y = parseFloat(autoDeliveryBinDimensions.y.toFixed(2));
          // console.log(`${autoDeliveryBin.part.name} wanted height:${deliveryAreaParams.dx.y} real:${autoDeliveryBinDimensions.y}`);

          observer.next(autoDeliveryBin);
          observer.complete();
        })
        .catch((err) => {
          throw new Error(err);
        });
    });
  }

  getDryer(dryerParams: Dryer3DParams): Observable<Part3D> {
    const dryer = new Part3D();
    const material = new THREE.MeshPhongMaterial({
      color: dryerParams.color,
    });

    return new Observable<Part3D>((observer) => {
      Promise.all(this.loadParts(DRYER_PATHS))
        .then((loadedParts) => {
          const parts = {
            bottom: new THREE.Mesh(loadedParts[0], material),
            middle: new THREE.Mesh(loadedParts[1], material),
            top: new THREE.Mesh(loadedParts[2], material),
          };
          parts.bottom.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );
          parts.middle.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );
          parts.top.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );

          parts.bottom.geometry.rotateX(-Math.PI / 2);
          parts.middle.geometry.rotateX(-Math.PI / 2);
          parts.top.geometry.rotateX(-Math.PI / 2);

          const bottomPartDimensions = this.getModelDimensions(parts.bottom);
          const middlePartDimensions = this.getModelDimensions(parts.middle);
          const topPartDimensions = this.getModelDimensions(parts.top);

          const midParts = Math.max(
            1,
            Math.round(
              (dryerParams.dx.y -
                bottomPartDimensions.y -
                topPartDimensions.y) /
                middlePartDimensions.y
            )
          );

          const middleGroup = new THREE.Group();
          for (let i = 0; i < midParts; i++) {
            const meshCopy = parts.middle.clone();
            meshCopy.position.y = i * middlePartDimensions.y;
            middleGroup.add(meshCopy);
          }

          parts.bottom.position.y = 0;
          parts.bottom.position.x -=
            bottomPartDimensions.z - bottomPartDimensions.x / 2;
          parts.bottom.position.z +=
            bottomPartDimensions.z - bottomPartDimensions.x / 2;

          middleGroup.position.y = 0;
          middleGroup.position.x -=
            bottomPartDimensions.z - bottomPartDimensions.x / 2;
          middleGroup.position.z +=
            bottomPartDimensions.z - bottomPartDimensions.x / 2;

          parts.top.position.y = (midParts - 1) * middlePartDimensions.y;
          parts.top.position.x -=
            bottomPartDimensions.z - bottomPartDimensions.x / 2;
          parts.top.position.z +=
            bottomPartDimensions.z - bottomPartDimensions.x / 2;

          dryer.part = new THREE.Group();
          dryer.part.add(parts.bottom);
          dryer.part.add(middleGroup);
          dryer.part.add(parts.top);
          dryer.part.castShadow = true;
          dryer.part.name = dryerParams.name
            ? dryerParams.name
            : 'Unknown silo bin';

          observer.next(dryer);
          observer.complete();
        })
        .catch((err) => {
          throw new Error(err);
        });
    });
  }

  getCleaner(cleanerParams: Cleaner3DParams): Observable<Part3D> {
    const ROOF_HEIGHT_BUFFER = 0.5;
    const METALLIC_HOUSING_PADDING = 0.5;
    const CLEANER_HOUSING_NAME = 'Cleaner Housing';
    const cleaner = new Part3D(new THREE.Group());
    return new Observable<Part3D>((observer) => {
      combineLatest([
        this.getSupport(
          cleanerParams.support,
          SUPPORT_LEG_WIDTH,
          cleanerParams.color
        ),
        this.getEquipmentMetallicHousing(
          new THREE.Vector3(
            cleanerParams.support.x - METALLIC_HOUSING_PADDING,
            cleanerParams.cover.y,
            cleanerParams.support.z - METALLIC_HOUSING_PADDING
          ),
          cleanerParams.color,
          CLEANER_HOUSING_NAME,
          ROOF_HEIGHT_BUFFER
        ),
      ]).subscribe((parts) => {
        parts[1].part.position.y = cleanerParams.support.y;
        const loader = new STLLoader();
        loader.load(CLEANER_3D_PATH, (geometry) => {
          const material = new THREE.MeshPhongMaterial();
          const equipment = new THREE.Mesh(geometry, material);
          equipment.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );
          const cleanerDimensions = this.getModelDimensions(equipment);
          equipment.position.y = cleanerParams.support.y + 1.2;
          equipment.position.x -= cleanerDimensions.x / 2;
          equipment.position.z -= cleanerDimensions.z / 2;
          cleaner.part.add(parts[0].part);
          cleaner.part.add(parts[1].part);
          cleaner.part.add(equipment);
          cleaner.part.position.y = 0;
          cleaner.part.name = cleanerParams.name;
          observer.next(cleaner);
          observer.complete();
        });
      });
    });
  }

  getBeltConveyor(beltConveyorParams: Conveyor3DParams): Observable<Part3D> {

    if(beltConveyorParams.nOutputs === undefined){
      beltConveyorParams.nOutputs = 1;
    }
    if(beltConveyorParams.outputDistances === undefined){
      beltConveyorParams.outputDistances = [0];
    }

    const material = new THREE.MeshPhongMaterial({
      color: beltConveyorParams.color,
    });
    const conveyor = new Part3D(new THREE.Group());
    return new Observable<Part3D>((observer) => {
      Promise.all(this.loadParts(BELT_CONVEYOR_PATHS))
        .then((loadedParts) => {
          const parts = {
            tensioningStation: new THREE.Mesh(loadedParts[0], material),
            intake: new THREE.Mesh(loadedParts[1], material),
            l05: new THREE.Mesh(loadedParts[2], material),
            l10: new THREE.Mesh(loadedParts[3], material),
            motor: new THREE.Mesh(loadedParts[4], material),
          };

          parts.intake.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );
          parts.l10.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );
          parts.l05.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );
          parts.motor.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );
          parts.tensioningStation.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );

          const intakeDimensions = this.getModelDimensions(parts.intake);
          const l05Dimensions = this.getModelDimensions(parts.l05);
          const l10Dimensions = this.getModelDimensions(parts.l10);
          const motorDimensions = this.getModelDimensions(parts.motor);
          const tensioningStationDimensions = this.getModelDimensions(
            parts.tensioningStation
          );
          const BELT_CONVEYOR_MOTOR_BASE_HEIGHT =
            motorDimensions.z - l10Dimensions.z;
          /** pos = dimension X = Y  Y = Z Z = X*/
          parts.tensioningStation.geometry.rotateX(-Math.PI / 2);
          parts.tensioningStation.geometry.rotateY(Math.PI / 2);
          parts.tensioningStation.position.y = BELT_CONVEYOR_MOTOR_BASE_HEIGHT;
          parts.tensioningStation.position.x += tensioningStationDimensions.y;
          parts.tensioningStation.position.z +=
            tensioningStationDimensions.x / 2;
          conveyor.part.add(parts.tensioningStation);

          parts.intake.geometry.rotateX(-Math.PI / 2);
          parts.intake.geometry.rotateY(Math.PI / 2);
          parts.intake.position.y = BELT_CONVEYOR_MOTOR_BASE_HEIGHT;
          parts.intake.position.x +=
            intakeDimensions.y + tensioningStationDimensions.y;
          parts.intake.position.z += intakeDimensions.x / 2;
          conveyor.part.add(parts.intake);

          parts.l05.geometry.rotateX(-Math.PI / 2);
          parts.l05.geometry.rotateY(Math.PI / 2);
          parts.l10.geometry.rotateX(-Math.PI / 2);
          parts.l10.geometry.rotateY(Math.PI / 2);

          const middleGroupLength =
            beltConveyorParams.dx.x -
            intakeDimensions.y -
            motorDimensions.y -
            tensioningStationDimensions.y;
          let l10Parts = Math.floor(middleGroupLength / l10Dimensions.y);
          let l05Parts = 0;
          if (middleGroupLength - l10Parts > 0.75) l10Parts++;
          else if (middleGroupLength - l10Parts > 0.25) {
            l05Parts = 1;
          }

          const middleGroup = new THREE.Group();
          for (let i = 0; i < l10Parts; i++) {
            const l10PartCopy = parts.l10.clone();
            l10PartCopy.position.x = i * l10Dimensions.y;
            middleGroup.add(l10PartCopy);
          }
          if (l05Parts) {
            parts.l05.position.x = l10Parts * l10Dimensions.y - l05Dimensions.y;
            middleGroup.add(parts.l05);
          }
          middleGroup.position.y = BELT_CONVEYOR_MOTOR_BASE_HEIGHT;
          middleGroup.position.x +=
            intakeDimensions.y +
            tensioningStationDimensions.y +
            l10Dimensions.y;
          middleGroup.position.z += intakeDimensions.x / 2;
          conveyor.part.add(middleGroup);

          parts.motor.geometry.rotateX(-Math.PI / 2);
          parts.motor.geometry.rotateY(Math.PI / 2);
          parts.motor.position.y = 0;
          parts.motor.position.x +=
            motorDimensions.y +
            tensioningStationDimensions.y +
            intakeDimensions.y +
            l10Parts * l10Dimensions.y +
            l05Parts * l05Dimensions.y;
          parts.motor.position.z += intakeDimensions.x / 2;
          conveyor.part.add(parts.motor);

          const conveyorDimensions = this.getModelDimensions(conveyor.part);
          // console.log(`${beltConveyorParams.name} wanted length:${beltConveyorParams.dx.x} real:${conveyorDimensions.x}`);

          conveyor.part.name = beltConveyorParams.name;
          conveyor.params.dx.x = parseFloat(conveyorDimensions.x.toFixed(2));
          conveyor.params.dx.y = parseFloat(conveyorDimensions.y.toFixed(2));
          conveyor.params.dx.z = parseFloat(conveyorDimensions.z.toFixed(2));
          observer.next(conveyor);
          observer.complete();
        })
        .catch((err) => {
          throw new Error(err);
        });
    });
  }

  getChainConveyor(chainConveyorParams: Conveyor3DParams): Observable<Part3D> {
    if(chainConveyorParams.nOutputs === undefined){
      chainConveyorParams.nOutputs = 1;
    }
    if(chainConveyorParams.outputDistances === undefined){
      chainConveyorParams.outputDistances = [0];
    }

    const material = new THREE.MeshPhongMaterial({
      color: chainConveyorParams.color,
    });
    const conveyor = new Part3D(new THREE.Group());
    // chainConveyorParams.dx.x = 30;
    // chainConveyorParams.nOutputs = 3;
    // chainConveyorParams.outputDistances = [6, 12, 12];
    return new Observable<Part3D>((observer) => {
      Promise.all(this.loadParts(CHAIN_CONVEYOR_PATHS))
        .then((loadedParts) => {
          const parts = {
            tensioningStation: new THREE.Mesh(loadedParts[0], material),
            intake: new THREE.Mesh(loadedParts[1], material),
            l05: new THREE.Mesh(loadedParts[2], material),
            l10: new THREE.Mesh(loadedParts[3], material),
            motor: new THREE.Mesh(loadedParts[4], material),
          };

          parts.intake.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );
          parts.l10.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );
          parts.l05.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );
          parts.motor.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );
          parts.tensioningStation.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );

          const intakeDimensions = this.getModelDimensions(parts.intake);
          const l05Dimensions = this.getModelDimensions(parts.l05);
          const l10Dimensions = this.getModelDimensions(parts.l10);
          const motorDimensions = this.getModelDimensions(parts.motor);
          const tensioningStationDimensions = this.getModelDimensions(
            parts.tensioningStation
          );
          const CHAIN_CONVEYOR_MOTOR_BASE_HEIGHT =
            motorDimensions.z - l10Dimensions.z;
          /** pos = dimension X = Y  Y = Z Z = X*/
          /** ADD FIRST PART - TENSIONING STATION */
          parts.tensioningStation.geometry.rotateX(-Math.PI / 2);
          parts.tensioningStation.geometry.rotateY(Math.PI / 2);
          parts.tensioningStation.position.y = CHAIN_CONVEYOR_MOTOR_BASE_HEIGHT;
          parts.tensioningStation.position.x += tensioningStationDimensions.y;
          parts.tensioningStation.position.z +=
            tensioningStationDimensions.x / 2;
          conveyor.part.add(parts.tensioningStation);

          /** ADD THE INTAKE NEXT TO THE TENSIONING STATION */
          parts.intake.geometry.rotateX(-Math.PI / 2);
          parts.intake.geometry.rotateY(Math.PI / 2);
          parts.intake.position.y = CHAIN_CONVEYOR_MOTOR_BASE_HEIGHT;
          parts.intake.position.x +=
            intakeDimensions.y + tensioningStationDimensions.y;
          parts.intake.position.z += intakeDimensions.x / 2;
          conveyor.part.add(parts.intake);

          parts.l05.geometry.rotateX(-Math.PI / 2);
          parts.l05.geometry.rotateY(Math.PI / 2);
          parts.l10.geometry.rotateX(-Math.PI / 2);
          parts.l10.geometry.rotateY(Math.PI / 2);

          const middleGroup = new THREE.Group();
          let middleGroupLength,
            l10Parts,
            l05Parts,
            offset = 0;
          /** ONLY 1 OUTPUT => THAT IS THE FINAL ONE */
          if (chainConveyorParams.nOutputs === 1) {
            chainConveyorParams.outputDistances[0] =
              chainConveyorParams.dx.x -
              intakeDimensions.y -
              tensioningStationDimensions.y -
              motorDimensions.y;
          }
          for (let n = 0; n < chainConveyorParams.nOutputs; n++) {
            /** CALCULATE MIDDLE PART LENGTH DEPENDING ON THE NUMBER OF OUTPUTS AND DISTANCES / TOTAL LENGTH(DX.X) */

            if (n === 0) {
              middleGroupLength =
                chainConveyorParams.outputDistances[n] -
                intakeDimensions.y -
                tensioningStationDimensions.y;
            } else if (n === chainConveyorParams.nOutputs - 1) {
              middleGroupLength =
                chainConveyorParams.outputDistances[n] -
                intakeDimensions.y -
                motorDimensions.y;
            } else {
              middleGroupLength =
                chainConveyorParams.outputDistances[n] - intakeDimensions.y;
            }

            /** CALCULATE NUMBER OF NEEDED PARTS TO ACHIEVE DESIRED LENGTH */
            l10Parts = Math.floor(middleGroupLength / l10Dimensions.y);
            l05Parts = 0;
            if (middleGroupLength - l10Parts > 0.75) l10Parts++;
            else if (middleGroupLength - l10Parts > 0.25) {
              l05Parts = 1;
            }
            /** CREATE MIDDLE PART WITHOUT ADDING DRAIN */
            for (let i = 0; i < l10Parts; i++) {
              const l10PartCopy = parts.l10.clone();
              l10PartCopy.position.x = offset + i * l10Dimensions.y;
              middleGroup.add(l10PartCopy);
            }
            if (l05Parts) {
              const l05Copy = parts.l05.clone();
              l05Copy.position.x =
                offset + l10Parts * l10Dimensions.y - l05Dimensions.y;
              middleGroup.add(l05Copy);
            }
            offset += l10Parts * l10Dimensions.y + l05Parts * l05Dimensions.y; // UPDATE OFFSET FOR THE NEXT MIDDLE PART

            if (n !== chainConveyorParams.nOutputs) {
              // TO DO : ADD OUTLET
            }
          }

          middleGroup.position.y = CHAIN_CONVEYOR_MOTOR_BASE_HEIGHT;
          middleGroup.position.x +=
            intakeDimensions.y +
            tensioningStationDimensions.y +
            l10Dimensions.y;
          middleGroup.position.z += intakeDimensions.x / 2;
          conveyor.part.add(middleGroup);

          const middleGroupDimensions = this.getModelDimensions(middleGroup);
          parts.motor.geometry.rotateX(-Math.PI / 2);
          parts.motor.geometry.rotateY(Math.PI / 2);
          parts.motor.position.y = 0;
          parts.motor.position.x +=
            motorDimensions.y +
            tensioningStationDimensions.y +
            intakeDimensions.y +
            middleGroupDimensions.x;
          parts.motor.position.z += intakeDimensions.x / 2;
          conveyor.part.add(parts.motor);

          const conveyorDimensions = this.getModelDimensions(conveyor.part);
          // console.log(`${chainConveyorParams.name} wanted length:${chainConveyorParams.dx.x} real:${conveyorDimensions.x}`);

          conveyor.part.name = chainConveyorParams.name;
          conveyor.params.dx.x = parseFloat(conveyorDimensions.x.toFixed(2));
          conveyor.params.dx.y = parseFloat(conveyorDimensions.y.toFixed(2));
          conveyor.params.dx.z = parseFloat(conveyorDimensions.z.toFixed(2));

          observer.next(conveyor);
          observer.complete();
        })
        .catch((err) => {
          throw new Error(err);
        });
    });
  }

  getElevator(elevatorParameters: Elevator3DParams): Observable<Part3D> {
    const material = new THREE.MeshPhongMaterial({
      color: elevatorParameters.color,
    });
    const elevator = new Part3D(new THREE.Group());

    return new Observable<Part3D>((observer) => {
      Promise.all(this.loadParts(ELEVATOR_3D_PATHS))
        .then((loadedParts) => {
          const parts = {
            base: new THREE.Mesh(loadedParts[0], material),
            l05: new THREE.Mesh(loadedParts[1], material),
            l10: new THREE.Mesh(loadedParts[2], material),
            motor: new THREE.Mesh(loadedParts[3], material),
          };

          parts.base.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );
          parts.l10.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );
          parts.l05.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );
          parts.motor.geometry.scale(
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1,
            STL_MODEL_SCALE_1_TO_1
          );

          const baseDimensions = this.getModelDimensions(parts.base);
          const l05Dimensions = this.getModelDimensions(parts.l05);
          const l10Dimensions = this.getModelDimensions(parts.l10);
          const motorDimensions = this.getModelDimensions(parts.motor);

          parts.base.geometry.rotateX(-Math.PI / 2);
          parts.l05.geometry.rotateX(-Math.PI / 2);
          parts.l10.geometry.rotateX(-Math.PI / 2);
          parts.motor.geometry.rotateX(-Math.PI / 2);

          const middleGroupHeight =
            elevatorParameters.dx.y - l10Dimensions.z - motorDimensions.z;
          let l10Parts = Math.floor(middleGroupHeight / l10Dimensions.z);
          let l05Parts = 0;
          if (middleGroupHeight - l10Parts > 0.75) l10Parts++;
          else if (middleGroupHeight - l10Parts > 0.25) {
            l05Parts = 1;
          }

          const middleGroup = new THREE.Group();
          for (let i = 0; i < l10Parts; i++) {
            const l10PartCopy = parts.l10.clone();
            l10PartCopy.position.y = i * l10Dimensions.z;
            middleGroup.add(l10PartCopy);
          }
          if (l05Parts) {
            parts.l05.position.y = l10Parts + 1 - l05Dimensions.z;
            middleGroup.add(parts.l05);
          }
          const middleGroupDimensions = this.getModelDimensions(middleGroup);

          parts.base.position.y = 0;
          parts.base.position.x -= baseDimensions.x / 2;
          parts.base.position.z += baseDimensions.y / 2;

          middleGroup.position.y -= l05Dimensions.z;
          middleGroup.position.x -= l10Dimensions.x / 2;
          middleGroup.position.z += l10Dimensions.y / 2;

          parts.motor.position.y =
            (l05Parts + 1) * l05Dimensions.z + (l10Parts - 2) * l10Dimensions.z;
          parts.motor.position.x -= baseDimensions.x / 2;
          parts.motor.position.z += l10Dimensions.y;

          elevator.part.add(parts.base);
          elevator.part.add(middleGroup);
          elevator.part.add(parts.motor);
          // elevator.part.rotateY(Math.PI / 2);

          elevator.part.name = elevatorParameters.name;
          const elevatorDimensions = this.getModelDimensions(elevator.part);
          elevator.params.dx.x = parseFloat(Math.max(l10Dimensions.x, l05Dimensions.x).toFixed(2));
          elevator.params.dx.y = parseFloat(elevatorDimensions.y.toFixed(2));
          elevator.params.dx.z = parseFloat(Math.max(l10Dimensions.y, l05Dimensions.y).toFixed(2));

          // console.log(`${elevatorParameters.name} wanted height:${elevatorParameters.dx.y} real:${elevatorDimensions.y}`);
          observer.next(elevator);
          observer.complete();
        })
        .catch((err) => {
          throw new Error(err);
        });
    });
  }

  getPartFactory(
    node: Part3DNode,
    sceneObjects$: Subject<SceneGeneratorEvent>,
    isLastNode:boolean = false,
  ): void {
    switch (node.type) {
      case PartTypeEnum.SILO_BIN: {
        if (
          node.partDetails3D.params.name === undefined ||
          node.partDetails3D.params.name === ''
        ) {
          node.partDetails3D.params.name = 'Silo bin #1';
        }
        this.getSiloBin(
          node.partDetails3D.params.dx,
          node.partDetails3D.params.color,
          node.partDetails3D.params.name
        ).subscribe((part) => {
          part.part.position.x = node.position.x;
          part.part.position.y = node.position.y;
          part.part.position.z = node.position.z;
          part.part.rotateY(node.orientation.y);
          node.part3D = part;
          const generatorEvent = {
            next: isLastNode ? SceneGeneratorEnum.COMPLETE : SceneGeneratorEnum.DO_NOTHING,
            part3DNode: node,
          } as SceneGeneratorEvent;
          sceneObjects$.next(generatorEvent);
        });
        break;
      }
      case PartTypeEnum.INTAKE: {
        if (
          node.partDetails3D.params.name === undefined ||
          node.partDetails3D.params.name === ''
        ) {
          node.partDetails3D.params.name = 'Intake area #1';
        }
        this.getEquipmentMetallicHousing(
          node.partDetails3D.params.dx,
          node.partDetails3D.params.color,
          node.partDetails3D.params.name
        ).subscribe((part) => {
          part.part.position.x = node.position.x;
          part.part.position.y = node.position.y;
          part.part.position.z = node.position.z;
          part.part.rotateY(node.orientation.y);
          node.part3D = part;
          const generatorEvent = {
            next: isLastNode ? SceneGeneratorEnum.COMPLETE : SceneGeneratorEnum.DO_NOTHING,
            part3DNode: node,
          } as SceneGeneratorEvent;
          sceneObjects$.next(generatorEvent);
        });
        break;
      }
      case PartTypeEnum.ELEVATOR: {
        if (
          node.partDetails3D.params.name === undefined ||
          node.partDetails3D.params.name === ''
        ) {
          node.partDetails3D.params.name = 'Main elevator';
        }
        this.getElevator(
          node.partDetails3D.params as Elevator3DParams
        ).subscribe((part) => {
          part.part.position.x = node.position.x;
          part.part.position.y = node.position.y;
          part.part.position.z = node.position.z;

          node.partDetails3D.params.dx.x = part.params.dx.x;
          node.partDetails3D.params.dx.y = part.params.dx.y;
          node.partDetails3D.params.dx.z = part.params.dx.z;

          part.part.rotateY(node.orientation.y);
          part.part.updateMatrix();
          node.part3D = part;
          const generatorEvent = {
            next: isLastNode ? SceneGeneratorEnum.COMPLETE : SceneGeneratorEnum.DO_NOTHING,
            part3DNode: node,
          } as SceneGeneratorEvent;
          sceneObjects$.next(generatorEvent);
        });
        break;
      }
      case PartTypeEnum.DELIVERY: {
        if (
          node.partDetails3D.params.name === undefined ||
          node.partDetails3D.params.name === ''
        ) {
          node.partDetails3D.params.name = 'Delivery bin #1';
        }
        this.getAutoDeliveryBin(
          node.partDetails3D.params as Delivery3DParams
        ).subscribe((part) => {
          part.part.position.x = node.position.x;
          part.part.position.y = node.position.y;
          part.part.position.z = node.position.z;

          part.part.rotateY(node.orientation.y);
          part.part.updateMatrix();

          node.part3D = part;
          const generatorEvent = {
            next: isLastNode ? SceneGeneratorEnum.COMPLETE : SceneGeneratorEnum.DO_NOTHING,
            part3DNode: node,
          } as SceneGeneratorEvent;
          sceneObjects$.next(generatorEvent);
        });
        break;
      }
      case PartTypeEnum.DRYER: {
        if (
          node.partDetails3D.params.name === undefined ||
          node.partDetails3D.params.name === ''
        ) {
          node.partDetails3D.params.name = 'Dryer';
        }
        this.getDryer(node.partDetails3D.params as Dryer3DParams).subscribe(
          (part) => {
            part.part.position.x = node.position.x;
            part.part.position.y = node.position.y;
            part.part.position.z = node.position.z;

            part.part.rotateY(node.orientation.y);

            node.part3D = part;
            const bbox = new THREE.Box3().setFromObject(node.part3D.part);

            const generatorEvent = {
              next: isLastNode ? SceneGeneratorEnum.COMPLETE : SceneGeneratorEnum.DO_NOTHING,
              part3DNode: node,
            } as SceneGeneratorEvent;
            sceneObjects$.next(generatorEvent);
          }
        );
        break;
      }
      case PartTypeEnum.BUFFER_BIN: {
        if (
          node.partDetails3D.params.name === undefined ||
          node.partDetails3D.params.name === ''
        ) {
          node.partDetails3D.params.name = 'Buffer bin';
        }
        this.getBufferBin(node.partDetails3D.params as Bin3DParams).subscribe(
          (part) => {
            part.part.position.x = node.position.x;
            part.part.position.y = node.position.y;
            part.part.position.z = node.position.z;

            part.part.rotateY(node.orientation.y);
            part.part.updateMatrix();

            node.part3D = part;
            const generatorEvent = {
              next: isLastNode ? SceneGeneratorEnum.COMPLETE : SceneGeneratorEnum.DO_NOTHING,
              part3DNode: node,
            } as SceneGeneratorEvent;
            sceneObjects$.next(generatorEvent);
          }
        );
        break;
      }
      case PartTypeEnum.CLEANER: {
        if (
          node.partDetails3D.params.name === undefined ||
          node.partDetails3D.params.name === ''
        ) {
          node.partDetails3D.params.name = 'Pre-Cleaner';
        }
        this.getCleaner(node.partDetails3D.params as Cleaner3DParams).subscribe(
          (part) => {
            part.part.position.x = node.position.x;
            part.part.position.y = node.position.y;
            part.part.position.z = node.position.z;
            
            part.part.rotateY(node.orientation.y);
            node.part3D = part;
            const bbox = new THREE.Box3().setFromObject(node.part3D.part);

            const generatorEvent = {
              next: isLastNode ? SceneGeneratorEnum.COMPLETE : SceneGeneratorEnum.DO_NOTHING,
              part3DNode: node,
            } as SceneGeneratorEvent;
            sceneObjects$.next(generatorEvent);
          }
        );
        break;
      }
      case PartTypeEnum.BELT_CONVEYOR: {
        if (
          node.partDetails3D.params.name === undefined ||
          node.partDetails3D.params.name === ''
        ) {
          node.partDetails3D.params.name = 'Belt conveyor';
        }
        this.getBeltConveyor(
          node.partDetails3D.params as Conveyor3DParams
        ).subscribe((part) => {
          part.part.position.x = node.position.x;
          part.part.position.y = node.position.y;
          part.part.position.z = node.position.z;

          part.part.rotateY(node.orientation.y);
          part.part.updateMatrix();

          node.partDetails3D.params.dx.x = part.params.dx.x;
          node.partDetails3D.params.dx.y = part.params.dx.y;
          node.partDetails3D.params.dx.z = part.params.dx.z;
          node.part3D = part;
          const generatorEvent = {
            next: isLastNode ? SceneGeneratorEnum.COMPLETE : SceneGeneratorEnum.DO_NOTHING,
            part3DNode: node,
          } as SceneGeneratorEvent;
          sceneObjects$.next(generatorEvent);
        });
        break;
      }
      case PartTypeEnum.CHAIN_CONVEYOR: {
        if (
          node.partDetails3D.params.name === undefined ||
          node.partDetails3D.params.name === ''
        ) {
          node.partDetails3D.params.name = 'Chain conveyor';
        }
        this.getChainConveyor(
          node.partDetails3D.params as Conveyor3DParams
        ).subscribe((part) => {
          part.part.position.x = node.position.x;
          part.part.position.y = node.position.y;
          part.part.position.z = node.position.z;

          part.part.rotateY(node.orientation.y);
          part.part.updateMatrix();

          node.partDetails3D.params.dx.x = part.params.dx.x;
          node.partDetails3D.params.dx.y = part.params.dx.y;
          node.partDetails3D.params.dx.z = part.params.dx.z;
          node.part3D = part;
          const generatorEvent = {
            next: isLastNode ? SceneGeneratorEnum.COMPLETE : SceneGeneratorEnum.DO_NOTHING,
            part3DNode: node
          } as SceneGeneratorEvent;
          sceneObjects$.next(generatorEvent);
        });
        break;
      }
    }
  }

  // ------ CREATE ON-DEMAND PART ------ //
  getPart(sceneObjects$: Subject<SceneGeneratorEvent>): void {
    const node = new Part3DNode();
    this.getPartFactory(node, sceneObjects$);
  }

  // ------ HELPER METHODS ------ //
  loadParts(parts): Iterable<any> {
    const loader = new STLLoader();

    return parts.map((filename) => {
      return new Promise((resolve, reject) => {
        loader.load(
          filename,
          (part) => resolve(part),
          undefined,
          (err) => reject(err)
        );
      });
    });
  }

  getBoundingBox(obj: THREE.Mesh | THREE.Group | THREE.Object3D): number[] {
    const boundingBoxSize = new THREE.Vector3();
    const objBoundingBox = new THREE.Box3().setFromObject(obj);
    const objBoundingBoxX = objBoundingBox.getSize(boundingBoxSize).x;
    const objBoundingBoxY = objBoundingBox.getSize(boundingBoxSize).y;
    const objBoundingBoxZ = objBoundingBox.getSize(boundingBoxSize).z;

    return [objBoundingBoxX, objBoundingBoxY, objBoundingBoxZ];
  }

  fitBeltConveyorParts(
    length: number,
    l100PartLength: number,
    l200PartLength: number
  ): number[] {
    const n200Parts = Math.floor(length / l200PartLength);
    const n100Parts = Math.ceil(
      (length - n200Parts * l200PartLength) / l100PartLength
    );
    return [n100Parts, n200Parts];
  }

  emitColor(
    sceneComponents: SceneParams,
    partName: string,
    color: number | string
  ): void {
    const part = sceneComponents.scene.children.find(
      (x) => x.name === partName && x.type !== 'Box3Helper'
    );
    if (part) {
      if (part && part.type === 'Group') {
        for (const [i, _] of part.children.entries()) {
          if (part.children[i].type === 'Group') {
            part.children[i].children.forEach((c) =>
              c.material.emissive.set(color)
            );
          } else {
            part.children[i].material.emissive.set(color);
          }
        }
        // if it's object not group
      } else if (part && part.type !== 'Group') {
        part.material.emissive.set(color);
      }
    }
  }
}
