import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { GenericSnackBarService } from './generic-snack-bar.service';
import { Producer } from '../models/producer';
import { CompanyTypeEnum, UserHelperServiceEnum, UserTypeEnum } from '../models/enums';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';
import { OperationResult } from '../models/helper-service-events';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class UserHelperService {
  private endpoint = environment.endpoint;

  constructor(
    private api: ApiService,
    private auth: AuthService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private snackBar: GenericSnackBarService
  ) {
    this.api.http = this.http;
  }

  getUserById(userService$: Subject<OperationResult>, userId: string): void {
    this.api.getById<User>(this.endpoint.userById, userId).subscribe(
      (response) => {
        const result = new OperationResult(UserHelperServiceEnum.LOADED, {
          user: response,
        });
        userService$.next(result);
      },
      (err) => {
        this.snackBar.showError(
          `Failed to load user details. Cause ${err.message}.`
        );
      }
    );
  }

  getUsersByCompany(userService$: Subject<OperationResult>, params: any): void {
    if (params.hasOwnProperty('companyType')) {
      this.api
        .get<User[]>(this.endpoint.users + '/byCompany', {
          companyType: params.companyType,
        })
        .subscribe((users) => {
          const result = new OperationResult(UserHelperServiceEnum.LOADED, {
            users: users,
          });
          userService$.next(result);
        }, (e) => {
          const result = new OperationResult(UserHelperServiceEnum.ERROR, e);
          userService$.next(result);
        });
    }
  }

  getUsers(userService$: Subject<OperationResult>, params: any): void {
    if (params.hasOwnProperty('type') && params.hasOwnProperty('status')) {
      this.api
        .get<Producer[]>(this.endpoint.users, {
          type: params.type,
          status: params.status,
        })
        .subscribe((producers) => {
          const result = new OperationResult(UserHelperServiceEnum.LOADED, {
            users: producers,
            type: params.type,
          });
          userService$.next(result);
        });
    }
  }

  getProducers(userService$: Subject<OperationResult>, params: any): void {
    if (params.hasOwnProperty('status')) {
      this.api
        .get<Producer[]>(this.endpoint.users + '/producer', {
          companyType: [
            //CompanyTypeEnum.MARKETPLACE,
            CompanyTypeEnum.SELLER
          ],
          status: params.status,
        })
        .subscribe((producers) => {
          const result = new OperationResult(UserHelperServiceEnum.LOADED, {
            users: producers,
          });
          userService$.next(result);
        });
    }
  }

  updateUser(userService$: Subject<OperationResult>, user: User) {
    this.api
      .put<User>(this.endpoint.userById, this.auth.getUserId(), user)
      .subscribe(
        (response) => {
          const result = new OperationResult(
            UserHelperServiceEnum.EDITED,
            response
          );
          userService$.next(result);
        },
        (e) => {
          const result = new OperationResult(UserHelperServiceEnum.ERROR, e);
          userService$.next(result);
        }
      );
  }
}
