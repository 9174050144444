import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../shared/services/api.service';
import { UserActivateRequest } from '../shared/models/user';
import { AuthService } from '../shared/services/auth.service';
import { environment } from '../../environments/environment';
import { GenericSnackBarService } from '../shared/services/generic-snack-bar.service';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss'],
})
export class ActivateComponent implements OnInit {
  activateForm: FormGroup;
  error: any[];
  private endpoint = environment.endpoint;
  private routes = environment.routes;
  loading = false;
  constructor(
    private router: Router,
    private http: HttpClient,
    private api: ApiService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private snackBar: GenericSnackBarService
  ) {
    this.api.http = this.http;
  }
  ngOnInit(): void {
    this.activateForm = new FormGroup({
      username: new FormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
      code: new FormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
    });
    this.route.queryParams.subscribe((param) => {
      let email = param.email,
        code = param.code;
      if (this.isInvalidParam(email) || this.isInvalidParam(code)) {
        this.router.navigate([this.routes.signIn]);
        this.snackBar.showError(
          "Your account couldn't be activated! Invalid parameters"
        );
        return;
      }
      email = decodeURIComponent(param.email);
      code = decodeURIComponent(param.code);
      email = email.replace(' ', '');
      code = code.replace(' ', '');
      this.activateForm.setValue({
        username: email,
        code: code,
      });
    });
    this.auth.redirect();
  }
  isInvalidParam(param): boolean {
    return (
      param === undefined ||
      param === null ||
      param === '' ||
      param.length <= 0 ||
      param == 'undefined'
    );
  }
  onSubmit(form: FormGroup): void {
    const formValue = form.value;
    interface UserActivateResponse {
      error;
    }
    const UserActivateRequest: UserActivateRequest = {
      email: formValue.username.toLocaleLowerCase(),
      code: formValue.code,
    };
    this.api
      .post<UserActivateRequest, UserActivateResponse>(
        this.endpoint.activate,
        UserActivateRequest
      )
      .subscribe(
        (r: UserActivateResponse) => {
          this.router.navigate([this.routes.signIn]).then(() => {
            window.location.reload();
          });
          this.snackBar.showSuccess(
            'Your account have been activated! You can start using the application now, but you must register your company details first...'
          );
        },
        (e) => {
          if (e.error.message instanceof Array) {
            this.error = e.error.message;
          } else {
            const nl = [];
            nl.push(e.error.message);
            this.error = nl;
          }
        }
      );
  }
}
