import { Injectable } from '@angular/core';
import { ExportToCsv } from 'export-to-csv';
import {
  PartAreaEnum,
  PartSubTypeEnum,
  PartTypeEnum,
  ProjectHelperServiceEnum,
} from '../models/enums';
import {
  Bin3DParams,
  Cleaner3DParams,
  Conveyor3DParams,
  Delivery3DParams,
  Dryer3DParams,
  Elevator3DParams,
  Intake3DParams,
} from '../models/part';
import { saveAs } from 'file-saver';
import { Project } from '../models/project';
import { STLExporter } from 'three/examples/jsm/exporters/STLExporter.js';
import { Scene } from 'three';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { request } from 'http';
import { ApiService } from './api.service';
import { Subject, forkJoin, of } from 'rxjs';
import { OperationResult } from '../models/helper-service-events';
import {
  ProjectFile,
  ProjectFileType,
  ProjectSnapshotType,
  filesType,
} from '../models/projectfile';
import { SceneParams } from '../models/scene-params';
import { Buffer } from 'buffer';
import * as THREE from 'three';
import { NotifyService } from './notify.service';
import { GenericSnackBarService } from './generic-snack-bar.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProjectFilesService {
  private endpoint = environment.endpoint;
  constructor(
    private api: ApiService,
    private http: HttpClient,
    private notifyService: NotifyService,
    private snackbar: GenericSnackBarService
  ) {
    this.api.http = this.http;
  }

  async numberProjectFiles(currentProject: Project): Promise<number> {
    let successCount = 0;
    const requests = filesType.map((fileType) => {
      const projectFileRequest = new ProjectFile();
      projectFileRequest.data = [];
      projectFileRequest.type = fileType;
      return this.api
        .post<ProjectFile, ProjectFile>(
          this.endpoint.projectFileById.replace(':id', currentProject._id),
          projectFileRequest
        )
        .pipe(catchError(() => of(null)));
    });
    return forkJoin(requests)
      .pipe(
        map((results) => {
          successCount = results.filter((result) => result !== null).length;
          return successCount;
        })
      )
      .toPromise();
  }

  downloadProjectFile(
    currentProject: Project,
    fileType: ProjectFileType,
    downloadService$: Subject<OperationResult>
  ): boolean {
    const projectFileRequest = new ProjectFile();
    projectFileRequest.data = [];
    projectFileRequest.type = fileType;
    this.api
      .post<ProjectFile, ProjectFile>(
        this.endpoint.projectFileById.replace(':id', currentProject._id),
        projectFileRequest
      )
      .subscribe(
        (r) => {
          let fileData = r.data;
          let fileType = r.type;
          let message =
            'Project file "${name}" have been downloaded successfully.';
          switch (fileType) {
            case ProjectFileType.CSV: {
              this.generateBlobAndDownload(
                fileData,
                currentProject.name + '_ListofEquipments.csv',
                'text/csv;charset=utf8;'
              );
              message = message.replace(
                '${name}',
                currentProject.name + '_ListofEquipments.csv'
              );
              break;
            }
            case ProjectFileType.FLOW_DIAGRAM: {
              this.generateAndDownloadImage(
                fileData,
                currentProject.name + '_FlowDiagram.png'
              );
              message = message.replace(
                '${name}',
                currentProject.name + '_FlowDiagram.png'
              );
              break;
            }
            case ProjectFileType.STL_FILE: {
              this.generateSTLAndDownload(
                fileData,
                currentProject.name + '_3D.stl'
              );
              message = message.replace(
                '${name}',
                currentProject.name + '_3D.stl'
              );
              break;
            }
          }
          const operationResult = new OperationResult(
            ProjectHelperServiceEnum.SUCCESS,
            message
          );
          this.notifyService.stopDownload(true);
          downloadService$.next(operationResult);
          return true;
        },
        (err) => {
          console.log(err);
          const operationResult = new OperationResult(
            ProjectHelperServiceEnum.ERROR,
            err
          );
          this.notifyService.stopDownload(false);
          downloadService$.next(operationResult);
          return false;
        }
      );
    return false;
  }
  async uploadProjectFile(
    currentProject: Project,
    fileType: ProjectFileType,
    uploadService$: Subject<OperationResult>,
    snapshotType?: ProjectSnapshotType,
    canvasSelector?: string,
    sceneParams?: SceneParams
  ): Promise<void> {
    let data: any;
    let fileName: any;
    const projectFileRequest = new ProjectFile();
    let message = 'Project file "${name}" have been uploaded successfully.';
    switch (fileType) {
      case ProjectFileType.CSV: {
        data = this.generateEquipmentListFile(currentProject);
        message = message.replace(
          '${name}',
          currentProject.name + '_ListofEquipments.csv'
        );
        break;
      }
      case ProjectFileType.FLOW_DIAGRAM: {
        data = this.generateFlowDiagramImage(canvasSelector);
        message = message.replace(
          '${name}',
          currentProject.name + '_FlowDiagram.png'
        );
        break;
      }
      case ProjectFileType.STL_FILE: {
        data = this.generateSTLFile(sceneParams.scene);
        message = message.replace('${name}', currentProject.name + '_3D.stl');
        break;
      }
      case ProjectFileType.PROJECT_SNAPSHOT: {
        let snap;
        switch (snapshotType) {
          case ProjectSnapshotType.BOTTOM: {
            snap = 'bottom';
            break;
          }
          case ProjectSnapshotType.DEGREE40: {
            snap = 'degree40';
            break;
          }
          case ProjectSnapshotType.TOP: {
            snap = 'top';
            break;
          }
        }
        data = await this.generateProjectSnapshot(sceneParams, snapshotType);
        message = message.replace(
          '${name}',
          currentProject.name + '_SNAPSHOT_' + snap + '.png'
        );
        fileName = currentProject._id + '_SNAPSHOT_' + snap;
      }
    }
    projectFileRequest.data = data;
    projectFileRequest.type = fileType;
    projectFileRequest.fileName = fileName;
    this.api
      .put<ProjectFile>(
        this.endpoint.projectFileById,
        currentProject._id,
        projectFileRequest
      )
      .subscribe(
        () => {
          const operationResult = new OperationResult(
            ProjectHelperServiceEnum.SUCCESS,
            message
          );
          uploadService$.next(operationResult);
        },
        (err) => {
          console.log(err);
          const operationResult = new OperationResult(
            ProjectHelperServiceEnum.ERROR,
            err
          );
          uploadService$.next(operationResult);
        }
      );
  }
  async downloadProjectFiles(
    currentProject: Project,
    downloadService$: Subject<OperationResult>,
    stldirectly?: boolean,
    scene?: SceneParams
  ): Promise<void> {
    this.notifyService.startCount();
    const maxFiles = await this.numberProjectFiles(currentProject);
    if (maxFiles <= 0) {
      this.notifyService.stopCount();
      this.snackbar.showError(
        'No files have been downloaded, start by generating them...'
      );
      return;
    }
    this.notifyService.stopCount();
    this.notifyService.startDownload(maxFiles);
    this.downloadProjectFile(
      currentProject,
      ProjectFileType.CSV,
      downloadService$
    );
    this.downloadProjectFile(
      currentProject,
      ProjectFileType.FLOW_DIAGRAM,
      downloadService$
    );
    if (stldirectly) {
      this.generateSTLAndDownloadDirectly(scene.scene, currentProject);
    } else {
      this.downloadProjectFile(
        currentProject,
        ProjectFileType.STL_FILE,
        downloadService$
      );
    }
  }
  async uploadProjectFiles3DDesignStep(
    currentProject: Project,
    sceneComponents: SceneParams,
    uploadService$: Subject<OperationResult>
  ): Promise<void> {
    this.uploadProjectFile(
      currentProject,
      ProjectFileType.STL_FILE,
      uploadService$,
      null,
      null,
      sceneComponents
    );
    this.uploadProjectFile(currentProject, ProjectFileType.CSV, uploadService$);
    await this.uploadProjectFile(
      currentProject,
      ProjectFileType.PROJECT_SNAPSHOT,
      uploadService$,
      ProjectSnapshotType.TOP,
      null,
      sceneComponents
    );
    await this.uploadProjectFile(
      currentProject,
      ProjectFileType.PROJECT_SNAPSHOT,
      uploadService$,
      ProjectSnapshotType.BOTTOM,
      null,
      sceneComponents
    );
    await this.uploadProjectFile(
      currentProject,
      ProjectFileType.PROJECT_SNAPSHOT,
      uploadService$,
      ProjectSnapshotType.DEGREE40,
      null,
      sceneComponents
    );
  }
  async uploadProjectFilesFlowDiagramStep(
    currentProject: Project,
    canvasSelector: string,
    uploadService$: Subject<OperationResult>
  ): Promise<void> {
    await this.uploadProjectFile(
      currentProject,
      ProjectFileType.FLOW_DIAGRAM,
      uploadService$,
      null,
      canvasSelector
    );
    await this.uploadProjectFile(
      currentProject,
      ProjectFileType.CSV,
      uploadService$
    );
  }
  private generateBlobAndDownload(
    fileData: string,
    fileName: string,
    type: string
  ): void {
    const blob = new Blob([fileData], { type: type });
    const csvUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = csvUrl;
    link.download = fileName;
    link.click();
  }
  private generateSTLAndDownloadDirectly(
    scene: Scene,
    currentProject: Project
  ) {
    const copyParts = scene.clone();
    scene.rotation.x = Math.PI / 2;
    scene.updateMatrixWorld();
    const filterChildren = scene.children.filter((x) => x.name !== '');
    copyParts.children = [...filterChildren];
    const exporter = new STLExporter();
    const StlData = exporter.parse(copyParts, { binary: true });
    //const StlData = exporter.parse(copyParts);
    const blob = new Blob([StlData], { type: 'application/octet-stream' });
    //const blob = new Blob([StlData], { type: 'text/plain' });
    saveAs(blob, `${currentProject.name}_3D.stl`);
    scene.rotation.x = 0;
    scene.updateMatrixWorld();
    this.notifyService.stopDownload(true);
    return true;
  }
  private generateSTLAndDownload(fileData: string, fileName: string) {
    const stlData = Buffer.from(fileData, 'base64');
    const blob = new Blob([stlData], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
  }
  private generateAndDownloadImage(fileData: string, fileName: string): void {
    let e: MouseEvent;
    const link = document.createElement('a');
    link.download = fileName;
    link.href = fileData;
    if (document.createEvent) {
      e = document.createEvent('MouseEvents');
      e.initMouseEvent(
        'click',
        true,
        true,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      link.dispatchEvent(e);
    }
  }

  private generateSTLFile(scene: Scene): string {
    const copyParts = scene.clone();
    scene.rotation.x = Math.PI / 2;
    scene.updateMatrixWorld();
    const filterChildren = scene.children.filter((x) => x.name !== '');
    copyParts.children = [...filterChildren];
    const exporter = new STLExporter();
    const stlData = exporter.parse(copyParts, { binary: true });
    const stlString = Buffer.from(stlData.buffer).toString('base64');
    scene.rotation.x = 0;
    scene.updateMatrixWorld();
    return stlString;
  }

  generateProjectSnapshot(
    sceneParams: SceneParams,
    snapType: ProjectSnapshotType
  ): Promise<any> {
    return new Promise((resolve) => {
      sceneParams.camera.fov = 9;
      sceneParams.camera.rotation.x = 0;
      sceneParams.camera.position.x = 0;
      sceneParams.camera.position.z = 77;
      sceneParams.camera.position.y = 590;
      sceneParams.scene.translateX(50);
      sceneParams.scene.translateZ(-55);
      switch (snapType) {
        case ProjectSnapshotType.BOTTOM: {
          sceneParams.scene.rotation.z = Math.PI;
          sceneParams.camera.rotation.x = Math.PI / 2;
          sceneParams.scene.updateMatrixWorld();
          break;
        }
        case ProjectSnapshotType.TOP: {
          sceneParams.camera.fov = 8;
          sceneParams.scene.translateX(-100);
          sceneParams.scene.updateMatrixWorld();
          break;
        }
        case ProjectSnapshotType.DEGREE40: {
          sceneParams.camera.fov = 12;
          sceneParams.scene.translateX(-100);
          sceneParams.camera.position.x = 187;
          sceneParams.camera.position.y = 110;
          sceneParams.camera.position.z = 170;
          sceneParams.scene.updateMatrixWorld();
          break;
        }
      }
      sceneParams.scene.updateMatrixWorld();
      setTimeout(() => {
        const renderer = sceneParams.renderer;
        const snapshot = renderer.domElement.toDataURL('image/png', 0.0000001);
        /*
        const snapshotWindow = window.open();
        snapshotWindow.name = snapType + '_snapshot';
        snapshotWindow.document.title = snapType + '_snapshot';
        snapshotWindow.document.write(
          '<img src="' + snapshot + '" alt="Snapshot" />'
        );
        */
        sceneParams.scene.position.x = 0;
        sceneParams.scene.position.z = 0;
        sceneParams.scene.rotation.x = 0;
        sceneParams.scene.rotation.z = 0;
        sceneParams.camera.position.x = 219;
        sceneParams.camera.position.y = 500;
        sceneParams.camera.position.z = 219;
        sceneParams.camera.lookAt(new THREE.Vector3(0, 0, 0));
        sceneParams.scene.updateMatrixWorld();
        renderer.domElement.focus();
        resolve(snapshot);
      }, 1000);
    });
  }

  private detectLatestDrawnByteX(canvas) {
    const context = canvas.getContext('2d');
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    const pixels = imageData.data;
    const bytesPerPixel = 4;
    for (let x = canvas.width - 1; x >= 0; x--) {
      for (let y = 0; y < canvas.height; y++) {
        const index = (y * canvas.width + x) * bytesPerPixel;
        const red = pixels[index];
        const green = pixels[index + 1];
        const blue = pixels[index + 2];
        const alpha = pixels[index + 3];
        if (red !== 255 || green !== 255 || blue !== 255 || alpha !== 255) {
          return x;
        }
      }
    }
    return -1;
  }

  private detectLatestDrawnByteY(canvas) {
    const context = canvas.getContext('2d');
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    const pixels = imageData.data;
    const bytesPerPixel = 4;
    for (let y = canvas.height - 1; y >= 0; y--) {
      for (let x = 0; x < canvas.width; x++) {
        const index = (y * canvas.width + x) * bytesPerPixel;
        const red = pixels[index];
        const green = pixels[index + 1];
        const blue = pixels[index + 2];
        const alpha = pixels[index + 3];
        if (red !== 255 || green !== 255 || blue !== 255 || alpha !== 255) {
          return y;
        }
      }
    }
    return -1;
  }

  private getSnapshotAtLocation(canvas): string {
    const margin = 30;
    const snapshotCanvas = document.createElement('canvas');
    const context = snapshotCanvas.getContext('2d');
    let sizeW = this.detectLatestDrawnByteX(canvas) + margin;
    let sizeY = this.detectLatestDrawnByteY(canvas) + margin;
    snapshotCanvas.width = sizeW;
    snapshotCanvas.height = sizeY;
    context.drawImage(canvas, 0, 0, sizeW, sizeY, 0, 0, sizeW, sizeY);
    const snapshotDataUrl = snapshotCanvas.toDataURL('image/png');
    return snapshotDataUrl;
  }

  generateFlowDiagramImage(canvasSelector): string {
    const canvas = document.querySelector(canvasSelector);
    const snapshotDataUrl = this.getSnapshotAtLocation(canvas);
    return snapshotDataUrl;
  }

  /*
  private generateFlowDiagramImage(canvasSelector: string): string {
    const canvas: HTMLCanvasElement = document.querySelector(canvasSelector);
    retur canvas.toDataURL('image/png;base64');
  }
  */

  private ifNan(input: string) {
    if (input.match('NaN')) {
      return '1';
    }
    return input;
  }

  private generateEquipmentListFile(currentProject: Project): any {
    const csvData = [];
    const columns = [
      { field: 'id', headers: 'ID' },
      { field: 'name', headers: 'Name' },
      { field: 'type', headers: 'Type' },
      { field: 'diameter', headers: 'Diameter(m)' },
      { field: 'length', headers: 'Length(m)' },
      { field: 'height', headers: 'Height(m)' },
      { field: 'volume', headers: 'Volume(m^3)' },
      { field: 'capacity', headers: 'Capacity(t/h)' },
      { field: 'inlets', headers: 'Inlets' },
      { field: 'outlets', headers: 'Outlets' },
      { field: 'moistureReduction', headers: 'Moisture Reduction(%)' },
      { field: 'accessories', headers: 'Accessories' },
      { field: 'comments', headers: 'Comments' },
    ];
    ///////////////////////////////////////////////////////////////////
    if (!currentProject) return;
    const sceneObjects = [...currentProject.nodes, ...currentProject.extras];
    for (const [index, _] of sceneObjects.entries()) {
      let sceneObject = sceneObjects[index];
      let parts3D = sceneObject.partDetails3D.params;
      const equipment = {
        id: '',
        name: '',
        type: '',
        diameter: '',
        length: '',
        height: '',
        volume: '',
        capacity: '',
        inlets: '',
        outlets: '',
        moistureReduction: '',
      };
      if (parts3D.name !== 'Root') {
        equipment.name = parts3D.name;
        equipment.id = Math.round(sceneObject.id).toString();
        switch (sceneObject.type) {
          case PartTypeEnum.SILO_BIN: {
            equipment.type = PartTypeEnum.SILO_BIN;
            if (sceneObject.area == PartAreaEnum.STORAGE) {
              equipment.diameter = Math.round(parts3D.dx.z).toString();
              equipment.height = Math.round(parts3D.dx.y).toString();
              equipment.volume = Math.round(
                (parts3D as Bin3DParams).volume
              ).toString();
            }
            break;
          }
          case PartTypeEnum.BUFFER_BIN: {
            equipment.type = PartTypeEnum.BUFFER_BIN;
            equipment.diameter = Math.round(parts3D.dx.z).toString();
            equipment.height = Math.round(parts3D.dx.y).toString();
            equipment.volume = Math.round(
              (parts3D as Bin3DParams).volume
            ).toString();
            break;
          }
          case PartTypeEnum.CLEANER: {
            equipment.type = PartTypeEnum.CLEANER;
            equipment.capacity = Math.round(
              (parts3D as Cleaner3DParams).capacity
            )
              ? (equipment.capacity = Math.round(
                  (parts3D as Cleaner3DParams).capacity
                ).toString())
              : '';
            break;
          }
          case PartTypeEnum.ELEVATOR: {
            equipment.type = PartTypeEnum.ELEVATOR;
            equipment.height = Math.round(parts3D.dx.y).toString();
            equipment.capacity = Math.round(
              (parts3D as Elevator3DParams).capacity
            )
              ? (equipment.capacity = Math.round(
                  (parts3D as Elevator3DParams).capacity
                ).toString())
              : '';
            break;
          }
          case PartTypeEnum.BELT_CONVEYOR: {
            equipment.type = PartTypeEnum.BELT_CONVEYOR;
            if (sceneObject.parents.length <= 0) {
              equipment.inlets = '1';
            } else {
              equipment.inlets = sceneObject.parents.length + '';
            }
            equipment.outlets = '1';
            equipment.length = Math.round(parts3D.dx.x).toString();
            equipment.capacity = Math.round(
              (parts3D as Conveyor3DParams).capacity
            )
              ? (equipment.capacity = Math.round(
                  (parts3D as Conveyor3DParams).capacity
                ).toString())
              : '';
            break;
          }
          case PartTypeEnum.CHAIN_CONVEYOR: {
            equipment.type = PartTypeEnum.CHAIN_CONVEYOR;
            if (sceneObject.parents.length <= 0) {
              equipment.inlets = '1';
            } else {
              equipment.inlets = sceneObject.parents.length + '';
            }
            equipment.outlets = this.ifNan(
              (
                Math.round((parts3D as Conveyor3DParams).nOutputs) + 1
              ).toString()
            );
            equipment.length = Math.round(parts3D.dx.x).toString();
            equipment.capacity = Math.round(
              (parts3D as Conveyor3DParams).capacity
            )
              ? (equipment.capacity = Math.round(
                  (parts3D as Conveyor3DParams).capacity
                ).toString())
              : '';
            break;
          }
          case PartTypeEnum.DRYER: {
            equipment.type = PartTypeEnum.DRYER;
            equipment.height = Math.round(parts3D.dx.y).toString();
            equipment.moistureReduction = Math.round(
              (parts3D as Dryer3DParams).moistureReduction
            )
              ? Math.round(
                  (parts3D as Dryer3DParams).moistureReduction
                ).toString()
              : '';
            equipment.capacity = Math.round((parts3D as Dryer3DParams).capacity)
              ? (equipment.capacity = Math.round(
                  (parts3D as Dryer3DParams).capacity
                ).toString())
              : '';
            break;
          }
          case PartTypeEnum.DELIVERY: {
            equipment.type = PartTypeEnum.DELIVERY;
            equipment.volume = Math.round(
              (parts3D as Delivery3DParams).volume
            ).toString();
            break;
          }
          case PartTypeEnum.INTAKE: {
            equipment.type = PartTypeEnum.INTAKE;
            equipment.capacity = Math.round(
              (parts3D as Intake3DParams).capacity
            )
              ? (equipment.capacity = Math.round(
                  (parts3D as Intake3DParams).capacity
                ).toString())
              : '';
            break;
          }
        }
        if (equipment.type != PartTypeEnum.INTAKE) {
          csvData.push(equipment);
        }
      }
    }
    const header = [];
    columns.forEach((selectedColumn) => {
      header.push(selectedColumn.headers);
    });
    return this.downloadEquipmentListFile(currentProject, csvData, header);
  }

  private downloadEquipmentListFile(
    currentProject: Project,
    result: any[],
    header: any[]
  ): any {
    const options = {
      fieldSeparator: ',',
      filename: currentProject.name + '_ListofEquipments',
      quoteStrings: '"',
      decimalSeparator: 'locale',
      showLabels: true,
      showTitle: true,
      title: 'The list of equipments',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: header,
    };
    const csvExporter = new ExportToCsv(options);
    const data = csvExporter.generateCsv(result, true);
    return data;
  }
}
