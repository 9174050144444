import { Injectable } from '@angular/core';
import * as THREE from 'three';
import {
  BoundingBox1D,
  BoundingBox2D,
  BoundingBox3D,
  Interval1D,
} from '../models/geometric-objects';
import { Part3DNode } from '../models/part';

@Injectable({
  providedIn: 'root',
})
export class VectorService {
  constructor() {}

  computeDistance(v1: THREE.Vector3, v2: THREE.Vector3): number {
    const delta = this.subtractVectors(v1, v2);
    return Math.sqrt(delta.x * delta.x + delta.y * delta.y + delta.z * delta.z);
  }

  isPointWithinRadius(
    center: THREE.Vector3,
    point: THREE.Vector3,
    radius?: number
  ): boolean {
    const DEFAULT_RADIUS = 0.05;
    if (!radius) {
      radius = DEFAULT_RADIUS;
    }
    return point.distanceTo(center) <= radius;
  }

  addVectors(v1: THREE.Vector3, v2: THREE.Vector3): THREE.Vector3 {
    return new THREE.Vector3(v1.x + v2.x, v1.y + v2.y, v1.z + v2.z);
  }

  addMultipleVectors(v1: THREE.Vector3[]): THREE.Vector3 {
    const v2 = new THREE.Vector3();
    for (const v of v1) {
      v2.x += v.x;
      v2.y += v.y;
      v2.z += v.z;
    }
    return v2;
  }

  subtractVectors(v1: THREE.Vector3, v2: THREE.Vector3): THREE.Vector3 {
    return new THREE.Vector3(v1.x - v2.x, v1.y - v2.y, v1.z - v2.z);
  }

  multiplyVectors(v1: THREE.Vector3, v2: THREE.Vector3): THREE.Vector3 {
    return new THREE.Vector3(v1.x * v2.x, v1.y * v2.y, v1.z * v2.z);
  }

  dotProductVectors(v1: THREE.Vector3, v2: THREE.Vector3): number {
    return v1.x * v2.x + v1.y * v2.y + v1.z * v2.z;
  }

  multiplyScalarVector(v1: THREE.Vector3, s: number): THREE.Vector3 {
    return new THREE.Vector3(v1.x * s, v1.y * s, v1.z * s);
  }

  addScalarVector(v1: THREE.Vector3, s: number): THREE.Vector3 {
    return new THREE.Vector3(v1.x + s, v1.y + s, v1.z + s);
  }

  subtractScalarVector(v1: THREE.Vector3, s: number): THREE.Vector3 {
    const s1 = -1 * s;
    return this.addScalarVector(v1, s1);
  }

  inverseVector(v1: THREE.Vector3): THREE.Vector3 {
    const inversedVector = new THREE.Vector3(-v1.x, -v1.y, -v1.z);
    return inversedVector;
  }

  absVector(v1: THREE.Vector3): THREE.Vector3 {
    const absVector = new THREE.Vector3(Math.abs(v1.x), Math.abs(v1.y), Math.abs(v1.z));
    return absVector;
  }

  getNonZeroComponentVector(v: THREE.Vector3): number {
    if (v.x && !v.y && !v.z) {
      return v.x;
    }
    if (v.y && !v.x && !v.z) {
      return v.y;
    }
    if (v.z && !v.y && !v.x) {
      return v.z;
    }
    const absX = Math.abs(v.x);
    const absY = Math.abs(v.y);
    const absZ = Math.abs(v.z);

  return Math.max(absX, absY, absZ);
  }

  computeIntermediaryCorner(
    start: THREE.Vector3,
    end: THREE.Vector3,
    axis1?: THREE.Vector3,
    axis2?: THREE.Vector3
  ) {
    if (!axis1) {
      axis1 = new THREE.Vector3(1, 0, 0);
    }
    if (!axis2) {
      axis2 = new THREE.Vector3(0, 0, 1);
    }
    const v0 = this.subtractVectors(end, start);
    const d1 = this.dotProductVectors(v0, axis1);
    const v1 = this.multiplyScalarVector(axis1, d1);
    return this.addVectors(start, v1);
  }

  compute2DAngle(v1: THREE.Vector3, v2: THREE.Vector3): number {
    return Math.atan2(v2.z * v1.x - v2.x * v1.z, v2.x * v1.x + v2.z * v1.z);
  }

  computeBBoxVolume(v: THREE.Vector3): number {
    const x = v.x ? v.x : 1;
    const y = v.y ? v.y : 1;
    const z = v.z ? v.z : 1;
    return x * y * z;
  }

  buildBoundingBox1D(node: Part3DNode): BoundingBox1D {
    // generate a box
    let box;
    if (!node.part3D.part) {
      box = new THREE.Box3();
    } else {
      box = new THREE.Box3().setFromObject(node.part3D.part);
    }

    const interval = {
      min: box.min.x,
      max: box.max.x,
    } as Interval1D;
    const bbox = {
      dx: interval,
    } as BoundingBox1D;
    return bbox;
  }

  buildBoundingBox2D(node: Part3DNode): BoundingBox2D {
    // generate a box
    let box;
    if (!node.part3D.part) {
      box = new THREE.Box3();
    } else {
      box = new THREE.Box3().setFromObject(node.part3D.part);
    }

    // compute dx and dy for bbox
    const dx = {
      min: box.min.x,
      max: box.max.x,
    } as Interval1D;
    const dy = {
      min: box.min.y,
      max: box.max.y,
    } as Interval1D;

    // build bbox
    const bbox = {
      dx: dx,
      dy: dy,
    } as BoundingBox2D;

    return bbox;
  }

  buildBoundingBox3D(node: Part3DNode): BoundingBox3D {
    // generate a box
    let box;
    if (!node.part3D.part) {
      box = new THREE.Box3();
    } else {
      try {
        box = new THREE.Box3().setFromObject(node.part3D.part, true);
      } catch {
        const loader = new THREE.ObjectLoader();
        const part = loader.parse(node.part3D.part);
        box = new THREE.Box3().setFromObject(part, true);
      }
    }

    // compute 1D intervals along each axis of the box
    const dx = {
      min: box.min.x,
      max: box.max.x,
    } as Interval1D;
    const dy = {
      min: box.min.y,
      max: box.max.y,
    } as Interval1D;
    const dz = {
      min: box.min.z,
      max: box.max.z,
    } as Interval1D;

    // build final bbox
    const bbox = {
      dx: dx,
      dy: dy,
      dz: dz,
    } as BoundingBox3D;

    return bbox;
  }

  isOverlapping1D(dx1: Interval1D, dx2: Interval1D): boolean {
    // check if two 1D intervals overlap

    return dx1.max >= dx2.min && dx2.max >= dx1.min;
  }

  isOverlapping2D(box1: BoundingBox2D, box2: BoundingBox2D): boolean {
    // check if two objects (boxes) overlap in 2D by checking if their OX and OY projections overlap
    return (
      this.isOverlapping1D(box1.dx, box2.dx) ||
      this.isOverlapping1D(box1.dy, box2.dy)
    );
  }

  isOverlapping3D(box1: BoundingBox3D, box2: BoundingBox3D): boolean {
    // check if two objects (volumes) overlap in 3D by checking if their OX, OY, and OZ projections overlap
    return (
      this.isOverlapping1D(box1.dx, box2.dx) ||
      this.isOverlapping1D(box1.dy, box2.dy) ||
      this.isOverlapping1D(box1.dz, box2.dz)
    );
  }

  isOverlappingOX(box1: BoundingBox3D, box2: BoundingBox3D): boolean {
    return this.isOverlapping1D(box1.dx, box2.dx);
  }

  isOverlappingOY(box1: BoundingBox3D, box2: BoundingBox3D): boolean {
    return this.isOverlapping1D(box1.dy, box2.dy);
  }

  isOverlappingOZ(box1: BoundingBox3D, box2: BoundingBox3D): boolean {
    return this.isOverlapping1D(box1.dz, box2.dz);
  }

  isOverlappingXOZ(box1: BoundingBox3D, box2: BoundingBox3D): boolean {
    // determine if the XOZ projections of the two bounding boxes overlap
    const box1Proj = {
      dx: box1.dx,
      dy: box1.dz,
    } as BoundingBox2D;

    const box2Proj = {
      dx: box2.dx,
      dy: box2.dz,
    } as BoundingBox2D;

    return this.isOverlapping2D(box1Proj, box2Proj);
  }

  isOverlappingXOY(box1: BoundingBox3D, box2: BoundingBox3D): boolean {
    // determine if the XOY projections of the two bounding boxes overlap
    const box1Proj = {
      dx: box1.dx,
      dy: box1.dy,
    } as BoundingBox2D;

    const box2Proj = {
      dx: box2.dx,
      dy: box2.dy,
    } as BoundingBox2D;

    return this.isOverlapping2D(box1Proj, box2Proj);
  }

  isOverlappingYOZ(box1: BoundingBox3D, box2: BoundingBox3D): boolean {
    // determine if the YOZ projections of the two bounding boxes overlap
    const box1Proj = {
      dx: box1.dy,
      dy: box1.dz,
    } as BoundingBox2D;

    const box2Proj = {
      dx: box2.dy,
      dy: box2.dz,
    } as BoundingBox2D;

    return this.isOverlapping2D(box1Proj, box2Proj);
  }

  isUnder(box1: BoundingBox3D, box2: BoundingBox3D): boolean {
    return box1.dy.max > box2.dy.max;
  }

  computeDistanceToBBox1D(box1: BoundingBox1D, box2: BoundingBox1D): number {
    // compute distance between two intervals.
    // note that the distance is computed between the centers of the two intervals.
    const c1 = box1.dx.min + (box1.dx.max - box1.dx.min) / 2;
    const c2 = box2.dx.min + (box2.dx.max - box2.dx.min) / 2;
    return Math.abs(c1 - c2);
  }

  computeDistanceToBBox2D(box1: BoundingBox2D, box2: BoundingBox2D): number {
    // compute distance between two boxes in 2D.
    // note that the distance is computed between the centers of the two boxes.
    const c1x = box1.dx.min + (box1.dx.max - box1.dx.min) / 2;
    const c2x = box2.dx.min + (box2.dx.max - box2.dx.min) / 2;

    const c1y = box1.dy.min + (box1.dy.max - box1.dy.min) / 2;
    const c2y = box2.dy.min + (box2.dy.max - box2.dy.min) / 2;

    return Math.sqrt(Math.pow(c1x - c2x, 2) + Math.pow(c1y - c2y, 2));
  }

  computeDistanceToBBox3D(box1: BoundingBox3D, box2: BoundingBox3D): number {
    // compute distance between two volumes in 3D.
    // note that the distance is computed between the centers of the two volumes.
    const c1x = box1.dx.min + (box1.dx.max - box1.dx.min) / 2;
    const c2x = box2.dx.min + (box2.dx.max - box2.dx.min) / 2;

    const c1y = box1.dy.min + (box1.dy.max - box1.dy.min) / 2;
    const c2y = box2.dy.min + (box2.dy.max - box2.dy.min) / 2;

    const c1z = box1.dz.min + (box1.dz.max - box1.dz.min) / 2;
    const c2z = box2.dz.min + (box2.dz.max - box2.dz.min) / 2;

    return Math.sqrt(
      Math.pow(c1x - c2x, 2) + Math.pow(c1y - c2y, 2) + Math.pow(c1z - c2z, 2)
    );
  }

  computeDistanceToBBoxXOZ(box1: BoundingBox3D, box2: BoundingBox3D): number {
    // compute distance between the XOZ projections of two 3D bounding boxes
    const box1Proj = {
      dx: box1.dx,
      dy: box1.dz,
    } as BoundingBox2D;

    const box2Proj = {
      dx: box2.dx,
      dy: box2.dz,
    } as BoundingBox2D;

    return this.computeDistanceToBBox2D(box1Proj, box2Proj);
  }

  computeDistanceToBBoxXOY(box1: BoundingBox3D, box2: BoundingBox3D): number {
    // compute distance between the XOY projections of two 3D bounding boxes
    const box1Proj = {
      dx: box1.dx,
      dy: box1.dy,
    } as BoundingBox2D;

    const box2Proj = {
      dx: box2.dx,
      dy: box2.dy,
    } as BoundingBox2D;

    return this.computeDistanceToBBox2D(box1Proj, box2Proj);
  }

  computeDistanceToBBoxYOZ(box1: BoundingBox3D, box2: BoundingBox3D): number {
    // compute distance between the YOZ projections of two 3D bounding boxes
    const box1Proj = {
      dx: box1.dy,
      dy: box1.dz,
    } as BoundingBox2D;

    const box2Proj = {
      dx: box2.dy,
      dy: box2.dz,
    } as BoundingBox2D;

    return this.computeDistanceToBBox2D(box1Proj, box2Proj);
  }

  alongSameAxis(
    v1: THREE.Vector3,
    v2: THREE.Vector3,
    bothWays: boolean = false
  ): boolean {
    const EPS = 0.2;
    const angle = v1.angleTo(v2);
    if (bothWays) {
      if (
        (-EPS <= angle && angle <= EPS) ||
        (Math.PI - EPS <= angle && angle <= Math.PI + EPS)
      ) {
        return true;
      }
    } else {
      if (-EPS <= angle && angle <= EPS) {
        return true;
      }
    }
    return false;
  }

  normalize(vector: THREE.Vector3) {
    const length = Math.sqrt(vector.x * vector.x + vector.y * vector.y + vector.z * vector.z);
    if (length > 0) {
      return this.multiplyScalarVector(vector, 1 / length);
    } else {
      throw Error('Unable to normalize. Vector length = 0.');
    }
  }
}
