import { PartAreaEnum, PartSubTypeEnum, PartTypeEnum } from '../models/enums';
import {
  PartAreaDisplay,
  PartSubTypeDisplay,
  PartTypeDisplay,
} from '../models/part';

export const PART_AREAS_DISPLAY: PartAreaDisplay[] = [
  {
    id: PartAreaEnum.INTAKE,
    name: 'Intake',
  },
  {
    id: PartAreaEnum.STORAGE,
    name: 'Storage',
  },
  {
    id: PartAreaEnum.CLEANER,
    name: 'Cleaning',
  },
  {
    id: PartAreaEnum.DELIVERY,
    name: 'Delivery',
  },
  {
    id: PartAreaEnum.DRYER,
    name: 'Drying',
  },
];

export const PART_TYPES_DISPLAY: PartTypeDisplay[] = [
  {
    id: PartTypeEnum.INTAKE,
    name: 'Truck intake',
  },
  {
    id: PartTypeEnum.CLEANER,
    name: 'Pre-cleaner',
  },
  {
    id: PartTypeEnum.DELIVERY,
    name: 'Truck delivery bin',
  },
  {
    id: PartTypeEnum.DRYER,
    name: 'Dryer',
  },
  {
    id: PartTypeEnum.BUFFER_BIN,
    name: 'Buffer bin',
  },
  {
    id: PartTypeEnum.SILO_BIN,
    name: 'Silo bin',
  },
  {
    id: PartTypeEnum.ELEVATOR,
    name: 'Elevator',
  },
  {
    id: PartTypeEnum.BELT_CONVEYOR,
    name: 'Belt conveyor',
  },
  {
    id: PartTypeEnum.CHAIN_CONVEYOR,
    name: 'Chain conveyor',
  },
];

export const PART_SUBTYPES_DISPLAY: PartSubTypeDisplay[] = [
  {
    id: PartSubTypeEnum.HORIZONTAL_SIEVES,
    name: 'Horizontal sieves',
  },
  {
    id: PartSubTypeEnum.AIR_SIFTER,
    name: 'Air sifter',
  },
  {
    id: PartSubTypeEnum.DRUM_SIEVE,
    name: 'Drum sieve',
  },
];

export const PART_CONVEYORS_DISPLAY: PartTypeDisplay[] = [
  {
    id: PartTypeEnum.BELT_CONVEYOR,
    name: 'Belt conveyor',
  },
  {
    id: PartTypeEnum.CHAIN_CONVEYOR,
    name: 'Chain conveyor',
  },
];
