import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { HttpClient } from '@angular/common/http';
import { Project } from '../../shared/models/project';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GenericSnackBarService } from '../../shared/services/generic-snack-bar.service';
import {
  ProjectHelperServiceEnum,
  ProjectTypeEnum,
  QuotationHelperServiceEnum,
  QuotationStatusEnum,
} from 'src/app/shared/models/enums';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ProjectHelperService } from 'src/app/shared/services/project-helper.service';
import { OperationResult } from 'src/app/shared/models/helper-service-events';
import { QuotationHelperService } from 'src/app/shared/services/quotation-helper.service';
import { NotifyService } from 'src/app/shared/services/notify.service';

@Component({
  selector: 'app-all-projects',
  templateUrl: './all-projects.component.html',
  styleUrls: ['./all-projects.component.scss'],
})
export class AllProjectsComponent implements OnInit, OnDestroy {
  public loading = true;
  public isTemplate = false;
  public cardsDetails: any[];
  public filterCardsDetails: any[];
  filterSelectObj = [];
  filterValues = {
    status: '',
  };
  private routes = environment.routes;
  private endpoint = environment.endpoint;
  private DEFAULT_IMAGE_URL = '../../assets/siloapp-thumbnail-default.png';
  private projectService$: Subject<OperationResult> =
    new Subject<OperationResult>();
  private downloadService$: Subject<OperationResult> =
    new Subject<OperationResult>();
  private quotationService$: Subject<OperationResult> =
    new Subject<OperationResult>();
  constructor(
    private api: ApiService,
    private http: HttpClient,
    private router: Router,
    private snackBar: GenericSnackBarService,
    private projectHelper: ProjectHelperService,
    private quotationHelper: QuotationHelperService
  ) {
    this.api.http = this.http;
  }
  ngOnDestroy(): void {
    if (this.projectService$) {
      this.projectService$.unsubscribe();
    }
    if (this.downloadService$) {
      this.downloadService$.unsubscribe();
    }
    if (this.quotationService$) {
      this.quotationService$.unsubscribe();
    }
  }
  ngOnInit(): void {
    this.projectService$.subscribe((r: OperationResult) => {
      if (r.status === ProjectHelperServiceEnum.LOADED) {
        let list = r.data as Project[];
        if (this.isTemplate) {
          list = list.filter(
            (project) => project.type !== ProjectTypeEnum.PROJECT
          );
        } else {
          list = list.filter(
            (project: Project) => project.type !== ProjectTypeEnum.TEMPLATE
          );
        }
        this.cardsDetails = list;
        this.cardsDetails.reverse();
        this.filterSelectObj.filter((o) => {
          o.options = this.getFilterObject(this.cardsDetails, o.columnProp);
        });
        this.filterCardsDetails = this.cardsDetails;
        this.loading = false;
      }
      if (r.status === ProjectHelperServiceEnum.CREATED) {
        this.loading = false;
        this.router.navigate([this.routes.projects, r.data._id]);
      }
      if (r.status === ProjectHelperServiceEnum.SUCCESS) {
        this.init();
      }
    });
    this.downloadService$.subscribe((event) => {
      if (event.status === ProjectHelperServiceEnum.SUCCESS) {
        this.snackBar.showSuccess(event.data);
      }
    });
    this.quotationService$.subscribe((event: OperationResult) => {
      if (event.status === QuotationHelperServiceEnum.LOADED) {
        const quotationId = event.data.quotation._id;
        this.router
          .navigate([this.routes.quotations + '/individual/', quotationId])
          .then(() => {});
      }
      if (event.status === QuotationHelperServiceEnum.ERROR) {
        this.snackBar.showError(event.data.message);
      }
    });
    this.init();
  }
  init(): void {
    this.loading = true;
    this.cardsDetails = [];
    this.filterCardsDetails = [];
    const url = this.router.url;
    if (url.includes('/templates')) {
      this.isTemplate = true;
    }
    this.filterSelectObj = [
      { options: [], name: 'Status', columnProp: 'status' },
    ];
    this.projectHelper.getProjects(this.projectService$);
  }
  searchProject(event): void {
    let value = event.target.value.trim().toLowerCase();
    this.loading = true;
    this.filterCardsDetails = this.cardsDetails.filter((card: Project) =>
      card.name.toLowerCase().includes(value)
    );

    this.loading = false;
  }
  // Called on Filter change
  filterChange(filter, event) {
    let ProjectStatus;
    let value = event.target.value.trim().toLowerCase();
    switch (value) {
      case 'accepted': {
        ProjectStatus = QuotationStatusEnum.ACCEPTED;
        break;
      }
      case 'rejected': {
        ProjectStatus = QuotationStatusEnum.REJECTED;
        break;
      }
      case 'pending': {
        ProjectStatus = QuotationStatusEnum.PENDING;
        break;
      }
      case 'not sent': {
        ProjectStatus = QuotationStatusEnum.NOT_SENT;
        break;
      }
    }
    this.loading = true;
    this.filterValues[filter.columnProp] = ProjectStatus;
    const status = this.filterValues.status;
    if (!status) {
      this.filterCardsDetails = this.cardsDetails;
    } else {
      this.filterCardsDetails = this.cardsDetails.filter(
        (card: Project) => card.status === ProjectStatus
      );
    }
    this.loading = false;
  }
  getFilterObject(fullObj, key) {
    const uniqChk = [];
    fullObj.filter((obj) => {
      if (!uniqChk.includes(obj[key])) {
        let name;
        let keyE = obj[key];
        switch (keyE) {
          case QuotationStatusEnum.ACCEPTED: {
            name = 'Accepted';
            break;
          }
          case QuotationStatusEnum.REJECTED: {
            name = 'Rejected';
            break;
          }
          case QuotationStatusEnum.PENDING: {
            name = 'Pending';
            break;
          }
          case QuotationStatusEnum.NOT_SENT: {
            name = 'Not Sent';
          }
        }
        if (name && !uniqChk.includes(name)) {
          uniqChk.push(name);
        }
      }
      return obj;
    });
    return uniqChk;
  }
  editProject($event: any): void {
    this.loading = true;
    this.api.getById<Project>(this.endpoint.projectById, $event).subscribe(
      (r) => {
        this.router.navigate([this.routes.projects, r._id], {
          queryParams: {
            view: 'plant',
          },
        });
        this.loading = false;
      },
      (err) => {
        this.snackBar.showError(err.message);
      }
    );
  }
  viewQuotationDetails($event: any): void {
    this.quotationHelper.getQuotationByProjectId(
      this.quotationService$,
      $event
    );
  }
  deleteProject($event: any): void {
    this.loading = true;
    this.projectHelper.deleteProject(this.projectService$, $event);
  }
  downloadProject($event: any): void {
    this.projectHelper.downloadProject($event, this.downloadService$);
  }
  createNewProject(): void {
    this.loading = true;
    this.projectHelper.createProject(
      this.projectService$,
      ProjectTypeEnum.PROJECT,
      this.DEFAULT_IMAGE_URL
    );
  }
  sendQuotationRequest($event: any): void {
    this.loading = true;
    this.api.getById<Project>(this.endpoint.projectById, $event).subscribe(
      (r) => {
        const url = this.routes.projects + '/' + r._id + '/submit-quotation';
        this.router.navigate([url]);
        this.loading = false;
      },
      (err) => {
        this.snackBar.showError(err.message);
      }
    );
  }
}
