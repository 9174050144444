<div class="container" *ngIf="showCreateCompany">
  <!-- Logo -->
  <div class="box-content">
    <img class="image" [src]="'./assets/siloapp_logo.svg'" alt="" />
    <!-- Message -->
    <div>
      <p class="general-text">Register Company</p>
      <p class="general-text" style="color: black">Company Informations</p>
    </div>
    <!-- Create Company Form -->
    <form
      class="signup-form"
      [formGroup]="createCompanyForm"
      (submit)="submitData(createCompanyForm)"
    >
      <mat-form-field class="signup-input" appearance="outline">
        <mat-label class="form-label">Phone</mat-label>
        <input matInput type="text" formControlName="phone" />
      </mat-form-field>
      <mat-form-field class="signup-input" appearance="outline">
        <mat-label>Country</mat-label>
        <mat-select
          formControlName="country"
          (valueChange)="onCountryChange(createCompanyForm)"
        >
          <mat-option *ngFor="let country of countries" [value]="country.code">
            {{ country.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="signup-input" appearance="outline">
        <mat-label class="form-label">Company Name</mat-label>
        <input matInput type="text" formControlName="companyName" />
      </mat-form-field>
      <mat-form-field class="signup-input" appearance="outline">
        <mat-label class="form-label">Company Address</mat-label>
        <input matInput type="text" formControlName="companyAddress" />
      </mat-form-field>
      <mat-form-field class="signup-input" appearance="outline">
        <mat-label>Company City</mat-label>
        <mat-select formControlName="companyCity">
          <mat-option *ngFor="let city of cities" [value]="city">
            {{ city }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="signup-input" appearance="outline">
        <mat-label class="form-label">Company ZIP Code</mat-label>
        <input matInput type="text" formControlName="companyZIP" />
      </mat-form-field>
      <mat-radio-group formControlName="companyType">
        <mat-radio-button [value]="companyTypeEnum.SELLER">
          Seller
        </mat-radio-button>
        <!--
        <mat-radio-button checked [value]="companyTypeEnum.BUYER"
          >Buyer</mat-radio-button
        >
        <mat-radio-button [value]="companyTypeEnum.MARKETPLACE"
          >Marketplace</mat-radio-button
        >-->
      </mat-radio-group>
      <button
        mat-button
        type="submit"
        class="button-default-dimension button-access"
      >
        Register Company
      </button>
    </form>
    <div>
      <p class="general-black-text">
        Back to the login page?
        <a class="general-text" href="" routerLink="/sign-in">Click here</a>
      </p>
    </div>
    <div
      *ngIf="error && error.length > 0 && createCompanyForm.touched"
      style="display: flex; flex-direction: column; gap: 4px; margin-top: 1rem"
    >
      <label *ngFor="let item of error" style="color: red; text-align: center">
        {{ item }}
      </label>
    </div>
  </div>
</div>
<div class="spinner-loading">
  <app-spinner [loading]="loading"></app-spinner>
</div>
