import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import {
  PART_AREAS_DISPLAY,
  PART_SUBTYPES_DISPLAY,
  PART_TYPES_DISPLAY,
} from 'src/app/shared/constants/customize-plant-defaults';
import { PartTypeEnum } from 'src/app/shared/models/enums';
import {
  Part3DDetailsParams,
  Part3DNode,
  PartDetails3DNew,
  PartTypeDisplay,
  PartSubTypeDisplay,
  PartAreaDisplay,
} from 'src/app/shared/models/part';

@Component({
  selector: 'app-add-equipment-dialog',
  templateUrl: './add-equipment-dialog.component.html',
  styleUrls: ['./add-equipment-dialog.component.scss'],
})
export class AddEquipmentDialogComponent implements OnInit {
  @Input() equipmentParams: PartDetails3DNew;
  @Input() part3DNodes: Part3DNode[];
  @Output() closeClicked = new EventEmitter();
  @Output() addClicked = new EventEmitter();
  @Output() mouseOn = new EventEmitter();
  @Output() mouseOff = new EventEmitter();

  partTypeEnum = PartTypeEnum;

  selectedPartType: PartTypeDisplay = {
    id: undefined,
    name: undefined,
  };

  partAreas: PartAreaDisplay[] = PART_AREAS_DISPLAY;
  partTypes: PartTypeDisplay[] = PART_TYPES_DISPLAY;
  subTypes: PartSubTypeDisplay[] = PART_SUBTYPES_DISPLAY;
  params = {} as Part3DDetailsParams;

  equipmentName: FormControl = new FormControl('', Validators.required);
  equipmentArea: FormControl = new FormControl('', Validators.required);
  equipmentType: FormControl = new FormControl('', Validators.required);
  equipmentSubType: FormControl = new FormControl('', Validators.required);
  equipmentParents: FormControl = new FormControl('', Validators.required);
  equipmentChildren: FormControl = new FormControl('', Validators.required);

  constructor() {}

  ngOnInit(): void {
    if(this.equipmentParams != null && this.equipmentParams !== undefined) {
      this.equipmentName.setValue(this.equipmentParams.name);
      this.equipmentArea.setValue(this.equipmentParams.area);
      this.equipmentType.setValue(this.equipmentParams.type);
      this.equipmentSubType.setValue(this.equipmentParams.subType);
      this.equipmentChildren.setValue(this.equipmentParams.children);
      this.equipmentParents.setValue(this.equipmentParams.parents);
      switch(this.equipmentParams.type){
        case PartTypeEnum.CHAIN_CONVEYOR: ;
        case PartTypeEnum.BELT_CONVEYOR: {
          this.params['length'] = this.equipmentParams.params.dx.x;
          this.params['nOutputs'] = this.equipmentParams.params['nOutputs'];

          break;
        }

        case PartTypeEnum.ELEVATOR: {
          this.params['height'] = this.equipmentParams.params.dx.y;

          break;
        }

        case PartTypeEnum.SILO_BIN: {}
        case PartTypeEnum.BUFFER_BIN: {}
        case PartTypeEnum.DELIVERY: {
          this.params['diameter'] = this.equipmentParams.params.dx.x;
          this.params['volume'] = Math.PI * Math.pow(this.equipmentParams.params.dx.x / 2, 2) * this.equipmentParams.params.dx.y;

          break;
        }
      }

    }
  }

  addEquipment(): void {
    let newPartDetails: PartDetails3DNew;
    if (this.equipmentType.value === PartTypeEnum.CLEANER) {
      newPartDetails = {
        name: this.equipmentName.value,
        area: this.equipmentArea.value,
        type: this.equipmentType.value,
        parents: this.equipmentParents.value,
        children: this.equipmentChildren.value,
        params: this.params,
        subType: this.equipmentSubType.value,
      };
    } else {
      newPartDetails = {
        name: this.equipmentName.value,
        area: this.equipmentArea.value,
        type: this.equipmentType.value,
        parents: this.equipmentParents.value,
        children: this.equipmentChildren.value,
        params: this.params,
      };
    }

    this.addClicked.emit(newPartDetails);
    this.params = {} as Part3DDetailsParams;
  }

  closeDialog(): void {
    this.equipmentName.reset();
    this.equipmentArea.reset();
    this.equipmentType.reset();
    this.equipmentChildren.reset();
    this.equipmentParents.reset();
    this.equipmentSubType.reset();
    this.params = {} as Part3DDetailsParams;
    this.closeClicked.emit();
  }

  mouseOverOn($event: any): void {
    let element = $event.target.textContent;
    if (element && element !== '') {
      try {
        element = element.split('.')[1].trim();
      } catch (err) {
        element = element.trim();
      }
      this.mouseOn.emit({ nodeName: element });
    }
  }

  mouseOverOff($event: any): void {
    let element = $event.target.textContent;
    if (element && element !== '') {
      try {
        element = element.split('.')[1].trim();
      } catch (err) {
        element = element.trim();
      }
      this.mouseOff.emit({ nodeName: element });
    }
  }

  get formsState(): boolean {
    return this.equipmentName.invalid || this.equipmentArea.invalid
        || this.equipmentType.invalid || this.equipmentParents.invalid
        || this.equipmentChildren.invalid;
  }

}
