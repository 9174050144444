import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { Part3DNode } from 'src/app/shared/models/part';
import { PartTypeEnum } from '../../../models/enums';

@Component({
  selector: 'app-individual-equipment-card',
  templateUrl: './individual-equipment-card.component.html',
  styleUrls: ['./individual-equipment-card.component.scss'],
})
export class IndividualEquipmentCardComponent implements OnInit {
  @Input() equipment: Part3DNode;
  @Input() editEquipmentCard: boolean;
  @Input() deleteEquipmentCard: boolean;
  @Input() cardExpanded: boolean;
  @Input() showDeleteButton: boolean;
  @Output() changedEquipment = new EventEmitter();
  @Output() deleteEquipment = new EventEmitter();

  partTypeEnum = PartTypeEnum;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  onChange(): void {
    this.changedEquipment.emit(true);
  }

  ngAfterViewInit() {
    this.disableUpDownArrows();
  }

  disableUpDownArrows() {
    const numberInputs = document.querySelectorAll('input[type="number"]');
    numberInputs.forEach((input) => {
      this.renderer.setAttribute(input, 'onwheel', 'return false');
    });
  }
  
  deleteEquipmentEvent(equipment: Part3DNode): void {
    this.deleteEquipment.emit(equipment.id);
  }

  formatInput(inputElement: HTMLInputElement) {
    let val: string | number = inputElement.value;
    var v = parseFloat(val);
    if (!isNaN(v)) {
      val = Math.round(v * 100) / 100;
    }
    inputElement.value = val.toString();
  }
}
