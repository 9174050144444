import { Part3DNode } from 'src/app/shared/models/part';
import {
  GrainsEnum,
  PaymentAcceptedEnum,
  QuotationStatusEnum,
  QuotationTypeEnum,
} from './enums';

export class Quotation {
  _id: string;
  userId: string;
  userFirstName?: string;
  userLastName?: string;
  userCompany?: string;
  userCountry?: string;
  companyId: string;
  projectId: string;
  projectName?: string;
  createdAt: Date;
  lastUpdatedAt: Date;
  producerId: string;
  status: QuotationStatusEnum;
  mainProductType: GrainsEnum;
  otherProductTypes: GrainsEnum[];
  message?: string;
  country?: string;
  paymentAccepted?: PaymentAcceptedEnum;
  producerCompany?: string;
  quotationType?: QuotationTypeEnum;
  equipments?: Part3DNode[];
  numberBins?: number;
  storageCapacity?: number;
  dryingCapacity?: number;
  mainElevatorCapacity?: number;
  handlingCapacity?: number;
  minHandlingCapacity?: number;
  maxHandlingCapacity?: number;
  constructor() {}
}

export class QuotationsTableRow extends Quotation {
  actions?: any;
}

export class QuotationCsvRow {
  id: string = '';
  quantity: string = '';
  name: string = '';
  diameter: string = '';
  height: string = '';
  length: string = '';
  width: string = '';
  volume: string = '';
  capacity: string = '';
  moistureReduction: string = '';
  details: string = '';

  constructor(
    id?: string,
    quantity?: string,
    name?: string,
    diameter?: string,
    length?: string,
    width?: string,
    height?: string,
    volume?: string,
    capacity?: string,
    moistureReduction?: string,
    details?: string
  ) {
    if (id) {
      this.id = id;
    }
    if (quantity) {
      this.quantity = quantity;
    }
    if (name) {
      this.name = name;
    }
    if (diameter) {
      this.diameter = diameter;
    }
    if (length) {
      this.length = length;
    }
    if (width) {
      this.width = width;
    }
    if (height) {
      this.height = height;
    }
    if (volume) {
      this.volume = volume;
    }
    if (capacity) {
      this.capacity = capacity;
    }
    if (moistureReduction) {
      this.moistureReduction = moistureReduction;
    }
    if (details) {
      this.details = details;
    }
  }
}
