<section>
  <div class="nav-button-account" *ngIf="isVisible">
    <h2 *ngIf="isVisible">My Account</h2>
    <button
      mat-button
      class="gray-button"
      (click)="clickUnsubscribe()"
      *ngIf="isVisible"
    >
      Close my account
    </button>
  </div>
  <div class="nav-button-unsubscribe" *ngIf="!isVisible">
    <button
      mat-mini-fab
      class="button-back-details"
      (click)="clickDetails()"
      *ngIf="!isVisible"
    >
      <
    </button>
    <h2 class="text-close-account" *ngIf="!isVisible">Close my account</h2>
  </div>
  <div class="container">
    <div class="content container-general" *ngIf="isVisible">
      <!-- Account details form -->
      <form [formGroup]="accountDetailsForm" class="account-details-form">
        <div class="content-form">
          <div class="title_wrapper">
            <p class="general-text"><strong>Personal details</strong></p>
          </div>
          <div class="input_wrapper">
            <label>First name</label>
            <mat-form-field appearance="outline">
              <input
                matInput
                type="text"
                formControlName="firstName"
                required
              />
            </mat-form-field>
          </div>
          <div class="input_wrapper">
            <label>Last name</label>
            <mat-form-field appearance="outline">
              <input matInput type="text" formControlName="lastName" required />
            </mat-form-field>
          </div>
          <div class="input_wrapper">
            <label>E-mail address</label>
            <mat-form-field appearance="outline">
              <input matInput type="email" formControlName="email" />
            </mat-form-field>
          </div>
          <!--===========================================================-->
          <div class="title_wrapper">
            <p class="general-text"><strong>Company details</strong></p>
          </div>
          <div class="input_wrapper">
            <label>Company Name</label>
            <mat-form-field appearance="outline">
              <input matInput type="text" formControlName="companyname" />
            </mat-form-field>
          </div>
          <div class="input_wrapper">
            <label>Company Type</label>
            <mat-form-field appearance="outline">
              <input matInput type="text" formControlName="companytype" />
            </mat-form-field>
          </div>
          <div class="input_wrapper">
            <label>Phone number</label>
            <mat-form-field appearance="outline">
              <input
                matInput
                type="text"
                formControlName="companyphone"
                required
              />
            </mat-form-field>
          </div>
          <div class="input_wrapper">
            <label>Address</label>
            <mat-form-field appearance="outline">
              <input
                matInput
                type="text"
                formControlName="companyaddress"
                required
              />
            </mat-form-field>
          </div>
          <div class="input_wrapper">
            <label>Zip Code</label>
            <mat-form-field appearance="outline">
              <input
                matInput
                type="text"
                formControlName="companyzip"
                required
              />
            </mat-form-field>
          </div>
          <div class="input_wrapper">
            <label>City</label>
            <mat-form-field appearance="outline">
              <mat-select formControlName="companycity">
                <mat-option *ngFor="let city of cities" [value]="city">
                  {{ city }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="input_wrapper">
            <label>Country</label>
            <mat-form-field appearance="outline">
              <mat-select
                formControlName="companycountry"
                (valueChange)="onCountryChange(accountDetailsForm)"
              >
                <mat-option
                  *ngFor="let country of countries"
                  [value]="country.code"
                >
                  {{ country.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="buttons_wrapper">
            <button mat-button class="white-button" (click)="triggerEdit()">
              Edit
            </button>
            <button
              mat-button
              class="green-button"
              (click)="editUserDetails(accountDetailsForm)"
            >
              Save
            </button>
          </div>
        </div>
      </form>
      <div
        *ngIf="error && error.length > 0 && accountDetailsForm.touched"
        style="
          gap: 4px;
          display: flex;
          margin-top: 1rem;
          flex-direction: column;
        "
      >
        <label *ngFor="let item of error" style="color: red; width: 100%">
          {{ item }}
        </label>
      </div>
    </div>
    <div class="container-unsubscribe" [hidden]="isVisible">
      <div class="container-text-unsubscribe">
        <p class="general-text">
          We are sorry to see that you want to terminate your subscription.
        </p>
        <p class="general-text">
          To terminate your account, please contact us using the form below.
        </p>
      </div>
      <!-- Contact us form -->
      <form
        (ngSubmit)="unsubscribe(unsubscribeForm)"
        [formGroup]="unsubscribeForm"
        class="account-details-form"
      >
        <label class="form-label-unsubscribe"
          >Why do you want to leave us?</label
        >
        <mat-form-field
          class="account-details-full-input-subscribe"
          appearance="outline"
        >
          <textarea
            matInput
            type="text"
            formControlName="reason"
            required
          ></textarea>
        </mat-form-field>
        <label class="form-label-unsubscribe"
          >What could we have done better?</label
        >
        <mat-form-field
          class="account-details-full-input-subscribe"
          appearance="outline"
        >
          <textarea
            matInput
            type="text"
            formControlName="feedback"
            required
          ></textarea>
        </mat-form-field>
        <button
          mat-button
          type="submit"
          class="gray-button"
          [disabled]="!unsubscribeForm.valid && unsubscribeForm.touched"
        >
          Unsubscribe
        </button>
      </form>
    </div>
    <app-spinner [loading]="loadingUnsubscribe"></app-spinner>
    <app-spinner [loading]="loadingGeneral"></app-spinner>
  </div>
</section>
