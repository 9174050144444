<div class="container">
  <h3>Add new equipment</h3>
  <div class="name-container">
    <mat-form-field appearance="fill" class="name-input">
      <mat-label>Name</mat-label>
      <input matInput [formControl]="equipmentName" required=true />
    </mat-form-field>
  </div>

  <div class="input-container">
    <mat-form-field appearance="fill" class="first-input">
      <mat-label>Area</mat-label>
      <mat-select [formControl]="equipmentArea" required>
        <mat-option *ngFor="let area of partAreas" [value]="area.id">
          {{ area.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="second-input">
      <mat-label>Type</mat-label>
      <mat-select [formControl]="equipmentType" required>
        <mat-option *ngFor="let partType of partTypes" [value]="partType.id">
          {{ partType.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>


  <div class="input-container">
    <app-dropdown-search [options]="part3DNodes" label="Inputs" [_formControl]="equipmentParents" 
        [formFieldClass]="'first-input'"
        (onMouseOverEvent)="mouseOverOn($event)" (onMouseOutEvent)="mouseOverOff($event)"
        [multipleOption]="true">
      </app-dropdown-search>

    <app-dropdown-search [options]="part3DNodes" label="Outputs" [_formControl]="equipmentChildren" 
        [formFieldClass]="'second-input'"
        (onMouseOverEvent)="mouseOverOn($event)" (onMouseOutEvent)="mouseOverOff($event)"
        [multipleOption]="true">
      </app-dropdown-search>
  </div>


  <!-- Equipment parameters -->
  <!-- Silo bin -->
  <div *ngIf="equipmentType.value === partTypeEnum.SILO_BIN">
    <mat-form-field appearance="fill" class="first-input">
      <mat-label>Volume (m^3)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="params.volume" />
    </mat-form-field>

    <mat-form-field appearance="fill" class="second-input">
      <mat-label>Diameter (m)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="params.diameter" />
    </mat-form-field>
  </div>

  <!-- Buffere bin -->
  <div *ngIf="equipmentType.value === partTypeEnum.BUFFER_BIN">
    <mat-form-field appearance="fill" class="first-input">
      <mat-label>Volume (m^3)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="params.volume" />
    </mat-form-field>

    <mat-form-field appearance="fill" class="second-input">
      <mat-label>Diameter (m)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="params.diameter" />
    </mat-form-field>
  </div>

  <!-- Delivery bin -->
  <div *ngIf="equipmentType.value === partTypeEnum.DELIVERY">
    <mat-form-field appearance="fill" class="first-input">
      <mat-label>Volume (m^3)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="params.volume" />
    </mat-form-field>

    <mat-form-field appearance="fill" class="second-input">
      <mat-label>Diameter (m)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="params.diameter" />
    </mat-form-field>
  </div>

  <!-- Elevator -->
  <div *ngIf="equipmentType.value === partTypeEnum.ELEVATOR">
    <mat-form-field appearance="fill" class="first-input">
      <mat-label>Capacity (t/h)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="params.capacity" />
    </mat-form-field>

    <mat-form-field appearance="fill" class="second-input">
      <mat-label>Height (m)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="params.height" matTooltip="Leave empty to use the height between parents and children." />
    </mat-form-field>
  </div>

  <!-- Cleaner -->
  <div *ngIf="equipmentType.value === partTypeEnum.CLEANER">
    <mat-form-field appearance="fill" class="first-input">
      <mat-label>Subtype</mat-label>
      <mat-select [formControl]="equipmentSubType" required>
        <mat-option *ngFor="let type of subTypes" [value]="type.id">
          {{ type.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="second-input">
      <mat-label>Capacity (t/h)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="params.capacity" />
    </mat-form-field>
  </div>

  <!-- Chain conveyor -->
  <div *ngIf="equipmentType.value === partTypeEnum.CHAIN_CONVEYOR">
    <mat-form-field appearance="fill" class="first-input">
      <mat-label>Capacity (t/h)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="params.capacity" />
    </mat-form-field>

    <!-- <mat-form-field appearance="fill" class="second-input">
      <mat-label>Length (m)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="params.length" matTooltip="Leave empty to use the length between parents and children." />
    </mat-form-field> -->

    <mat-form-field appearance="fill" class="first-input">
      <mat-label>Number of outlets</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="params.nOutputs" />
    </mat-form-field>
  </div>

  <!-- Belt conveyor -->
  <div *ngIf="equipmentType.value === partTypeEnum.BELT_CONVEYOR">
    <mat-form-field floatLabel="auto" appearance="fill" class="first-input">
      <mat-label>Capacity (t/h)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="params.capacity" />
    </mat-form-field>

    <!-- <mat-form-field appearance="fill" class="second-input">
      <mat-label>Length (m)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="params.length" matTooltip="Leave empty to use the length between parents and children." />
    </mat-form-field> -->
  </div>

  <!-- Dryer -->
  <div *ngIf="equipmentType.value === partTypeEnum.DRYER">
    <mat-form-field appearance="fill" class="first-input">
      <mat-label>Height (m)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="params.height" />
    </mat-form-field>

    <mat-form-field appearance="fill" class="second-input">
      <mat-label>Base width (m)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="params.width" />
    </mat-form-field>

    <mat-form-field appearance="fill" class="first-input">
      <mat-label>Base length (m)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="params.length" />
    </mat-form-field>

    <mat-form-field appearance="fill" class="second-input">
      <mat-label>Capacity (t/h)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="params.capacity" />
    </mat-form-field>

    <mat-form-field appearance="fill" class="first-input">
      <mat-label>Moisture Reduction (%)</mat-label>
      <input matInput class="input-content" type="number" [(ngModel)]="params.moistureReduction" />
    </mat-form-field>
  </div>
  <div class="layout-button">
    <button mat-button class="white-button" (click)="closeDialog()">Close</button>
    <button mat-button class="green-button" (click)="addEquipment()" [disabled]="formsState">Next</button>
    <mat-error *ngIf="formsState">Please add all the required informations.</mat-error>

  </div>
</div>
