<div [hidden]="isLoading">
  <div class="container-img">
    <img class="image" [src]='"./assets/siloapp_logo.svg"' alt="">
  </div>
  <div class="container">
    <div class="container-title">
      <p class="general-text-title"><b>Payment approval</b></p>
      <br>
    </div>
    <div class="container-text-contact">
      <p class="general-subtitle">Dear <b>{{userName}}</b>, you are about to view some details available for an extra fee only. Please be aware that
        continuing will result in additional costs for your subscription.</p>
      <br>
      <p class="general-subtitle">Are you sure you want to continue?</p>
    </div>
    <br>
    <div class="container-buttons">
      <button mat-button class="white-button" (click)="cancel()">Cancel</button>
      <button mat-button class="green-button" (click)="continue()">Accept</button>
    </div>
  </div>
</div>
<div class="spinner-loading">
  <app-spinner [loading]="isLoading"></app-spinner>
</div>
