// TODO: Add Signup to routes after signup flow is confirmed
// TODO: Refactor & fix routes for the whole application
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignupComponent } from './signup/signup.component';
import { AuthGuard } from './auth.guard';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { PendingAccountComponent } from './shared/modules/misc/pending-account/pending-account.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { ActivateComponent } from './activate/activate.component';
import { NotActiveAccountComponent } from './shared/modules/misc/notactive-account/notactive-account.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/projects',
  },
  {
    path: 'sign-in',
    component: SignInComponent,
    pathMatch: 'full',
  },
  {
    path: 'signup',
    component: SignupComponent,
    pathMatch: 'full',
  },
  {
    path: 'activation-pending',
    component: PendingAccountComponent,
    pathMatch: 'full',
  },
  {
    path: 'active-pending',
    component: NotActiveAccountComponent,
    pathMatch: 'full',
  },
  {
    path: 'activate',
    component: ActivateComponent,
    pathMatch: 'full',
  },
  {
    path: 'tutorial',
    component: TutorialComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountModule),
    pathMatch: 'full',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'projects',
    loadChildren: () =>
      import('./projects/projects.module').then((m) => m.ProjectsModule),
    // pathMatch: 'full',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  /*
  {
    path: 'team',
    component: TeamComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'billing',
    component: BillingComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'templates',
    loadChildren: () =>
      import('./projects/projects.module').then((m) => m.ProjectsModule),
    // pathMatch: 'full',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'quotations',
    loadChildren: () =>
      import('./quotations/quotations.module').then((m) => m.QuotationsModule),
    pathMatch: 'full',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'quotations/new',
    component: SubmitQuotationComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'quotations/payment-approval/:id',
    component: PaymentApprovalComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'quotations/individual-quotation/:id',
    component: ViewIndividualQuotationComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  */
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
