import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import {
  ProjectHelperServiceEnum,
  ProjectTypeEnum,
  QuotationStatusEnum,
} from 'src/app/shared/models/enums';
import { OperationResult } from 'src/app/shared/models/helper-service-events';
import { Project } from 'src/app/shared/models/project';
import { ProjectHelperService } from 'src/app/shared/services/project-helper.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-plant',
  templateUrl: './plant.component.html',
  styleUrls: ['./plant.component.scss'],
})
export class PlantComponent implements OnInit {
  public loading = true;
  public cardsDetails: any[];
  public filterCardsDetails: any[];
  filterSelectObj = [];
  filterValues = {
    status: '',
  };
  private routes = environment.routes;
  private projectService$: Subject<OperationResult> =
    new Subject<OperationResult>();
  constructor(
    private projectHelper: ProjectHelperService,
    private router: Router
  ) {}
  ngOnDestroy(): void {
    if (this.projectService$) {
      this.projectService$.unsubscribe();
    }
  }
  ngOnInit(): void {
    this.projectService$.subscribe((r: OperationResult) => {
      if (r.status === ProjectHelperServiceEnum.LOADED) {
        let list = r.data as Project[];
        list = list.filter((x: Project) => x.type !== ProjectTypeEnum.TEMPLATE);
        list = list.filter(
          (x: Project) => x.parentId === undefined || x.parentId === null
        );
        this.cardsDetails = list;
        this.cardsDetails.reverse();
        this.filterSelectObj.filter((o) => {
          o.options = this.getFilterObject(this.cardsDetails, o.columnProp);
        });
        this.filterCardsDetails = this.cardsDetails;
        this.loading = false;
      }
      if (r.status === ProjectHelperServiceEnum.SUCCESS) {
        this.init();
      }
    });
    this.init();
  }
  init(): void {
    this.loading = true;
    this.cardsDetails = [];
    this.filterCardsDetails = [];
    this.filterSelectObj = [
      { options: [], name: 'Status', columnProp: 'status' },
    ];
    this.projectHelper.getProjects(this.projectService$);
  }
  selectProject($event) {
    this.router.navigate([this.routes.projects, $event], {
      queryParams: {
        view: 'plant',
      },
    });
  }
  // Called on Filter change
  filterChange(filter, event) {
    let ProjectStatus;
    let value = event.target.value.trim().toLowerCase();
    switch (value) {
      case 'accepted': {
        ProjectStatus = QuotationStatusEnum.ACCEPTED;
        break;
      }
      case 'rejected': {
        ProjectStatus = QuotationStatusEnum.REJECTED;
        break;
      }
      case 'pending': {
        ProjectStatus = QuotationStatusEnum.PENDING;
        break;
      }
      case 'not sent': {
        ProjectStatus = QuotationStatusEnum.NOT_SENT;
        break;
      }
    }
    this.loading = true;
    this.filterValues[filter.columnProp] = ProjectStatus;
    const status = this.filterValues.status;
    if (!status) {
      this.filterCardsDetails = this.cardsDetails;
    } else {
      this.filterCardsDetails = this.cardsDetails.filter(
        (card: Project) => card.status === ProjectStatus
      );
    }
    this.loading = false;
  }
  getFilterObject(fullObj, key) {
    const uniqChk = [];
    fullObj.filter((obj) => {
      if (!uniqChk.includes(obj[key])) {
        let name;
        let keyE = obj[key];
        switch (keyE) {
          case QuotationStatusEnum.ACCEPTED: {
            name = 'Accepted';
            break;
          }
          case QuotationStatusEnum.REJECTED: {
            name = 'Rejected';
            break;
          }
          case QuotationStatusEnum.PENDING: {
            name = 'Pending';
            break;
          }
          case QuotationStatusEnum.NOT_SENT: {
            name = 'Not Sent';
          }
        }
        if (name && !uniqChk.includes(name)) {
          uniqChk.push(name);
        }
      }
      return obj;
    });
    return uniqChk;
  }
}
