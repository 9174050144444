import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export type Download = {
  maxFiles: number;
  downloading: boolean;
  downloadedFiles: number;
};

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  private maxFiles = 0;
  private fileCounter = 0;
  private downloadedFiles = 0;
  private countSubject = new Subject<boolean>();
  public count$ = this.countSubject.asObservable();
  private loadingSubject = new Subject<Download>();
  public loading$ = this.loadingSubject.asObservable();

  constructor() {}

  public startCount() : void {
    this.countSubject.next(true);
  }

  public stopCount() : void {
    this.countSubject.next(false);
  }

  public startDownload(maxFiles: number): void {
    this.downloadedFiles = 0;
    this.maxFiles = maxFiles;
    this.fileCounter = this.maxFiles;
    this.loadingSubject.next({
      downloading: true,
      maxFiles: this.maxFiles,
      downloadedFiles: this.downloadedFiles,
    });
  }

  public stopDownload(downloaded: boolean): void {
    if (downloaded) {
      this.downloadedFiles++;
    }
    this.fileCounter--;
    this.loadingSubject.next({
      downloading: true,
      maxFiles: this.maxFiles,
      downloadedFiles: this.downloadedFiles,
    });
    if (this.fileCounter <= 0) {
      this.loadingSubject.next({
        downloading: false,
        maxFiles: this.maxFiles,
        downloadedFiles: this.downloadedFiles,
      });
    }
  }
}
