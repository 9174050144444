<div class="full-container">
  <div class="container-img">
    <img class="image" [src]="'./assets/siloapp_logo.svg'" alt="" />
  </div>
  <div class="container">
    <div class="container-title">
      <p class="general-text-title"><b>Activated account</b></p>
      <br />
    </div>
    <div class="container-text-contact">
      <p class="general-text">
        Thank you for activating an account with us. Your account is currently
        waiting for admins approval. Our support team will contact you soon!
      </p>
      <br />
    </div>
    <div class="container-buttons">
      <button mat-button class="green-button" (click)="signin()">Ok</button>
    </div>
  </div>
</div>
