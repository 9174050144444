import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

// Material design modules
import {MatTabsModule} from '@angular/material/tabs';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';

import {AccountRoutingModule} from './account-routing.module';
import {AccountComponent} from './account.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from "@angular/material/button";
import {MiscModule} from '../shared/modules/misc/misc.module';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [AccountComponent],
  imports: [
    CommonModule,
    AccountRoutingModule,
    ReactiveFormsModule,
    // Material design
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatButtonModule,
    MiscModule
  ]

})
export class AccountModule {
}
