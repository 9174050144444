import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {PartTypeEnum} from 'src/app/shared/models/enums';
import {Part3DNode} from 'src/app/shared/models/part';

@Component({
  selector: 'app-delete-equipment-dialog',
  templateUrl: './delete-equipment-dialog.component.html',
  styleUrls: ['./delete-equipment-dialog.component.scss'],
})
export class DeleteEquipmentDialogComponent implements OnInit {
  @Input() part3DNodes: Part3DNode[];
  @Output() closeClicked = new EventEmitter();
  @Output() deleteClicked = new EventEmitter();
  @Output() mouseOn = new EventEmitter();
  @Output() mouseOff = new EventEmitter();

  equipments: FormControl = new FormControl('', Validators.required);

  constructor() {
  }

  ngOnInit(): void {

  }

  deleteEquipment(): void {
    const selectedEquipments = [...this.equipments.value];
    for (const nodeId of selectedEquipments) {
      const equipment = this.part3DNodes.find((x) => x.id === parseInt(nodeId, 0));
      if (
        equipment &&
        equipment.type === PartTypeEnum.INTAKE &&
        !this.equipments.value.includes(equipment.children[0])
      ) {
        this.equipments.value.push(parseInt(equipment.children[0], 0));
        break;
      } else if (
        equipment &&
        equipment.type === PartTypeEnum.INTAKE &&
        this.equipments.value.includes(equipment.children[0])
      ) {
        break;
      } else if (
        equipment &&
        equipment.type === PartTypeEnum.BELT_CONVEYOR &&
        !this.equipments.value.includes(equipment.parents[0])
      ) {
        const firstParent = this.part3DNodes.find(
          (x) => x.id === parseInt(equipment.parents[0], 0)
        );
        if (firstParent && firstParent.type === PartTypeEnum.INTAKE) {
          this.equipments.value.push(parseInt(equipment.parents[0], 0));
        }
        break;
      } else if (
        equipment &&
        equipment.type === PartTypeEnum.BELT_CONVEYOR &&
        this.equipments.value.includes(equipment.parents[0])
      ) {
        break;
      } else if (
        equipment &&
        equipment.type === PartTypeEnum.CHAIN_CONVEYOR &&
        !this.equipments.value.includes(equipment.parents[0])
      ) {
        const firstParent = this.part3DNodes.find(
          (x) => x.id === parseInt(equipment.parents[0], 0)
        );
        if (firstParent && firstParent.type === PartTypeEnum.INTAKE) {
          this.equipments.value.push(parseInt(equipment.parents[0], 0));
        }
        break;
      } else if (
        equipment &&
        equipment.type === PartTypeEnum.CHAIN_CONVEYOR &&
        this.equipments.value.includes(equipment.parents[0])
      ) {
        break;
      }
    }
    this.equipments.setValue(
      this.equipments.value.filter((item, i, a) => a.indexOf(item) === i)
    );
    this.deleteClicked.emit(this.equipments.value);
    this.equipments.reset();
  }

  closeDialog(): void {
    this.equipments.reset();
    this.closeClicked.emit();
  }

  mouseOverOn($event: any): void {
    let element = $event.target.textContent;
    if (element && element !== '') {
      try {
        element = element.split('.')[1].trim();
      } catch (err) {
        element = element.trim();
      }
      this.mouseOn.emit({nodeName: element});
    }
  }

  mouseOverOff($event: any): void {
    let element = $event.target.textContent;
    if (element && element !== '') {
      try {
        element = element.split('.')[1].trim();
      } catch (err) {
        element = element.trim();
      }
      this.mouseOff.emit({nodeName: element});
    }
  }
}
