export const NTILES = 9;
export const DEFAULT_TILE_SIZE = 12;

// Coords origin
export const X0 = 0;
export const Y0 = 0;
export const Z0 = 0;

// Constants controlling the initial camera
export const FIELD_OF_VIEW = 12;
export const ASPECT_RATIO = window.innerWidth / window.innerHeight;
export const NEAR_FIELD = 1;
export const FAR_FIELD = 1000;
export const CAMERA_POSX = (DEFAULT_TILE_SIZE * NTILES) * 2;
export const CAMERA_POSY = 500;
export const CAMERA_POSZ = (DEFAULT_TILE_SIZE * NTILES) * 2;

// Constants controlling the initial lighting
export const LIGHT_COLOR = 0xffffff;
export const AMBIENT_LIGHT_INTENSITY = 0.5;
export const DIRECTIONAL_LIGHT_INTENSITY = 0.7;
export const LIGHT_POSX = (NTILES * DEFAULT_TILE_SIZE) / 2;
export const LIGHT_POSY = 50;
export const LIGHT_POSZ = (NTILES * DEFAULT_TILE_SIZE) / 2;

export const MOUSE_DRAG_TRESHOLD = 20;
