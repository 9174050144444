import { PartAreaEnum, PartTypeEnum } from './enums';
import { Part3DNode } from './part';

export enum ConveyorLocation {
  LOADING,
  RECLAIMING,
}

class ConveyorMap {
  binRowNum: number;
  conveyorId: number;
  location: ConveyorLocation;

  constructor(
    binRowNum: number,
    conveyorId: number,
    location: ConveyorLocation
  ) {
    this.binRowNum = binRowNum;
    this.conveyorId = conveyorId;
    this.location = location;
  }
}

class Relation {
  parent: number;
  child: number;

  constructor(parent?: number, child?: number) {
    if (parent) {
      this.parent = parent;
    }
    if (child) {
      this.child = child;
    }
  }

  setRelation(parent: number, child: number): void {
    this.parent = parent;
    this.child = child;
  }

  setParent(parent: number): void {
    this.parent = parent;
  }

  setChild(child: number): void {
    this.child = child;
  }
}

class EquipmentGraph {
  relations: Relation[] = [];

  constructor() {}

  getChildren(parent: number): number[] {
    let relations = [];
    const matching = this.relations.filter((r) => r.parent === parent);
    if (matching) {
      relations = matching.map((r) => r.child);
    }
    return relations;
  }

  getParents(child: number): number[] {
    let relations = [];
    const matching = this.relations.filter((r) => r.child === child);
    if (matching) {
      relations = matching.map((r) => r.parent);
    }
    return relations;
  }

  addRelation(parent: number, child: number): void {
    const relation = new Relation(parent, child);
    this.relations.push(relation);
  }

  removeRelation(parent: number, child: number): void {
    const relation = this.relations.find(
      (r) => r.parent === parent && r.child === child
    );
    // remove relation if exists from relations
    if (relation) {
      const index = this.relations.indexOf(relation);
      if (index > -1) {
        this.relations.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
  }
}

class BaseSiloConfig {
  protected _idPrefix = 'id';

  cleanerElevatorId = 1;
  dryerElevatorId = 2;
  cleanerId = 3;
  deliveryId = 4;
  dryerId = 5;
  bufferBinId = 6;
  deliveryLoadingConveyorId = 7;
  dryerLoadingConveyorId = 8;
  dryerReclaimingConveyorId = 9;
  bufferBinReclaimingConveyorId = 10;

  equipmentGraph: EquipmentGraph;

  constructor() {
    this.equipmentGraph = new EquipmentGraph();
  }

  getMaxEquipmentId(): number {
    const properties = Object.getOwnPropertyNames(this);
    const ids = [];
    for (const property of properties) {
      if (
        property.toLowerCase().indexOf(this._idPrefix) >= 0 &&
        typeof this[property] === 'number' &&
        this[property] >= 0
      ) {
        ids.push(this[property]);
      }
    }
    return Math.max(...ids);
  }

  buildBaseRelations(): void {
    // cleaner section
    this.equipmentGraph.addRelation(this.cleanerElevatorId, this.cleanerId);
    // delivery section
    this.equipmentGraph.addRelation(
      this.deliveryLoadingConveyorId,
      this.deliveryId
    );
    // dryer section
    this.equipmentGraph.addRelation(
      this.dryerElevatorId,
      this.dryerLoadingConveyorId
    );
    this.equipmentGraph.addRelation(this.dryerLoadingConveyorId, this.dryerId);
    this.equipmentGraph.addRelation(
      this.dryerLoadingConveyorId,
      this.bufferBinId
    );
    this.equipmentGraph.addRelation(
      this.dryerId,
      this.dryerReclaimingConveyorId
    );
    this.equipmentGraph.addRelation(
      this.bufferBinId,
      this.bufferBinReclaimingConveyorId
    );
    this.equipmentGraph.addRelation(
      this.dryerReclaimingConveyorId,
      this.dryerElevatorId
    );
    
    this.equipmentGraph.addRelation(
      this.bufferBinReclaimingConveyorId,
      this.dryerElevatorId
    );
    
  }
}

export class SingleSiloBinBlockSingleIntakeConfig extends BaseSiloConfig {
  mainElevatorId: number;
  intakeId: number;
  intakeTransversalConveyorId: number;
  intakeLongitudinalConveyorId: number;
  siloBinIds: number[][] = [];
  siloLoadingTransversalConveyorIds: number[] = [];
  siloReclaimingTransversalConveyorIds: number[] = [];
  siloLoadingLongitudinalConveyorIds: number[] = [];
  siloReclaimingLongitudinalConveyorIds: number[] = [];
  siloConveyorMapping: ConveyorMap[] = [];

  siloBins: Part3DNode[];

  constructor(nodes: Part3DNode[]) {
    super();
    let maxId = this.getMaxEquipmentId() + 1;
    this.intakeTransversalConveyorId = maxId;
    maxId += 1;
    this.intakeLongitudinalConveyorId = maxId;
    // get intake ID from the existing list of nodes
    this.intakeId = nodes.find(
      (n) => n.area === PartAreaEnum.INTAKE && n.type === PartTypeEnum.INTAKE
    ).id;
    //get main elevator id from the existing list of nodes
    this.mainElevatorId = nodes.find(
      (n) => n.area === PartAreaEnum.STORAGE && n.type === PartTypeEnum.ELEVATOR
    ).id;
    // extract silo bins
    this.siloBins = nodes.filter(
      (n) => n.area === PartAreaEnum.STORAGE && n.type === PartTypeEnum.SILO_BIN
    );
    // build base parent - child relations between automatically generated equipments
    this.buildBaseRelations();
  }

  buildRelations(): void {
    // add main elevator relations
    this.equipmentGraph.addRelation(
      this.bufferBinReclaimingConveyorId,
      this.mainElevatorId
    );
    this.equipmentGraph.addRelation(
      this.dryerReclaimingConveyorId,
      this.mainElevatorId
    );
    this.equipmentGraph.addRelation(
      this.mainElevatorId,
      this.deliveryLoadingConveyorId
    );
    this.equipmentGraph.addRelation(
      this.cleanerId, 
      this.mainElevatorId);
    // add intake relations
    this.equipmentGraph.addRelation(
      this.intakeId,
      this.intakeTransversalConveyorId
    );
    this.equipmentGraph.addRelation(
      this.intakeTransversalConveyorId,
      this.intakeLongitudinalConveyorId
    );
    this.equipmentGraph.addRelation(
      this.intakeLongitudinalConveyorId,
      this.cleanerElevatorId
    );
    this.equipmentGraph.addRelation(
      this.intakeLongitudinalConveyorId,
      this.dryerElevatorId
    );
    this.equipmentGraph.addRelation(
      this.intakeLongitudinalConveyorId,
      this.mainElevatorId
    );

    // add storage area relations
    const nSiloBinRows = Math.max(
      ...this.siloBins.map((s) => s.partDetails3D.binRowNum)
    );
    let maxId = this.getMaxEquipmentId() + 1;
    if (nSiloBinRows === 0) {
      // generate IDs for loading and reclaiming conveyors
      this.siloLoadingLongitudinalConveyorIds.push(maxId);
      this.siloConveyorMapping.push(
        new ConveyorMap(
          this.siloBins[0].partDetails3D.binRowNum,
          maxId,
          ConveyorLocation.LOADING
        )
      );
      maxId += 1;
      this.siloReclaimingLongitudinalConveyorIds.push(maxId);
      this.siloConveyorMapping.push(
        new ConveyorMap(
          this.siloBins[0].partDetails3D.binRowNum,
          maxId,
          ConveyorLocation.RECLAIMING
        )
      );
      maxId += 1;
      // build relations
      this.equipmentGraph.addRelation(
        this.mainElevatorId,
        this.siloLoadingLongitudinalConveyorIds[0]
      );
      for (const bin of this.siloBins) {
        this.equipmentGraph.addRelation(
          this.siloLoadingLongitudinalConveyorIds[0],
          bin.id
        );
        this.equipmentGraph.addRelation(
          bin.id,
          this.siloReclaimingLongitudinalConveyorIds[0]
        );
      }
      this.equipmentGraph.addRelation(
        this.siloReclaimingLongitudinalConveyorIds[0],
        this.mainElevatorId
      );
    } else {
      // determine if we have multiple transversal conveyors
      const isLeftMainAxis = this.siloBins.filter(
        (s) => s.partDetails3D.isLeftMainAxis
      );
      const hasMultipleTransversalConveyors =
        isLeftMainAxis.length < this.siloBins.length;
      if (hasMultipleTransversalConveyors) {
        // build relations for the first transversal conveyor
        const siloBinsLeft = this.siloBins.filter(
          (s) => s.partDetails3D.isLeftMainAxis
        );
        if (siloBinsLeft) {
          maxId = this.buildStorageRelations(siloBinsLeft, maxId);
        }
        // build relations for the other transversal conveyor
        const siloBinsRight = this.siloBins.filter(
          (s) => !s.partDetails3D.isLeftMainAxis
        );
        if (siloBinsRight) {
          maxId = this.buildStorageRelations(siloBinsRight, maxId);
        }
      } else {
        // build storage relations
        maxId = this.buildStorageRelations(this.siloBins, maxId);
      }
    }
  }

  buildStorageRelations(siloBins: Part3DNode[], maxId?: number): number {
    if (!maxId) {
      maxId = this.getMaxEquipmentId();
    }
    const siloBinsSorted = siloBins.sort((b1, b2) => {
      if (b1.partDetails3D.binRowNum < b2.partDetails3D.binRowNum) {
        return -1;
      }
      if (b1.partDetails3D.binRowNum > b2.partDetails3D.binRowNum) {
        return 1;
      }
      return 0;
    });
    maxId += 1;
    const transversalLoadingConveyorId = maxId;
    this.siloLoadingTransversalConveyorIds.push(maxId);
    this.equipmentGraph.addRelation(this.mainElevatorId, maxId);
    maxId += 1;
    this.siloReclaimingTransversalConveyorIds.push(maxId);
    const transversalReclaimingConveyorId = maxId;
    this.equipmentGraph.addRelation(maxId, this.mainElevatorId);
    maxId += 1;
    const binRowNums = [];
    for (const bin of siloBinsSorted) {
      if (!binRowNums.includes(bin.partDetails3D.binRowNum)) {
        binRowNums.push(bin.partDetails3D.binRowNum);
        this.siloLoadingLongitudinalConveyorIds.push(maxId);
        this.equipmentGraph.addRelation(transversalLoadingConveyorId, maxId);
        this.siloConveyorMapping.push(
          new ConveyorMap(
            bin.partDetails3D.binRowNum,
            maxId,
            ConveyorLocation.LOADING
          )
        );
        maxId += 1;
        this.siloReclaimingLongitudinalConveyorIds.push(maxId);
        this.equipmentGraph.addRelation(maxId, transversalReclaimingConveyorId);
        this.siloConveyorMapping.push(
          new ConveyorMap(
            bin.partDetails3D.binRowNum,
            maxId,
            ConveyorLocation.RECLAIMING
          )
        );
        maxId += 1;
      }
      const lastReclaimingConveyorIx =
        this.siloReclaimingLongitudinalConveyorIds.length - 1;
      const lastLoadConveyorIx =
        this.siloLoadingLongitudinalConveyorIds.length - 1;
      // this.equipmentGraph.addRelation(this.siloLoadingLongitudinalConveyorIds[lastLongConveyorIx], this.siloLoadingLongitudinalConveyorIds[-1]);
      this.equipmentGraph.addRelation(
        this.siloLoadingLongitudinalConveyorIds[lastLoadConveyorIx],
        bin.id
      );
      this.equipmentGraph.addRelation(
        bin.id,
        this.siloReclaimingLongitudinalConveyorIds[lastReclaimingConveyorIx]
      );
    }
    return maxId;
  }
}

export class SingleSiloBinBlockMultipleIntakeConfig extends BaseSiloConfig {
  siloBins: Part3DNode[];
  constructor(nodes: Part3DNode[]) {
    super();
    this.siloBins = nodes.filter(
      (n) => n.area === PartAreaEnum.STORAGE && n.type === PartTypeEnum.SILO_BIN
    );
  }
}

export class TwoSiloBinBlocksSingleIntakeConfig extends BaseSiloConfig {
  constructor() {
    super();
  }
}

export class TwoSiloBinBlocksMultipleIntakeConfig extends BaseSiloConfig {
  constructor() {
    super();
  }
}

export class BrazilConfig {
  constructor() {}
}
