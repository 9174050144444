<div class="container" *ngIf="!loading">
  <div>
    <h2>Send email request</h2>
    <p class="general-subtitle">
      You are about to send project files for
      <span class="title-project">{{ currentProject.name }}</span
      >.
    </p>
    <p class="general-subtitle">
      Please fill in the details below so we can make sure your email it's send
      with success.
    </p>
  </div>
  <div class="details-form">
    <div class="details-form-producers-and-products">
      <!-- Producers and product types -->
      <div>
        <p class="general-title"><strong>Recipients & Product Types</strong></p>
      </div>
      <!-- Producers dropdown list -->
      <mat-form-field appearance="outline" class="receipts-class">
        <mat-label>Recipients*</mat-label>
        <input
          #Input
          matInput
          type="text"
          class="receipt-input"
          [formControl]="receipts"
        />
      </mat-form-field>
      <p class="general-text1">
        To include multiple email addresses, simply separate them by using
        commas. This allows you to specify multiple recipients for the message.
      </p>
      <div class="label-rfp">
        <label class="form-label rfp-details-half-input-label"
          >Main Product Type</label
        >
        <label class="form-label rfp-details-half-input-no-margin-label"
          >Other Product Types</label
        >
      </div>
      <!-- Product types dropdown list -->
      <app-dropdown-search
        [options]="mainProdType"
        label="Main product type*"
        [_formControl]="selectedMainProductTypes"
        [formFieldClass]="
          'rfp-details-half-input rfp-details-half-input-mainprodtype'
        "
        (onSelectionChangeEvent)="onMainProductTypesChange($event)"
      >
      </app-dropdown-search>
      <!-- Product types dropdown list -->
      <label class="form-label rfp-details-half-input-no-margin rfp-mobile"
        >Other Product Types</label
      >
      <app-dropdown-search
        [options]="otherProdType"
        label="Other product type"
        [_formControl]="selectedProductTypes"
        [formFieldClass]="
          'rfp-details-half-input-no-margin rfp-details-half-input-otherprodtype'
        "
        (onSelectionChangeEvent)="onOtherProductTypesChange($event)"
        [multipleOption]="true"
      >
      </app-dropdown-search>

      <!-- Additional message -->
      <div class="title-container">
        <p class="general-title"><strong>Additional Details</strong></p>
      </div>

      <label class="form-label"
        >Please add any relevant information about your project files...</label
      >
      <mat-form-field class="full-input" appearance="fill">
        <textarea matInput type="text" [formControl]="message"> </textarea>
      </mat-form-field>
    </div>
    <div class="details-form-equipments">
      <div>
        <p class="general-title-total">
          <strong
            >Total number of equipments {{ totalEquimentsList.length }}</strong
          >
        </p>
      </div>
      <div class="add-individual-equipment">
        <button mat-button [matMenuTriggerFor]="first" class="white-button">
          + Add
        </button>
        <mat-menu #first="matMenu">
          <button
            mat-menu-item
            *ngFor="let group of individualEquipmentsGroups"
            [matMenuTriggerFor]="second"
          >
            {{ group.name }}
            <mat-menu #second="matMenu">
              <button
                mat-menu-item
                title="{{ group.name }}"
                (click)="addEquipment(equipment.id, group.id)"
                *ngFor="let equipment of findEquipmentsInGroup(group.id)"
              >
                {{ equipment.partDetails3D.params.name }}
              </button>
            </mat-menu>
          </button>
        </mat-menu>
        <div class="equipment-list">
          <div class="mat-elevation-z2">
            <mat-accordion multi>
              <app-equipment-card
                *ngFor="let equipment of projectEquimentsList"
                [cardExpanded]="true"
                [equipment]="equipment"
                [editEquipmentCard]="false"
                [deleteEquipmentCard]="false"
                (typingEvent)="handleCapacityChange($event)"
              >
              </app-equipment-card>
            </mat-accordion>
            <mat-accordion multi>
              <app-individual-equipment-card
                *ngFor="let equipment of selectedIndividualEquipmentsList"
                [equipment]="equipment"
                [editEquipmentCard]="true"
                [deleteEquipmentCard]="true"
                [cardExpanded]="true"
                [showDeleteButton]="true"
                (deleteEquipment)="deleteEquipment($event)"
              >
              </app-individual-equipment-card>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
    <!-- User navigation -->
    <button mat-button class="white-button button-back" (click)="back()">
      Back
    </button>
    <div class="button-container">
      <button
        mat-button
        class="white-button button-back-mobile"
        (click)="back()"
      >
        Back
      </button>
      <span class="button-spacer"></span>
      <button mat-button class="green-button" (click)="submitEmail()">
        Send email
      </button>
    </div>
  </div>
</div>
<div class="spinner-loading">
  <app-spinner [loading]="loading"></app-spinner>
</div>
