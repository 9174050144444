import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {PartTypeEnum} from 'src/app/shared/models/enums';
import {Part3DNode} from 'src/app/shared/models/part';

@Component({
  selector: 'app-duplicate-equipment-dialog',
  templateUrl: './duplicate-equipment-dialog.component.html',
  styleUrls: ['./duplicate-equipment-dialog.component.scss'],
})
export class DuplicateEquipmentDialogComponent implements OnInit {
  @Input() part3DNodes: Part3DNode[];
  @Output() closeClicked = new EventEmitter();
  @Output() duplicateClicked = new EventEmitter();
  @Output() mouseOn = new EventEmitter();
  @Output() mouseOff = new EventEmitter();

  equipments: FormControl = new FormControl('', Validators.required);
  filteredPart3DNodes: Part3DNode[] = [];

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    const intakes = this.part3DNodes.filter(
      (x) => x.type === PartTypeEnum.INTAKE
    );
    const intakeIds = intakes.map((x) => x.id);
    const intakeChildren = intakes.map((x) => x.children[0]);
    for (const equipment of this.part3DNodes) {
      if (
        !intakeIds.includes(equipment.id) &&
        !intakeChildren.includes(equipment.id.toString())
      ) {
        this.filteredPart3DNodes.push(equipment);
      }
    }
  }

  duplicateEquipment(): void {
    this.equipments.setValue(
      this.equipments.value.filter((item, i, a) => a.indexOf(item) === i)
    );
    this.duplicateClicked.emit(this.equipments.value);
    this.equipments.reset();
  }

  closeDialog(): void {
    this.equipments.reset();
    this.closeClicked.emit();
  }

  mouseOverOn($event: any): void {
    let element = $event.target.textContent;
    if (element && element !== '') {
      try {
        element = element.split('.')[1].trim();
      } catch (err) {
        element = element.trim();
      }
      this.mouseOn.emit({nodeName: element});
    }
  }

  mouseOverOff($event: any): void {
    let element = $event.target.textContent;
    if (element && element !== '') {
      try {
        element = element.split('.')[1].trim();
      } catch (err) {
        element = element.trim();
      }
      this.mouseOff.emit({nodeName: element});
    }
  }
}
