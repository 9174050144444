import * as THREE from 'three';
import {
  PartAreaEnum,
  PartSubTypeEnum,
  PartTypeEnum,
  SceneGeneratorEnum,
  SyncStatesEnum,
} from './enums';

export class Part3DParamsBase {
  name: string;
  color: string | number;
  padding: THREE.Vector3;
  dx: THREE.Vector3;
  group?: number;

  constructor(
    name?: string,
    color?: number | string,
    dx?: THREE.Vector3 | number[],
    padding?: THREE.Vector3 | number[]
  ) {
    this.name = name;
    this.color = color;

    if (dx) {
      if (Array.isArray(dx)) {
        this.dx = new THREE.Vector3(dx[0], dx[1], dx[2]);
      } else {
        this.dx = new THREE.Vector3(dx.x, dx.y, dx.z);
      }
    } else {
      this.dx = new THREE.Vector3();
    }

    if (padding) {
      if (Array.isArray(padding)) {
        this.padding = new THREE.Vector3(padding[0], padding[1], padding[2]);
      } else {
        this.padding = new THREE.Vector3(padding.x, padding.y, padding.z);
      }
    } else {
      this.padding = new THREE.Vector3();
    }
  }
}

export class Intake3DParams extends Part3DParamsBase {
  capacity: number;

  constructor(
    name?: string,
    color?: number | string,
    dx?: THREE.Vector3 | number[],
    padding?: THREE.Vector3 | number[],
    capacity?: number,
    group?: number
  ) {
    super(name, color, dx, padding);
    this.capacity = Math.round(capacity);
    this.group = group;
  }
}

export class Delivery3DParams extends Part3DParamsBase {
  volume: number;
  support: THREE.Vector3;

  constructor(
    name?: string,
    color?: string | number,
    dx?: THREE.Vector3 | number[],
    support?: THREE.Vector3 | number[],
    padding?: THREE.Vector3 | number[],
    volume?: number
  ) {
    super(name, color, dx, padding);
    if (!volume) {
      volume = Math.PI * Math.pow(this.dx.x / 2, 2) * this.dx.y;
    }
    this.volume = Math.round(volume);

    if (support) {
      if (Array.isArray(support)) {
        this.support = new THREE.Vector3(support[0], support[1], support[2]);
      } else {
        this.support = new THREE.Vector3(support.x, support.y, support.z);
      }
    } else {
      this.support = new THREE.Vector3();
    }
  }
}

export class Dryer3DParams extends Part3DParamsBase {
  capacity: number;
  moistureReduction: number;

  constructor(
    name?: string,
    color?: number | string,
    dx?: THREE.Vector3 | number[],
    padding?: THREE.Vector3 | number[],
    capacity?: number,
    moistureReduction?: number
  ) {
    super(name, color, dx, padding);
    this.capacity = Math.round(capacity);
    this.moistureReduction = Math.round(moistureReduction);
  }
}

export class Cleaner3DParams extends Part3DParamsBase {
  support: THREE.Vector3;
  cover: THREE.Vector3;
  capacity: number;

  constructor(
    name?: string,
    color?: number | string,
    dx?: THREE.Vector3 | number[],
    support?: THREE.Vector3 | number[],
    cover?: THREE.Vector3 | number[],
    padding?: THREE.Vector3 | number[],
    capacity?: number
  ) {
    super(name, color, dx, padding);

    if (support) {
      if (Array.isArray(support)) {
        this.support = new THREE.Vector3(support[0], support[1], support[2]);
      } else {
        this.support = new THREE.Vector3(support.x, support.y, support.z);
      }
    } else {
      this.support = new THREE.Vector3();
    }

    if (cover) {
      if (Array.isArray(cover)) {
        this.cover = new THREE.Vector3(cover[0], cover[1], cover[3]);
      } else {
        this.cover = new THREE.Vector3(cover.x, cover.y, cover.z);
      }
    } else {
      this.cover = new THREE.Vector3();
    }
    this.capacity = Math.round(capacity);
  }
}

export class Bin3DParams extends Part3DParamsBase {
  volume: number;

  constructor(
    name?: string,
    color?: string | number,
    dx?: THREE.Vector3 | number[],
    padding?: THREE.Vector3 | number[],
    volume?: number,
    group?: number
  ) {
    super(name, color, dx, padding);

    if (!volume) {
      volume = Math.PI * Math.pow(this.dx.x / 2, 2) * this.dx.y;
    }
    this.volume = Math.round(volume);

    this.group = group;
  }
}

export class Elevator3DParams extends Part3DParamsBase {
  capacity: number;
  depth: THREE.Vector3;
  isMain: boolean;

  constructor(
    name?: string,
    color?: string | number,
    dx?: THREE.Vector3 | number[],
    padding?: THREE.Vector3 | number[],
    capacity?: number,
    depth?: THREE.Vector3,
    isMain?: boolean
  ) {
    super(name, color, dx, padding);
    this.capacity = Math.round(capacity);

    if (depth) {
      if (Array.isArray(depth)) {
        this.depth = new THREE.Vector3(depth[0], depth[1], depth[2]);
      } else {
        this.depth = new THREE.Vector3(depth.x, depth.y, depth.z);
      }
    } else {
      this.depth = new THREE.Vector3();
    }

    if (isMain) {
      this.isMain = isMain;
    } else {
      this.isMain = false;
    }
  }
}

export class Conveyor3DParams extends Part3DParamsBase {
  capacity: number;
  nOutputs: number;
  depth: THREE.Vector3;
  outputDistances: Array<number>;

  constructor(
    name?: string,
    color?: string | number,
    dx?: THREE.Vector3 | number[],
    nOutputs?: number,
    capacity?: number,
    depth?: THREE.Vector3 | number,
    outputDistances?: Array<number>,
    padding?: THREE.Vector3 | number[]
  ) {
    super(name, color, dx, padding);
    this.capacity = Math.round(capacity);
    this.nOutputs = nOutputs;

    if (depth) {
      if (Array.isArray(depth)) {
        this.depth = new THREE.Vector3(depth[0], depth[1], depth[2]);
      } else {
        this.depth = new THREE.Vector3(depth.x, depth.y, depth.z);
      }
    } else {
      this.depth = new THREE.Vector3();
    }

    this.outputDistances = outputDistances;
  }
}

export class Part3DParams {
  dx?: THREE.Vector3;

  constructor(dx?: THREE.Vector3 | number[]) {
    if (dx) {
      if (Array.isArray(dx)) {
        this.dx = new THREE.Vector3(dx[0], dx[1], dx[2]);
      } else {
        this.dx = new THREE.Vector3(dx.x, dx.y, dx.z);
      }
    } else {
      this.dx = new THREE.Vector3();
    }
  }
}

export class Part3D {
  part: THREE.Group | THREE.Mesh;
  params: Part3DParams;

  constructor(part?: THREE.Group | THREE.Mesh, dx?: THREE.Vector3 | number[]) {
    this.part = part;
    this.params = new Part3DParams(dx);
  }
}

export type Part3DDetailsParams =
  | Intake3DParams
  | Part3DParamsBase
  | Delivery3DParams
  | Dryer3DParams
  | Cleaner3DParams
  | Bin3DParams
  | Elevator3DParams
  | Conveyor3DParams;

export class Part3DDetails {
  params: Part3DDetailsParams;
  binRowNum?: number;
  binNumberPerRow?: number;
  isLeftMainAxis?: boolean;
  quantity?: number;
  specifications?: string;

  constructor(
    params?: Part3DDetailsParams,
    binRowNum?: number,
    binNumberPerRow?: number
  ) {
    this.params = params;
    this.binRowNum = binRowNum;
    this.binNumberPerRow = binNumberPerRow;
  }
}

export interface BoundingBox {
  x0: THREE.Vector3;
  x1: THREE.Vector3;
  x2: THREE.Vector3;
  x3: THREE.Vector3;
}

export class BoundingBox implements BoundingBox {
  constructor(
    x0?: THREE.Vector3,
    x1?: THREE.Vector3,
    x2?: THREE.Vector3,
    x3?: THREE.Vector3
  ) {
    if (x0) {
      this.x0 = new THREE.Vector3(x0.x, x0.y, x0.z);
    } else {
      this.x0 = new THREE.Vector3();
    }
    if (x1) {
      this.x1 = new THREE.Vector3(x1.x, x1.y, x1.z);
    } else {
      this.x1 = new THREE.Vector3();
    }
    if (x2) {
      this.x2 = new THREE.Vector3(x2.x, x2.y, x2.z);
    } else {
      this.x2 = new THREE.Vector3();
    }
    if (x3) {
      this.x3 = new THREE.Vector3(x3.x, x3.y, x3.z);
    } else {
      this.x3 = new THREE.Vector3();
    }
  }
}

export interface BinIntakePair {
  bin: THREE.Vector3;
  intake: THREE.Vector3;
  distance: number;
}

export interface Part3DNode {
  id: number;
  partDetails3D: Part3DDetails;
  position: THREE.Vector3;
  orientation: THREE.Vector3;
  part3D: THREE.Group | THREE.Mesh;
  parents: string[];
  children: string[];
  area: PartAreaEnum;
  type: PartTypeEnum;
  subType?: PartSubTypeEnum;
  tileName?: string;
  physicsBody?: any;
  sync?:SyncStatesEnum;
}

export class Part3DNode implements Part3DNode {
  constructor(
    id?: number,
    area?: PartAreaEnum,
    type?: PartTypeEnum,
    position?: THREE.Vector3 | number[],
    orientation?: THREE.Vector3 | number[]
  ) {
    if (id !== undefined) {
      this.id = id;
    } else {
      this.id = Math.random() * 100;
    }

    this.parents = [];
    this.children = [];
    this.area = area;
    this.type = type;

    if (position) {
      if (Array.isArray(position)) {
        this.position = new THREE.Vector3(
          position[0],
          position[1],
          position[2]
        );
      } else {
        this.position = new THREE.Vector3(position.x, position.y, position.z);
      }
    } else {
      this.position = new THREE.Vector3();
    }

    if (orientation) {
      if (Array.isArray(orientation)) {
        this.orientation = new THREE.Vector3(
          orientation[0],
          orientation[1],
          orientation[2]
        );
      } else {
        this.orientation = new THREE.Vector3(
          orientation.x,
          orientation.y,
          orientation.z
        );
      }
    } else {
      this.orientation = new THREE.Vector3();
    }
  }
}

export interface SiloGroupAxis {
  L: THREE.Vector3;
  T: THREE.Vector3;
}

export interface SiloGroupOrientation {
  bin: Part3DNode;
  axis: SiloGroupAxis;
  binsMatrix: Part3DNode[][]
}

export interface PartAreaDisplay {
  id: PartAreaEnum;
  name: string;
}

export interface PartTypeDisplay {
  id: PartTypeEnum;
  name: string;
}

export interface PartSubTypeDisplay {
  id: PartSubTypeEnum;
  name: string;
}

export interface PartDetails3DNew {
  name: string;
  area: PartAreaEnum;
  type: PartTypeEnum;
  parents: string[];
  children: string[];
  params: Part3DDetailsParams;
  subType?: PartSubTypeEnum;
}

export interface PartDetails3DEditsEvent {
  name: string;
  area: PartAreaEnum;
  type?: PartTypeEnum;
  parents: string[];
  children: string[];
  params: Part3DDetailsParams;
  subType?: PartSubTypeEnum;
}

export interface SceneGeneratorEvent {
  part3DNode: Part3DNode;
  next: SceneGeneratorEnum;
}

export interface OverlappingEquipmentsPair {
  firstEquipment: Part3DNode,
  secondEquipment: Part3DNode,
  axis: Array<string>
}
