<div class="container">
  <!-- select all button -->
  <!-- list with checkboxes with all equipments -->
  <!-- cancel button  -->
  <!-- ok button  -->
  <div class="header">
    <h3> Move equipment</h3>
  </div>
  
  <app-dropdown-search [options]="part3DNodes" label="Select equipment" [_formControl]="equipmentsForm" [formFieldClass]="'first-input'" 
    (onMouseOverEvent)="mouseOverOn($event)" (onMouseOutEvent)="mouseOverOff($event)"
    [multipleOption]="true">
  </app-dropdown-search>

  <div class="elevation">
    <mat-label>Elevation (m)</mat-label>
      <input
        matInput
        type="number"
        [formControl]="elevationForm"
        value="{{elevationForm.value | number: '1.0-2'}}"
        step="1"
        class="elevation-input"
      />
  </div>
  <div class="joystick" [ngClass]="{ disabled: equipmentsForm.value === '' }">
    <div class="row">
      <span class="material-icons" (click)="moveEquipmentDepth($event)"
        >keyboard_arrow_up</span
      >
    </div>
    <div class="row grid-button">
      <span class="material-icons" (click)="moveEquipmentLeft($event)"
        >keyboard_arrow_left</span
      >
      <div class="up-down-buttons">
        <span class="material-icons" (click)="moveEquipmentUp($event)"
          ><mat-icon>arrow_upward</mat-icon></span
        >
        <span class="material-icons" (click)="moveEquipmentDown($event)"
          ><mat-icon>arrow_downward</mat-icon></span
        >
      </div>
      <span class="material-icons" (click)="moveEquipmentRight($event)"
        >keyboard_arrow_right</span
      >
    </div>
    <div class="row">
      <span class="material-icons" (click)="moveEquipmentForward($event)"
        >keyboard_arrow_down</span
      >
    </div>
  </div>

  <div class="rotate-container">
    <mat-label>Rotation</mat-label>
    <mat-slider
      min="-180"
      max="180"
      step="1"rotateEquipment
      [formControl]="angleForm"
      thumbLabel
      aria-label="unit(degrees)"
    ></mat-slider>
  </div>
  <!-- <div class="bbox-toggle-button">
    <button (click)="toggleBoundingBox()" class="white-button">Show bounding boxes</button>
  </div> -->

  <div class="layout-button">
    <button mat-button class="white-button" (click)="closeDialog()">
      Close
    </button>
  </div>
</div>
