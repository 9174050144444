import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../shared/services/api.service';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { User } from '../shared/models/user';
import { AuthService } from '../shared/services/auth.service';
import { GenericSnackBarService } from '../shared/services/generic-snack-bar.service';
import {
  Countries,
  citiesMap,
  countriesList,
} from '../shared/models/countries';
import { Company } from '../shared/models/company';
import { CompanyTypeEnum, UserHelperServiceEnum } from '../shared/models/enums';
import { UserHelperService } from '../shared/services/user-helper.service';
import { Subject } from 'rxjs';
import { OperationResult } from '../shared/models/helper-service-events';
import { CompanyHelperService } from '../shared/services/company-helper.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  error: any[];
  isVisible = true;
  loadingGeneral = true;
  userFirstName: string;
  editTriggered: boolean;
  cities: string[] = [];
  loadingUnsubscribe = true;
  defaultCity = 'Bucuresti';
  unsubscribeForm: FormGroup;
  userCompany: Company = new Company();
  countries: Countries[] = countriesList;
  accountDetailsForm: FormGroup = new FormGroup({});
  private userService$: Subject<OperationResult> =
    new Subject<OperationResult>();
  private companyService$: Subject<OperationResult> =
    new Subject<OperationResult>();

  constructor(
    private http: HttpClient,
    private api: ApiService,
    private auth: AuthService,
    private userHelper: UserHelperService,
    private companyHelper: CompanyHelperService,
    private _snackBar: GenericSnackBarService
  ) {
    this.api.http = this.http;
  }

  ngOnInit(): void {
    this.userFirstName = this.auth.getFirstName();
    this.accountDetailsForm = new FormGroup({
      firstName: new FormControl(
        {
          value: null,
          disabled: !this.editTriggered,
        },
        [Validators.required, Validators.minLength(4)]
      ),
      lastName: new FormControl(
        {
          value: null,
          disabled: !this.editTriggered,
        },
        [Validators.required, Validators.minLength(4)]
      ),
      email: new FormControl(
        {
          value: null,
          disabled: true,
        },
        [Validators.email]
      ),
      companyname: new FormControl(
        {
          value: null,
          disabled: true,
        },
        [Validators.minLength(3)]
      ),
      companytype: new FormControl({
        value: null,
        disabled: true,
      }),
      companyphone: new FormControl(
        {
          value: null,
          disabled: !this.editTriggered,
        },
        [
          Validators.required,
          Validators.minLength(7),
          Validators.pattern('[- +()0-9]+'),
        ]
      ),
      companyaddress: new FormControl(
        {
          value: null,
          disabled: !this.editTriggered,
        },
        [Validators.required, Validators.minLength(6)]
      ),
      companycity: new FormControl(
        {
          value: null,
          disabled: !this.editTriggered,
        },
        [Validators.required]
      ),
      companyzip: new FormControl(
        {
          value: null,
          disabled: !this.editTriggered,
        },
        [Validators.required, Validators.minLength(5)]
      ),
      companycountry: new FormControl(
        {
          value: null,
          disabled: !this.editTriggered,
        },
        [Validators.required]
      ),
    });
    this.loadingGeneral = false;
    this.initAccountDetailsForm();
    this.loadingUnsubscribe = false;
    this.unsubscribeForm = new FormGroup({
      reason: new FormControl(null, [Validators.required]),
      feedback: new FormControl(null, [Validators.required]),
      subscribe: new FormControl(null, [Validators.required]),
    });
    this.companyService$.subscribe((event: OperationResult) => {
      if (event.status === UserHelperServiceEnum.EDITED) {
        this.triggerEdit();
        this._snackBar.showSuccess('Account successfully edited!', 'OK');
      }
      if (event.status === UserHelperServiceEnum.ERROR) {
        let error = event.data;
        if (error.error.message instanceof Array) {
          this.error = error.error.message;
        } else {
          const nl = [];
          nl.push(error.error.message);
          this.error = nl;
        }
        this._snackBar.showError('Account failed to edit.');
      }
    });
    this.userService$.subscribe((event: OperationResult) => {
      if (event.status === UserHelperServiceEnum.LOADED) {
        const response: User = event.data.user;
        this.userCompany = response.company;
        for (const key of Object.keys(response)) {
          const formControl = this.accountDetailsForm.get(key);
          if (key.startsWith('company')) {
            for (const companykey of Object.keys(response.company)) {
              const searchKey = key + companykey;
              const formControl = this.accountDetailsForm.get(searchKey);
              if (formControl) {
                formControl.setValue(response.company[companykey]);
              }
            }
          } else {
            if (formControl) {
              formControl.setValue(response[key]);
            }
          }
        }
        const selectedCountryCode = response.company.country;
        this.cities = citiesMap.get(selectedCountryCode) ?? [];
      }
      if (event.status === UserHelperServiceEnum.EDITED) {
        let companyData = new Company();
        const formValue = this.accountDetailsForm.value;
        ////////////////////////////////////////////////
        companyData._id = this.userCompany._id;
        companyData.zip = formValue.companyzip;
        companyData.name = this.userCompany.name;
        companyData.type = this.userCompany.type;
        companyData.city = formValue.companycity;
        companyData.phone = formValue.companyphone;
        companyData.country = formValue.companycountry;
        companyData.address = formValue.companyaddress;
        this.companyHelper.updateCompany(this.companyService$, companyData);
      }
    });
  }

  initAccountDetailsForm(): void {
    this.userHelper.getUserById(this.userService$, this.auth.getUserId());
  }

  onCountryChange(form: FormGroup): void {
    setTimeout(() => {
      const selectedCountryCode = form.value.companycountry;
      this.cities = citiesMap.get(selectedCountryCode) ?? [];
      form.get('companycity').reset();
    }, 100);
  }

  editUserDetails(form: FormGroup): void {
    if (!this.editTriggered) {
      this._snackBar.showError('Enable the edit mode before saving');
      return;
    }
    if (!this.accountDetailsForm.valid) {
      this._snackBar.showError(
        'Invalid details in the form, fix all the incorrect details'
      );
      return;
    }
    if (!this.accountDetailsForm.touched) {
      this._snackBar.showError('Modify some details before saving the data');
      return;
    }
    const formValue = form.value;
    const user: User = new User();
    user.lastName = formValue.lastName;
    user.firstName = formValue.firstName;
    this.userHelper.updateUser(this.userService$, user);
  }
  triggerEdit(): void {
    for (const controlName of Object.keys(this.accountDetailsForm.controls)) {
      let control = this.accountDetailsForm.get(controlName);
      let valid = control.validator;
      if (valid && valid({} as AbstractControl)?.required) {
        control[!this.editTriggered ? 'enable' : 'disable']();
      }
    }
    this.editTriggered = !this.editTriggered;
  }
  clickUnsubscribe(): void {
    this.isVisible = false;
  }
  clickDetails(): void {
    this.isVisible = true;
  }
  ngOnDestroy(): void {
    if (this.userService$) {
      this.userService$.unsubscribe();
    }
    if (this.companyService$) {
      this.companyService$.unsubscribe();
    }
  }
  // TODO: Implement logic for changing user password (with confirmation for existing password or reset link)
  editUserPassword(form: FormGroup): void {}
  // TODO: Implemet logic for unsubscribe
  unsubscribe(form: FormGroup): void {}
}
