import { Component, Input, OnInit, Output, Optional, ViewChild, EventEmitter, Type} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject,Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';


//TODO: INCLUDE MULTIPLE OPTION
@Component({
    selector: 'app-dropdown-search',
    templateUrl: './dropdown-search.component.html',
    styleUrls: ['./dropdown-search.component.css'],
  })
export class DropdownSearchComponent implements OnInit {
  @Input() options: any[];
  @Input() label!: string;
  @Input() _formControl: FormControl;
  @Input() formFieldClass: Type<any>;

  @Input() @Optional() multipleOption: Boolean = false;
  @Output() onSelectionChangeEvent = new EventEmitter();
  @Output() @Optional() onMouseOverEvent = new EventEmitter();
  @Output() @Optional() onMouseOutEvent = new EventEmitter();
  @Output() @Optional() onMouseClickEvent = new EventEmitter();

  @ViewChild('matSelectRef') matSelectRef;
  dropDownOptions:DropdownData[] = [];
  filteredOptions!: Observable<any>;
  selectedOptions = [];
  private searchString:string = '';
  private searchString$:Subject<String> = new Subject();

  constructor(){

  }

  ngOnInit() {
  }

  ngOnChanges(){
    if(this.options.length > 0){
      this.dropDownOptions = this.createDropdownData(this.options);
    }

    this.filteredOptions = this.searchString$.pipe(
      startWith(''),
      map((value) => this.filter(value || '', this.dropDownOptions)),
      map((filtered) => Array.from(new Set(filtered.concat(this.selectedOptions)))),
    );

  }

  onSelectionChange(event){
    this.onSelectionChangeEvent.emit(event);
    if(this.multipleOption === true){
      this.selectedOptions = event.value.map((value) => this.dropDownOptions.find((x) => x.id === value))
    }
  }

  onMouseOver(event: Event){
    this.onMouseOverEvent.emit(event)
  }

  onMouseOut(event: Event){
    this.onMouseOutEvent.emit(event)
  }

  onMouseClick(event: Event){
    this.onMouseClickEvent.emit(event)
  }

  onKeyPressed(event: KeyboardEvent):void{
    const key:String = event.key;
    switch(key){
      case 'Backspace':{
        this.searchString = this.searchString = ''
        this.searchString$.next(this.searchString);
        break;
      }
      default:{
        if(key.length === 1 || key === ' '){
          this.searchString += key;
          this.searchString$.next(this.searchString);
        }
      }
    }
  }

  clearSearchInput(){
    setTimeout(() => {
      this.searchString = ''
      this.searchString$.next(this.searchString);
    }, 0);
  }

  createDropdownData(data: any[]): DropdownData[] {
    let dropdownData: DropdownData[] = [];
    try {
      if (data[0].hasOwnProperty('name') && data[0].hasOwnProperty('id')) {
        dropdownData = data.map((option) => ({
          name: option.name,
          id: option.id,
          object: option,
        }));
      } else {
        if (data[0].hasOwnProperty('partDetails3D')) {
          if (data[0].partDetails3D !== undefined && data[0].partDetails3D)
            dropdownData = data.map((option) => ({
              name: option.partDetails3D.params.name,
              id: option.id,
              object: option,
            }));
        }
      }
    } catch (err) {
      console.log(err);
    }
    dropdownData = dropdownData.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    return dropdownData;
  }

  filter(value: any, data: DropdownData[]): DropdownData[] {
    try {
      if (typeof value === 'number' || data.some((item) => item.id === value)) {
        value = data.find((item) => item.id === value).name;
      }

      if (typeof value === 'string') {
        return data.filter((option) =>
          option.name.toLowerCase().includes(value.toLowerCase())
        );
      } else {
        console.log(`${value} is not a string`);
        return [];
      }
    } catch (err) {
      console.log(err);
    }
  }

  ngOnDestroy(){
  }

}

interface DropdownData {
  name: string;
  id: number;
  object: object;
}
