<div class="spinner-loading">
  <app-spinner [loading]="loading"></app-spinner>
</div>
<div class="container" [hidden]="loading">
  <div class="head-container">
    <div class="content-title">
      <h2>{{ isTemplate ? "Templates" : "Projects" }}</h2>
    </div>
    <!--
    <button
      mat-mini-fab
      class="new-project-button"
      (click)="createNewProject()"
    >
      +
    </button>
    <div>
      <mat-form-field
        *ngFor="let filter of filterSelectObj"
        class="account-details-half-input"
        appearance="outline"
        style="margin-left: 15px"
      >
        <mat-label>Filter {{ filter.name }}</mat-label>
        <select
          matNativeControl
          name="{{ filter.columnProp }}"
          [(ngModel)]="filter.modelValue"
          (change)="filterChange(filter, $event)"
        >
          <option value="">Select {{ filter.name }}</option>
          <option [value]="item" *ngFor="let item of filter.options">
            {{ item }}
          </option>
        </select>
      </mat-form-field>
    </div>
    -->
    <mat-form-field appearance="outline">
      <mat-label>Search project</mat-label>
      <input
        #Input
        matInput
        class="input-content"
        type="text"
        (input)="searchProject($event)"
      />
    </mat-form-field>
  </div>
  <div class="parent-grid" [hidden]="loading">
    <div class="content-grid">
      <div *ngFor="let cardDetails of filterCardsDetails">
        <app-generic-card
          [cardDetails]="cardDetails"
          (editProjectClicked)="editProject($event)"
          (deleteProjectClicked)="deleteProject($event)"
          (downloadProjectClicked)="downloadProject($event)"
          (sendQuotationRequestClicked)="sendQuotationRequest($event)"
          (viewQuotationDetailsClicked)="viewQuotationDetails($event)"
        >
        </app-generic-card>
      </div>
    </div>
  </div>
  <app-notify-download></app-notify-download>
</div>
