import {environment} from '../../../environments/environment';

// Project related routes
export const routeMapPrototype: Map<string, string> = new Map();
// routeMapPrototype = new Map();
routeMapPrototype.set(environment.routes.projects, 'Projects');
routeMapPrototype.set(environment.routes.templates, 'Templates');
// routeMapPrototype.set(environment.routes., 'Create new silo design');
// routeMapPrototype.set(environment.routes.newTemplate, 'Create new silo design template');
routeMapPrototype.set(environment.routes.flowDiagram, 'Flow diagram');

// RFPs related routes
// routeMapPrototype.set(environment.routes.rfps, 'Quotation request');
// routeMapPrototype.set(environment.routes.submitRfp, 'New quotation request');

// Account related routes
routeMapPrototype.set(environment.routes.account, 'My account');

// Contact related routes
routeMapPrototype.set(environment.routes.contact, 'Contact');
routeMapPrototype.set(environment.routes.consultingRequest, 'Consulting request');

// Help request routes
routeMapPrototype.set(environment.routes.help, 'Support');
