import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApiService } from '../shared/services/api.service';
import { AuthService } from '../shared/services/auth.service';
import { GenericSnackBarService } from '../shared/services/generic-snack-bar.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  userMessage: string;
  contactForm: FormGroup;

  private routes = environment.routes;
  private endpoints = environment.endpoint;

  constructor(
    private router: Router,
    private http: HttpClient,
    private api: ApiService,
    private auth: AuthService,
    private snack: GenericSnackBarService
  ) {
    this.api.http = this.http;
  }
  ngOnInit(): void {
    this.contactForm = new FormGroup({
      message: new FormControl(null, [Validators.required]),
    });
  }
  submit(): void {
    const message = {
      firstName: this.auth.getFirstName(),
      lastName: this.auth.getLastName(),
      _id: this.auth.getUserId(),
      message: this.contactForm.value.message,
      type:"contact"
    };
    this.api.post(this.endpoints.contactUs, message).subscribe(
      () => {
        this.snack.showSuccess('Thank you for your message. Our advisors will get in touch ASAP.');
        this.router.navigate([this.routes.projects]);
    },
      (err) => {
        this.snack.showError(err.message);
        console.log(err);
      }
    );
  }
}
