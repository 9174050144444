import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {environment} from '../../../environments/environment';

@Injectable()
export class ApiService {
  private _baseUrl = environment.endpoint.baseUrl;
  constructor(private auth: AuthService) {
  }
  private _http: HttpClient;
  get http(): HttpClient {
    return this._http;
  }
  set http(http: HttpClient) {
    this._http = http;
  }
  _buildHeaders(optionalHeaders?: object): HttpHeaders {
    let headers = new HttpHeaders();
    const token = this.auth.getToken();
    if (token) {
      headers = headers.append('Authorization', `Bearer ${token}`);
    }
    if (optionalHeaders) {
      for (const key of Object.keys(optionalHeaders)) {
        headers = headers.append(key, optionalHeaders[key]);
      }
    }
    if (!headers.has('Content-Type')) {
      headers = headers.append('Content-Type', 'application/json');
    }
    return headers;
  }

  get<T>(endpoint: string, parameters?: object, optionalHeaders?: object, responseType?: any): Observable<T> {
    const headers = this._buildHeaders(optionalHeaders);
    let params = new HttpParams();
    if (parameters) {
      for (const key of Object.keys(parameters)) {
        params = params.append(key, parameters[key]);
      }
    }
    if (!responseType) {
      responseType = 'json';
    }
    return this.http.get<T>(endpoint, {headers, params, responseType})
      .pipe(map((response: any) => response as T));
  }

  getById<T>(endpoint: string, id: number | string, parameters?: any, optionalHeaders?: object, responseType?: any): Observable<T> {
    const resourceId = typeof id === 'number' ? id.toString(0) : id;
    endpoint = endpoint.replace(':id', resourceId)
    const headers = this._buildHeaders(optionalHeaders);
    let params = new HttpParams();
    if (parameters) {
      for (const key of Object.keys(parameters)) {
        params = params.append(key, parameters[key]);
      }
    }
    if (!responseType) {
      responseType = 'json';
    }
    return this.http.get<T>(endpoint, {headers, params, responseType})
      .pipe(map((response: any) => response as T));
  }

  post<T, U>(endpoint: string, data: T, parameters?: any, optionalHeaders?: object, responseType?: any): Observable<U> {
    const headers = this._buildHeaders(optionalHeaders);
    let params = new HttpParams();
    if (parameters) {
      for (const key of Object.keys(parameters)) {
        params = params.append(key, parameters[key]);
      }
    }
    if (!responseType) {
      responseType = 'json';
    }
    return this.http.post<T>(endpoint, JSON.stringify(data), { headers, params, responseType })
      .pipe(map((response: any) => response as U));
  }

  put<T>(endpoint: string, id: number | string, data: T, parameters?: any, optionalHeaders?: object, responseType?: any): Observable<T> {
    const resourceId = typeof id === 'number' ? id.toString(0) : id;
    endpoint = endpoint.replace(':id', resourceId)
    const headers = this._buildHeaders(optionalHeaders);
    let params = new HttpParams();
    if (parameters) {
      for (const key of Object.keys(parameters)) {
        params = params.append(key, parameters[key]);
      }
    }
    if (!responseType) {
      responseType = 'json';
    }
    return this.http.put<T>(endpoint, JSON.stringify(data), {headers, params, responseType})
      .pipe(map((response: any) => response as T));
  }

  delete<T>(endpoint: string, id: number | string, parameters?: any, optionalHeaders?: object, responseType?: any): Observable<T> {
    const resourceId = typeof id === 'number' ? id.toString(0) : id;
    endpoint = endpoint.replace(':id', resourceId)
    const headers = this._buildHeaders(optionalHeaders);
    let params = new HttpParams();
    if (parameters) {
      for (const key of Object.keys(parameters)) {
        params = params.append(key, parameters[key]);
      }
    }
    if (!responseType) {
      responseType = 'json';
    }
    return this.http.delete<T>(endpoint, {headers, params, responseType})
      .pipe(map((response: any) => response as T));
  }
}

