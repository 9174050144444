<div class="container" *ngIf="!loading">
  <div class="box-content">
    <!-- Logo -->
    <img class="image" [src]="'./assets/siloapp_logo.svg'" alt="" />
    <!-- Message -->
    <div>
      <p class="general-text">Enter your credentials below</p>
    </div>
    <!-- Activate form -->
    <form
      class="activate-form"
      [formGroup]="activateForm"
      (ngSubmit)="onSubmit(activateForm)"
    >
      <mat-form-field class="activate-input" appearance="outline">
        <mat-label class="form-label">Email</mat-label>
        <input matInput formControlName="username" [disabled]="true" />
      </mat-form-field>
      <mat-form-field class="activate-input" appearance="outline">
        <mat-label class="form-label">Code</mat-label>
        <input matInput type="code" formControlName="code" />
      </mat-form-field>
      <button
        mat-button
        type="submit"
        class="button-default-dimension button-access"
      >
        Activate
      </button>
    </form>
    <div
      *ngIf="error && error.length > 0"
      style="display: flex; flex-direction: column; gap: 4px; margin-top: 1rem"
    >
      <label *ngFor="let item of error" style="color: red; text-align: center">
        {{ item }}
      </label>
    </div>
    <div>
      <p class="general-black-text">
        Don't have an account?
        <a class="general-text" href="" routerLink="/signup"> Sign up</a>
      </p>
    </div>
  </div>
</div>
<app-spinner [loading]="loading"></app-spinner>
