import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CompanyTypeEnum,
  PaymentAcceptedEnum,
  QuotationHelperServiceEnum,
  QuotationStatusEnum,
  QuotationTypeEnum,
  UserHelperServiceEnum,
  UserStatusEnum,
  UserTypeEnum,
} from 'src/app/shared/models/enums';
import { User } from 'src/app/shared/models/user';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { GenericSnackBarService } from 'src/app/shared/services/generic-snack-bar.service';
import { environment } from 'src/environments/environment';
import { GRAIN_TYPES } from '../../shared/constants/grain-types';
import { Quotation } from 'src/app/shared/models/quotation';
import { QuotationHelperService } from '../../shared/services/quotation-helper.service';
import { OperationResult } from '../../shared/models/helper-service-events';
import { Subject } from 'rxjs';
import { ProjectFilesService } from 'src/app/shared/services/projectfiles.service';
import { UserHelperService } from 'src/app/shared/services/user-helper.service';
import { Producer } from 'src/app/shared/models/producer';

@Component({
  selector: 'app-view-individual-quotation',
  templateUrl: './view-individual-quotation.component.html',
  styleUrls: ['./view-individual-quotation.component.scss'],
})
export class ViewIndividualQuotationComponent implements OnInit {
  quotation: Quotation;
  quotationId: string;
  activateRoute: ActivatedRoute;
  country: FormControl = new FormControl(
    { value: null, disabled: true },
    Validators.required
  );
  selectedProductTypes: FormControl = new FormControl(
    { value: null, disabled: true },
    Validators.required
  );
  selectedMainProductTypes: FormControl = new FormControl(
    { value: null, disabled: true },
    Validators.required
  );
  message: FormControl = new FormControl(
    { value: null, disabled: true },
    Validators.required
  );
  currentProjectName: string;
  //availableProducers: Producer[];
  availableProductTypes = GRAIN_TYPES;
  loading = true;
  showAcceptReject = false;
  allowEditEquipment = false;
  equipmentEdited = false;
  canSeeLayout = false;
  isSingleMachine = false;
  user: User;
  private routes = environment.routes;
  private quotationService$: Subject<OperationResult> =
    new Subject<OperationResult>();
  private userService$: Subject<OperationResult> =
    new Subject<OperationResult>();
  constructor(
    private router: Router,
    private http: HttpClient,
    private api: ApiService,
    private auth: AuthService,
    private projectFiles: ProjectFilesService,
    private activatedRoute: ActivatedRoute,
    private snackBar: GenericSnackBarService,
    private userHelper: UserHelperService,
    private quotationHelper: QuotationHelperService
  ) {
    this.api.http = this.http;
  }
  ngOnInit(): void {
    setTimeout(this.initTimedOutFunction.bind(this), 0);
  }
  initTimedOutFunction(): void {
    this.userService$.subscribe((event: OperationResult) => {
      if (event.status === UserHelperServiceEnum.LOADED) {
        if (event.data.hasOwnProperty('users')) {
          let producers: Producer[] = event.data.users;
          producers = producers.filter(
            (x: Producer) => x._id === this.auth.getUserId()
          );
          this.canSeeLayout =
            this.quotation.paymentAccepted === PaymentAcceptedEnum.ACCEPTED &&
            producers.length > 0;
        }
        if (event.data.hasOwnProperty('user')) {
          this.user = event.data.user;
        }
        this.loading = false;
      }
      if (event.status === UserHelperServiceEnum.ERROR) {
        this.loading = false;
      }
    });
    this.quotationService$.subscribe((event: OperationResult) => {
      if (event.status === QuotationHelperServiceEnum.OPEN_PROJECT) {
        this.loading = false;
        this.equipmentEdited = false;
        this.snackBar.showSuccess(
          "Quotation '" + this.quotation._id + "' have been saved."
        );
      }
      if (event.status === QuotationHelperServiceEnum.LOADED) {
        this.showAcceptReject =
          event.data.quotation.status === QuotationStatusEnum.PENDING;
        if (this.auth.getUserId() === event.data.quotation.userId) {
          this.showAcceptReject = false;
        }
        this.quotation = event.data.quotation;
        this.isSingleMachine =
          this.quotation.quotationType === QuotationTypeEnum.SINGLE_MACHINES;
        this.allowEditEquipment =
          event.data.quotation.status === QuotationStatusEnum.ACCEPTED ||
          event.data.quotation.status === QuotationStatusEnum.REJECTED;
        this.country.setValue(this.quotation.country);
        this.message.setValue(this.quotation.message);
        const mainProductTypeSelected = GRAIN_TYPES.find(
          (x) => x.name === this.quotation.mainProductType
        );
        if (mainProductTypeSelected) {
          this.selectedMainProductTypes.setValue(mainProductTypeSelected.id);
        }
        const otherProductTypesSelected = [];
        if (this.quotation.hasOwnProperty('otherProductTypes')) {
          for (let i = 0; i < this.quotation.otherProductTypes.length; i++) {
            otherProductTypesSelected.push(
              GRAIN_TYPES.find(
                (x) => x.name === this.quotation.otherProductTypes[i]
              ).id
            );
          }
        }
        this.selectedProductTypes.setValue(otherProductTypesSelected);
        this.userHelper.getProducers(this.userService$, {
          status: UserStatusEnum.ACTIVE,
        });
      }
      if (event.status === QuotationHelperServiceEnum.ERROR) {
        this.loading = false;
      }
    });
    this.loading = true;
    this.activatedRoute.params.subscribe((params) => {
      this.quotationId = params.id;
      this.quotationHelper.getQuotation(
        this.quotationService$,
        this.quotationId
      );
    });
  }
  save(): void {
    this.loading = true;
    this.quotationHelper.editQuotation(this.quotationService$, this.quotation);
  }
  acceptRfp(): void {
    this.quotation.status = QuotationStatusEnum.ACCEPTED;
    this.quotationHelper.editQuotation(this.quotationService$, this.quotation);
    this.quotationHelper.acceptQuotationById(this.quotation._id);
  }
  rejectRfp(): void {
    this.quotation.status = QuotationStatusEnum.REJECTED;
    this.quotationHelper.editQuotation(this.quotationService$, this.quotation);
    this.quotationHelper.rejectQuotationById(this.quotation._id);
  }
  download(): void {
    this.quotationHelper.download(this.quotation);
  }
  deleteEquipment(event: number): void {
    if (event) {
      this.quotation.equipments.forEach((x, index) => {
        if (x.id === event) {
          this.quotation.equipments.splice(index, 1);
        }
      });
    }
  }
  changedEquipment(event): void {
    this.equipmentEdited = event;
  }
  viewLayout(): void {
    this.router.navigate([this.routes.projects, this.quotation.projectId], {
      queryParams: {
        view: 'quotation',
        from: this.quotation._id,
      },
    });
  }
  back(): void {
    if (this.equipmentEdited) {
      this.loading = true;
      this.quotationHelper.editQuotation(
        this.quotationService$,
        this.quotation
      );
    }
    this.router.navigate([this.routes.quotations]);
  }
}
