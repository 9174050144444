<div class="head-container" [hidden]="clickedPaymentRequired">
  <button
    mat-button
    (click)="viewSiloOffers()"
    *ngIf="!clickedPaymentRequired"
    [ngClass]="!isSingleMachine ? 'button-selected' : 'button-deselected'"
  >
    Silo Offers
  </button>
  <button
    mat-button
    (click)="viewPersonalSingleEquipmentQuotations()"
    *ngIf="!clickedPaymentRequired"
    [ngClass]="isSingleMachine ? 'button-selected' : 'button-deselected'"
  >
    My Single Machines
  </button>
</div>
<div class="container" [hidden]="clickedPaymentRequired">
  <div class="rfp-table-container">
    <div class="rfp-table-header">
      <mat-panel-title>
        <h1>{{ !isSingleMachine ? "Silo Offers" : "My Single Machines" }}</h1>
      </mat-panel-title>
      <div class="rfp-filter-options">
        <mat-form-field
          appearance="outline"
          class="first-input account-details-half-input"
        >
          <mat-label>Status</mat-label>
          <mat-select [formControl]="statuses" (selectionChange)="filterData()">
            <mat-option value="">Select Status</mat-option>
            <mat-option *ngFor="let status of filterSelect" [value]="status">
              {{ status }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="first-input account-details-half-input"
        >
          <mat-label>Country</mat-label>
          <mat-select [formControl]="country" (selectionChange)="filterData()">
            <mat-option value="">Select Country</mat-option>
            <mat-option *ngFor="let country of filterCountry" [value]="country">
              {{ country }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="first-input account-details-half-input"
        >
          <mat-label>Producer</mat-label>
          <mat-select
            [formControl]="producers"
            (selectionChange)="filterData()"
          >
            <mat-option value="">Select Producer</mat-option>
            <mat-option *ngFor="let prod of filterProducer" [value]="prod">
              {{ prod }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          *ngIf="!isSingleMachine"
          class="first-input account-details-half-input"
        >
          <mat-label>Number of bins</mat-label>
          <mat-select
            [formControl]="numberofbins"
            (selectionChange)="filterData()"
          >
            <mat-option value="">Select Number of bins</mat-option>
            <mat-option *ngFor="let bins of filterNumberOfBins" [value]="bins">
              {{ isEmptyReturnZero(bins) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="first-input account-details-half-input"
        >
          <mat-label>Total storage capacity</mat-label>
          <mat-select
            [formControl]="transfers"
            (selectionChange)="filterData()"
          >
            <mat-option value="">Select Total storage capacity</mat-option>
            <mat-option
              *ngFor="let transfer of filterTotalCapacity"
              [value]="transfer"
            >
              {{ isEmptyReturnZero(transfer) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          *ngIf="!isSingleMachine"
          class="first-input account-details-half-input"
        >
          <mat-label>Drying capacity</mat-label>
          <mat-select [formControl]="dryings" (selectionChange)="filterData()">
            <mat-option value="">Select Drying capacity</mat-option>
            <mat-option *ngFor="let dry of filterDryingCapacity" [value]="dry">
              {{ isEmptyReturnZero(dry) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          *ngIf="!isSingleMachine"
          class="first-input account-details-half-input"
        >
          <mat-label>Transfer capacity</mat-label>
          <mat-select
            [formControl]="captransfers"
            (selectionChange)="filterData()"
          >
            <mat-option value="">Select Transfer capacity</mat-option>
            <mat-option
              *ngFor="let stat of filterTransferCapacity"
              [value]="stat"
            >
              {{ isEmptyReturnZero(stat) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="first-input account-details-half-input"
        >
          <mat-label>Main Product</mat-label>
          <mat-select [formControl]="products" (selectionChange)="filterData()">
            <mat-option value="">Select Main Product</mat-option>
            <mat-option *ngFor="let prod of filterMainProduct" [value]="prod">
              {{ prod }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="mat-elevation-z8">
      <mat-table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="projectId">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="id-column">
            <strong>ID</strong>
          </mat-header-cell>
          <mat-cell
            class="capitalize-input underline-input id-column"
            *matCellDef="let element"
            (click)="getQuotationId(element)"
          >
            {{ element._id }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            ><strong>Status</strong></mat-header-cell
          >
          <mat-cell *matCellDef="let element" class="capitalize-input">
            {{ element.status }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="country">
          <mat-header-cell
            style="display: flex; justify-content: center"
            class="table-header"
            *matHeaderCellDef
            mat-sort-header
            ><strong style="text-align: center"
              >Country</strong
            ></mat-header-cell
          >
          <mat-cell *matCellDef="let element" class="capitalize-input">
            {{ isEmpty(element.country) ? "N/A" : element.country }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="mainElevatorCapacity">
          <mat-header-cell
            class="table-header"
            *matHeaderCellDef
            mat-sort-header
            ><strong *ngIf="quotationType === siloPlantQuotation"
              >Main elevator capacity (t/h)</strong
            ></mat-header-cell
          >
          <mat-cell *matCellDef="let element">
            {{ isEmptyReturnZero(element.mainElevatorCapacity) }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="handlingCapacity">
          <mat-header-cell
            class="table-header"
            *matHeaderCellDef
            mat-sort-header
            ><strong *ngIf="quotationType === siloPlantQuotation"
              >Handling capacity (t/h)</strong
            ></mat-header-cell
          >
          <mat-cell *matCellDef="let element">
            {{ isEmptyReturnZero(element.handlingCapacity) }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="storageCapacity">
          <mat-header-cell
            class="table-header"
            *matHeaderCellDef
            mat-sort-header
            ><strong *ngIf="quotationType === siloPlantQuotation"
              >Total storage capacity (m^3)</strong
            >
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{{
            isEmptyReturnZero(element.storageCapacity)
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="dryingCapacity">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            ><strong *ngIf="quotationType === siloPlantQuotation"
              >Drying capacity (t/h)</strong
            ></mat-header-cell
          >
          <mat-cell *matCellDef="let element">
            {{ isEmptyReturnZero(element.dryingCapacity) }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="minimumCapacity">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            ><strong>Minimum capacity (m^3)</strong>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{{
            isEmptyReturnZero(element.minHandlingCapacity)
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="maximumCapacity">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            ><strong>Maximum capacity (m^3)</strong>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{{
            isEmptyReturnZero(element.maxHandlingCapacity)
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="mainProductType">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            ><strong>Main product</strong></mat-header-cell
          >
          <mat-cell *matCellDef="let element">
            {{ element.mainProductType }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="producerCompany">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            ><strong *ngIf="showProducer">Producer Company</strong>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <p *ngIf="showProducer">
              {{
                isEmpty(element.producerCompany)
                  ? "N/A"
                  : element.producerCompany
              }}
            </p>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="userCompany">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            ><strong *ngIf="!showProducer">Company</strong>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <p *ngIf="!showProducer">
              {{ isEmpty(element.userCompany) ? "N/A" : element.userCompany }}
            </p>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sendReminder">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            ><strong *ngIf="showReminder">Send reminder</strong>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button
              mat-button
              class="white-button reminder"
              [disabled]="element.status !== quotationStatusEnum.PENDING"
              *ngIf="showReminder"
              (click)="quotationHelper.sendReminder(element._id)"
            >
              Send Reminder
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="tableColumns()"></mat-header-row>
        <mat-row *matRowDef="let row; columns: tableColumns()"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
  </div>
</div>
<div class="spinner-loading">
  <app-spinner [loading]="loading"></app-spinner>
</div>
<div>
  <app-payment-approval
    [hidden]="!clickedPaymentRequired"
    (buttonClicked)="acceptedPayment($event)"
  >
  </app-payment-approval>
</div>
