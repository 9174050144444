import { Injectable } from '@angular/core';
import * as THREE from 'three';
import { OverlappingEquipmentsPair, Part3DNode } from '../models/part';
import { PartTypeEnum } from '../models/enums';

@Injectable({
  providedIn: 'root',
})
export class CollisionsService {
  constructor() {}

  checkPairOverlap(
    equipment1: Part3DNode,
    equipment2: Part3DNode,
    scene: THREE.Scene
  ): OverlappingEquipmentsPair {
    const overlappingPair: OverlappingEquipmentsPair = {
      firstEquipment: equipment1,
      secondEquipment: equipment2,
      axis: [],
    };
    const box1 = scene.children.find(
      (c) =>
        c.type === 'Box3Helper' &&
        c.name === equipment1.partDetails3D.params.name
    );
    const box2 = scene.children.find(
      (c) =>
        c.type === 'Box3Helper' &&
        c.name === equipment2.partDetails3D.params.name
    );

    let collision;
    if (box1 !== undefined && box2 !== undefined) {
      collision = box1.box.intersectsBox(box2.box);
    } else {
      console.log(
        'One of the equipments does not have a boxHelper added to the scene when collision checked.'
      );
    }

    if (collision) {
      const box1Center = box1.box.getCenter();

      const xGeometry = new THREE.PlaneGeometry(
        box1.box.max.z - box1.box.min.z,
        box1.box.max.y - box1.box.min.y
      );
      const zGeometry = new THREE.PlaneGeometry(
        box1.box.max.x - box1.box.min.x,
        box1.box.max.y - box1.box.min.y
      );

      const material = new THREE.MeshBasicMaterial({
        color: 0xff0000,
        side: THREE.DoubleSide,
      });
      const xPlaneMax = new THREE.Mesh(xGeometry, material);
      xPlaneMax.rotation.y = Math.PI / 2;
      const xPlaneMin = xPlaneMax.clone();
      xPlaneMax.position.set(box1.box.max.x, box1Center.y, box1Center.z);
      xPlaneMin.position.set(box1.box.min.x, box1Center.y, box1Center.z);

      const zPlaneMax = new THREE.Mesh(zGeometry, material);
      const zPlaneMin = zPlaneMax.clone();
      zPlaneMax.position.set(box1Center.x, box1Center.y, box1.box.max.z);
      zPlaneMin.position.set(box1Center.x, box1Center.y, box1.box.min.z);

      const boxMaxX = new THREE.Box3().setFromObject(xPlaneMax);
      const boxMinX = new THREE.Box3().setFromObject(xPlaneMin);
      const boxMaxZ = new THREE.Box3().setFromObject(zPlaneMax);
      const boxMinZ = new THREE.Box3().setFromObject(zPlaneMin);

      if (box2.box.intersectsBox(boxMaxX)) {
        // console.log(`${equipment1.partDetails3D.params.name} external X collision with ${equipment2.partDetails3D.params.name}`);
        overlappingPair.axis.push('MAX_X_SIDE');
      }
      if (box2.box.intersectsBox(boxMinX)) {
        // console.log(`${equipment1.partDetails3D.params.name} internal X collision with ${equipment2.partDetails3D.params.name}`);
        overlappingPair.axis.push('MIN_X_SIDE');
      }
      if (box2.box.intersectsBox(boxMaxZ)) {
        // console.log(`${equipment1.partDetails3D.params.name} external Z collision with ${equipment2.partDetails3D.params.name}`);
        overlappingPair.axis.push('MAX_Z_SIDE');
      }
      if (box2.box.intersectsBox(boxMinZ)) {
        // console.log(`${equipment1.partDetails3D.params.name} internal Z collision with ${equipment2.partDetails3D.params.name}`);
        overlappingPair.axis.push('MIN_Z_SIDE');
      }
      // scene.add(xPlaneMax, xPlaneMin, zPlaneMax, zPlaneMin);
    }

    return collision ? overlappingPair : null;
  }

  checkColisions(nodes, collisions, scene): OverlappingEquipmentsPair[] {
    /**
     * Return an array of pairs that are colliding + the axis on which the collide
     */
    const overlappingList = [];
    for (let i = 0; i < nodes.length - 2; i++) {
      for (let j = i + 1; j <= nodes.length - 1; j++) {
        const overlappingPair = this.checkPairOverlap(
          nodes[i],
          nodes[j],
          scene
        );
        if (overlappingPair !== null) {
          overlappingList.push(overlappingPair);
        }
      }
    }

    const solvedColisions = collisions.filter((colision) => {
      return !overlappingList.some((overlap) => {
        return (
          (colision.firstEquipment.id === overlap.firstEquipment.id &&
            colision.secondEquipment.id === overlap.secondEquipment.id) ||
          (colision.firstEquipment.id === overlap.secondEquipment.id &&
            colision.secondEquipment.id === overlap.firstEquipment.id)
        );
      });
    });
    solvedColisions.forEach((col) => {
      const box1 = scene.children.find(
        (c) =>
          c.type === 'Box3Helper' &&
          c.name === col.firstEquipment.partDetails3D.params.name
      );
      const box2 = scene.children.find(
        (c) =>
          c.type === 'Box3Helper' &&
          c.name === col.secondEquipment.partDetails3D.params.name
      );
      box1.material.color.set(0x00ff00);
      box2.material.color.set(0x00ff00);
    });

    collisions = overlappingList;
    overlappingList.forEach((pair) => {
      const box1 = scene.children.find(
        (c) =>
          c.type === 'Box3Helper' &&
          c.name === pair.firstEquipment.partDetails3D.params.name
      );
      const box2 = scene.children.find(
        (c) =>
          c.type === 'Box3Helper' &&
          c.name === pair.secondEquipment.partDetails3D.params.name
      );
      box1.material.color.set(0xff0000);
      box2.material.color.set(0xff0000);
    });

    return collisions;
  }

  addEquipmentsBoxHelper(nodes, scene): void {
    //when move equipments dialog is displayed add bounding boxes to all equipments
    // scene.children.remove((c) => {
    //   return c.type === 'Box3Helper';
    // })
    nodes.forEach((node) => {
      this.addEquipmentBoxHelper(node, scene);
    });
  }

  addEquipmentBoxHelper(node: Part3DNode, scene: THREE.Scene): THREE.Box3 {
    const existingBB = scene.children.find(
      (c) =>
        c.type === 'Box3Helper' && c.name === node.partDetails3D.params.name
    );
    let color = 0x00ff00;
    if (existingBB) {
      scene.remove(existingBB);
    }
    node.part3D.part.position.copy(node.position);
    const box = new THREE.Box3().setFromObject(node.part3D.part);
    if (node.type === PartTypeEnum.INTAKE && node.tileName !== undefined) {
      box.min.y = node.part3D.part.position.y;
      box.max.y *= 2;
    }
    const boxHelper = new THREE.Box3Helper(box, color);
    boxHelper.name = node.partDetails3D.params.name;
    boxHelper.visible = false;

    scene.add(boxHelper);
  }

  updateBoundingBox(node: Part3DNode, scene: THREE.Scene): void {
    const boxHelper = scene.children.find(
      (c) =>
        c.type === 'Box3Helper' && c.name === node.partDetails3D.params.name
    );
    if (boxHelper) {
      node.part3D.part.position.copy(node.position);
      boxHelper.box.setFromObject(node.part3D.part);
      if (node.type === PartTypeEnum.INTAKE && node.tileName !== undefined) {
        boxHelper.box.min.y = node.part3D.part.position.y;
        boxHelper.box.max.y *= 2;
      }
      boxHelper.material.color.set(0x00ff00);
    }
  }
}
