import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { PartTypeEnum } from '../../../models/enums';
import { ProjectHelperService } from 'src/app/shared/services/project-helper.service';
import { Part3DNode } from 'src/app/shared/models/part';

@Component({
  selector: 'app-equipment-card',
  templateUrl: './equipment-card.component.html',
  styleUrls: ['./equipment-card.component.scss'],
})
export class EquipmentCardComponent implements OnInit {
  @Input() equipment: Part3DNode;
  @Input() editEquipmentCard: boolean;
  @Input() deleteEquipmentCard: boolean;
  @Input() cardExpanded: boolean;
  @Output() editEquipment = new EventEmitter();
  @Output() deleteEquipment = new EventEmitter();
  @Output() mouseOn = new EventEmitter();
  @Output() mouseOff = new EventEmitter();
  @Output() valueChangedEvent = new EventEmitter();
  @ViewChild('FirstEquipmentParameter', { static: false, read: ElementRef })
  FirstEquipmentParameter: ElementRef<HTMLInputElement>;
  @ViewChild('SecondEquipmentParameter', { static: false, read: ElementRef })
  SecondEquipmentParameter: ElementRef<HTMLInputElement>;
  partTypeEnum = PartTypeEnum;

  constructor(
    private renderer: Renderer2,
    private projectHelper: ProjectHelperService
  ) {}

  ngOnInit(): void {
    // Change params (dx, volume, capacity, moistureReduction) to two decimal places.
    // TODO: Add this feature to part creation!
    if (this.equipment.partDetails3D.params.hasOwnProperty('dx')) {
      this.equipment.partDetails3D.params.dx.x =
        Math.round(this.equipment.partDetails3D.params.dx.x * 100) / 100;
      this.equipment.partDetails3D.params.dx.y =
        Math.round(this.equipment.partDetails3D.params.dx.y * 100) / 100;
      this.equipment.partDetails3D.params.dx.z =
        Math.round(this.equipment.partDetails3D.params.dx.z * 100) / 100;
    }

    if (this.equipment.partDetails3D.params.hasOwnProperty('volume')) {
      this.equipment.partDetails3D.params['volume'] =
        Math.round(this.equipment.partDetails3D.params['volume'] * 100) / 100;
    }

    if (this.equipment.partDetails3D.params.hasOwnProperty('capacity')) {
      this.equipment.partDetails3D.params['capacity'] =
        Math.round(this.equipment.partDetails3D.params['capacity'] * 100) / 100;
    }

    if (
      this.equipment.partDetails3D.params.hasOwnProperty('moistureReduction')
    ) {
      this.equipment.partDetails3D.params['moistureReduction'] =
        Math.round(this.equipment.partDetails3D.params['capacity'] * 100) / 100;
    }
  }

  ngAfterViewInit() {
    this.disableUpDownArrows();
  }

  emitValueChange(): void {
    // Don't send an event if there are still empty inputs.
    if (this.FirstEquipmentParameter.nativeElement.value === '') {
      return;
    }
    if (
      this.FirstEquipmentParameter.nativeElement.value !== '' &&
      this.SecondEquipmentParameter.nativeElement &&
      this.SecondEquipmentParameter.nativeElement.value === ''
    ) {
      return;
    }

    const event = {
      id: this.equipment.id,
      type: this.equipment.type,
      area: this.equipment.area,
      firstParameterValue: undefined,
      secondParameterValue: undefined,
    };
    if (this.FirstEquipmentParameter != undefined) {
      event.firstParameterValue = parseFloat(
        this.FirstEquipmentParameter.nativeElement.value
      );
    }
    if (this.SecondEquipmentParameter != undefined) {
      event.secondParameterValue = parseFloat(
        this.SecondEquipmentParameter.nativeElement.value
      );
    }

    this.valueChangedEvent.emit(event);
  }

  editEquipmentEvent(equipment: Part3DNode): void {
    this.editEquipment.emit(equipment.id);
  }

  deleteEquipmentEvent(equipment: Part3DNode): void {
    this.deleteEquipment.emit(equipment.id);
  }

  mouseOverOn($event: any): void {
    let element = $event.target.textContent;
    if (element && element !== '') {
      try {
        element = element.split('.')[1].trim();
      } catch (err) {
        element = element.trim();
      }
      this.mouseOn.emit({ nodeName: element });
    }
  }

  mouseOverOff($event: any): void {
    let element = $event.target.textContent;
    if (element && element !== '') {
      try {
        element = element.split('.')[1].trim();
      } catch (err) {
        element = element.trim();
      }
      this.mouseOff.emit({ nodeName: element });
    }
  }

  isCardExpanded(equipment: Part3DNode) {
    return !this.projectHelper.isEquipmentValid(equipment);
  }

  disableUpDownArrows() {
    const numberInputs = document.querySelectorAll('input[type="number"]');
    numberInputs.forEach((input) => {
      this.renderer.setAttribute(input, 'onwheel', 'return false');
    });
  }
}
