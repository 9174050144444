import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../shared/services/api.service';
import { HttpClient } from '@angular/common/http';
import { CreateUserRequest, User } from '../shared/models/user';
import { environment } from '../../environments/environment';
import { UserStatusEnum, UserTypeEnum } from '../shared/models/enums';
import { GenericSnackBarService } from '../shared/services/generic-snack-bar.service';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit, AfterViewInit {
  error: any[];
  signupForm: FormGroup;
  showPassword: boolean = false;
  showRePassword: boolean = false;
  private endpoint = environment.endpoint;
  private routes = environment.routes;
  loading = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private api: ApiService,
    private snackBar: GenericSnackBarService,
    private cdr: ChangeDetectorRef,
    private auth: AuthService
  ) {
    this.api.http = this.http;
  }

  ngOnInit(): void {
    this.auth.redirect();
    this.signupForm = new FormGroup({
      firstName: new FormControl(null, [
        Validators.required,
        Validators.minLength(3),
      ]),
      lastName: new FormControl(null, [
        Validators.required,
        Validators.minLength(3),
      ]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      firstPassword: new FormControl(null, [
        Validators.required,
        Validators.minLength(8),
      ]),
      secondPassword: new FormControl(null, [
        Validators.required,
        Validators.minLength(8),
      ]),
      privacy: new FormControl(false, [Validators.required]),
    });
  }
  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
  async checkEmailExists(email: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.api
        .post<EmailRequestDTO, boolean>(this.endpoint.users + '/checkEmail', {
          email: email,
        })
        .subscribe(
          (result) => {
            resolve(result);
          },
          (error) => {
            if (error.error.message instanceof Array) {
              reject(error.error.message);
            } else {
              reject([error.error.message]);
            }
          }
        );
    });
  }
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
  toggleRePasswordVisibility() {
    this.showRePassword = !this.showRePassword;
  }
  sendData(data: CreateUserRequest): void {
    this.api.post<CreateUserRequest, User>(this.endpoint.users, data).subscribe(
      (r) => {
        this.snackBar.showSuccess(
          'Account have been created! Please verify your email address and click on the link we send you in order to activate your account.'
        );
        this.router.navigate([this.routes.signIn]).then(() => {
          this.loading = false;
        });
      },
      (e) => {
        this.loading = false;
        if (e.error.message instanceof Array) {
          this.error = e.error.message;
        } else {
          const nl = [];
          nl.push(e.error.message);
          this.error = nl;
        }
      }
    );
  }
  async registerUser(form: FormGroup): Promise<void> {
    try {
      let formValue = form.value;
      if (this.signupForm.invalid) {
        this.snackBar.showError(
          'Invalid form! Please fill all the required fields with the correct data, accept the T&C and privacy policy.'
        );
        return;
      }
      if (!formValue.privacy) {
        this.snackBar.showError('You must accept the T&C and privacy policy');
        return;
      }
      let firstPass = formValue.firstPassword.trim();
      let secondPass = formValue.secondPassword.trim();
      if (firstPass !== secondPass) {
        this.snackBar.showError('The passwords does not match');
        return;
      }
      this.loading = true;
      let exists = await this.checkEmailExists(formValue.email);
      if (exists) {
        this.loading = false;
        this.snackBar.showError(
          'User with this email address already exists in our database.'
        );
        return;
      }
      let data: CreateUserRequest = {
        email: formValue.email.toLowerCase(),
        lastName: formValue.lastName.trim(),
        firstName: formValue.firstName.trim(),
        password: firstPass,
        secondPassword: secondPass,
        status: UserStatusEnum.PENDING,
        acceptTerms: formValue.privacy,
      };
      this.sendData(data);
    } catch (e) {
      this.loading = false;
      if (e.error.message instanceof Array) {
        this.error = e.error.message;
      } else {
        const nl = [];
        nl.push(e.error.message);
        this.error = nl;
      }
      return;
    }
  }
}
interface EmailRequestDTO {
  email: string;
}
