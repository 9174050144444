import {PartSubTypeEnum, PartTypeEnum} from 'src/app/shared/models/enums';
import { Part3DDetails, Part3DNode, Part3DParamsBase } from 'src/app/shared/models/part';


// conveyors
const rollerBelt = new Part3DNode(1, undefined, PartTypeEnum.BELT_CONVEYOR);
rollerBelt.partDetails3D = new Part3DDetails(new Part3DParamsBase());
rollerBelt.partDetails3D.params.name = 'Roller belt conveyor';
rollerBelt.partDetails3D.quantity = 1;
rollerBelt.partDetails3D.params.group = 1;

const screwConveyor = new Part3DNode(2, undefined, PartTypeEnum.CHAIN_CONVEYOR);
screwConveyor.partDetails3D = new Part3DDetails(new Part3DParamsBase());
screwConveyor.partDetails3D.params.name = 'Screw conveyor';
screwConveyor.partDetails3D.quantity = 1;
screwConveyor.partDetails3D.params.group = 1;

const chainConveyor = new Part3DNode(3, undefined, PartTypeEnum.CHAIN_CONVEYOR);
chainConveyor.partDetails3D = new Part3DDetails(new Part3DParamsBase());
chainConveyor.partDetails3D.params.name = 'Chain conveyor';
chainConveyor.partDetails3D.quantity = 1;
chainConveyor.partDetails3D.params.group = 1;

// cleaners
const drumSieve = new Part3DNode(4, undefined, PartTypeEnum.CLEANER);
drumSieve.partDetails3D = new Part3DDetails(new Part3DParamsBase());
drumSieve.partDetails3D.params.name = 'Drum sieve cleaner';
drumSieve.partDetails3D.quantity = 1;
drumSieve.subType = PartSubTypeEnum.DRUM_SIEVE;
drumSieve.partDetails3D.params.group = 2;

const airSifter = new Part3DNode(5, undefined, PartTypeEnum.CLEANER);
airSifter.partDetails3D = new Part3DDetails(new Part3DParamsBase());
airSifter.partDetails3D.params.name = 'Air sifter cleaner';
airSifter.partDetails3D.quantity = 1;
airSifter.subType = PartSubTypeEnum.AIR_SIFTER;
airSifter.partDetails3D.params.group = 2;

const universalCleaner = new Part3DNode(6, undefined, PartTypeEnum.CLEANER);
universalCleaner.partDetails3D = new Part3DDetails(new Part3DParamsBase());
universalCleaner.partDetails3D.params.name = 'Universal cleaner';
universalCleaner.partDetails3D.quantity = 1;
universalCleaner.partDetails3D.params.group = 2;

const processCleaner = new Part3DNode(7, undefined, PartTypeEnum.CLEANER);
processCleaner.partDetails3D = new Part3DDetails(new Part3DParamsBase());
processCleaner.partDetails3D.params.name = 'Process cleaner';
processCleaner.partDetails3D.quantity = 1;
processCleaner.partDetails3D.params.group = 2;

// elevators
const bucketElevator = new Part3DNode(8, undefined, PartTypeEnum.ELEVATOR);
bucketElevator.partDetails3D = new Part3DDetails(new Part3DParamsBase());
bucketElevator.partDetails3D.params.name = 'Bucket elevator';
bucketElevator.partDetails3D.quantity = 1;
bucketElevator.partDetails3D.params.group = 3;

const zTypeElevator = new Part3DNode(9, undefined, PartTypeEnum.ELEVATOR);
zTypeElevator.partDetails3D = new Part3DDetails(new Part3DParamsBase());
zTypeElevator.partDetails3D.params.name = 'Z-Type / Pendulum elevator';
zTypeElevator.partDetails3D.quantity = 1;
zTypeElevator.partDetails3D.params.group = 3;

// dryers
const continuousFlowWithoutAirRecirculationDryer = new Part3DNode(10, undefined, PartTypeEnum.DRYER);
continuousFlowWithoutAirRecirculationDryer.partDetails3D = new Part3DDetails(new Part3DParamsBase());
continuousFlowWithoutAirRecirculationDryer.partDetails3D.params.name = 'Continuous flow without air recirculation dryer';
continuousFlowWithoutAirRecirculationDryer.partDetails3D.quantity = 1;
continuousFlowWithoutAirRecirculationDryer.partDetails3D.params.group = 4;

const continuousFlowWithAirRecirculationDryer = new Part3DNode(11, undefined, PartTypeEnum.DRYER);
continuousFlowWithAirRecirculationDryer.partDetails3D = new Part3DDetails(new Part3DParamsBase());
continuousFlowWithAirRecirculationDryer.partDetails3D.params.name = 'Continuous flow with air recirculation dryer';
continuousFlowWithAirRecirculationDryer.partDetails3D.quantity = 1;
continuousFlowWithAirRecirculationDryer.partDetails3D.params.group = 4;

const batchDryer = new Part3DNode(12, undefined, PartTypeEnum.DRYER);
batchDryer.partDetails3D = new Part3DDetails(new Part3DParamsBase());
batchDryer.partDetails3D.params.name = 'Batch dryer';
batchDryer.partDetails3D.quantity = 1;
batchDryer.partDetails3D.params.group = 4;

// bins
const flatBottomBin = new Part3DNode(13, undefined, PartTypeEnum.SILO_BIN);
flatBottomBin.partDetails3D = new Part3DDetails(new Part3DParamsBase());
flatBottomBin.partDetails3D.params.name = 'Flat bottom bin';
flatBottomBin.partDetails3D.quantity = 1;
flatBottomBin.partDetails3D.params.group = 5;

const conicalBottomBin = new Part3DNode(14, undefined, PartTypeEnum.SILO_BIN);
conicalBottomBin.partDetails3D = new Part3DDetails(new Part3DParamsBase());
conicalBottomBin.partDetails3D.params.name = 'Conical bottom bin';
conicalBottomBin.partDetails3D.quantity = 1;
conicalBottomBin.partDetails3D.params.group = 5;

const bufferBin = new Part3DNode(15, undefined, PartTypeEnum.BUFFER_BIN);
bufferBin.partDetails3D = new Part3DDetails(new Part3DParamsBase());
bufferBin.partDetails3D.params.name = 'Buffer bin';
bufferBin.partDetails3D.quantity = 1;
bufferBin.partDetails3D.params.group = 5;

const farmBin = new Part3DNode(16, undefined, PartTypeEnum.SILO_BIN);
farmBin.partDetails3D = new Part3DDetails(new Part3DParamsBase());
farmBin.partDetails3D.params.name = 'Farm bin';
farmBin.partDetails3D.quantity = 1;
farmBin.partDetails3D.params.group = 5;

const squareBin = new Part3DNode(17, undefined, PartTypeEnum.SILO_BIN);
squareBin.partDetails3D = new Part3DDetails(new Part3DParamsBase());
squareBin.partDetails3D.params.name = 'Square bin';
squareBin.partDetails3D.quantity = 1;
squareBin.partDetails3D.params.group = 5;

// conditioning
const fineCleaner = new Part3DNode(18, undefined, PartTypeEnum.CONDITIONING);
fineCleaner.partDetails3D = new Part3DDetails(new Part3DParamsBase());
fineCleaner.partDetails3D.params.name = 'Fine cleaner';
fineCleaner.partDetails3D.quantity = 1;
fineCleaner.partDetails3D.params.group = 6;

const triour = new Part3DNode(19, undefined, PartTypeEnum.CONDITIONING);
triour.partDetails3D = new Part3DDetails(new Part3DParamsBase());
triour.partDetails3D.params.name = 'Triour';
triour.partDetails3D.quantity = 1;
triour.partDetails3D.params.group = 6;

const gravityTable = new Part3DNode(20, undefined, PartTypeEnum.CONDITIONING);
gravityTable.partDetails3D = new Part3DDetails(new Part3DParamsBase());
gravityTable.partDetails3D.params.name = 'Gravity table';
gravityTable.partDetails3D.quantity = 1;
gravityTable.partDetails3D.params.group = 6;

const treatingMachine = new Part3DNode(21, undefined, PartTypeEnum.CONDITIONING);
treatingMachine.partDetails3D = new Part3DDetails(new Part3DParamsBase());
treatingMachine.partDetails3D.params.name = 'Treating machine';
treatingMachine.partDetails3D.quantity = 1;
treatingMachine.partDetails3D.params.group = 6;

const coatingMachine = new Part3DNode(22, undefined, PartTypeEnum.CONDITIONING);
coatingMachine.partDetails3D = new Part3DDetails(new Part3DParamsBase());
coatingMachine.partDetails3D.params.name = 'Coating machine';
coatingMachine.partDetails3D.quantity = 1;
coatingMachine.partDetails3D.params.group = 6;

const magneticSeparator = new Part3DNode(23, undefined, PartTypeEnum.CONDITIONING);
magneticSeparator.partDetails3D = new Part3DDetails(new Part3DParamsBase());
magneticSeparator.partDetails3D.params.name = 'Magnetic separator';
magneticSeparator.partDetails3D.quantity = 1;
magneticSeparator.partDetails3D.params.group = 6;

const calibratingMachine = new Part3DNode(24, undefined, PartTypeEnum.CONDITIONING);
calibratingMachine.partDetails3D = new Part3DDetails(new Part3DParamsBase());
calibratingMachine.partDetails3D.params.name = 'Calibrating machine';
calibratingMachine.partDetails3D.quantity = 1;
calibratingMachine.partDetails3D.params.group = 6;

// accesories
const accessories = new Part3DNode(25, undefined, PartTypeEnum.ACCESSORIES);
accessories.partDetails3D = new Part3DDetails(new Part3DParamsBase());
accessories.partDetails3D.params.name = 'Accessories';
accessories.partDetails3D.quantity = 1;
accessories.partDetails3D.params.group = 7;

export const INDIVIDUAL_EQUIPMENTS_LIST: Part3DNode[] = [
  rollerBelt,
  screwConveyor,
  chainConveyor,
  drumSieve,
  airSifter,
  universalCleaner,
  processCleaner,
  bucketElevator,
  zTypeElevator,
  continuousFlowWithoutAirRecirculationDryer,
  continuousFlowWithAirRecirculationDryer,
  batchDryer,
  flatBottomBin,
  conicalBottomBin,
  bufferBin,
  farmBin,
  squareBin,
  fineCleaner,
  triour,
  gravityTable,
  treatingMachine,
  coatingMachine,
  magneticSeparator,
  calibratingMachine,
  accessories
];

export const INDIVIDUAL_EQUIPMENTS_GROUPS = [
  {
    id: 1,
    name: 'Conveyors'
  },
  {
    id: 2,
    name: 'Cleaners'
  },
  {
    id: 3,
    name: 'Elevators'
  },
  {
    id: 4,
    name: 'Dryers'
  },
  {
    id: 5,
    name: 'Bins'
  },
  {
    id: 6,
    name: 'Conditioning'
  },
  {
    id: 7,
    name: 'Accessories'
  }
]
