import { Injectable } from '@angular/core';
import {
  Part3DNode,
  SiloGroupAxis,
  SiloGroupOrientation,
} from '../models/part';
import * as THREE from 'three';
import { CloneObjectService } from './clone-object.service';
import { DEFAULT_TILE_SIZE } from '../constants/scene-defaults';
import { VectorService } from './vector.service';
import { PartAreaEnum, PartTypeEnum } from '../models/enums';
import { Project } from '../models/project';
import { DEFAULT_EQUIPMENTS_POSITIONING_ANGLE } from '../constants/equipment-parameters-default';

@Injectable({
  providedIn: 'root',
})
export class SiloPlantHelperService {
  constructor(
    private cloner: CloneObjectService,
    private geometry: VectorService
  ) {}

  findClosestEquipment(
    equipments: Part3DNode[],
    target: Part3DNode
  ): Part3DNode {
    let minDistance = 10000000;
    let closestEquipment: Part3DNode;
    for (const equipment of equipments) {
      target.position = new THREE.Vector3(
        target.position.x,
        target.position.y,
        target.position.z
      );
      equipment.position = new THREE.Vector3(
        equipment.position.x,
        equipment.position.y,
        equipment.position.z
      );
      const distance = target.position.distanceTo(equipment.position);
      if (distance <= minDistance) {
        closestEquipment = this.cloner.deepCopyPart3DNode(
          equipment,
          equipment.partDetails3D.params.name,
          true
        );
        minDistance = distance;
      }
    }
    return closestEquipment;
  }

  getSiloBinGroupAxis(bins: Part3DNode[]): SiloGroupAxis {
    const dx = this.getDx(bins);
    const dz = this.getDz(bins);
    if (dx > dz) {
      return {
        L: new THREE.Vector3(1, 0, 0),
        T: new THREE.Vector3(0, 0, 1),
      };
    } else {
      return {
        L: new THREE.Vector3(0, 0, 1),
        T: new THREE.Vector3(1, 0, 0),
      };
    }
  }

  getDx(bins: Part3DNode[]): number {
    const positionsX = bins.map((bin) => bin.position.x);
    return Math.max(...positionsX) - Math.min(...positionsX);
  }

  getDz(bins: Part3DNode[]): number {
    const positionsZ = bins.map((bin) => bin.position.z);
    return Math.max(...positionsZ) - Math.min(...positionsZ);
  }

  getNumberOfSiloBinRows(bins: Part3DNode[], axis?: SiloGroupAxis): number {
    if (!axis) {
      axis = this.getSiloBinGroupAxis(bins);
    }
    if (axis.L.x !== 0) {
      const dz = this.getDz(bins);
      if (dz) {
        return Math.round(dz / bins[0].partDetails3D.params.dx.z) + 1;
      } else {
        return 1;
      }
    }

    if (axis.L.x === 0) {
      const dx = this.getDx(bins);
      if (dx) {
        return Math.round(dx / bins[0].partDetails3D.params.dx.z) + 1;
      } else {
        return 1;
      }
    }
  }

  getNumberOfSiloBinsPerRow(bins: Part3DNode[], axis?: SiloGroupAxis): number {
    if (!axis) {
      axis = this.getSiloBinGroupAxis(bins);
    }
    if (axis.L.x !== 0) {
      const dx = this.getDx(bins);
      return Math.round(dx / bins[0].partDetails3D.params.dx.z) + 1;
    }
    if (axis.L.x === 0) {
      const dz = this.getDz(bins);
      return Math.round(dz / bins[0].partDetails3D.params.dx.z) + 1;
    }
  }

  computeLongitudinalAndTransversalAxis(
    elevator: Part3DNode,
    bins: Part3DNode[]
  ): SiloGroupOrientation {
    /** Longitudinal axis = direction from main elevator to closest bin
     *  Transversal axis = direction from main elevator to furthest bin, perpendicular to the longitudinal axis
     */
    let bin = this.findClosestEquipment(bins, elevator);

    let axis: SiloGroupAxis;

    // determine the direction from elevator to closest bin
    let elevatorToBin = new THREE.Vector3(
      bin.position.x - elevator.position.x,
      bin.position.y - elevator.position.y,
      bin.position.z - elevator.position.z
    );
    elevatorToBin.x = Math.sign(elevatorToBin.x);
    elevatorToBin.y = 0;
    elevatorToBin.z = Math.sign(elevatorToBin.z);

    let T0: THREE.Vector3;
    if (elevatorToBin.x !== 0) {
      // check if elevator is on the same z with bin => search for furthest z
      const minZ = Math.min(...bins.map((b) => b.position.z));
      const maxZ = Math.max(...bins.map((b) => b.position.z));
      const dz1 = minZ - bin.position.z;
      const dz2 = maxZ - bin.position.z;
      if (dz1 === dz2) {
        T0 = new THREE.Vector3(0, 0, 1);
      } else {
        T0 = new THREE.Vector3(0, 0, dz1 ? Math.sign(dz1) : Math.sign(dz2));
      }
    } else {
      // elevator on the same x with the bin => search for furthest x
      const minX = Math.min(...bins.map((b) => b.position.x));
      const maxX = Math.max(...bins.map((b) => b.position.x));
      const dx1 = minX - bin.position.x;
      const dx2 = maxX - bin.position.x;
      if (dx1 === dx2) {
        T0 = new THREE.Vector3(1, 0, 0);
      } else {
        T0 = new THREE.Vector3(dx1 ? Math.sign(dx1) : Math.sign(dx2), 0, 0);
      }
    }
    axis = {
      L: elevatorToBin,
      T: T0,
    };
    return { bin, axis } as SiloGroupOrientation;
  }

  adjustSiloGroupAxis(
    closestBin: Part3DNode,
    bins: Part3DNode[]
  ): SiloGroupAxis {
    // todo: there is an issue if intake is positioned next to tile with a missing corner bin.
    closestBin.position = new THREE.Vector3(
      closestBin.position.x,
      closestBin.position.y,
      closestBin.position.z
    );

    const x0 = Math.min(...bins.map((b) => b.position.x));
    const z0 = Math.min(...bins.map((b) => b.position.z));
    const x1 = Math.max(...bins.map((b) => b.position.x));
    const z1 = Math.max(...bins.map((b) => b.position.z));

    // find bounding box (corners of the silo group)
    const b1 = new THREE.Vector3(closestBin.position.x, 0, z0);
    const b2 = new THREE.Vector3(closestBin.position.x, 0, z1);
    const b3 = new THREE.Vector3(x0, 0, closestBin.position.z);
    const b4 = new THREE.Vector3(x1, 0, closestBin.position.z);
    const d1 = closestBin.position.distanceTo(b1);
    const d2 = closestBin.position.distanceTo(b2);
    const d3 = closestBin.position.distanceTo(b3);
    const d4 = closestBin.position.distanceTo(b4);

    const dx = d3 >= d4 ? d3 : d4;
    const dz = d1 >= d2 ? d1 : d2;

    let L: THREE.Vector3;
    let T: THREE.Vector3;
    if (dx > dz) {
      if (d3 > d4) {
        L = new THREE.Vector3(Math.sign(b3.x - closestBin.position.x), 0, 0);
      } else {
        L = new THREE.Vector3(Math.sign(b4.x - closestBin.position.x), 0, 0);
      }
      if (d1 > d2) {
        T = new THREE.Vector3(0, 0, Math.sign(b1.z - closestBin.position.z));
      } else {
        T = new THREE.Vector3(0, 0, Math.sign(b2.z - closestBin.position.z));
      }
    } else {
      if (d1 > d2) {
        L = new THREE.Vector3(0, 0, Math.sign(b1.z - closestBin.position.z));
      } else {
        L = new THREE.Vector3(0, 0, Math.sign(b2.z - closestBin.position.z));
      }
      if (d3 > d4) {
        T = new THREE.Vector3(Math.sign(b3.x - closestBin.position.x), 0, 0);
      } else {
        T = new THREE.Vector3(Math.sign(b4.x - closestBin.position.x), 0, 0);
      }
    }

    return { L, T } as SiloGroupAxis;
  }

  findClosestTile(
    tiles: THREE.Group[] | THREE.Mesh[],
    node: Part3DNode,
    maxDistance?: number
  ): THREE.Mesh | THREE.Group | undefined {
    if (maxDistance === undefined) {
      maxDistance = 0.5 * DEFAULT_TILE_SIZE;
    }
    const intersectedTiles = [];
    for (const tile of tiles) {
      const dx = tile.position.x - node.position.x;
      const dz = tile.position.z - node.position.z;
      const distance = Math.sqrt(Math.pow(dx, 2) + Math.pow(dz, 2));
      if (distance <= maxDistance) {
        const overlap = this.findTilePartOZOveralap(tile, node);
        intersectedTiles.push({
          tile,
          overlap,
        });
      }
    }
    const maxOverlap = Math.max(...intersectedTiles.map((o) => o.overlap));
    const tile = intersectedTiles.find((t) => t.overlap === maxOverlap);
    if (tile) {
      return tile.tile;
    }
  }

  findTilePartOZOveralap(
    obj1: THREE.Group | THREE.Mesh,
    obj2: Part3DNode
  ): number {
    const x1Min = obj1.position.x - DEFAULT_TILE_SIZE / 2;
    const x1Max = obj1.position.x + DEFAULT_TILE_SIZE / 2;
    const z1Min = obj1.position.z - DEFAULT_TILE_SIZE / 2;
    const z1Max = obj1.position.z + DEFAULT_TILE_SIZE / 2;

    const x2Min = obj2.position.x - obj2.partDetails3D.params.dx.x / 2;
    const x2Max = obj2.position.x + obj2.partDetails3D.params.dx.x / 2;
    const z2Min = obj2.position.z - obj2.partDetails3D.params.dx.z / 2;
    const z2Max = obj2.position.z + obj2.partDetails3D.params.dx.z / 2;

    const minX = Math.max(x1Min, x2Min);
    const maxX = Math.min(x1Max, x2Max);

    const minZ = Math.max(z1Min, z2Min);
    const maxZ = Math.min(z1Max, z2Max);

    const overlap = (maxX - minX) * (maxZ - minZ);

    return overlap;
  }

  findPairwiseDistances(nodes: Part3DNode[]): any {
    const distances = {};
    for (const [i, node1] of nodes.entries()) {
      for (const [j, node2] of nodes.entries()) {
        const p1 = new THREE.Vector3(
          node1.position.x,
          node1.position.y,
          node1.position.z
        );
        const p2 = new THREE.Vector3(
          node2.position.x,
          node2.position.y,
          node2.position.z
        );
        const distance = p1.distanceTo(p2);
        const pair = `${i}-${j}`;
        distances[pair] = distance;
      }
    }
    return distances;
  }

  findEquipmentAlongAxis(
    nodes: Part3DNode[],
    target: Part3DNode,
    axis: THREE.Vector3,
    distance: number
  ): Part3DNode {
    const THRESHOLD = 1;
    let d: number;
    let isSameAxis: boolean;
    for (const node of nodes) {
      if (axis.x) {
        d = Math.abs(node.position.x - target.position.x);
        isSameAxis =
          node.position.z === target.position.z &&
          node.position.y === node.position.y;
      } else if (axis.y) {
        d = Math.abs(node.position.y - target.position.y);
        isSameAxis =
          node.position.x === target.position.x &&
          node.position.z === target.position.z;
      } else {
        d = Math.abs(node.position.z - target.position.z);
        isSameAxis =
          node.position.x === target.position.x &&
          node.position.y === node.position.y;
      }
      if (
        isSameAxis &&
        distance - THRESHOLD <= d &&
        d <= distance + THRESHOLD
      ) {
        return node;
      }
    }
  }

  findEquipmentWithinRadius(
    nodes: Part3DNode[],
    position: THREE.Vector3,
    threshold?: number
  ): Part3DNode | undefined {
    if (!threshold) {
      threshold = 0.5;
    }
    for (const node of nodes) {
      const isWithinRadius = this.isWithinRadius(node, position, threshold);
      if (isWithinRadius) {
        return node;
      }
    }
  }

  isWithinRadius(
    node: Part3DNode,
    center: THREE.Vector3,
    radius: number
  ): boolean {
    const dv = new THREE.Vector3(
      node.position.x - center.x,
      node.position.y - center.y,
      node.position.z - center.z
    );
    const dist = dv.distanceTo(new THREE.Vector3());
    return dist <= radius;
  }
  findInvalidTiles(
    bins: Part3DNode[],
    tiles: THREE.Group[] | THREE.Mesh[],
    axis
  ): THREE.Mesh | THREE.Group | undefined {
    /** make the difference between 2 silo bins
     * if x axis is 0 => check if all x coordinates are equal => z is longitudinal axis, single row configuration
     * same for z
     * if z is longitudinal => x+ and x- must be invalid for elevator
     * */
    if (axis.x !== 0) {
      // all bins are on the same x
      bins.sort((a, b) => a.position.z - b.position.z);
      const numberOfBins = bins.length;
      const minPos = bins[0].position;

      tiles = tiles.filter((t) => {
        const xDiff = Math.abs(t.position.x - minPos.x);
        const zDiff = t.position.z - minPos.z;

        return (
          xDiff > 0 &&
          xDiff < DEFAULT_TILE_SIZE + 1 && // take first upper and lower row
          zDiff < numberOfBins * (DEFAULT_TILE_SIZE + 1) &&
          zDiff >= -(DEFAULT_TILE_SIZE + 1)
        ); // interval between first bin and last bin
      });
    } else {
      if (axis.z !== 0) {
        // all bins are on the same z
        bins.sort((a, b) => a.position.x - b.position.x);
        const numberOfBins = bins.length;
        const referenceBin = bins[0];

        tiles = tiles.filter((t) => {
          const xDiff = t.position.x - referenceBin.position.x;
          const zDiff = Math.abs(t.position.z - referenceBin.position.z);

          const zValid = zDiff > 0 && zDiff < DEFAULT_TILE_SIZE + 1;
          const xValid =
            xDiff >= -(DEFAULT_TILE_SIZE + 1) &&
            xDiff <= numberOfBins * (DEFAULT_TILE_SIZE + 1);
          if (zValid && xValid) {
            return true;
          } else return false;
        });
      }
    }
    return tiles;
  }

  binsOneRowConfig(bins: Part3DNode[]): THREE.Vector3 {
    let axis = new THREE.Vector3(0, 0, 0);
    let rowDifference = bins[0].position.x - bins[1].position.x;

    if (rowDifference === 0) {
      //bins are on the same x axis, check if all of them are on the same x
      const binXPos = bins[0].position.x;
      axis.x = +bins.every((b) => b.position.x === binXPos);
    } else {
      rowDifference = bins[0].position.z - bins[1].position.z;
      if (rowDifference === 0) {
        //bins are on the same z axis, check if all of them are on the same z
        const binZPos = bins[0].position.z;
        axis.z = +bins.every((b) => b.position.z === binZPos);
      }
    }

    return axis;
  }
  intakeNeedsAdjusting(
    orientation: SiloGroupOrientation,
    intake: Part3DNode
  ): boolean {
    /**
     * ADJUSTMENTS NEEDED WHEN INTAKE AND MAIN ELEVATOR(REFERENCE BIN) ARE ON THE SAME LONGITUDINAL AXIS
     */
    const isIntakeAlongLongitudinalAxis = this.geometry.alongSameAxis(
      this.geometry.subtractVectors(intake.position, orientation.bin.position),
      this.geometry.multiplyScalarVector(orientation.axis.L, -1)
    );
    const distance = this.geometry.computeDistance(
      orientation.bin.position,
      intake.position
    );
    if (isIntakeAlongLongitudinalAxis && distance <= 2 * DEFAULT_TILE_SIZE) {
      return true;
    }
    return false;
  }

  findLowestEquipment(nodes: Part3DNode[]): Part3DNode {
    const maxDepth = Math.min(...nodes.map((n) => n.position.y));
    return nodes.find((n) => n.position.y === maxDepth);
  }

  getCapacity(
    nodes: Part3DNode[],
    area: PartAreaEnum,
    type: PartTypeEnum
  ): number {
    let capacity = 0;
    const selectedNodes = nodes.filter(
      (n) => n.area === area && n.type === type
    );
    if (!selectedNodes) {
      return capacity;
    }

    if (area === PartAreaEnum.STORAGE && type === PartTypeEnum.SILO_BIN) {
      for (const node of selectedNodes) {
        capacity += node.partDetails3D.params['volume']
          ? node.partDetails3D.params['volume']
          : 0;
      }
      return capacity;
    }

    for (const node of selectedNodes) {
      capacity += node.partDetails3D.params['capacity']
        ? node.partDetails3D.params['capacity']
        : 0;
    }
    return capacity;
  }

  getPlantCapacityDetails(currentProject: Project): Project {
    currentProject.storageCapacity = this.getCapacity(
      currentProject.nodes,
      PartAreaEnum.STORAGE,
      PartTypeEnum.SILO_BIN
    );
    currentProject.handlingCapacity = Math.min(
      this.getCapacity(
        currentProject.nodes,
        PartAreaEnum.INTAKE,
        PartTypeEnum.CHAIN_CONVEYOR
      ),
      this.getCapacity(
        currentProject.nodes,
        PartAreaEnum.INTAKE,
        PartTypeEnum.BELT_CONVEYOR
      )
    );
    currentProject.dryingCapacity = this.getCapacity(
      currentProject.nodes,
      PartAreaEnum.DRYER,
      PartTypeEnum.DRYER
    );
    currentProject.mainElevatorCapacity = this.getCapacity(
      currentProject.nodes,
      PartAreaEnum.STORAGE,
      PartTypeEnum.ELEVATOR
    );
    return currentProject;
  }

  getMinHandlingCapacity(nodes: Part3DNode[]): number {
    let result = Math.min.apply(
      Math,
      nodes.map((node) => {
        if (!node.partDetails3D.params['capacity']) {
          return 0;
        } else {
          return node.partDetails3D.params['capacity'];
        }
      })
    );
    if (result == Infinity) result = 0;
    return result;
  }

  getMaxHandlingCapacity(nodes: Part3DNode[]): number {
    let result = Math.max.apply(
      Math,
      nodes.map((node) => {
        if (!node.partDetails3D.params['capacity']) {
          return 0;
        } else {
          return node.partDetails3D.params['capacity'];
        }
      })
    );
    if (result < 0) result = 0;
    return result;
  }

  positionEquipmentByAngle(height: number, angle?: number): number {
    if (angle === undefined) {
      angle = DEFAULT_EQUIPMENTS_POSITIONING_ANGLE;
    }

    const displacement = height / Math.tan((angle * Math.PI) / 180);
    return displacement;
  }
}
