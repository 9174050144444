import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { UserHelperServiceEnum } from '../models/enums';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';
import { OperationResult } from '../models/helper-service-events';
import { Company } from '../models/company';

@Injectable({
  providedIn: 'root',
})
export class CompanyHelperService {
  private endpoint = environment.endpoint;

  constructor(private api: ApiService, private http: HttpClient) {
    this.api.http = this.http;
  }

  getCompanies(companyService$: Subject<OperationResult>): void {
    this.api.get<Company[]>(this.endpoint.company).subscribe((response) => {
      const result = new OperationResult(
        UserHelperServiceEnum.LOADED,
        response
      );
      companyService$.next(result);
    });
  }

  getCompanyById(companyService$: Subject<OperationResult>, id: string): void {
    this.api
      .getById<Company>(this.endpoint.company, id)
      .subscribe((response) => {
        const result = new OperationResult(
          UserHelperServiceEnum.CREATED,
          response
        );
        companyService$.next(result);
      });
  }

  updateCompany(
    companyService$: Subject<OperationResult>,
    data: Company
  ): void {
    this.api.put<Company>(this.endpoint.companyById, data._id, data).subscribe(
      (response) => {
        const result = new OperationResult(
          UserHelperServiceEnum.EDITED,
          response
        );
        companyService$.next(result);
      },
      (err) => {
        const result = new OperationResult(UserHelperServiceEnum.ERROR, err);
        companyService$.next(result);
      }
    );
  }

  createCompany(
    companyService$: Subject<OperationResult>,
    data: Company
  ): void {
    this.api.post<Company, Company>(this.endpoint.company, data).subscribe(
      (response) => {
        const result = new OperationResult(
          UserHelperServiceEnum.CREATED,
          response
        );
        companyService$.next(result);
      },
      (err) => {
        const result = new OperationResult(UserHelperServiceEnum.ERROR, err);
        companyService$.next(result);
      }
    );
  }

  deleteCompany(companyService$: Subject<OperationResult>, id: string): void {
    this.api.delete(this.endpoint.companyById, id).subscribe(
      (response) => {
        const result = new OperationResult(
          UserHelperServiceEnum.DELETED,
          response
        );
        companyService$.next(result);
      },
      (err) => {
        const result = new OperationResult(UserHelperServiceEnum.ERROR, err);
        companyService$.next(result);
      }
    );
  }

  getCompanyByUserId(companyService$: Subject<OperationResult>): void {
    let company: Company;
    this.api.put<Company>(this.endpoint.company, null, company).subscribe(
      (response) => {
        const result = new OperationResult(
          UserHelperServiceEnum.LOADED,
          response
        );
        companyService$.next(result);
      },
      () => {
        const result = new OperationResult(UserHelperServiceEnum.ERROR);
        companyService$.next(result);
      }
    );
  }
}
