export enum UserTypeEnum {
  SUPERADMIN,
  //BUYER,
  MERCHANT,
  //MARKETPLACE,
  ADMIN
}

export const UserTypeMapping = {
  4: 'ADMIN',
  1: 'BUYER',
  2: 'MERCHANT',
  3: 'MARKETPLACE',
  0: 'SUPERADMIN'
};

export enum CompanyTypeEnum {
  //BUYER = 'BUYER',
  SELLER = 'SELLER',
  //MARKETPLACE = 'MARKETPLACE',
}

export const CompanyTypeMapping = {
  0: 'BUYER',
  1: 'SELLER',
  2: 'MARKETPLACE',
};

export enum UserStatusEnum {
  DISABLED,
  PENDING,
  NOT_ACTIVE,
  ACTIVE,
}

export const UserStatusMapping = {
  Disabled: 'Disabled',
  Pending: 'Pending',
  NotActive: 'NotActive',
  Active: 'Active',
};

export enum ProjectTypeEnum {
  PROJECT,
  TEMPLATE,
}

export enum GrainsEnum {
  CORN = 'Corn',
  WHEAT = 'Wheat',
  OAT = 'Oat',
  BARLEY = 'Barley',
  SOY_BEANS = 'Soy beans',
  SUNFLOWER = 'Sunflower',
  RAPESEED = 'Rapeseed (Canola)',
  RICE = 'Rice',
}

export enum PaymentAcceptedEnum {
  REJECTED,
  ACCEPTED,
}

export enum PartAreaEnum {
  INTAKE,
  CLEANER,
  DRYER,
  DELIVERY,
  STORAGE,
}

export enum PartTypeEnum {
  INTAKE = 'intake',
  DELIVERY = 'delivery',
  SILO_BIN = 'silo-bin',
  BUFFER_BIN = 'buffer-bin',
  ELEVATOR = 'elevator',
  BELT_CONVEYOR = 'belt-conveyor',
  CHAIN_CONVEYOR = 'chain-conveyor',
  TRUCK = 'truck',
  CLEANER = 'cleaner',
  DRYER = 'dryer',
  CONDITIONING = 'conditioning',
  ACCESSORIES = 'accessories',
}

export enum PartSubTypeEnum {
  HORIZONTAL_SIEVES,
  AIR_SIFTER,
  DRUM_SIEVE,
}

export enum QuotationStatusEnum {
  NOT_SENT = 'not sent',
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}

export enum QuotationViewBy {
  ADMIN,
  CURRENT_USER,
  PRODUCER,
}

export enum QuotationTypeEnum {
  SILO_PLANT,
  SINGLE_MACHINES,
}

// Silo prototype steps
export enum SiloPlantStepperEnum {
  SILO_BINS,
  MAIN_ELEVATOR,
  INTAKE,
  PLANT,
  FLOW_DIAGRAM
}

export enum AddEquipmentStepperEnum {
  SET_PARAMETERS,
  POSITION_EQUIPMENT
}

// Scene enums
export enum SceneGeneratorEnum {
  POSITION_SILO_BINS,
  POSITION_INTAKE,
  POSITION_MAIN_ELEVATOR,
  POSITION_STORAGE_LOADING_CONVEYORS,
  POSITION_STORAGE_RECLAIMING_CONVEYORS,
  POSITION_CLEANER_ELEVATOR,
  POSITION_CLEANER,
  POSITION_DRYER_ELEVATOR,
  POSITION_DRYER,
  POSITION_BUFFER_BIN,
  POSITION_DRYER_LOADING_CONVEYORS,
  POSITION_DRYER_RECLAIMING_CONVEYORS,
  POSITION_DELIVERY_BINS,
  POSITION_DELIVERY_LOADING_CONVEYORS,
  ADJUST_INTAKE_POSITION,
  POSITION_INTAKE_RECLAIMING_CONVEYORS,
  CONNECT_EQUIPMENTS,
  DO_NOTHING,
  COMPLETE,
}

// User flow enums
export enum ButtonClickedEnum {
  DOWNLOAD = 'download',
  SUBMIT_RFP = 'submit rfp',
  RFP = 'rfp',
  NEW_PROJECT = 'new project',
  SHOW_EQUIPMENTS = 'show equipments',
}

// PROJECT HELPER SERVICE STATUS ENUM
export enum ProjectHelperServiceEnum {
  CREATED,
  EDITED,
  DELETED,
  LOADED,
  NO_DATA,
  LEAVE_PAGE,
  NEXT,
  ERROR,
  SUCCESS,
}

// QUOTATION HELPER SERVICE STATUS ENUM
export enum QuotationHelperServiceEnum {
  CREATED,
  EDITED,
  DELETED,
  LOADED,
  NO_DATA,
  NEXT,
  ERROR,
  SUCCESS,
  OPEN_PROJECT,
}

// USER HELPER SERVICE STATUS ENUM
export enum UserHelperServiceEnum {
  CREATED,
  EDITED,
  DELETED,
  LOADED,
  NO_DATA,
  NEXT,
  ERROR,
  SUCCESS,
}

export enum SyncStatesEnum {
  SYNC_NEEDED,
  SYNC_DONE,
  SYNC_NOT_NEEDED
}

export enum CustomizeDialogOptionEnum {
  ADD,
  DELETE,
  ROTATE,
  MOVE,
  SPECS
}
