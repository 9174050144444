import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  PartTypeEnum,
  QuotationHelperServiceEnum,
  QuotationTypeEnum,
} from '../models/enums';
import { OperationResult } from '../models/helper-service-events';
import {
  Quotation,
  QuotationCsvRow,
  QuotationsTableRow,
} from '../models/quotation';
import { ApiService } from './api.service';
import { GenericSnackBarService } from './generic-snack-bar.service';
import { CloneObjectService } from './clone-object.service';
import { DownloadCsvService } from 'src/app/shared/services/download-csv.service';
import {
  Bin3DParams,
  Cleaner3DParams,
  Conveyor3DParams,
  Dryer3DParams,
  Elevator3DParams,
  Intake3DParams,
} from '../models/part';

@Injectable({
  providedIn: 'root',
})
export class QuotationHelperService {
  private endpoint = environment.endpoint;
  constructor(
    private api: ApiService,
    private http: HttpClient,
    private snackBar: GenericSnackBarService,
    private cloner: CloneObjectService,
    private downloadCsv: DownloadCsvService
  ) {
    this.api.http = this.http;
  }
  createQuotation(
    quotation,
    quotationService$: Subject<OperationResult>
  ): void {
    this.api.post(this.endpoint.quotations, [quotation]).subscribe(
      () => {
        this.snackBar.showSuccess('Quotations sent successfully.');
        const operationResult = new OperationResult(
          QuotationHelperServiceEnum.CREATED
        );
        quotationService$.next(operationResult);
      },
      (err) => {
        this.snackBar.showError(err.error.message);
        const operationResult = new OperationResult(
          QuotationHelperServiceEnum.ERROR
        );
        quotationService$.next(operationResult);
      }
    );
  }
  getQuotations(
    quotationService$: Subject<OperationResult>,
    requestParams
  ): void {
    this.api
      .get<QuotationsTableRow[]>(this.endpoint.quotations, requestParams)
      .subscribe(
        (quotations) => {
          const result = new OperationResult(
            QuotationHelperServiceEnum.LOADED,
            { quotations }
          );
          quotationService$.next(result);
        },
        (err) => {
          this.snackBar.showError(err.error.message);
          const result = new OperationResult(QuotationHelperServiceEnum.ERROR);
          quotationService$.next(result);
        }
      );
  }
  getQuotation(
    quotationService$: Subject<OperationResult>,
    quotationId: string
  ): void {
    this.api
      .getById<Quotation>(this.endpoint.quotationById, quotationId)
      .subscribe(
        (quotation) => {
          const result = new OperationResult(
            QuotationHelperServiceEnum.LOADED,
            { quotation }
          );
          quotationService$.next(result);
        },
        (err) => {
          this.snackBar.showError(err.error.message);
          const result = new OperationResult(QuotationHelperServiceEnum.ERROR);
          quotationService$.next(result);
        }
      );
  }
  getQuotationByProjectId(
    quotationService$: Subject<OperationResult>,
    id: string
  ): void {
    this.api
      .getById<Quotation>(this.endpoint.quotationByProjectId, id)
      .subscribe(
        (quotation) => {
          const result = new OperationResult(
            QuotationHelperServiceEnum.LOADED,
            { quotation }
          );
          quotationService$.next(result);
        },
        (err) => {
          const result = new OperationResult(
            QuotationHelperServiceEnum.ERROR,
            err.error
          );
          quotationService$.next(result);
        }
      );
  }
  editQuotation(
    quotationService$: Subject<OperationResult>,
    quotation: Quotation
  ): void {
    this.api
      .put(this.endpoint.quotationById, quotation._id, quotation)
      .subscribe(
        () => {
          const result = new OperationResult(
            QuotationHelperServiceEnum.OPEN_PROJECT
          );
          quotationService$.next(result);
        },
        (err) => {
          this.snackBar.showError(err.error.message);
          const result = new OperationResult(QuotationHelperServiceEnum.ERROR);
          quotationService$.next(result);
        }
      );
  }
  deleteQuotation(
    quotationService$: Subject<OperationResult>,
    quotationId: string
  ): void {}
  sendReminder(quotationId: string): void {
    this.api
      .post(this.endpoint.reminderQuotationById.replace(':id', quotationId), {})
      .subscribe(
        () => {
          this.snackBar.showSuccess(`Reminder sent successfully!`);
        },
        () => {
          this.snackBar.showError(
            "Something went wrong. We couldn't inform the producer about your quotation. Please try again."
          );
        }
      );
  }
  acceptQuotationByProjectId(projectId: string): void {
    this.api
      .getById<Quotation>(this.endpoint.quotationByProjectId, projectId)
      .subscribe(
        (quotation) => {
          this.api
            .post(
              this.endpoint.acceptQuotationById.replace(':id', quotation._id),
              {}
            )
            .subscribe(
              () => {
                this.snackBar.showSuccess(
                  'Offer accepted! The project owner will get in touch with you ASAP.'
                );
              },
              () => {
                this.snackBar.showError(
                  'Something went wrong. Please try again.'
                );
              }
            );
        },
        (err) => {
          this.snackBar.showError(err.error.message);
        }
      );
  }

  acceptQuotationById(quotationId: string): void {
    this.api
      .post(this.endpoint.acceptQuotationById.replace(':id', quotationId), {})
      .subscribe(
        () => {
          this.snackBar.showSuccess(
            'Offer accepted! The project owner will get in touch with you ASAP.'
          );
        },
        () => {
          this.snackBar.showError('Something went wrong. Please try again.');
        }
      );
  }

  rejectQuotationByProjectId(projectId: string): void {
    this.api
      .getById<Quotation>(this.endpoint.quotationByProjectId, projectId)
      .subscribe(
        (quotation) => {
          this.api
            .post(
              this.endpoint.rejectQuotationById.replace(':id', quotation._id),
              {}
            )
            .subscribe(
              () => {
                this.snackBar.showSuccess(
                  'Offer accepted! The project owner will get in touch with you ASAP.'
                );
              },
              () => {
                this.snackBar.showError(
                  'Something went wrong. Please try again.'
                );
              }
            );
        },
        (err) => {
          this.snackBar.showError(err.error.message);
        }
      );
  }

  rejectQuotationById(quotationId: string): void {
    this.api
      .post(this.endpoint.rejectQuotationById.replace(':id', quotationId), {})
      .subscribe(
        () => {
          this.snackBar.showSuccess(
            'Offer rejected! The project owner will get in touch with you ASAP.'
          );
        },
        () => {
          this.snackBar.showError('Something went wrong. Please try again.');
        }
      );
  }

  download(quotation: Quotation): void {
    const csvData = [];
    if (!quotation) {
      this.snackBar.showError(
        `Failed to download offer. Missing details, please reload the page and try again.`
      );
      return;
    }
    if (quotation.equipments.length <= 0) {
      csvData.push(new QuotationCsvRow());
    }
    for (const [ix, equipment] of quotation.equipments.entries()) {
      const rowId = (ix + 1).toString();
      const row = new QuotationCsvRow(
        rowId,
        equipment.partDetails3D.quantity?.toString(),
        equipment.partDetails3D.params.name
      );
      row.details = equipment.partDetails3D.specifications
        ? equipment.partDetails3D.specifications
        : '';
      switch (equipment.type) {
        case PartTypeEnum.SILO_BIN: {
          row.diameter = equipment.partDetails3D.params.dx.z
            ? Math.round(equipment.partDetails3D.params.dx.z).toString()
            : Math.round(equipment.partDetails3D.params.dx.x).toString();
          row.height = equipment.partDetails3D.params.dx.y
            ? equipment.partDetails3D.params.dx.y.toString()
            : '';
          if (equipment.partDetails3D.params.hasOwnProperty('volume')) {
            const param = equipment.partDetails3D.params['volume'];
            row.volume = param ? Math.round(param).toString() : '';
          }
          break;
        }
        case PartTypeEnum.BUFFER_BIN: {
          row.diameter = equipment.partDetails3D.params.dx.z
            ? Math.round(equipment.partDetails3D.params.dx.z).toString()
            : Math.round(equipment.partDetails3D.params.dx.x).toString();
          row.height = equipment.partDetails3D.params.dx.y
            ? equipment.partDetails3D.params.dx.y.toString()
            : '';
          if (equipment.partDetails3D.params.hasOwnProperty('volume')) {
            const param = equipment.partDetails3D.params['volume'];
            row.volume = param ? Math.round(param).toString() : '';
          }
          break;
        }
        case PartTypeEnum.ELEVATOR: {
          row.height = equipment.partDetails3D.params.dx.y
            ? equipment.partDetails3D.params.dx.y.toString()
            : '';
          if (equipment.partDetails3D.params.hasOwnProperty('capacity')) {
            const param = equipment.partDetails3D.params['capacity'];
            row.capacity = param ? Math.round(param).toString() : '';
          }
          break;
        }
        case PartTypeEnum.BELT_CONVEYOR: {
          row.length = equipment.partDetails3D.params.dx.x
            ? Math.round(equipment.partDetails3D.params.dx.x).toString()
            : '';
          if (equipment.partDetails3D.params.hasOwnProperty('capacity')) {
            const param = equipment.partDetails3D.params['capacity'];
            row.capacity = param ? Math.round(param).toString() : '';
          }
          break;
        }
        case PartTypeEnum.CHAIN_CONVEYOR: {
          row.length = equipment.partDetails3D.params.dx.x
            ? Math.round(equipment.partDetails3D.params.dx.x).toString()
            : '';
          if (equipment.partDetails3D.params.hasOwnProperty('capacity')) {
            const param = equipment.partDetails3D.params['capacity'];
            row.capacity = param ? Math.round(param).toString() : '';
          }
          break;
        }
        case PartTypeEnum.DRYER: {
          if (
            equipment.partDetails3D.params.hasOwnProperty('moistureReduction')
          ) {
            const param = equipment.partDetails3D.params['moistureReduction'];
            row.moistureReduction = param ? Math.round(param).toString() : '';
          }
          if (equipment.partDetails3D.params.hasOwnProperty('capacity')) {
            const param = equipment.partDetails3D.params['capacity'];
            row.capacity = param ? Math.round(param).toString() : '';
          }
          break;
        }
        case PartTypeEnum.DELIVERY: {
          row.diameter = equipment.partDetails3D.params.dx.z
            ? Math.round(equipment.partDetails3D.params.dx.z).toString()
            : Math.round(equipment.partDetails3D.params.dx.x).toString();
          row.height = equipment.partDetails3D.params.dx.y
            ? equipment.partDetails3D.params.dx.y.toString()
            : '';
          if (equipment.partDetails3D.params.hasOwnProperty('volume')) {
            const param = equipment.partDetails3D.params['volume'];
            row.volume = param ? Math.round(param).toString() : '';
          }
          break;
        }
        case PartTypeEnum.INTAKE: {
          if (equipment.partDetails3D.params.hasOwnProperty('capacity')) {
            const param = equipment.partDetails3D.params['capacity'];
            row.capacity = param ? Math.round(param).toString() : '';
          }
          break;
        }
        case PartTypeEnum.CONDITIONING: {
          if (equipment.partDetails3D.params.hasOwnProperty('capacity')) {
            const param = equipment.partDetails3D.params['capacity'];
            row.capacity = param ? Math.round(param).toString() : '';
          }
          break;
        }
        case PartTypeEnum.CLEANER: {
          if (equipment.partDetails3D.params.hasOwnProperty('capacity')) {
            const param = equipment.partDetails3D.params['capacity'];
            row.capacity = param ? Math.round(param).toString() : '';
          }
          break;
        }
        case PartTypeEnum.ACCESSORIES: {
          break;
        }
      }
      csvData.push(row);
    }
    const header = [
      'Id',
      'Quantity',
      'Name',
      'Diameter (m)',
      'Height (m)',
      'Length (m)',
      'Width (m)',
      'Volume (m^3)',
      'Capacity (t/h)',
      'Moisture reduction (%)',
      'Details',
    ];
    let title = 'Single machines quotation';
    if (quotation.quotationType === QuotationTypeEnum.SILO_PLANT) {
      title = 'Silo plant quotation';
    }
    const filename = `Individual offer ${quotation._id}`;
    this.downloadCsv.download(title, filename, csvData, header);
  }
}
