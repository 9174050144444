import { Company } from './company';
import { UserStatusEnum } from './enums';

export interface CreateUserRequest {
  firstName: string;
  lastName: string;
  email: string;
  status: UserStatusEnum;
  password: string;
  secondPassword: string;
  acceptTerms: boolean;
}

export interface CreateUserResponse {
  firstName: string;
  lastName: string;
  email: string;
}

export interface UserActivateRequest {
  email: string;
  code: string;
}

export interface UserSignInRequest {
  email: string;
  password: string;
}

export interface UserSignInResponse {
  _id: string;
  message: string;
  token: string;
  firstName: string;
  lastName: string;
  isSuperAdmin: boolean;
  company: Company;
}

export class User {
  _id?: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  secondPassword: string;
  admin: boolean;
  superAdmin: boolean;
  company: Company;
  acceptTerms: boolean;
  createdAt: Date;
  lastUpdatedAt: Date;
  lastLoggedIn?: Date;
  status?: UserStatusEnum;
  constructor() {}
  init(obj: object): void {
    for (const key of Object.keys(obj)) {
      if (key in this) {
        this[key] = obj[key];
      }
    }
  }
  getValues(): object {
    const definedValues = {};
    for (const key of Object.keys(this)) {
      if (this[key] !== undefined) {
        definedValues[key] = this[key];
      }
    }
    return definedValues;
  }
}

export class UserDetails extends User {
  numberProjects?: number;
  numberOffers?: number;
  numberAcceptedOffers?: number;
  numberRejectedOffers?: number;
}

export class UserTableRow extends UserDetails {
  isChecked: boolean;
  actions?: any;
}

export class Counter {
  _id: string;
  count: number;
  status?: string;
}

export class CounterOffers {
  _id: Counter;
}
