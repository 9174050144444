import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  IterableDiffers,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  PART_AREAS_DISPLAY,
  PART_CONVEYORS_DISPLAY,
  PART_SUBTYPES_DISPLAY,
  PART_TYPES_DISPLAY,
} from 'src/app/shared/constants/customize-plant-defaults';
import {
  PartTypeEnum,
} from 'src/app/shared/models/enums';
import {
  Bin3DParams,
  Delivery3DParams,
  Part3DNode,
  PartAreaDisplay,
  PartDetails3DEditsEvent,
  PartSubTypeDisplay,
  PartTypeDisplay,
} from 'src/app/shared/models/part';

@Component({
  selector: 'app-equipment-specifications-dialog',
  templateUrl: './equipment-specifications-dialog.component.html',
  styleUrls: ['./equipment-specifications-dialog.component.scss'],
})
export class EquipmentSpecificationsDialogComponent implements OnInit {
  @Input() part3DNodes: Part3DNode[];
  @Output() closeClicked = new EventEmitter();
  @Output() editClick = new EventEmitter();
  @Output() mouseOn = new EventEmitter();
  @Output() mouseOff = new EventEmitter();

  partTypeEnum = PartTypeEnum;
  equipmentName: FormControl = new FormControl('', Validators.required);
  equipmentArea: FormControl = new FormControl('', Validators.required);
  equipmentType: FormControl = new FormControl(
    { value: '', disabled: true },
    Validators.required
  );
  equipmentSubType: FormControl = new FormControl('', Validators.required);
  equipmentTypeConveyor: FormControl = new FormControl('', Validators.required);
  equipmentParents: FormControl = new FormControl('', Validators.required);
  equipmentChildren: FormControl = new FormControl('', Validators.required);
  equipment: Part3DNode;

  selectedPartType: PartTypeDisplay = {
    id: undefined,
    name: undefined,
  };

  subTypes: PartSubTypeDisplay[] = PART_SUBTYPES_DISPLAY;
  partAreas: PartAreaDisplay[] = PART_AREAS_DISPLAY;
  partTypes: PartTypeDisplay[] = PART_TYPES_DISPLAY;
  partTypeConveyor: PartTypeDisplay[] = PART_CONVEYORS_DISPLAY;

  isSelected = false;
  isConveyor = false;
  updatedPart3DNodes: Part3DNode[] = [];
  private part3DNodesDiffer;
  constructor(private iterableDiffers: IterableDiffers) {
    this.part3DNodesDiffer = iterableDiffers.find([]).create(null);
  }

  ngOnInit(): void {
    this.isConveyor = false;
    this.isSelected = false;
  }
  ngOnChanges(): void {}

  ngDoCheck() {
    let changes = this.part3DNodesDiffer.diff(this.part3DNodes);
    if (changes) {
      this.updatedPart3DNodes = [...this.part3DNodes];
    }
  }

  applyChanges(): void {
    this.isConveyor = false;
    this.isSelected = false;
    let editedPartDetails: PartDetails3DEditsEvent;
    if (this.equipment == null || this.equipment == undefined) return;
    if (this.equipment.type === PartTypeEnum.CLEANER) {
      editedPartDetails = {
        name: this.equipment.partDetails3D.params.name,
        area: this.equipmentArea.value,
        parents: this.equipmentParents.value,
        children: this.equipmentChildren.value,
        params: this.equipment.partDetails3D.params,
        subType: this.equipmentSubType.value,
      };
    } else {
      editedPartDetails = {
        name: this.equipment.partDetails3D.params.name,
        area: this.equipmentArea.value,
        parents: this.equipmentParents.value,
        children: this.equipmentChildren.value,
        params: this.equipment.partDetails3D.params,
      };
    }
    if (
      this.equipment.type === PartTypeEnum.SILO_BIN ||
      this.equipment.type === PartTypeEnum.BUFFER_BIN
    ) {
      this.equipment.partDetails3D.params.dx.z = (
        this.equipment.partDetails3D.params as Bin3DParams
      ).dx.z;
      this.equipment.partDetails3D.params.dx.x = (
        this.equipment.partDetails3D.params as Bin3DParams
      ).dx.x;
    } else if (this.equipment.type === PartTypeEnum.DELIVERY) {
      this.equipment.partDetails3D.params.dx.z = (
        this.equipment.partDetails3D.params as Delivery3DParams
      ).dx.z;
      this.equipment.partDetails3D.params.dx.x = (
        this.equipment.partDetails3D.params as Delivery3DParams
      ).dx.x;
    }
    if (this.equipmentType.value) {
      editedPartDetails.type = this.equipmentType.value;
    } else {
      editedPartDetails.type = this.equipmentTypeConveyor.value;
    }

    this.editClick.emit(editedPartDetails);
    this.equipmentName.reset();
    this.equipmentArea.reset();
    this.equipmentType.reset();
    this.equipmentSubType.reset();
    this.equipmentTypeConveyor.reset();
    this.equipmentChildren.reset();
    this.equipmentParents.reset();
  }

  closeDialog(): void {
    this.isConveyor = false;
    this.isSelected = false;
    this.equipmentName.reset();
    this.equipmentArea.reset();
    this.equipmentType.reset();
    this.equipmentSubType.reset();
    this.equipmentChildren.reset();
    this.equipmentParents.reset();
    this.closeClicked.emit();
  }

  mouseOverOn($event: any): void {
    let element = $event.target.textContent;
    if (element && element !== '') {
      try {
        element = element.split('.')[1].trim();
      } catch (err) {
        element = element.trim();
      }
      this.mouseOn.emit({ nodeName: element });
    }
  }

  mouseOverOff($event: any): void {
    let element = $event.target.textContent;
    if (element && element !== '') {
      try {
        element = element.split('.')[1].trim();
      } catch (err) {
        element = element.trim();
      }
      this.mouseOff.emit({ nodeName: element });
    }
  }

  getNode(event: any): void {
    this.isConveyor = false;
    this.equipment = this.part3DNodes.find((node) => node.id === event.value);
    if (
      this.equipment.type === PartTypeEnum.CHAIN_CONVEYOR ||
      this.equipment.type === PartTypeEnum.BELT_CONVEYOR
    ) {
      this.isConveyor = true;
    }
    this.initForm();
    this.isSelected = true;
  }

  initForm(): void {
    const partArea = this.partAreas.find((x) => x.id === this.equipment.area);
    if (partArea) {
      this.equipmentArea.setValue(partArea.id);
    }
    if (this.equipment.type === PartTypeEnum.CLEANER) {
      const subType = this.subTypes.find(
        (x) => x.id === this.equipment.subType
      );
      if (subType) {
        this.equipmentSubType.setValue(subType.id);
      }
    }
    if (
      this.equipment.type === PartTypeEnum.CHAIN_CONVEYOR ||
      this.equipment.type === PartTypeEnum.BELT_CONVEYOR
    ) {
      const partTypeConveyor = this.partTypeConveyor.find(
        (x) => x.id === this.equipment.type
      );
      if (partTypeConveyor) {
        this.equipmentTypeConveyor.setValue(partTypeConveyor.id);
        this.equipmentType.reset();
      }
    } else {
      const partType = this.partTypes.find((x) => x.id === this.equipment.type);
      if (partType) {
        this.equipmentType.setValue(partType.id);
        this.equipmentTypeConveyor.reset();
      }
    }
    if (this.equipment.children) {
      const children: Part3DNode[] = [];
      for (const child of this.equipment.children) {
        const childNode = this.part3DNodes.find(
          (x) => x.id === parseInt(child, 0)
        );
        children.push(childNode);
      }
      const childrenIds = this.equipment.children.map((x) => parseInt(x, 0));
      this.equipmentChildren.setValue(childrenIds);
    }
    if (this.equipment.parents) {
      const parents: Part3DNode[] = [];
      for (const parent of this.equipment.parents) {
        const parentNode = this.part3DNodes.find(
          (x) => x.id === parseInt(parent, 0)
        );
        parents.push(parentNode);
      }
      const parentsIds = this.equipment.parents.map((x) => parseInt(x, 0));
      this.equipmentParents.setValue(parentsIds);
    }
    switch (this.equipment.type) {
      case PartTypeEnum.BUFFER_BIN: {
        (this.equipment.partDetails3D.params as Bin3DParams).dx.z = this
          .equipment.partDetails3D.params.dx.z
          ? this.equipment.partDetails3D.params.dx.z
          : this.equipment.partDetails3D.params.dx.x;
        break;
      }
      case PartTypeEnum.CLEANER: {
        this.equipment.subType = this.equipmentSubType.value;
        break;
      }
      case PartTypeEnum.SILO_BIN: {
        (this.equipment.partDetails3D.params as Bin3DParams).dx.z = this
          .equipment.partDetails3D.params.dx.z
          ? this.equipment.partDetails3D.params.dx.z
          : this.equipment.partDetails3D.params.dx.x;
        break;
      }
      case PartTypeEnum.DELIVERY: {
        (this.equipment.partDetails3D.params as Delivery3DParams).dx.z = this
          .equipment.partDetails3D.params.dx.z
          ? this.equipment.partDetails3D.params.dx.z
          : this.equipment.partDetails3D.params.dx.x;
        break;
      }
    }
  }
}
