import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CompanyTypeEnum, UserTypeEnum } from '../models/enums';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private localStorageKeys = environment.localStorageKeys;
  private routes = environment.routes;

  constructor(private router: Router) {}

  setToken(token: string): void {
    localStorage.setItem(this.localStorageKeys.userJwt, token);
  }

  getToken(): string {
    return localStorage.getItem(this.localStorageKeys.userJwt);
  }

  isLoggedIn(): boolean {
    return this.getToken() !== null && this.getCompanyType() !== null && this.getCompanyId() !== null;
    return this.getToken() !== null;
  }

  specificRoute(): string {
    let route: string = this.routes.projects;
    let companyType: CompanyTypeEnum = this.getCompanyType();
    /*
    switch (companyType) {
      case CompanyTypeEnum.MARKETPLACE: {
        route = this.routes.quotations;
        break;
      }
      default:
        break;
    }
    */
    if (this.isAdmin()) {
      route = this.routes.admin;
    }
    return route;
  }

  redirect(): void {
    if (!this.isLoggedIn()) return;
    let route = this.specificRoute();
    this.router.navigate([route]).then(() => {
      window.location.reload();
    });
  }

  logout(): void {
    localStorage.clear();
    this.router.navigate([this.routes.signIn]);
  }

  setFirstName(firstName: string): void {
    localStorage.setItem(this.localStorageKeys.userFirstName, firstName);
  }

  setLastName(lastName: string): void {
    localStorage.setItem(this.localStorageKeys.userLastName, lastName);
  }

  setUserId(userId: string): void {
    localStorage.setItem(this.localStorageKeys.userId, userId);
  }

  setAdminFlag(isAdmin: boolean): void {
    if (!isAdmin) {
      isAdmin = false;
    }
    localStorage.setItem(this.localStorageKeys.userIsAdmin, isAdmin.toString());
  }

  getFirstName(): string {
    return localStorage.getItem(this.localStorageKeys.userFirstName);
  }

  getLastName(): string {
    return localStorage.getItem(this.localStorageKeys.userLastName);
  }

  getUserId(): string {
    return localStorage.getItem(this.localStorageKeys.userId);
  }

  isAdmin(): boolean {
    return localStorage.getItem(this.localStorageKeys.userIsAdmin) === 'true';
  }

  tokenExpired(): boolean {
    const expiry = JSON.parse(atob(this.getToken().split('.')[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  clearToken(): void {
    localStorage.removeItem(this.localStorageKeys.userJwt);
  }

  setCompanyId(companyId: string): void {
    localStorage.setItem(
      this.localStorageKeys.companyId,
      companyId
    );
  }

  getCompanyId(): string {
    return localStorage.getItem(this.localStorageKeys.companyId);
  }

  setCompanyType(companyType: CompanyTypeEnum): void {
    localStorage.setItem(
      this.localStorageKeys.companyType,
      companyType.toString()
    );
  }

  getCompanyType(): CompanyTypeEnum {
    const compType = localStorage.getItem(this.localStorageKeys.companyType);
    switch (compType) {
      case 'BUYER':
        return CompanyTypeEnum.SELLER;
      case 'SELLER':
        return CompanyTypeEnum.SELLER;
      case 'MARKETPLACE':
        return CompanyTypeEnum.SELLER;
      default:
        return null;
    }
  }
}
