import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  CompanyTypeEnum,
  UserHelperServiceEnum,
  UserStatusEnum,
  UserTypeEnum,
} from '../shared/models/enums';
import { UserTableRow } from '../shared/models/user';
import { ApiService } from '../shared/services/api.service';
import { GenericSnackBarService } from '../shared/services/generic-snack-bar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OperationResult } from '../shared/models/helper-service-events';
import { UserHelperService } from '../shared/services/user-helper.service';
import { AuthService } from '../shared/services/auth.service';

export class FilterData {
  status;
  type;
}
@Component({
  selector: 'app-rfps',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  companyTyp: CompanyTypeEnum;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selectedUserType: FormControl = new FormControl(null);
  selectedUserStatus: FormControl = new FormControl(null);
  availableUserTypes = ['ADMIN', 'FARMER', 'PRODUCER'];
  availableUserStatuses = ['DISABLED', 'PENDING', 'ACTIVE'];
  filterSelectObj = [];
  filterValues = {
    status: '',
  };
  title = 'Users';
  usersTableData = new MatTableDataSource();
  displayedColumns: string[] = [
    'selected',
    'name',
    'email',
    'createdAt',
    'lastLoggedIn',
    'company',
    'companyType',
    'companyPhone',
    'status',
  ];
  users: UserTableRow[] = [];
  private routes = environment.routes;
  private endpoint = environment.endpoint;
  private adminService$: Subject<OperationResult> =
    new Subject<OperationResult>();
  constructor(
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private snackBar: GenericSnackBarService,
    public userHelper: UserHelperService
  ) {
    this.api.http = this.http;
    this.filterSelectObj = [
      { options: [], name: 'Status', columnProp: 'status' },
    ];
  }
  ngOnInit(): void {
    // Security checks
    if (this.auth.isLoggedIn()) {
      if (!this.auth.isAdmin) {
        this.router.navigate([this.auth.specificRoute()]);
        return;
      }
    } else {
      this.auth.clearToken();
      this.router.navigate([this.routes.signIn]);
      return;
    }
    this.usersTableData.filterPredicate = this.createFilter();
    this.route.queryParams.subscribe((params) => {
      const ct = params.companyType;
      if (!ct) {
        this.companyTyp = null;
      }
      switch (ct) {
        /*
        case 'BUYER': {
          this.companyTyp = CompanyTypeEnum.BUYER;
          break;
        }
        case 'MARKETPLACE': {
          this.companyTyp = CompanyTypeEnum.MARKETPLACE;
          break;
        }*/
        case 'SELLER': {
          this.companyTyp = CompanyTypeEnum.SELLER;
          break;
        }
      }
      const requestParams = {
        companyType: this.companyTyp,
      };

      this.adminService$.subscribe((event: OperationResult) => {
        if (event.status === UserHelperServiceEnum.LOADED) {
          const users = event.data.users;
          users.forEach((user) => {
            user.isChecked = false;
          });
          this.users = [...users];
          this.users.reverse();
          this.usersTableData.data = users;
          this.filterSelectObj.filter((o) => {
            o.options = this.getFilterObject(users, o.columnProp);
          });
          this.usersTableData.sort = this.sort;
          this.usersTableData.paginator = this.paginator;
        }
        if (event.status === UserHelperServiceEnum.ERROR) {
          this.snackBar.showError(event.data);
        }
      });
      this.userHelper.getUsersByCompany(this.adminService$, requestParams);
    });
    /*this.api.get<UserTableRow[]>(this.endpoint.users).subscribe(
      (users) => {
        users.forEach((user) => {
          user.isChecked = false;
        });
        this.users = [...users];
        this.users.reverse();
        this.usersTableData.data = users;
        this.filterSelectObj.filter((o) => {
          o.options = this.getFilterObject(users, o.columnProp);
        });
        this.usersTableData.sort = this.sort;
        this.usersTableData.paginator = this.paginator;
        console.log(users);
      },
      (error) => {
        if (error) {
          this.snackBar.showError(error.message);
        }
      }
    );*/
  }
  filterChangeValue(value: string) {
    let companyType = null;
    switch (value) {
      /*
      case 'buyer': {
        companyType = CompanyTypeEnum.BUYER;
        break;
      }
      case 'marketplace': {
        companyType = CompanyTypeEnum.MARKETPLACE;
        break;
      }
      */
      case 'seller': {
        companyType = CompanyTypeEnum.SELLER;
        break;
      }
    }
    this.title = value;
    let filterval = { company: companyType };
    this.usersTableData.filter = JSON.stringify(filterval);
  }
  // Called on Filter change
  filterChange(filter, event) {
    let UserStatus = UserStatusEnum.ACTIVE;
    let value = event.target.value.trim().toLowerCase();
    switch (value) {
      case 'disabled': {
        UserStatus = UserStatusEnum.DISABLED;
        break;
      }
      case 'active': {
        UserStatus = UserStatusEnum.ACTIVE;
        break;
      }
      case 'pending': {
        UserStatus = UserStatusEnum.PENDING;
        break;
      }
    }
    this.filterValues[filter.columnProp] = UserStatus;
    this.usersTableData.filter = JSON.stringify(this.filterValues);
  }
  createFilter() {
    let filterFunction = function (data: any, filter: string): boolean {
      let isFilterSet = false;
      let searchTerms = JSON.parse(filter);
      for (const col in searchTerms) {
        console.log(col);
        const column = searchTerms[col];
        console.log(searchTerms);
        console.log('col' + column);
        if (column === undefined || column === null) {
          continue;
        }
        if (column.toString() !== '') {
          isFilterSet = true;
        } else {
          delete searchTerms[col];
        }
      }
      let nameSearch = () => {
        let found = false;
        if (isFilterSet) {
          for (const col in searchTerms) {
            let val = searchTerms[col];
            if (
              data[col]?.toString().toLowerCase().indexOf(val) != -1 &&
              isFilterSet
            ) {
              found = true;
            }
          }
          return found;
        } else {
          return true;
        }
      };
      return nameSearch();
    };
    return filterFunction;
  }
  getStatusFromNumber(number: number): string {
    if (!number) return 'Active';
    switch (number) {
      case 0:
        return 'Disabled';
      case 1:
        return 'Enabled';
      case 2:
        return 'Active';
    }
  }
  getFilterObject(fullObj, key) {
    const uniqChk = [];
    fullObj.filter((obj) => {
      if (!uniqChk.includes(obj[key])) {
        let name;
        let keyE = obj[key];
        switch (keyE) {
          case UserStatusEnum.DISABLED: {
            name = 'Disabled';
            break;
          }
          case UserStatusEnum.PENDING: {
            name = 'Pending';
            break;
          }
          case UserStatusEnum.ACTIVE: {
            name = 'Active';
            break;
          }
        }
        if (name && !uniqChk.includes(name)) {
          uniqChk.push(name);
        }
      }
      return obj;
    });
    return uniqChk;
  }
  resetFilters() {
    this.filterValues.status = '';
    this.filterSelectObj.forEach((value, key) => {
      value.modelValue = undefined;
    });
    this.usersTableData.filter = '';
  }
  activateUsers(): void {
    let usersToActivate = this.users.filter((user) => user.isChecked === true);
    if (usersToActivate && usersToActivate.length > 0) {
      const newUserStatus = {
        status: UserStatusEnum.ACTIVE,
      };
      const requests: Observable<any>[] = [];
      for (const user of usersToActivate) {
        requests.push(
          this.api.put(this.endpoint.userById, user._id, newUserStatus)
        );
      }
      forkJoin(requests).subscribe(
        () => {
          this.users.forEach((x: UserTableRow) => {
            if (usersToActivate.includes(x)) {
              x.status = UserStatusEnum.ACTIVE;
            }
          });
        },
        (err) => {
          console.log(err);
          this.snackBar.showError(err);
        }
      );
      this.users.forEach((u) => {
        u.isChecked = false;
      });
    } else {
      this.snackBar.showError('Please select users that you want to activate.');
    }
  }
  deactivateUsers(): void {
    let usersToDeactivate = this.users.filter(
      (user) => user.isChecked === true
    );
    if (usersToDeactivate && usersToDeactivate.length > 0) {
      const newUserStatus = {
        status: UserStatusEnum.DISABLED,
      };
      const requests: Observable<any>[] = [];
      for (const user of usersToDeactivate) {
        requests.push(
          this.api.put(this.endpoint.userById, user._id, newUserStatus)
        );
      }
      forkJoin(requests).subscribe(
        () => {
          this.users.forEach((x: UserTableRow) => {
            if (usersToDeactivate.includes(x)) {
              x.status = UserStatusEnum.DISABLED;
            }
          });
          this.snackBar.showSuccess('Disabled!');
        },
        (err) => {
          this.snackBar.showError(err);
          console.log(err);
        }
      );
      this.users.forEach((u) => {
        u.isChecked = false;
      });
    } else {
      this.snackBar.showError('Select users to disable their accounts');
    }
  }
  searchUsers(filterValue: any): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.usersTableData.filter = filterValue;
  }
  viewAll(): void {
    this.title = 'Users';
    this.router.navigate([this.routes.admin]);
  }
  /*
  viewBuyers(): void {
    this.title = "Buyers";
    this.router.navigate([this.routes.admin], {
      queryParams: {
        companyType: CompanyTypeEnum.BUYER
      },
    });
  }
  viewMarketplace(): void {
    this.title = "Marketplace";
    this.router.navigate([this.routes.admin], {
      queryParams: {
        companyType: CompanyTypeEnum.MARKETPLACE
      },
    });
  }
  */
  viewSellers(): void {
    this.title = 'Sellers';
    this.router.navigate([this.routes.admin], {
      queryParams: {
        companyType: CompanyTypeEnum.SELLER,
      },
    });
  }
}
