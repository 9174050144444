import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {Injectable} from '@angular/core';

@Injectable()
export class GenericDialogService {

  dialogDescription: string;
  dimensions: string[];

  constructor(private dialog: MatDialog) {
  }

  openDialog(component): MatDialogRef<any> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = '.container';
    // dialogConfig.data = {
    //   description: this.dialogDescription,
    //   dimensions: this.dimensions
    // };
    const dialogRef = this.dialog.open(component, dialogConfig);
    return dialogRef;
  }
}
