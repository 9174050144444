import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QuotationsRoutingModule } from './quotations-routing.module';
import { QuotationsComponent } from './quotations.component';

// Material design
import { MatTabsModule } from '@angular/material/tabs';
import { SubmitQuotationComponent } from './submit-quotation/submit-quotation.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MiscModule } from '../shared/modules/misc/misc.module';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { ProjectsModule } from '../projects/projects.module';
import { MatMenuModule } from '@angular/material/menu';
import { ViewIndividualQuotationComponent } from './view-individual-quotation/view-individual-quotation.component';
import { PlantComponent } from './plant-quotation/plant.component';

@NgModule({
  declarations: [
    QuotationsComponent,
    SubmitQuotationComponent,
    ViewIndividualQuotationComponent,
    PlantComponent,
  ],
  imports: [
    CommonModule,
    QuotationsRoutingModule,
    // Material
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSliderModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTableModule,
    FormsModule,
    MatPaginatorModule,
    MatExpansionModule,
    MiscModule,
    MatButtonModule,
    MatSnackBarModule,
    MatSortModule,
    ProjectsModule,
    MatMenuModule,
  ],
})
export class QuotationsModule {}
