<mat-horizontal-stepper #siloProjectStepper>

  <!-- Step 1 - select bins -->
  <mat-step #step1>
    <ng-template matStepLabel>Select silo bins layout</ng-template>
    <ng-container
      *ngIf="
        siloProjectStepper.selected === null ||
        siloProjectStepper.selected === step1
      "
    >
      <app-silo-layout
        #step1Component
        [stepper]="siloProjectStepper"
      ></app-silo-layout>
    </ng-container>
  </mat-step>

  <!-- Step 2 - select main elevator position -->
  <mat-step #step2>
    <ng-template matStepLabel>Select main elevator position</ng-template>
    <ng-container *ngIf="siloProjectStepper.selected === step2">
      <app-silo-layout
        #step2Component
        [stepper]="siloProjectStepper">
      </app-silo-layout>
    </ng-container>
  </mat-step>

  <!-- Step 3 - select intake position -->
  <mat-step #step3>
    <ng-template matStepLabel>Select intake position</ng-template>
    <ng-container *ngIf="siloProjectStepper.selected === step3">
      <app-silo-layout
        #step3Component
        [stepper]="siloProjectStepper"
      ></app-silo-layout>
    </ng-container>
  </mat-step>

  <!-- Step 4 - 3D design -->
  <mat-step #step4>
    <ng-template matStepLabel>Customize plant</ng-template>
    <ng-container *ngIf="siloProjectStepper.selected === step4">
      <app-silo-prototype
        #step4Component
        [stepper]="siloProjectStepper"
      ></app-silo-prototype>
    </ng-container>
  </mat-step>

  <!-- Step 5 - Flow diagram -->
  <mat-step #step5>
    <ng-template matStepLabel>Flow diagram</ng-template>
    <ng-container *ngIf="siloProjectStepper.selected === step5">
      <app-flow-diagram
      #step5Component
      [stepper]="siloProjectStepper">
      </app-flow-diagram>
    </ng-container>
  </mat-step>
</mat-horizontal-stepper>
