<div class="spinner-loading" [hidden]="loading">
  <app-spinner [loading]="loading"></app-spinner>
</div>
<div class="project-name" *ngIf="!loading">
  <mat-form-field>
    <label>
      <input
        matInput
        placeholder="Project Name"
        (keydown)="keyPressed($event)"
        [(ngModel)]="projectName"
      />
    </label>
  </mat-form-field>
</div>
<div class="parent-grid">
  <div class="content-grid">
    <canvas
      id="silo3dDesign"
      class="layout-grid"
      height="564"
      width="600"
      (click)="onMouseDown($event)"
      (mousemove)="onMouseMove($event)"
    ></canvas>
  </div>
  <div class="row-spacer"><br /></div>
  <div class="layout-grid-button-item" *ngIf="!loading">
    <button
      mat-button
      class="white-button margin-button button-spacer-back"
      *ngIf="stepper.selectedIndex !== 0"
      (click)="back()"
    >
      Back
    </button>
    <button mat-button class="white-button" (click)="cancel()">Cancel</button>
    <span class="button-spacer"></span>
    <button
      mat-button
      class="green-button button-spacer-continue-left"
      (click)="next()"
      [disabled]="loading"
    >
      Continue
    </button>
  </div>
</div>
<div class="name-modal" *ngIf="!loading && showEnterName">
  <div class="name-modal-form">
    <label class="name-modal-project">Specify the project name</label>
    <hr />
    <div class="name-modal-content">
      <mat-form-field>
        <label>
          <input
            matInput
            maxlength="100"
            [(ngModel)]="projectName"
            (keydown)="keyPressed($event)"
            placeholder="Enter the project name"
          />
        </label>
      </mat-form-field>
    </div>
    <button
      mat-button
      class="green-button button-spacer-continue-left"
      (click)="saveProjectName()"
      [disabled]="loading"
    >
      Rename
    </button>
  </div>
</div>
