import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuotationsComponent } from './quotations.component';
import { AuthGuard } from '../auth.guard';
import { SubmitQuotationComponent } from './submit-quotation/submit-quotation.component';
import { ViewIndividualQuotationComponent } from './view-individual-quotation/view-individual-quotation.component';
import { PlantComponent } from './plant-quotation/plant.component';

const routes: Routes = [
  {
    path: '',
    component: QuotationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'quotations/plant',
    component: PlantComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'quotations/individual/:id',
    component: ViewIndividualQuotationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'projects/:id/submit-quotation',
    component: SubmitQuotationComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QuotationsRoutingModule {}
