import {Injectable} from '@angular/core';
import {Part3DNode} from '../models/part';
import * as THREE from 'three';
import { Project } from '../models/project';

@Injectable({
  providedIn: 'root',
})
export class CloneObjectService {
  constructor() {
  }

  deepCopyPart3DNode(source: Part3DNode, name?: string, copyThreeJs?: boolean): Part3DNode {
    let target = {} as Part3DNode;
    const keys = Object.keys(source);

    for (const key of keys) {
      if (copyThreeJs) {
        target[key] = source[key];
      } else {
        if (key == "physicsBody") {
          target[key] = null;
        } else if (key == "part3D") {
          target[key] = source[key];
          target[key].part = null;
        } else {
          target[key] = source[key];
        }
      }
    }

    const partName = source.partDetails3D.params.name;
    target.partDetails3D.params.name = name ? name : `Copy of ${partName}`;

    if (copyThreeJs && source.part3D && source.part3D.part) {
      try {
        const loader = new THREE.ObjectLoader();
        target.part3D.part = loader.parse(JSON.parse(JSON.stringify(target.part3D.part)));
      } catch (e) {
        console.log(e);
      }
    }

    return target;
  }

  deepCopyProject(source: Project, name?: string, copyThreeJs?: boolean): Project {
    const keys = Object.keys(source);
    const target = new Project();

    for (const key of keys) {
      if (key != "nodes") {
        target[key] = source[key];
      } else {
        target[key] = [];
        for (const node of source[key]) {
          target[key].push(this.deepCopyPart3DNode(node,
            node.partDetails3D.params.name,
            copyThreeJs));
        }
      }
    }

    target.name = name ? name : `Copy of ${source.name}`;

    return target;
  }
}
