import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-pending-account',
  templateUrl: './pending-account.component.html',
  styleUrls: ['./pending-account.component.scss'],
})
export class PendingAccountComponent implements OnInit {
  isLoading = true;

  private routes = environment.routes;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  signin(): void {
    this.router.navigate([this.routes.signIn]);
  }
}
