<mat-form-field #formFieldRef="matFormField" appearance="fill" [ngClass]="formFieldClass">
  <mat-label>{{label}}</mat-label>
  <mat-select #matSelectRef [formControl]="_formControl" [matTooltip]="_formControl.disabled ? '':'Search..' " [matTooltipPosition]="'right'"
    (selectionChange)="onSelectionChange($event)" 
    (keydown)="onKeyPressed($event)"
    (closed)="clearSearchInput()"
    (opened)="clearSearchInput()"
    [disabled]="_formControl.disabled"
    [multiple]="multipleOption"
    required>
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id"
        (mouseover)="onMouseOver($event)"
        (mouseout)="onMouseOut($event)"
        (click)="onMouseClick($event)">
        {{option.name}}
      </mat-option>
  </mat-select>
</mat-form-field>