<mat-card class="mat-elevation-z4">
  <div class="silo-image-container">
    <img mat-card-image [src]="imageUrl" alt="silo project overview image" />
  </div>
  
  <div class="card-status" *ngIf="cardDetails.status">
    <mat-card-subtitle>
      <h1 class="card-name">
        {{ cardDetails.name }}
      </h1>
    </mat-card-subtitle>
    <!--
    <h3
      class="status"
      [ngClass]="{
        'status-pending': cardDetails.status === enumStatusProject.PENDING,
        'status-accepted': cardDetails.status === enumStatusProject.ACCEPTED,
        'status-rejected': cardDetails.status === enumStatusProject.REJECTED
      }"
      *ngIf="cardDetails.status !== enumStatusProject.PENDING"
    >
      {{ cardDetails.status }}
    </h3>-->
  </div>
  
  <mat-card-header>
    <mat-card-subtitle>
      <div>
        <p class="p-bold-without-margin">
          Storage capacity :
          {{ cardDetails.storageCapacity ? cardDetails.storageCapacity : 0 }}
        </p>
      </div>
      <div>
        <p class="p-bold-without-margin">
          Handling capacity :
          {{ cardDetails.handlingCapacity ? cardDetails.handlingCapacity : 0 }}
        </p>
      </div>
    </mat-card-subtitle>
    <br />
    <br />
    <mat-card-subtitle>
      <p class="last-Update">Last Updated:</p>
      <time> {{ cardDetails.lastUpdatedAt }} </time>
    </mat-card-subtitle>
    <mat-card-subtitle>
      <p class="last-Update">Created At:</p>
      <time> {{ cardDetails.createdAt }} </time>
    </mat-card-subtitle>
  </mat-card-header>
  <hr />
  <!-- Project card actions -->
  <mat-card-actions *ngIf="cardDetails.type === 0 && !isPlantSelect">
    <!--
    <button
      mat-flat-button
      class="button-rfp"
      *ngIf="cardDetails.status === enumStatusProject.NOT_SENT"
      (click)="sendQuotationRequestClickedEvent(cardDetails)"
    >
      Quotation
    </button>
    <button
      mat-button
      class="green-button"
      (click)="viewQuotationDetailsClickedEvent(cardDetails)"
      *ngIf="cardDetails.status !== enumStatusProject.NOT_SENT"
    >
      Quotation details
    </button>
    -->
    <button
      mat-flat-button
      class="white-button"
      (click)="deleteProjectClickedEvent(cardDetails)"
      *ngIf="
        cardDetails.status === enumStatusProject.REJECTED ||
        cardDetails.status === enumStatusProject.NOT_SENT
      "
    >
      Delete
    </button>
    <button
      mat-flat-button
      class="green-button"
      (click)="editProjectClickedEvent(cardDetails)"
    >
      Edit
    </button>
    <button
      mat-flat-button
      class="green-button"
      (click)="downloadProjectClickedEvent(cardDetails)"
    >
      Download
    </button>
  </mat-card-actions>
  <!-- Template card actions -->
  <mat-card-actions
    class="mat-card-template"
    *ngIf="cardDetails.type === 'template' && !isPlantSelect"
  >
    <button
      mat-flat-button
      class="green-button button-new-silo"
      (click)="newSiloFromTemplateClickedEvent(cardDetails)"
    >
      New silo
    </button>
  </mat-card-actions>
  <!-- Plant card actions -->
  <mat-card-actions class="mat-card-template" *ngIf="isPlantSelect">
    <button
      mat-flat-button
      class="green-button"
      (click)="editProjectClickedEvent(cardDetails)"
    >
      Select
    </button>
  </mat-card-actions>
</mat-card>
