import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: 'input[type="number"]',
})
export class DisableScrollDirective {
  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent) {
    event.preventDefault();
  }
  @HostListener('keydown', ['$event'])
  disableEvents(event: KeyboardEvent) {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
    }
  }
}
