import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Material design
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MiscModule } from '../shared/modules/misc/misc.module';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { ProjectsModule } from '../projects/projects.module';
import { MatMenuModule } from '@angular/material/menu';
import { SignupComponent } from './signup.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIcon, MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [SignupComponent],
  imports: [
    CommonModule,
    // Material
    MatTabsModule,
    MatRadioModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSliderModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    MatTableModule,
    FormsModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatCheckboxModule,
    MiscModule,
    MatButtonModule,
    MatSnackBarModule,
    MatSortModule,
    ProjectsModule,
    MatMenuModule,
  ],
  exports: [SignupComponent],
})
export class SignUpModule {}
