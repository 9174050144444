import { Component, OnInit } from '@angular/core';
import { GenericSnackBarService } from 'src/app/shared/services/generic-snack-bar.service';
import { NotifyService } from 'src/app/shared/services/notify.service';

@Component({
  selector: 'app-notify-download',
  templateUrl: './notify-download.component.html',
  styleUrls: ['./notify-download.component.scss'],
})
export class NotifyDownloadComponent implements OnInit {
  public maxFiles = 0;
  public count = false;
  public downloading = false;
  public downloadedFiles = 0;
  constructor(
    private notificationService: NotifyService,
    private snackBar: GenericSnackBarService
  ) {}
  ngOnInit(): void {
    this.notificationService.count$.subscribe((r) => {
      this.count = r;
    });
    this.notificationService.loading$.subscribe((r) => {
      this.maxFiles = r.maxFiles;
      this.downloadedFiles = r.downloadedFiles;
      if (!r.downloading) {
        setTimeout(() => {
          this.downloading = false;
        }, 1000);
      } else {
        this.downloading = true;
      }
      if (!r.downloading && r.downloadedFiles > 0) {
        setTimeout(() => {
          this.snackBar.showSuccess(
            'All the project files have been downloaded!'
          );
        }, 2000);
      }
    });
  }
}
