import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { routeMapPrototype } from '../shared/mappings/route-map';
import { AuthService } from '../shared/services/auth.service';
import { environment } from '../../environments/environment';
import {
  ProjectHelperServiceEnum,
  ProjectTypeEnum,
  QuotationTypeEnum,
} from '../shared/models/enums';
import { ApiService } from '../shared/services/api.service';
import { Subject } from 'rxjs';
import { WINDOW } from '@ng-toolkit/universal';
import { OperationResult } from '../shared/models/helper-service-events';
import { ProjectHelperService } from '../shared/services/project-helper.service';
import { GenericSnackBarService } from '../shared/services/generic-snack-bar.service';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
})
export class MainNavComponent implements OnInit {
  private routeMap = routeMapPrototype;
  public routes = environment.routes;
  public isAdmin;
  public isOpen = false;
  public isMobile = true;
  private closed = false;
  public isOpenInquiries = true;
  public isOpenTeam = true;
  public isOpenProjects = true;
  private DEFAULT_IMAGE_URL = '../../assets/siloapp-thumbnail-default.png';
  private projectService$: Subject<OperationResult> =
    new Subject<OperationResult>();

  constructor(
    private api: ApiService,
    private router: Router,
    private auth: AuthService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private projectHelper: ProjectHelperService,
    private snackBar: GenericSnackBarService,
    @Inject(WINDOW) private window: Window
  ) {
    this.api.http = this.http;
  }
  ngOnInit(): void {
    this.projectService$.subscribe((r: OperationResult) => {
      if (r.status === ProjectHelperServiceEnum.CREATED) {
        this.router
          .navigate([this.routes.projects, r.data._id])
          .then(() => window.location.reload());
      }
    });
    this.typeOfMenu();
    this.isAdmin = this.auth.isAdmin();
    this.window.addEventListener('resize', () => {
      const width = this.window.innerWidth;
      if (width < 800) {
        if (this.closed) {
          this.isMobile = true;
          return;
        }
        this.isMobile = true;
        this.closed = true;
        this.isOpen = false;
      } else {
        this.closed = false;
        this.isOpen = true;
        this.isMobile = false;
      }
    });
  }
  public typeOfMenu() {
    if (window.innerWidth > 800) {
      this.isMobile = false;
      this.isOpen = true;
    }
  }

  createNewProject(): void {
    let alreadyInProject = false;
    const pathname = window.location.pathname;
    const hasPath = pathname.split('/');
    if (hasPath.length >= 3) {
      alreadyInProject = true;
    }
    if (alreadyInProject) {
      this.snackBar
        .showWarning(
          'Are you sure you want to create a new project? You might want to save the current project first.'
        )
        .afterDismissed()
        .subscribe((info) => {
          if (info.dismissedByAction === true) {
            this.projectHelper.createProject(
              this.projectService$,
              ProjectTypeEnum.PROJECT,
              this.DEFAULT_IMAGE_URL
            );
          }
        });
    } else {
      this.projectHelper.createProject(
        this.projectService$,
        ProjectTypeEnum.PROJECT,
        this.DEFAULT_IMAGE_URL
      );
    }
  }

  createNewTemplate(): void {
    //this.router.navigate([this.routes.templates]);
  }

  createNewQuotation(): void {
    const navigationLink = `${this.routes.quotations}/new`;
    this.router.navigate([navigationLink], {
      queryParams: {
        quotationType: QuotationTypeEnum.SINGLE_MACHINES,
      },
    });
  }
  createNewMailer(): void {
    const navigationLink = `${this.routes.quotations}/send-mail`;
    this.router.navigate([navigationLink]);
  }
  createNewPlantQuotation(): void {
    const navigationLink = `${this.routes.quotations}/plant`;
    this.router.navigate([navigationLink]);
  }
  getPageTitle(): string {
    const currentUrl = this.router.url;
    return this.routeMap.get(currentUrl);
  }
  signOut(): void {
    this.auth.logout();
  }
  navigateAllProjects(): void {
    this.router.navigate([this.routes.projects]);
  }
  navigateAllTemplates(): void {
    this.router.navigate([this.routes.templates]);
  }
  delay() {
    return new Promise((isOpenMenu) => setTimeout(isOpenMenu, 400));
  }
  async isOpenMenu() {
    if (this.isOpen) {
      await this.delay();
      this.isOpen = false;
    } else {
      this.isOpen = true;
    }
  }
  openProjectsMenu() {
    if (this.isOpenProjects) {
      this.isOpenProjects = false;
    } else {
      this.isOpenProjects = true;
    }
  }
  openTeamMenu() {
    if (this.isOpenTeam) {
      this.isOpenTeam = false;
    } else {
      this.isOpenTeam = true;
    }
  }
  openInquiriesMenu() {
    if (this.isOpenInquiries) {
      this.isOpenInquiries = false;
    } else {
      this.isOpenInquiries = true;
    }
  }
}
