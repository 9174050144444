<mat-expansion-panel [expanded]="cardExpanded">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{ equipment.partDetails3D.params.name }}
    </mat-panel-title>
    <button
      mat-button
      class="red-button"
      (click)="deleteEquipmentEvent(equipment)"
      *ngIf="showDeleteButton"
    >
      <mat-icon>delete_outline</mat-icon>
    </button>
  </mat-expansion-panel-header>
  <!-- Silo bin -->
  <div *ngIf="equipment.type === partTypeEnum.SILO_BIN">
    <mat-form-field appearance="outline">
      <mat-label>Volume (m^3)</mat-label>
      <input
        matInput
        class="input-content"
        [(ngModel)]="equipment.partDetails3D.params.volume"
        [disabled]="!editEquipmentCard"
        (change)="onChange()"
        (blur)="formatInput($event.target)"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Diameter (m)</mat-label>
      <input
        matInput
        class="input-content"
        [(ngModel)]="equipment.partDetails3D.params.dx.x"
        [disabled]="!editEquipmentCard"
        (blur)="formatInput($event.target)"
      />
    </mat-form-field>
  </div>

  <!-- Buffere bin -->
  <div *ngIf="equipment.type === partTypeEnum.BUFFER_BIN">
    <mat-form-field appearance="outline">
      <mat-label>Volume (m^3)</mat-label>
      <input
        matInput
        class="input-content"
        [(ngModel)]="equipment.partDetails3D.params.volume"
        [disabled]="!editEquipmentCard"
        (blur)="formatInput($event.target)"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Diameter (m)</mat-label>
      <input
        matInput
        class="input-content"
        [(ngModel)]="equipment.partDetails3D.params.dx.x"
        [disabled]="!editEquipmentCard"
        (blur)="formatInput($event.target)"
      />
    </mat-form-field>
  </div>

  <!-- Delivery bin -->
  <div *ngIf="equipment.type === partTypeEnum.DELIVERY">
    <mat-form-field appearance="outline">
      <mat-label>Volume (m^3)</mat-label>
      <input
        matInput
        class="input-content"
        [disabled]="!editEquipmentCard"
        [(ngModel)]="equipment.partDetails3D.params.volume"
        (blur)="formatInput($event.target)"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Diameter (m)</mat-label>
      <input
        matInput
        class="input-content"
        [disabled]="!editEquipmentCard"
        [(ngModel)]="equipment.partDetails3D.params.dx.x"
        (blur)="formatInput($event.target)"
      />
    </mat-form-field>
  </div>

  <!-- Elevator -->
  <div *ngIf="equipment.type === partTypeEnum.ELEVATOR">
    <mat-form-field appearance="outline">
      <mat-label>Capacity (t/h)</mat-label>
      <input
        matInput
        class="input-content"
        [disabled]="!editEquipmentCard"
        [(ngModel)]="equipment.partDetails3D.params.capacity"
        (blur)="formatInput($event.target)"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Height (m)</mat-label>
      <input
        matInput
        class="input-content"
        [disabled]="!editEquipmentCard"
        [(ngModel)]="equipment.partDetails3D.params.dx.y"
        (blur)="formatInput($event.target)"
      />
    </mat-form-field>
  </div>

  <!-- Chain conveyor -->
  <div *ngIf="equipment.type === partTypeEnum.CHAIN_CONVEYOR">
    <mat-form-field appearance="outline">
      <mat-label>Capacity (t/h)</mat-label>
      <input
        matInput
        class="input-content"
        [disabled]="!editEquipmentCard"
        [(ngModel)]="equipment.partDetails3D.params.capacity"
        (blur)="formatInput($event.target)"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Length (m)</mat-label>
      <input
        matInput
        class="input-content"
        [disabled]="!editEquipmentCard"
        [(ngModel)]="equipment.partDetails3D.params.dx.x"
        (blur)="formatInput($event.target)"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Number of outlets</mat-label>
      <input
        matInput
        class="input-content"
        [disabled]="true"
        [(ngModel)]="equipment.partDetails3D.params.nOutputs"
        (blur)="formatInput($event.target)"
      />
    </mat-form-field>
  </div>

  <!-- Belt conveyor -->
  <div *ngIf="equipment.type === partTypeEnum.BELT_CONVEYOR">
    <!--<mat-form-field appearance="outline">
      <mat-label>Quantity</mat-label>
      <input
        matInput
        class="input-content"
        [disabled]="!editEquipmentCard"
        [(ngModel)]="equipment.partDetails3D.quantity"
      />
    </mat-form-field>-->
    <mat-form-field floatLabel="auto" appearance="outline">
      <mat-label>Capacity (t/h)</mat-label>
      <input
        matInput
        class="input-content"
        [disabled]="!editEquipmentCard"
        [(ngModel)]="equipment.partDetails3D.params.capacity"
        (blur)="formatInput($event.target)"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Length (m)</mat-label>
      <input
        matInput
        class="input-content"
        [disabled]="!editEquipmentCard"
        [(ngModel)]="equipment.partDetails3D.params.dx.x"
        (blur)="formatInput($event.target)"
      />
    </mat-form-field>
  </div>

  <!-- Dryer -->
  <div *ngIf="equipment.type === partTypeEnum.DRYER">
    <mat-form-field appearance="outline">
      <mat-label>Capacity (t/h)</mat-label>
      <input
        matInput
        class="input-content"
        [disabled]="!editEquipmentCard"
        [(ngModel)]="equipment.partDetails3D.params.capacity"
        (blur)="formatInput($event.target)"
      />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Moisture Reduction (%)</mat-label>
      <input
        matInput
        class="input-content"
        [disabled]="!editEquipmentCard"
        [(ngModel)]="equipment.partDetails3D.params.moistureReduction"
        (blur)="formatInput($event.target)"
      />
    </mat-form-field>
  </div>

  <!-- Cleaner -->
  <div *ngIf="equipment.type === partTypeEnum.CLEANER">
    <mat-form-field floatLabel="auto" appearance="outline">
      <mat-label>Capacity (t/h)</mat-label>
      <input
        matInput
        class="input-content"
        [disabled]="!editEquipmentCard"
        [(ngModel)]="equipment.partDetails3D.params.capacity"
        (blur)="formatInput($event.target)"
      />
    </mat-form-field>
  </div>

  <!-- Conditioning -->
  <div *ngIf="equipment.type === partTypeEnum.CONDITIONING">
    <mat-form-field appearance="outline">
      <mat-label>Capacity (t/h)</mat-label>
      <input
        matInput
        class="input-content"
        [disabled]="!editEquipmentCard"
        [(ngModel)]="equipment.partDetails3D.params.capacity"
        (blur)="formatInput($event.target)"
      />
    </mat-form-field>
  </div>

  <!-- Accessories -->
  <div *ngIf="equipment.type === partTypeEnum.ACCESSORIES">
    <mat-form-field appearance="fill">
      <mat-label class="form-label">Details</mat-label>
      <textarea
        matInput
        class="input-content"
        type="text"
        [disabled]="!editEquipmentCard"
        [(ngModel)]="equipment.partDetails3D.specifications"
      >
      </textarea>
    </mat-form-field>
  </div>
</mat-expansion-panel>
