import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PaymentAcceptedEnum } from 'src/app/shared/models/enums';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-payment-approval',
  templateUrl: './payment-approval.component.html',
  styleUrls: ['./payment-approval.component.scss'],
})
export class PaymentApprovalComponent implements OnInit {
  @Output() buttonClicked = new EventEmitter();
  userName: string;
  isLoading = true;

  constructor(
    private auth: AuthService
  ) {
  }

  ngOnInit(): void {
    if (this.auth.getFirstName()) {
      this.userName = this.auth.getFirstName();
    } else {
      this.userName = undefined;
    }
    this.isLoading = false;
  }

  cancel(): void {
    this.buttonClicked.emit({ action: PaymentAcceptedEnum.REJECTED });
  }

  continue(): void {
    this.buttonClicked.emit({action: PaymentAcceptedEnum.ACCEPTED});
  }
}
