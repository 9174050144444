<mat-drawer-container
  class="container"
  (backdropClick)="sidenavDrawer.toggle(); isOpenMenu()"
>
  <mat-drawer
    #sidenavDrawer
    class="sidenav"
    [mode]="isMobile ? 'over' : 'side'"
    [opened]="isMobile ? 'false' : 'true'"
  >
    <button mat-icon-button (click)="sidenavDrawer.toggle(); isOpenMenu()">
      <mat-icon class="menu-icon-menu">menu</mat-icon>
    </button>
    <div class="top-space"></div>
    <div class="image-container">
      <img class="image" [src]="'./assets/siloapp_logo.svg'" alt="" />
    </div>
    <div class="middle-wrapper">
      <button color="primary" mat-button (click)="navigateAllProjects()">
        <mat-icon>file_copy</mat-icon>
        Projects
      </button>
      <!--
      <button
        mat-button
        *ngIf="isOpenProjects"
        (click)="navigateAllProjects()"
        style="padding-left: 20%"
      >
        <i class="material-icons">file_present</i>
        Overview
      </button>
      <button
        mat-button
        *ngIf="isOpenProjects"
        (click)="createNewProject()"
        style="padding-left: 20%"
      >
        <i class="material-icons">my_library_add</i>
        New Project
      </button>
      <button color="primary" mat-button (click)="navigateAllTemplates()">
        <mat-icon>library_books</mat-icon>
        Templates
      </button>
      <button color="primary" mat-button (click)="openInquiriesMenu()">
        <mat-icon>file_copy</mat-icon>
        Inquiries
      </button>
      <button
        mat-button
        *ngIf="isOpenInquiries"
        routerLink="/quotations"
        style="padding-left: 20%"
      >
        <i class="material-icons">file_present</i>
        Overview
      </button>
      <button
        mat-button
        *ngIf="isOpenInquiries"
        (click)="createNewPlantQuotation()"
        style="padding-left: 20%"
      >
        <i class="material-icons">my_library_add</i>
        +Plant
      </button>
      <button
        mat-button
        *ngIf="isOpenInquiries"
        (click)="createNewMailer()"
        style="padding-left: 20%"
      >
        <i class="material-icons">my_library_add</i>
        +Send mail
      </button>
      -->
      <!--- 
      <button
        mat-button
        *ngIf="isOpenInquiries"
        (click)="createNewQuotation()"
        style="padding-left: 20%"
      >
        <i class="material-icons">my_library_add</i>
        +Single machines
      </button>
      <button color="primary" mat-button (click)="openTeamMenu()">
        <mat-icon>file_copy</mat-icon>
        Team
      </button>
      <button
        mat-button
        *ngIf="isOpenTeam"
        routerLink="/team"
        style="padding-left: 20%"
      >
        <i class="material-icons">file_present</i>
        Overview
      </button>
      <button
        mat-button
        *ngIf="isOpenTeam"
        routerLink="/team"
        style="padding-left: 20%"
      >
        <i class="material-icons">my_library_add</i>
        Reports
      </button>
      -->
      <button color="primary" mat-button routerLink="/account">
        <mat-icon>account_box</mat-icon>
        Account
      </button>
      <!--
      <button color="primary" mat-button routerLink="/billing">
        <mat-icon>account_balance</mat-icon>
        Billing & Payments
      </button>
      -->
      <button color="primary" mat-button routerLink="/tutorial">
        <mat-icon>school</mat-icon>
        Tutorial
      </button>
      <button color="primary" mat-button routerLink="/contact-us">
        <mat-icon>contact_support</mat-icon>
        Contact
      </button>
      <button color="primary" mat-button routerLink="/admin" *ngIf="isAdmin">
        <mat-icon>storage</mat-icon>
        Admin dashboard
      </button>
    </div>
  </mat-drawer>
  <mat-drawer-content class="sidenav-content">
    <mat-toolbar color="primary">
      <mat-toolbar-row>
        <button
          mat-icon-button
          (click)="sidenavDrawer.toggle(); isOpenMenu()"
          *ngIf="!isOpen"
        >
          <mat-icon class="menu-icon">menu</mat-icon>
        </button>
        <div class="layout-buttons">
          <!-- this is where you add a set of icons for quick actions on the toolbar -->
          <!-- <mat-icon>favorite</mat-icon> -->
          <button
            mat-stroked-button
            class="orange-button"
            (click)="createNewProject()"
          >
            New Project
          </button>
          <button
            mat-mini-fab
            [matMenuTriggerFor]="topBarMenu"
            class="button-question"
          >
            ?
          </button>
          <mat-menu #topBarMenu="matMenu">
            <!-- Request consulting services button -->
            <button mat-menu-item routerLink="/contact-us">
              <mat-icon>chat_bubble_outline</mat-icon>
              <span>Ask a specialist</span>
            </button>
            <!-- Help button -->
            <button mat-menu-item routerLink="/contact-us">
              <mat-icon>help_outline</mat-icon>
              <span>Help center</span>
            </button>
            <!-- Sign out button -->
            <button mat-menu-item (click)="signOut()">
              <mat-icon>stop_circle</mat-icon>
              <span>Sign out</span>
            </button>
          </mat-menu>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
