import { Injectable } from '@angular/core';
import {
  BELT_CONVEYOR_PARAMS,
  CHAIN_CONVEYOR_PARAMS,
} from '../constants/equipment-parameters-default';
import {
  PartAreaEnum,
  PartTypeEnum,
  SceneGeneratorEnum,
} from '../models/enums';
import {
  Bin3DParams,
  Cleaner3DParams,
  Conveyor3DParams,
  Dryer3DParams,
  Elevator3DParams,
  Intake3DParams,
  Part3DNode,
  PartDetails3DEditsEvent,
  SceneGeneratorEvent,
  SiloGroupOrientation,
} from '../models/part';
import { Subject } from 'rxjs';
import { EquipmentGeneratorService } from './equipment-generator.service';
import { VectorService } from './vector.service';
import { GenericSnackBarService } from './generic-snack-bar.service';
import * as THREE from 'three';
import { SiloPlantGeneratorService } from './silo-plant-generator.service';

@Injectable({
  providedIn: 'root',
})
export class SiloPlantCustomizerService {
  constructor(
    private equipmentGenerator: EquipmentGeneratorService,
    private geometry: VectorService,
    private snackBar: GenericSnackBarService,
    private siloGenerator: SiloPlantGeneratorService
  ) {}

  editEquipmentSpecifications(
    nodes: Part3DNode[],
    event: PartDetails3DEditsEvent
  ): Part3DNode[] {
    if (event) {
      const node = nodes.find(
        (x) => x.partDetails3D.params.name === event.name
      );
      if (node) {
        const index = nodes.indexOf(node);
        const originalPartType = node.type;
        nodes[index].children = [...event.children];
        nodes[index].parents = [...event.parents];
        nodes[index].area = event.area;
        nodes[index].type = event.type;
        nodes[index].subType = event.subType;
        nodes[index].partDetails3D.params = event.params;
        if (
          originalPartType === PartTypeEnum.SILO_BIN ||
          originalPartType === PartTypeEnum.BUFFER_BIN ||
          originalPartType === PartTypeEnum.DELIVERY
        ) {
          nodes[index].partDetails3D.params.dx.x = event.params.dx.x;
          nodes[index].partDetails3D.params.dx.z = event.params.dx.x;

          const volume = (event.params as Bin3DParams).volume;
          const height =
            volume / (Math.PI * Math.pow(event.params.dx.x / 2, 2));
          (nodes[index].partDetails3D.params as Bin3DParams).dx.y = height;
          (nodes[index].partDetails3D.params as Bin3DParams).volume = volume;
        }

        if (originalPartType === PartTypeEnum.INTAKE) {
          for (const object of nodes) {
            if (object !== nodes[index]) {
              switch (object.type) {
                case PartTypeEnum.BELT_CONVEYOR: {
                  (object.partDetails3D.params as Conveyor3DParams).capacity = (
                    event.params as Conveyor3DParams
                  ).capacity;
                  break;
                }
                case PartTypeEnum.CHAIN_CONVEYOR: {
                  (object.partDetails3D.params as Conveyor3DParams).capacity = (
                    event.params as Conveyor3DParams
                  ).capacity;
                  break;
                }
                case PartTypeEnum.CLEANER: {
                  (object.partDetails3D.params as Cleaner3DParams).capacity = (
                    event.params as Cleaner3DParams
                  ).capacity;
                  break;
                }
                case PartTypeEnum.ELEVATOR: {
                  (object.partDetails3D.params as Elevator3DParams).capacity = (
                    event.params as Elevator3DParams
                  ).capacity;
                  break;
                }
              }
            }
          }
        } else if (originalPartType === PartTypeEnum.CHAIN_CONVEYOR) {
          if (node.parents[0]) {
            const parent = nodes.find(
              (x) => x.id === parseInt(node.parents[0], 0)
            );
            if (parent && parent.type === PartTypeEnum.INTAKE) {
              (parent.partDetails3D.params as Intake3DParams).capacity = (
                event.params as Intake3DParams
              ).capacity;
            }
          }
          if (event.type === PartTypeEnum.BELT_CONVEYOR) {
            (nodes[index].partDetails3D.params as Conveyor3DParams).color =
              BELT_CONVEYOR_PARAMS.color;
            (nodes[index].partDetails3D.params as Conveyor3DParams).nOutputs =
              undefined;
          }
        } else if (originalPartType === PartTypeEnum.BELT_CONVEYOR) {
          if (node.parents[0]) {
            const parent = nodes.find(
              (x) => x.id === parseInt(node.parents[0], 0)
            );
            if (parent && parent.type === PartTypeEnum.INTAKE) {
              (parent.partDetails3D.params as Intake3DParams).capacity = (
                event.params as Intake3DParams
              ).capacity;
            }
          }
          if (event.type === PartTypeEnum.CHAIN_CONVEYOR) {
            (nodes[index].partDetails3D.params as Conveyor3DParams).color =
              CHAIN_CONVEYOR_PARAMS.color;
            (nodes[index].partDetails3D.params as Conveyor3DParams).nOutputs =
              undefined;
          }
        }
      }
    }

    return nodes;
  }

  redrawPartFactory(
    node: Part3DNode,
    nodes: Part3DNode[],
    orientation: SiloGroupOrientation,
    sceneObjects$: Subject<SceneGeneratorEvent>
  ): boolean {
    if (
      node.type === PartTypeEnum.ELEVATOR &&
      node.area === PartAreaEnum.STORAGE
    ) {
      this.redrawMainElevator(nodes, sceneObjects$);
      return true;
    }
    if (
      node.type === PartTypeEnum.ELEVATOR &&
      node.area === PartAreaEnum.CLEANER
    ) {
      this.redrawCleanerElevator(nodes, sceneObjects$);
      return true;
    }
    if (
      node.type === PartTypeEnum.ELEVATOR &&
      node.area === PartAreaEnum.DRYER
    ) {
      this.redrawDryerElevator(nodes, sceneObjects$);
      return true;
    }
    if (node.type === PartTypeEnum.DRYER) {
      this.redrawDryer(nodes, sceneObjects$);
      return true;
    }
    if (node.type === PartTypeEnum.CLEANER) {
      this.redrawCleaner(nodes, sceneObjects$);
      return true;
    }
    if(node.type === PartTypeEnum.SILO_BIN){
      this.redrawSiloBin(node, nodes, sceneObjects$);
      return true;
    }
    if (node.type === PartTypeEnum.BUFFER_BIN) {
      this.redrawBufferBin(nodes, orientation, sceneObjects$);
      return true;
    }
    if (node.type == PartTypeEnum.CHAIN_CONVEYOR || node.type === PartTypeEnum.BELT_CONVEYOR) {
      this.redrawConveyors(nodes, orientation, sceneObjects$)
      return true;
    }
    return false;
  }

  redrawMainElevator(
    nodes: Part3DNode[],
    sceneObjects$: Subject<SceneGeneratorEvent>
  ): void {
    const mainElevator = nodes.find(
      (n) => n.area === PartAreaEnum.STORAGE && n.type === PartTypeEnum.ELEVATOR
    );
    if (mainElevator) {
      this.equipmentGenerator
        .getElevator(mainElevator.partDetails3D.params as Elevator3DParams)
        .subscribe((p) => {
          p.part.name = mainElevator.partDetails3D.params.name;
          p.part.position.x = mainElevator.position.x;
          p.part.position.y = mainElevator.position.y;
          p.part.position.z = mainElevator.position.z;
          p.part.rotateY(mainElevator.orientation.y);
          mainElevator.part3D = p;
          sceneObjects$.next({
            part3DNode: mainElevator,
            next: SceneGeneratorEnum.DO_NOTHING,
          });
        });
    } else {
      this.snackBar.showError(
        'Cannot redraw plant. Missing main elevator. Please try again using at least one main elevator.'
      );
      sceneObjects$.next({
        part3DNode: undefined,
        next: SceneGeneratorEnum.DO_NOTHING,
      });
    }
  }

  redrawCleanerElevator(
    nodes: Part3DNode[],
    sceneObjects$: Subject<SceneGeneratorEvent>
  ): void {
    const cleanerElevator = nodes.find(
      (n) => n.type === PartTypeEnum.ELEVATOR && n.area === PartAreaEnum.CLEANER
    );
    if (cleanerElevator) {
      const mainElevator = nodes.find(
        (n) =>
          n.area === PartAreaEnum.STORAGE && n.type === PartTypeEnum.ELEVATOR
      );

      // TODO: check overlap between cleaner elevator and main elevator
      const overlap = true;

      let position: THREE.Vector3 = new THREE.Vector3(
        cleanerElevator.position.x,
        cleanerElevator.position.y,
        cleanerElevator.position.z
      );
      // TODO: Replace 2 with amount of padding in the direction from main elevator to cleaner elevator
      if (overlap) {
        position = this.geometry.addMultipleVectors([
          mainElevator.position,
          this.geometry.multiplyScalarVector(
            this.geometry.normalize(
              this.geometry.subtractVectors(
                cleanerElevator.position,
                mainElevator.position
              )
            ),
            2
          ),
        ]);
      }
      this.equipmentGenerator
        .getElevator(cleanerElevator.partDetails3D.params as Elevator3DParams)
        .subscribe((p) => {
          p.part.name = cleanerElevator.partDetails3D.params.name;
          p.part.position.x = position.x;
          p.part.position.y = position.y;
          p.part.position.z = position.z;
          p.part.rotateY(cleanerElevator.orientation.y);
          cleanerElevator.part3D = p;
          sceneObjects$.next({
            part3DNode: cleanerElevator,
            next: SceneGeneratorEnum.POSITION_CLEANER,
          });
        });
    } else {
      sceneObjects$.next({
        part3DNode: undefined,
        next: SceneGeneratorEnum.DO_NOTHING,
      });
    }
  }

  redrawCleaner(
    nodes: Part3DNode[],
    sceneObjects$: Subject<SceneGeneratorEvent>
  ): void {
    const cleaner = nodes.find(
      (n) => n.type === PartTypeEnum.CLEANER && n.area === PartAreaEnum.CLEANER
    );
    if (cleaner) {
      let elevator: Part3DNode;
      elevator = nodes.find(
        (n) =>
          n.type === PartTypeEnum.ELEVATOR && n.area === PartAreaEnum.CLEANER
      );
      if (!elevator) {
        elevator = nodes.find(
          (n) =>
            n.type === PartTypeEnum.ELEVATOR && n.area === PartAreaEnum.STORAGE
        );
      }
      if (elevator) {
        // TODO: check if cleaner overlaps with elevator.
        const overlap = true;

        let position = new THREE.Vector3(
          cleaner.position.x,
          cleaner.position.y,
          cleaner.position.z
        );
        // TODO: Replace 2 with amount of padding in the direction from main elevator to cleaner elevator
        if (overlap) {
          position = this.geometry.addMultipleVectors([
            elevator.position,
            this.geometry.multiplyScalarVector(
              this.geometry.normalize(
                this.geometry.subtractVectors(
                  cleaner.position,
                  elevator.position
                )
              ),
              2
            ),
          ]);
        }
        this.equipmentGenerator
          .getCleaner(cleaner.partDetails3D.params as Cleaner3DParams)
          .subscribe((p) => {
            p.part.name = cleaner.partDetails3D.params.name;
            p.part.position.x = position.x;
            p.part.position.y = position.y;
            p.part.position.z = position.z;
            p.part.rotateY(cleaner.orientation.y);
            cleaner.part3D = p;
            sceneObjects$.next({
              part3DNode: cleaner,
              next: SceneGeneratorEnum.DO_NOTHING,
            });
          });
      } else {
        sceneObjects$.next({
          part3DNode: undefined,
          next: SceneGeneratorEnum.DO_NOTHING,
        });
      }
    } else {
      sceneObjects$.next({
        part3DNode: undefined,
        next: SceneGeneratorEnum.DO_NOTHING,
      });
    }
  }

  redrawDryerElevator(
    nodes: Part3DNode[],
    sceneObjects$: Subject<SceneGeneratorEvent>
  ): void {
    const dryerElevator = nodes.find(
      (n) => n.type === PartTypeEnum.ELEVATOR && n.area === PartAreaEnum.DRYER
    );
    if (dryerElevator) {
      const mainElevator = nodes.find(
        (n) =>
          n.area === PartAreaEnum.STORAGE && n.type === PartTypeEnum.ELEVATOR
      );

      // todo: check overlap between cleaner elevator and main elevator
      const overlap = true;

      let position: THREE.Vector3 = new THREE.Vector3(
        dryerElevator.position.x,
        dryerElevator.position.y,
        dryerElevator.position.z
      );
      // TODO: Replace 2 with amount of padding in the direction from main elevator to cleaner elevator
      if (overlap) {
        position = this.geometry.addMultipleVectors([
          mainElevator.position,
          this.geometry.multiplyScalarVector(
            this.geometry.normalize(
              this.geometry.subtractVectors(
                dryerElevator.position,
                mainElevator.position
              )
            ),
            2
          ),
        ]);
      }
      this.equipmentGenerator
        .getElevator(dryerElevator.partDetails3D.params as Elevator3DParams)
        .subscribe((p) => {
          p.part.name = dryerElevator.partDetails3D.params.name;
          p.part.position.x = position.x;
          p.part.position.y = position.y;
          p.part.position.z = position.z;
          p.part.rotateY(dryerElevator.orientation.y);
          dryerElevator.part3D = p;
          sceneObjects$.next({
            part3DNode: dryerElevator,
            next: SceneGeneratorEnum.DO_NOTHING,
          });
        });
    } else {
      sceneObjects$.next({
        part3DNode: undefined,
        next: SceneGeneratorEnum.DO_NOTHING,
      });
    }
  }

  redrawDryer(
    nodes: Part3DNode[],
    sceneObjects$: Subject<SceneGeneratorEvent>
  ): void {
    const dryer = nodes.find(
      (n) => n.type === PartTypeEnum.DRYER && n.area === PartAreaEnum.DRYER
    );
    if (dryer) {
      const dryerElevator = nodes.find(
        (n) => n.type === PartTypeEnum.ELEVATOR && n.area === PartAreaEnum.DRYER
      );

      // TODO: Check collision between dryer and dryer elevator
      const overlap = true;

      let position: THREE.Vector3 = new THREE.Vector3(
        dryer.position.x,
        dryer.position.y,
        dryer.position.z
      );
      // TODO: Replace 2 with amount of padding in the direction from main elevator to cleaner elevator
      if (overlap) {
        position = this.geometry.addMultipleVectors([
          dryerElevator.position,
          this.geometry.multiplyScalarVector(
            this.geometry.normalize(
              this.geometry.subtractVectors(
                dryer.position,
                dryerElevator.position
              )
            ),
            2
          ),
        ]);
      }
      this.equipmentGenerator
        .getDryer(dryer.partDetails3D.params as Dryer3DParams)
        .subscribe((p) => {
          p.part.name = dryer.partDetails3D.params.name;
          p.part.position.x = position.x;
          p.part.position.y = position.y;
          p.part.position.z = position.z;
          p.part.rotateY(dryer.orientation.y);
          dryer.part3D = p;
          sceneObjects$.next({
            part3DNode: dryer,
            next: SceneGeneratorEnum.DO_NOTHING,
          });
        });
    } else {
      sceneObjects$.next({
        part3DNode: undefined,
        next: SceneGeneratorEnum.DO_NOTHING,
      });
    }
  }

  redrawBufferBin(
    nodes: Part3DNode[],
    orientation: SiloGroupOrientation,
    sceneObjects$: Subject<SceneGeneratorEvent>
  ): void {
    const bufferBins = nodes.filter(
      (n) => n.type === PartTypeEnum.BUFFER_BIN && n.area === PartAreaEnum.DRYER
    );
    if (bufferBins) {
      const dryer = nodes.find(
        (n) => n.type === PartTypeEnum.DRYER && n.area === PartAreaEnum.DRYER
      );

      let sortedBufferBins: Part3DNode[] = [];
      if (orientation.axis.T.x !== 0) {
        sortedBufferBins = bufferBins.sort((b1, b2) =>
          b1.position.x > b2.position.x ? 1 : -1
        );
      } else {
        sortedBufferBins = bufferBins.sort((b1, b2) =>
          b1.position.z > b2.position.z ? 1 : -1
        );
      }

      for (let i = 0; i < sortedBufferBins.length; i++) {
        // place buffer bin with respect to the previous bin
        // if first bin, then place it wrt the dryer
        let position = new THREE.Vector3(
          sortedBufferBins[i].position.x,
          sortedBufferBins[i].position.y,
          sortedBufferBins[i].position.z
        );
        if (i === 0) {
          // TODO: check if bbin overlaps with dryer
          const dryerOverlap = true;
          if (dryerOverlap) {
            position = this.geometry.addMultipleVectors([
              dryer.position,
              this.geometry.multiplyScalarVector(
                this.geometry.normalize(
                  this.geometry.subtractVectors(
                    sortedBufferBins[i].position,
                    dryer.position
                  )
                ),
                2
              ),
            ]);
          }
        } else {
          // TODO: check if bin overlaps with previous bin
          const binOverlap = true;
          if (binOverlap) {
            position = this.geometry.addMultipleVectors([
              sortedBufferBins[i - 1].position,
              this.geometry.multiplyScalarVector(
                this.geometry.normalize(
                  this.geometry.subtractVectors(
                    sortedBufferBins[i].position,
                    sortedBufferBins[i - 1].position
                  )
                ),
                2
              ),
            ]);
          }
        }
        this.equipmentGenerator
          .getBufferBin(sortedBufferBins[i].partDetails3D.params as Bin3DParams)
          .subscribe((p) => {
            p.part.name = sortedBufferBins[i].partDetails3D.params.name;
            p.part.position.x = position.x;
            p.part.position.y = position.y;
            p.part.position.z = position.z;
            p.part.rotateY(sortedBufferBins[i].orientation.y);
            sortedBufferBins[i].part3D = p;
            sceneObjects$.next({
              part3DNode: sortedBufferBins[i],
              next: SceneGeneratorEnum.POSITION_DRYER_ELEVATOR,
            });
          });
      }
    } else {
      sceneObjects$.next({
        part3DNode: undefined,
        next: SceneGeneratorEnum.DO_NOTHING,
      });
    }
  }

  redrawSiloBin(node:Part3DNode, nodes: Part3DNode[], sceneObjects$: Subject<SceneGeneratorEvent>): void {
    this.equipmentGenerator
      .getSiloBin(
        node.partDetails3D.params.dx,
        node.partDetails3D.params.color
      )
      .subscribe((b) => {
        b.part.name = node.partDetails3D.params.name;
        b.part.position.x = node.position.x;
        b.part.position.y = node.position.y;
        b.part.position.z = node.position.z;
        /** UPDATE BIN PARAMS */
        node.part3D = b;
        node.partDetails3D.params.dx.x = b.params.dx.x;
        node.partDetails3D.params.dx.y = b.params.dx.y;
        node.partDetails3D.params.dx.z = b.params.dx.z;

        sceneObjects$.next({
          part3DNode: node,
          next: SceneGeneratorEnum.POSITION_MAIN_ELEVATOR,
        });
      });
  }

  recenterSiloBins(nodes: Array<Part3DNode>, changedBin: Part3DNode, orientation: SiloGroupOrientation): void{

      for(let i = orientation.binsMatrix.length - 1; i > 0; i--){
        /**
         * Better solution would be to get the position of the bin that is the most distanced from the previous row, and start from the last row
         */
        const binId = this.getBiggestSiloBinId(orientation.binsMatrix[i])
        const bin = nodes.find((node) => node.id === binId);

        for(let j = 0; j < orientation.binsMatrix[i].length; j++){
          if(orientation.binsMatrix[i][j].id !== changedBin.id){
            if(orientation.axis.T.z){
              orientation.binsMatrix[i][j].physicsBody.position.z = bin.position.z;
            }
            else{
              orientation.binsMatrix[i][j].physicsBody.position.x = bin.position.x;
            }
          }
        }
      }
  }
  getBiggestSiloBinId(binsRow: Array<Part3DNode>): number{
    const maxBin = {
      maxDiameter: 0,
      bin: {} as Part3DNode
    };

    binsRow.forEach((b) => {
      if(b.part3D.params.dx.x >= maxBin.maxDiameter){
        maxBin.maxDiameter = b.part3D.params.dx.x;
        maxBin.bin = b;
      }
    })

    return maxBin.bin.id;
  }

  redrawDeliveryBin(
    nodes: Part3DNode[],
    sceneObjects$: Subject<SceneGeneratorEvent>
  ): void {
    const deliveryBins = nodes.filter(
      (n) =>
        n.type === PartTypeEnum.DELIVERY && n.area === PartAreaEnum.DELIVERY
    );
    if (deliveryBins) {
      let reference = nodes.find(
        (n) =>
          n.type === PartTypeEnum.CLEANER && n.area === PartAreaEnum.CLEANER
      );
      if (!reference) {
        reference = nodes.find(
          (n) =>
            n.type === PartTypeEnum.ELEVATOR &&
            (n.area === PartAreaEnum.STORAGE ||
              n.area === PartAreaEnum.DELIVERY)
        );
        if (reference) {
          // TODO: WHAT HAPPENS HERE @DANIEL?
        }
      }
    } else {
      sceneObjects$.next({
        part3DNode: undefined,
        next: SceneGeneratorEnum.DO_NOTHING,
      });
    }
  }

  // TODO: @DANIEL. CRED CA POTI REFOLOSI O PARTE DIN LOCICA DE LA GENERAREA DE INTAKE.
  redrawIntake(): void {}

  // TODO: @DANIEL. CRED CA POTI REFOLOSI O PARTE DIN LOGICA DE LA GENERAREA DE CONVEYORS.
  redrawConveyors(
    nodes: Part3DNode[],
    orientation: SiloGroupOrientation,
    sceneObjects$: Subject<SceneGeneratorEvent>
  ): void {
    this.siloGenerator.positionStorageLoadingConveyors(nodes, orientation, sceneObjects$, SceneGeneratorEnum.DO_NOTHING);
    this.siloGenerator.positionStorageReclaimingConveyors(nodes, orientation, sceneObjects$, SceneGeneratorEnum.DO_NOTHING);
    const intake = nodes.find(n => n.type == PartTypeEnum.INTAKE);
    this.siloGenerator.positionIntakeReclaimingConveyors(intake, nodes, orientation, sceneObjects$, SceneGeneratorEnum.DO_NOTHING);
    this.siloGenerator.positionDeliveryLoadingConveyors(nodes, orientation, sceneObjects$, undefined, SceneGeneratorEnum.DO_NOTHING);
    this.siloGenerator.positionDryerLoadingConveyors(nodes, orientation, sceneObjects$, undefined, SceneGeneratorEnum.DO_NOTHING);
    this.siloGenerator.positionDryerReclaimingConveyors(nodes, orientation, sceneObjects$, SceneGeneratorEnum.CONNECT_EQUIPMENTS);
  }
}
