import {ProjectHelperServiceEnum, QuotationHelperServiceEnum, UserHelperServiceEnum} from './enums';

export class OperationResult {
  status: ProjectHelperServiceEnum | QuotationHelperServiceEnum | UserHelperServiceEnum;
  data?: any;

  constructor(status: ProjectHelperServiceEnum | QuotationHelperServiceEnum | UserHelperServiceEnum, data?: any) {
    this.status = status;
    this.data = data;
  }
}
