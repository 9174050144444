import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import { Router } from '@angular/router';
import * as countryCityState from 'countrycitystatejson';
import { citiesMap, countriesList } from './shared/models/countries';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private auth: AuthService, private router: Router) {}

  isSignedIn(): boolean {
    return this.auth.isLoggedIn();
  }

  ngOnInit(): void {
    countriesList.forEach((countryCode) => {
      const citiesList = countryCityState.getStatesByShort(countryCode.code);
      citiesMap.set(countryCode.code, citiesList);
    });
  }
  @HostListener('keydown', ['$event'])
  disableUpDownArrow(event: KeyboardEvent) {
    const arrowKeys = ['ArrowUp', 'ArrowDown'];
    const isNumberInput = event.target instanceof HTMLInputElement && event.target.type === 'number';

    if (isNumberInput && arrowKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
}
