<div class="container">
  <h3>Delete equipment</h3>
  <!-- select all button -->
  <!-- list with checkboxes with all equipments -->
  <!-- cancel button  -->
  <!-- ok button  -->
  <mat-form-field appearance="fill" class="full-input">
    <mat-label>Equipments</mat-label>
    <mat-select [formControl]="equipments" required multiple>
      <mat-option *ngFor="let node of part3DNodes" [value]="node.id"
      (mouseover)="mouseOverOn($event)"
        (mouseout)="mouseOverOff($event)">
        {{node.id}}. {{ node.partDetails3D.params.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <!-- <app-dropdown-search [options]="part3DNodes" label="Select equipment" [_formControl]="equipments" [formFieldClass]="'full-input'" 
    (onMouseOverEvent)="mouseOverOn($event)" (onMouseOutEvent)="mouseOverOff($event)"
    [multipleOption]="true">
  </app-dropdown-search> -->
  <div class="layout-button">
    <button mat-button class="white-button" (click)="closeDialog()">Close</button>
    <button mat-button class="green-button" (click)="deleteEquipment()">Delete</button>
  </div>
</div>
