import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { User } from 'src/app/shared/models/user';
import { Part3DNode } from 'src/app/shared/models/part';
import {
  INDIVIDUAL_EQUIPMENTS_GROUPS,
  INDIVIDUAL_EQUIPMENTS_LIST,
} from 'src/app/shared/constants/individual-equipment-list';
import { Subject } from 'rxjs';
import { EquipmentCardComponent } from 'src/app/shared/modules/misc/equipment-card/equipment-card.component';
import { OperationResult } from 'src/app/shared/models/helper-service-events';
import { ProjectHelperService } from 'src/app/shared/services/project-helper.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserHelperService } from 'src/app/shared/services/user-helper.service';
import { GenericSnackBarService } from 'src/app/shared/services/generic-snack-bar.service';
import {
  ProjectHelperServiceEnum,
  QuotationStatusEnum,
  QuotationTypeEnum,
  UserHelperServiceEnum,
  UserStatusEnum,
} from 'src/app/shared/models/enums';
import { Producer } from 'src/app/shared/models/producer';
import { GRAIN_TYPES } from 'src/app/shared/constants/grain-types';
import { environment } from 'src/environments/environment';
import { Project } from 'src/app/shared/models/project';
import { ProjectFilesService } from 'src/app/shared/services/projectfiles.service';
import {
  ProjectFileType,
  SendProjectEmail,
} from 'src/app/shared/models/projectfile';

@Component({
  selector: 'app-send-mail',
  templateUrl: './send-mail.component.html',
  styleUrls: ['./send-mail.component.scss'],
})
export class SendMailComponent implements OnInit, OnDestroy {
  @ViewChildren(EquipmentCardComponent)
  equipmentCards: QueryList<EquipmentCardComponent>;
  receipts: FormControl = new FormControl(null, Validators.required);
  selectedProductTypes: FormControl = new FormControl(
    null,
    Validators.required
  );
  selectedMainProductTypes: FormControl = new FormControl(
    null,
    Validators.required
  );
  projectSendRequest: SendProjectEmail;
  totalEquimentsList: Part3DNode[] = [];
  projectEquimentsList: Part3DNode[] = [];
  selectedIndividualEquipmentsList: Part3DNode[] = [];
  selectedIndividualEquipmentsList$: Subject<Part3DNode> =
    new Subject<Part3DNode>();
  individualEquipmentsList: Part3DNode[] = INDIVIDUAL_EQUIPMENTS_LIST;
  individualEquipmentsGroups = INDIVIDUAL_EQUIPMENTS_GROUPS;
  canSeeMoreProducers = false;
  showMoreProducers = false;
  currentProject: Project = new Project();
  singleMachinesQuotationType = QuotationTypeEnum.SINGLE_MACHINES;
  siloPlantQuotationType = QuotationTypeEnum.SILO_PLANT;

  message: FormControl = new FormControl(null);
  availableProducers: Producer[];
  filterProducers: Producer[];
  availableProductTypes = GRAIN_TYPES;
  loading = true;
  mainProdType = GRAIN_TYPES;
  otherProdType = GRAIN_TYPES;

  private endpoint = environment.endpoint;
  private routes = environment.routes;
  private user: User;
  private projectId: string;

  private projectsService$: Subject<OperationResult> =
    new Subject<OperationResult>();
  private userService$: Subject<OperationResult> =
    new Subject<OperationResult>();
  private uploadService$: Subject<OperationResult> =
    new Subject<OperationResult>();
  private downloadService$: Subject<OperationResult> =
    new Subject<OperationResult>();
  private formFieldElement;
  private formFieldElement1;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private api: ApiService,
    private auth: AuthService,
    private projectFiles: ProjectFilesService,
    private projectHelper: ProjectHelperService,
    private userHelper: UserHelperService,
    private snackBar: GenericSnackBarService,
    private elementRef: ElementRef
  ) {
    this.api.http = this.http;
  }

  ngOnInit(): void {
    this.uploadService$.subscribe((event) => {
      if (event.status === ProjectHelperServiceEnum.SUCCESS) {
        this.save(null);
      }
    });
    this.downloadService$.subscribe((event) => {
      if (event.status === ProjectHelperServiceEnum.SUCCESS) {
        this.loading = false;
        this.snackBar.showSuccess(event.data?.message);
        this.router.navigate([this.routes.projects]);
      }
      if (event.status === ProjectHelperServiceEnum.ERROR) {
        this.snackBar.showError(
          `Failed to load project details. ${event.data.error.message}.`
        );
        this.loading = false;
      }
    });
    this.projectsService$.subscribe((event: OperationResult) => {
      if (event.status === ProjectHelperServiceEnum.EDITED) {
        if (event.data?.navigateTo != null) {
          this.loading = false;
          this.router.navigate([event.data.navigateTo]);
        } else {
          this.projectHelper.sendProjectFilesEmail(
            this.currentProject._id,
            this.projectSendRequest,
            this.downloadService$
          );
        }
      }
      if (event.status === ProjectHelperServiceEnum.ERROR) {
        this.loading = false;
      }
      if (event.status === ProjectHelperServiceEnum.LEAVE_PAGE) {
        this.loading = false;
        this.router.navigate(event.data.navigateTo);
      }
      if (event.status === ProjectHelperServiceEnum.LOADED) {
        this.loading = false;
      }
    });
    this.userService$.subscribe((event: OperationResult) => {
      if (event.status === UserHelperServiceEnum.LOADED) {
        if (event.data.hasOwnProperty('users')) {
          let country;
          if (this.user && this.user.company.country !== undefined) {
            country = this.user.company.country;
            this.canSeeMoreProducers = true;
          }
          this.availableProducers = event.data.users;
          if (this.canSeeMoreProducers) {
            this.filterProducers = this.availableProducers.filter(
              (producer: Producer) => producer.company.country === country
            );
            if (this.filterProducers.length <= 1) {
              this.canSeeMoreProducers = false;
            }
          }
        }
        if (event.data.hasOwnProperty('user')) {
          this.user = event.data.user;
        }
      }
      if (event.status === UserHelperServiceEnum.ERROR) {
        this.loading = false;
      }
    });
    this.initialize();
  }

  initialize(): void {
    this.loading = true;
    this.selectedIndividualEquipmentsList$.subscribe((equipment) => {
      this.selectedIndividualEquipmentsList.push(equipment);
      this.totalEquimentsList = [
        ...this.projectEquimentsList,
        ...this.selectedIndividualEquipmentsList,
      ];
    });
    this.route.params.subscribe((params) => {
      this.projectId = params.id;
      if (this.projectId) {
        this.projectHelper.getProject(this.projectsService$, this.projectId);
        this.api
          .getById<Project>(this.endpoint.projectById, this.projectId)
          .subscribe(
            (project) => {
              if (!project) {
                this.snackBar.showError(
                  'Empty equipment list. Please return to the design section before sending the email.'
                );
              } else {
                this.currentProject = project;
                this.projectEquimentsList = this.currentProject.nodes;
                if (this.currentProject.extras) {
                  this.selectedIndividualEquipmentsList =
                    this.currentProject.extras;
                }
                this.totalEquimentsList = [
                  ...this.projectEquimentsList,
                  ...this.selectedIndividualEquipmentsList,
                ];
                this.loading = false;
              }
            },
            (err) => {
              this.snackBar.showError(
                `Failed to load project details. Cause ${err.message}.`
              );
              this.loading = false;
            }
          );
      }
    });
    this.userHelper.getUserById(this.userService$, this.auth.getUserId());
    this.userHelper.getProducers(this.userService$, {
      status: UserStatusEnum.ACTIVE,
    });
  }

  // ====== INDIVIDUAL EQUIPMENTS INTERACTION ====== //
  addEquipment(equipmentId: number, groupId: number): void {
    for (const group of this.individualEquipmentsGroups) {
      if (group.id === groupId) {
        const selectedGroup = this.individualEquipmentsList.filter(
          (eq) => eq.partDetails3D.params.group === group.id
        );
        for (const equipment of selectedGroup) {
          if (equipment.id === equipmentId) {
            this.selectedIndividualEquipmentsList$.next(equipment);
            return;
          }
        }
      }
    }
  }

  private onChangeSelection($event): any[] {
    let list = [];
    const selected = $event.value;
    if (selected instanceof Array) {
      selected.forEach((id) => {
        list = GRAIN_TYPES.filter((v) => v.id !== id);
      });
      if (selected.length <= 0) {
        list = GRAIN_TYPES;
      }
    } else {
      list = GRAIN_TYPES.filter((v) => v.id !== selected);
    }
    return list;
  }

  onOtherProductTypesChange($event): void {
    this.mainProdType = this.onChangeSelection($event);
  }

  onMainProductTypesChange($event): void {
    this.otherProdType = this.onChangeSelection($event);
    this.formFieldElement1 = this.elementRef.nativeElement.querySelector(
      '.rfp-details-half-input-mainprodtype .mat-form-field-wrapper .mat-form-field-flex'
    );
    const v = $event.value;
    if (v) {
      this.formFieldElement1.style.border = '1px solid #dee5f6';
    }
  }

  onProducersChange($event): void {
    const selectedOptions = $event.value;
    this.formFieldElement = this.elementRef.nativeElement.querySelector(
      '.rfp-details-half-input-producers .mat-form-field-wrapper .mat-form-field-flex'
    );
    if (selectedOptions.length > 0) {
      this.formFieldElement.style.border = '1px solid #dee5f6';
    } else {
      this.formFieldElement.style.border = '1px solid red';
    }
  }

  producersList(): Producer[] {
    if (this.showMoreProducers) {
      return this.availableProducers;
    } else {
      return this.canSeeMoreProducers
        ? this.filterProducers
        : this.availableProducers;
    }
  }

  showMoreProducersBtn(): void {
    this.showMoreProducers = !this.showMoreProducers;
  }

  deleteEquipment(event: number): void {
    if (event) {
      this.selectedIndividualEquipmentsList.forEach((x, index) => {
        if (x.id === event) {
          this.selectedIndividualEquipmentsList.splice(index, 1);
        }
      });
      this.totalEquimentsList = [
        ...this.projectEquimentsList,
        ...this.selectedIndividualEquipmentsList,
      ];
    }
  }

  findEquipmentsInGroup(groupId: number): Part3DNode[] {
    return this.individualEquipmentsList.filter(
      (eq) => eq.partDetails3D.params.group === groupId
    );
  }

  isProducersEmpty() {
    if (!this.receipts.value) {
      return true;
    }
    if (this.receipts.value.length === 0) {
      return true;
    }
    return false;
  }

  // TODO: FIX THIS
  // handleCapacityChange($event): void {
  //   const value = $event.value;
  //   const dryer = this.currentProject.nodes.find(
  //     (n) => n.type === PartTypeEnum.DRYER
  //   );
  //   const excludedConveyors = this.currentProject.nodes.filter(
  //     (n) =>
  //       n.area === PartAreaEnum.DRYER &&
  //       (n.type === PartTypeEnum.CHAIN_CONVEYOR ||
  //         n.type === PartTypeEnum.BELT_CONVEYOR) &&
  //       n.parents.includes(dryer.id.toString())
  //   );
  //   const includedConveyors = this.currentProject.nodes.filter(
  //     (n) =>
  //       (n.type === PartTypeEnum.CHAIN_CONVEYOR ||
  //         n.type === PartTypeEnum.BELT_CONVEYOR) &&
  //       !excludedConveyors.includes(n)
  //   );
  //   for (let element of includedConveyors) {
  //     let card = this.equipmentCards.filter(
  //       (c) => c.equipment.id === element.id
  //     )[0];
  //     let param = element.partDetails3D.params as Dryer3DParams;
  //     param.capacity = value;
  //     card.InputRef.nativeElement.value = value;
  //   }
  // }

  validEmail(email: string): boolean {
    let validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  }

  async submitEmail(): Promise<void> {
    let recipientsValue: string = this.receipts.value;
    if (recipientsValue == null || recipientsValue.length <= 0) {
      this.loading = false;
      this.snackBar.showError(
        'Please specify the email address you want to send the project files!'
      );
      return;
    }
    this.formFieldElement = this.elementRef.nativeElement.querySelector(
      '.receipts-class .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline-thick'
    );
    this.formFieldElement1 = this.elementRef.nativeElement.querySelector(
      '.rfp-details-half-input-mainprodtype .mat-form-field-wrapper .mat-form-field-flex'
    );
    if (this.isProducersEmpty()) {
      this.formFieldElement.style.border = '1px solid red !important';
    } else {
      this.formFieldElement.style.border = 'none';
    }
    if (!this.selectedMainProductTypes.value) {
      this.formFieldElement1.style.border = '1px solid red';
    }
    if (this.receipts.errors || this.selectedMainProductTypes.errors) {
      this.loading = false;
      this.snackBar.showError(
        'Missing required information. Please complete the missing fields and try again.'
      );
      return;
    }
    // get main product type selected
    const mainProductType = this.availableProductTypes.find(
      (product) => product.id === this.selectedMainProductTypes.value
    ).name;
    // get other product types selected
    const otherProductTypes = [];
    if (
      this.selectedProductTypes.value &&
      this.selectedProductTypes.value.length > 0
    ) {
      if (this.selectedProductTypes.value instanceof Array) {
        for (const pt of this.selectedProductTypes.value) {
          const productType = this.availableProductTypes.find(
            (p) => p.id === pt
          );
          if (productType) {
            otherProductTypes.push(productType.name);
          }
        }
      } else {
        const productType = this.availableProductTypes.find(
          (p) => p.id === this.selectedProductTypes.value
        );
        if (productType) {
          otherProductTypes.push(productType.name);
        }
      }
    }
    // get user message
    let userMessage;
    if (this.message.value) {
      userMessage = this.message.value;
    }
    let validEmails = true;
    let recipients: string[] = [];
    this.currentProject.extras = this.selectedIndividualEquipmentsList;
    if (recipientsValue.includes(',')) {
      let array = recipientsValue.split(',');
      array.forEach((e) => {
        let email = e.trim().replace(' ', '').toLowerCase();
        recipients.push(email);
        if (!this.validEmail(email)) {
          validEmails = false;
        }
      });
    } else {
      recipientsValue = recipientsValue.trim().replace(' ', '').toLowerCase();
      recipients.push(recipientsValue);
      if (!this.validEmail(recipientsValue)) {
        validEmails = false;
      }
    }
    if (!validEmails) {
      this.loading = false;
      this.snackBar.showError(
        'Invalid email address, make sure you entered the right address!'
      );
      return;
    }
    if (
      !this.projectHelper.isProjectEquipmentValid(
        this.currentProject,
        this.snackBar
      )
    ) {
      return;
    }
    this.projectSendRequest = new SendProjectEmail();
    this.projectSendRequest.message = userMessage;
    this.projectSendRequest.mainProductType = mainProductType;
    this.projectSendRequest.otherProductTypes = otherProductTypes;
    this.projectSendRequest.recipients = recipients;
    await this.projectFiles.uploadProjectFile(
      this.currentProject,
      ProjectFileType.CSV,
      this.uploadService$
    );
  }

  save(navigateTo: string, status?: QuotationStatusEnum): void {
    this.loading = true;
    this.currentProject.extras = this.selectedIndividualEquipmentsList;
    this.projectHelper.editProject(this.projectsService$, this.currentProject, {
      plantCapacity: true,
      navigateTo,
      status,
    });
  }

  back(): void {
    this.save(this.routes.project.replace(':id', this.currentProject._id));
  }

  ngOnDestroy(): void {
    this.uploadService$.unsubscribe();
    this.downloadService$.unsubscribe();
    this.projectsService$.unsubscribe();
  }
}
