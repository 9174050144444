import {
  Bin3DParams,
  Cleaner3DParams,
  Conveyor3DParams,
  Delivery3DParams,
  Dryer3DParams,
  Elevator3DParams,
  Part3DParamsBase,
} from '../models/part';


export const flatBottomSiloDiameters = [8.40, 9.20, 9.90, 10.70, 11.50, 12.20, 13.00, 13.75, 14.50, 15.30, 16.80, 18.30, 19.90, 21.40, 22.90];
export const autoDeliveryDiameters = [3.10, 3.60, 3.80, 4.60]
export const conicalBottomDiameters = [...autoDeliveryDiameters, 5.30, 6.10, 6.90, 7.60, 8.40];

export const siloDiameters = {
  flatBottom: flatBottomSiloDiameters,
  conicalBottom: conicalBottomDiameters,
  autoDelivery: autoDeliveryDiameters,
}
export const ROOF_ANGLE = (15 * Math.PI) / 180;
export const BIN_TOP_ANGLE = (25 * Math.PI) / 180;
export const BIN_BOTTOM_ANGLE = (45 * Math.PI) / 180;
export const SUPPORT_LEG_WIDTH = 0.25;
export const BUFFER_BIN_LEG_WIDTH = 0.25;
export const BUFFER_BIN_LEG_COUNT = 8;
export const DEFAULT_INTAKE_LENGTH = 15;

export const CHAIN_CONVEYOR_MOTOR_BASE_HEIGHT = 0.375;
export const CHAIN_CONVEYOR_INTAKE_HOLE_HEIGHT = 0.100;
export const CHAIN_CONVEYOR_TENSIONING_STATION_LENGTH = 1.05;
export const CHAIN_CONVEYOR_MOTOR_LENGTH = 1;
export const CHAIN_CONVEYOR_MOTOR_PART_WIDTH = 0.350
export const CHAIN_CONVEYOR_HEIGHT = 1;

export const BELT_CONVEYOR_TENSIONING_LENGTH = 1.45;
export const BELT_CONVEYOR_MOTOR_LENGTH = 1.4
export const BELT_CONVEYOR_INTAKE_LENGTH = 0.5;
export const BELT_CONVEYOR_HEIGHT = 1.3;
export const CONVEYOR_DEFAULT_ORIENTATION = {x:1, y:0, z:0}

export const STL_MODEL_SCALE_1_TO_1 = 0.001;
export const ELEVATOR_MOTOR_HEIGHT = 1.3;
export const ELEVATOR_BASE_LENGTH = 1.8;
export const ELEVATOR_DEFAULT_ORIENTATION = {x:0, y:0, z:1};

export const CHAIN_CONVEYOR_MOTOR_PART_LENGTH = 1;

export const DRYER_INTAKE_OFFSET = 2.1;
export const DRYER_LENGTH = 9.1;
export const DRYER_DEFAULT_ORIENTATION = {x:0, y:0, z:1};

export const DEFAULT_EQUIPMENTS_POSITIONING_ANGLE = 45;

export const BIN_PARAMS = new Bin3DParams(
  '',
  'lightgray',
  [12, 15, 12],
  [2, 2, 1.5]
);

export const INTAKE_AREA_PARAMS = new Part3DParamsBase(
  'Intake Area',
  'lightblue',
  [5, 10, 5],
  [1, 0, 1],
);

export const DELIVERY_AREA_PARAMS = new Delivery3DParams(
  'Truck Delivery Bin',
  'lightgray',
  [3, 5, 3],
  [5, 10, 5],
  [0.5, 0, 0.5]
);

export const DRYER_PARAMS = new Dryer3DParams(
  'Dryer',
  '#E56D04',
  [9, 15, 3.1],
  [2, 0, 2]
);

export const BUFFER_BIN_PARAMS = new Bin3DParams(
  'Buffer Bin',
  'lightgray',
  [5, 15, 5],
  [2, 0, 2],
);

export const CLEANER_PARAMS = new Cleaner3DParams(
  'Cleaner',
  'white',
  [6.6, 4.1, 2.6],
  [8, 6, 4],
  [0, 6, 0],
  [0.5, 0, 0.5]
);

export const ELEVATOR_PARAMS = new Elevator3DParams(
  'Generic Elevator',
  'red',
  undefined,
  [3, 7, 3],
  undefined,
  [0, -4, 0],
);

export const BELT_CONVEYOR_PARAMS = new Conveyor3DParams(
  'Generic Belt Conveyor',
  'yellow',
  undefined,
  undefined,
  undefined,
  [0, -2, 0],
  undefined,
  [1.5, 1.5, 1.5]
);

export const CHAIN_CONVEYOR_PARAMS = new Conveyor3DParams(
  'Generic Chain Conveyor',
  'blue',
  undefined,
  undefined,
  undefined,
  [0, 0, 0],
  undefined,
  [1.5, 1, 1.5]
);

export const SILO_BIN_PARAMS = new Bin3DParams(
  BIN_PARAMS.name,
  BIN_PARAMS.color,
  BIN_PARAMS.dx,
  BIN_PARAMS.padding
);

export const MAIN_ELEVATOR_PARAMS = new Elevator3DParams(
  'Silo bins loading elevator',
  ELEVATOR_PARAMS.color,
  [
    0,
    SILO_BIN_PARAMS.dx.y +
    ELEVATOR_PARAMS.padding.y,
    0
  ],
  ELEVATOR_PARAMS.padding,
  ELEVATOR_PARAMS.capacity,
  ELEVATOR_PARAMS.depth
);

export const CLEANER_ELEVATOR_PARAMS = new Elevator3DParams(
  'Pre-cleaner elevator',
  ELEVATOR_PARAMS.color,
  [
    0,
    DELIVERY_AREA_PARAMS.dx.y +
    DELIVERY_AREA_PARAMS.support.y +
    (DELIVERY_AREA_PARAMS.dx.z / 2) * Math.tan(BIN_TOP_ANGLE) +
    ELEVATOR_PARAMS.padding.y,
    0
  ],
  ELEVATOR_PARAMS.padding,
  ELEVATOR_PARAMS.capacity,
  ELEVATOR_PARAMS.depth
);

export const DRYER_ELEVATOR_PARAMS = new Elevator3DParams(
  'Dryer elevator',
  ELEVATOR_PARAMS.color,
  [0, DRYER_PARAMS.dx.y + ELEVATOR_PARAMS.padding.y  + ELEVATOR_MOTOR_HEIGHT, 0],
  ELEVATOR_PARAMS.padding,
  ELEVATOR_PARAMS.capacity,
  ELEVATOR_PARAMS.depth
);

export const DELIVERY_LOADING_CONVEYOR_PARAMS = new Conveyor3DParams(
  'Generic Chain Conveyor',
  'blue',
  undefined,
  undefined,
  undefined,
  [0, CLEANER_ELEVATOR_PARAMS.dx.y - Math.abs(CLEANER_ELEVATOR_PARAMS.depth) - 2, 0],
  undefined,
  [1, 3, 1]
);

export const DRYER_LOADING_CONVEYOR_PARAMS = new Conveyor3DParams(
  'Generic Chain Conveyor',
  'blue',
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  [1, 2, 1]
);

//export const CLEANER_3D_PATH = '../../assets/3d-designs/grain-cleaner.stl';
export const CLEANER_3D_PATH = '../../assets/3d-designs/Cleaner/PC.stl';
export const BELT_CONVEYOR_PATHS = [
  '../../assets/3d-designs/Carriers/Band-carrier/TB_tensioning-station.stl',
  '../../assets/3d-designs/Carriers/Band-carrier/TB_middle-0.5-intake.stl',
  '../../assets/3d-designs/Carriers/Band-carrier/TB_middle-0.5.stl',
  '../../assets/3d-designs/Carriers/Band-carrier/TB_middle-1.0.stl',
  '../../assets/3d-designs/Carriers/Band-carrier/TB_motor.stl',
]

export const CHAIN_CONVEYOR_PATHS = [
  '../../assets/3d-designs/Carriers/Chain-carrier/TL_Tensioning-station.stl',
  '../../assets/3d-designs/Carriers/Chain-carrier/TL_middle-0.5m-intake.stl',
  '../../assets/3d-designs/Carriers/Chain-carrier/TL_middle-0.5m.stl',
  '../../assets/3d-designs/Carriers/Chain-carrier/TL_middle-1.0m.stl',
  '../../assets/3d-designs/Carriers/Chain-carrier/TL_motor.stl',
]

export const ELEVATOR_3D_PATHS = [
  '../../assets/3d-designs/Carriers/Elevator/EC_base.stl',
  '../../assets/3d-designs/Carriers/Elevator/EC_middle-0.5.stl',
  '../../assets/3d-designs/Carriers/Elevator/EC_middle-1.0.stl',
  '../../assets/3d-designs/Carriers/Elevator/EC_motor.stl',
]

export const DRYER_PATHS = [
  '../../assets/3d-designs/Dryers/Tower-dryer/UT_base.stl',
  '../../assets/3d-designs/Dryers/Tower-dryer/UT_middle.stl',
  '../../assets/3d-designs/Dryers/Tower-dryer/UT_top.stl',
]

export const SILO_PLANT_THUMBNAIL = '../../assets/siloapp-thumbnail-default.png';
