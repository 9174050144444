import { Injectable} from '@angular/core';
import * as dat from 'lil-gui'
import { Part3DNode } from '../models/part';
import { PartAreaEnum, PartTypeEnum, ProjectTypeEnum } from '../models/enums';
import * as THREE from 'three';
import { SceneParams } from '../models/scene-params';

@Injectable({
  providedIn: 'root',
})

export class Debug3DHelperService{

  private static instance: Debug3DHelperService;
  private debugger: dat.GUI;

  private cameraFolder: dat.GUI;
  private lightsFolder: dat.GUI;
  private equipmentsFolder: dat.GUI;

  private constructor(){
     
}

  initDebug3DHelper(): void{
    this.debugger = new dat.GUI();
    this.cameraFolder = this.debugger.addFolder('Camera');
    this.lightsFolder = this.debugger.addFolder('Lights');
    this.equipmentsFolder = this.debugger.addFolder('Equipments');
    this.debugger.hide();

    this.cameraFolder.close();
    this.lightsFolder.close();
    this.equipmentsFolder.close();

  }

  public static getDebugger(): Debug3DHelperService {
    if (!Debug3DHelperService.instance) {
      Debug3DHelperService.instance = new Debug3DHelperService();
    }
    return Debug3DHelperService.instance;
  }

  public getDebuggerInstance(): dat.GUI {
    return this.debugger;
  }
  public deleteDebugger(): void {
    this.debugger.destroy();
  }

  addEquipment(node: Part3DNode): void{

    const name: string = node.partDetails3D.params.name;
    let folder;
    if(name.includes(`${node.id}`)){
      folder = this.equipmentsFolder.addFolder(name);
    }
    else{
      folder = this.equipmentsFolder.addFolder(`${node.id}.${name}`);
    }
    const meshFolder = folder.addFolder('Mesh');
    const bodyFolder = folder.addFolder('Body');

    this.addPositionFolder(meshFolder, node);
    this.addRotationFolder(meshFolder, node);

    const dataObject = {
      showDetails(){
        console.log(node);
      },
      addAxisHelper(){
        const axis = new THREE.AxesHelper(5);
        node.part3D.part.add(axis);
        axis.position.copy(node.position);
      }
    }

    meshFolder
          .add(dataObject, 'showDetails')
          .name('Show equipment details');
    // folder
    //       .add(dataObject, 'addAxisHelper')
    //       .name('Add axis helper');
    meshFolder
          .add(node.part3D.part, 'visible')
          .name(`Display equipment`);
          meshFolder.close();

    if(node.physicsBody !== undefined && node.physicsBody !== null){
      const positionFolder = bodyFolder.addFolder('Position');
      positionFolder
              .add(node.physicsBody.position,'x')
              .min(-10)
              .max(100)
              .step(0.2)
              .name('X');
      positionFolder
              .add(node.physicsBody.position,'z')
              .min(-10)
              .max(100)
              .step(0.2)
              .name('Z');
      positionFolder
              .add(node.physicsBody.position,'x')
              .min(-10)
              .max(100)
              .step(0.2)
              .name('X');
      positionFolder.close();
      
      const rotationFolder = bodyFolder.addFolder('Rotation');
      rotationFolder
                  .add(node.physicsBody.quaternion,'x')
                  .min(-2*Math.PI)
                  .max(2*Math.PI)
                  .step(0.1)
                  .name('X');
      rotationFolder
                  .add(node.physicsBody.quaternion,'y')
                  .min(-2*Math.PI)
                  .max(2*Math.PI)
                  .step(0.1)
                  .name('Y');
      rotationFolder
                  .add(node.physicsBody.quaternion,'z')
                  .min(-2*Math.PI)
                  .max(2*Math.PI)
                  .step(0.1)
                  .name('Z');
      rotationFolder.close();

    }
    meshFolder.close();
    bodyFolder.close();
    folder.close();
  }

  addRotationFolder(folder: any, node: Part3DNode): void{
    const rotationFolder = folder.addFolder('Rotation(rad)');

    rotationFolder
                  .add(node.part3D.part.rotation,'x')
                  .min(-2*Math.PI)
                  .max(2*Math.PI)
                  .step(0.1)
                  .name('X');
    rotationFolder
                .add(node.part3D.part.rotation,'y')
                .min(-2*Math.PI)
                .max(2*Math.PI)
                .step(0.1)
                .name('Y');
    rotationFolder
                .add(node.part3D.part.rotation,'z')
                .min(-2*Math.PI)
                .max(2*Math.PI)
                .step(0.1)
                .name('Z');

    rotationFolder.close();
  }
  addPositionFolder(folder: any, node: Part3DNode): void{
    const positionFolder = folder.addFolder('Position(m)');

    positionFolder
    .add(node.part3D.part.position,'x')
    .min(-10)
          .max(100)
          .step(0.2)
    .name('X');
    positionFolder
    .add(node.part3D.part.position,'y')
    .min(-10)
          .max(100)
          .step(0.2)
    .name('Y');
    positionFolder
    .add(node.part3D.part.position,'z')
    .min(-10)
          .max(100)
          .step(0.2)
    .name('Z');

    positionFolder.close();
  }
  addSceneControls(sceneComponents: SceneParams): void{

    const camera = sceneComponents.camera;
    if(camera){
      this.cameraFolder
                        .add(camera, 'near')
                        .min(0)
                        .max(100)
                        .step(0.1)
                        .name('Near plane');
      this.cameraFolder
                        .add(camera, 'far')
                        .min(0)
                        .max(2000)
                        .step(0.1)
                        .name('Far plane');
      this.cameraFolder
                        .add(camera, 'aspect')
                        .min(0)
                        .max(1000)
                        .step(0.1)
                        .name('Aspect ratio');   
      this.cameraFolder
                        .add(camera, 'fov')
                        .min(0)
                        .max(360)
                        .step(0.1)
                        .name('Field of view');            
      this.cameraFolder
                      .add(camera.position,'x')
                      .min(0)
                      .max(1000)
                      .step(0.2)
                      .name('Position-X');
      this.cameraFolder
                      .add(camera.position,'y')
                      .min(-10)
                      .max(1000)
                      .step(0.2)
                      .name('Position-Y');
      this.cameraFolder
                      .add(camera.position,'z')
                      .min(0)
                      .max(1000)
                      .step(0.2)
                      .name('Position-Z');
      const cameraHelper = new THREE.CameraHelper( camera );
      sceneComponents.scene.add(cameraHelper); 
      this.cameraFolder.add(cameraHelper, 'visible')
              .name('Show helper');
    }

    sceneComponents.scene.traverse((element) => {
      if(element instanceof THREE.Light){
        const folder = this.lightsFolder.addFolder(element.type);
        
        folder
                  .add(element,'intensity')
                  .min(0)
                  .max(100)
                  .step(0.1)
                  .name('Intensity');

        folder
                  .add(element.position,'x')
                  .min(0)
                  .max(1000)
                  .step(0.2)
                  .name('Position-X');
        folder
                  .add(element.position,'y')
                  .min(-10)
                  .max(1000)
                  .step(0.2)
                  .name('Position-Y');
        folder
                  .add(element.position,'z')
                  .min(0)
                  .max(1000)
                  .step(0.2)
                  .name('Position-Z');

        let lightHelper;
        if(element.type !== 'AmbientLight'){
          switch(element.type){
            case 'HemisphereLight':{
              lightHelper = new THREE.HemisphereLightHelper(element, 1);
              break;
            }
            case 'DirectionalLight':{
              lightHelper = new THREE.DirectionalLightHelper(element, 1);
              break;
            }
            case 'PointLight':{
              lightHelper = new THREE.PointLightHelper(element, 1);
              break;
            }
            case 'SpotLight':{
              lightHelper = new THREE.SpotLightHelper(element, 1);
              break;
            }
          }
            sceneComponents.scene.add(lightHelper);
            folder.add(lightHelper, 'visible')
                .name('Show helper');
          }        
      }
    })
  }


};