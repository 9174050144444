<div class="container">
  <div>
    <h2>Equipment Offer</h2>
  </div>
  <div class="details-form">
    <div class="details-form-1">
      <label class="form-label full-input">Country</label>
      <mat-form-field class="rfp-details-half-input" appearance="fill">
        <input matInput type="text" [formControl]="country" [disabled]="true" />
      </mat-form-field>

      <!-- Products -->
      <div class="label-rfp">
        <label class="form-label rfp-details-half-input"
          >Main Product Type</label
        >
        <label class="form-label rfp-details-half-input-no-margin"
          >Other Product Types</label
        >
      </div>

      <!-- Product types dropdown list -->
      <label class="form-label label-one-row">Main Product Type</label>
      <mat-form-field class="rfp-details-half-input" appearance="fill">
        <mat-select [formControl]="selectedMainProductTypes" required>
          <mat-option
            *ngFor="let productType of availableProductTypes"
            [value]="productType.id"
            [disabled]="true"
          >
            {{ productType.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Product types dropdown list -->
      <label class="form-label label-one-row">Other Product Types</label>
      <mat-form-field
        class="rfp-details-half-input-no-margin"
        appearance="fill"
      >
        <mat-select [formControl]="selectedProductTypes" multiple>
          <mat-option
            *ngFor="let productType of availableProductTypes"
            [value]="productType.id"
            [disabled]="true"
          >
            {{ productType.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Additional message -->
      <div>
        <p class="general-title"><strong>Additional Details</strong></p>
      </div>

      <label class="form-label"
        >Please add any relevant information about your request for proposal...
      </label>
      <mat-form-field class="full-input" appearance="fill">
        <textarea matInput type="text" [formControl]="message" disabled>
        </textarea>
      </mat-form-field>
    </div>

    <div class="details-form-2">
      <!-- Cards with equipment details -->
      <div class="add-individual-equipment">
        <p class="general-title-total">
          <strong>New individual equipment</strong>
        </p>
      </div>
      <div class="equipment-list">
        <div class="mat-elevation-z2">
          <mat-accordion multi>
            <app-individual-equipment-card
              *ngFor="let equipment of quotation?.equipments"
              [equipment]="equipment"
              [cardExpanded]="true"
              [showDeleteButton]="false"
              [deleteEquipmentCard]="true"
              [editEquipmentCard]="allowEditEquipment"
              (changedEquipment)="changedEquipment($event)"
              (deleteEquipment)="deleteEquipment($event)"
            >
            </app-individual-equipment-card>
          </mat-accordion>
        </div>
      </div>
    </div>
    <div class="button-container-1">
      <button mat-button class="green-button back-button" (click)="back()">
        Back
      </button>
      <button
        *ngIf="equipmentEdited"
        mat-button
        class="green-button"
        (click)="save()"
      >
        Save Changes
      </button>
      <button
        mat-button
        class="reject-button white-button"
        (click)="rejectRfp()"
        *ngIf="showAcceptReject"
      >
        Reject
      </button>
    </div>
    <div class="button-container-2">
      <button
        mat-button
        class="green-button back-button-mobile"
        (click)="back()"
      >
        Back
      </button>
      <button
        mat-button
        class="reject-button-mobile white-button"
        (click)="rejectRfp()"
        *ngIf="showAcceptReject"
      >
        Reject
      </button>
      <button
        mat-button
        class="green-button accept-button margin-left--0"
        (click)="acceptRfp()"
        *ngIf="showAcceptReject"
      >
        Accept
      </button>
      <button
        mat-button
        class="download-button white-button"
        (click)="download()"
      >
        Download
      </button>
      <button
        mat-button
        *ngIf="!isSingleMachine && canSeeLayout"
        (click)="viewLayout()"
        class="layout-button white-button"
      >
        View Layout
      </button>
    </div>
  </div>
  <div class="spinner-loading">
    <app-spinner [loading]="loading"></app-spinner>
  </div>
</div>
