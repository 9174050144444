import {GrainsEnum} from '../models/enums';

export const GRAIN_TYPES = [
  {
    name: GrainsEnum.CORN,
    id: 1
  },
  {
    name: GrainsEnum.WHEAT,
    id: 2
  },
  {
    name: GrainsEnum.OAT,
    id: 3
  },
  {
    name: GrainsEnum.BARLEY,
    id: 4
  },
  {
    name: GrainsEnum.SOY_BEANS,
    id: 5
  },
  {
    name: GrainsEnum.SUNFLOWER,
    id: 6
  },
  {
    name: GrainsEnum.RAPESEED,
    id: 7
  },
  {
    name: GrainsEnum.RICE,
    id: 8
  }
];
