import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarRef, TextOnlySnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class GenericSnackBarService {

  constructor(private snackBar: MatSnackBar) {
  }

  showError(message: string, action?: string): void {
    const config = new MatSnackBarConfig();
    config.panelClass = ['snackbar-design-error'];
    this.snackBar.open(message, 'Ok', config);
  }

  showSuccess(message: string, action?: string): void {
    const config = new MatSnackBarConfig();
    config.panelClass = ['snackbar-design-success'];
    config.duration = 7000;
    this.snackBar.open(message, "Ok", config);
  }

  showWarning(message: string, action?: string): MatSnackBarRef<TextOnlySnackBar> {
    const config = new MatSnackBarConfig();
    config.panelClass = ['snackbar-design-warning'];
    config.duration = 7000;
    const snackBarRef = this.snackBar.open(message, "Ok", config);
    return snackBarRef;
  }
}
