import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SiloLayoutComponent } from './silo-layout/silo-layout.component';
import { AuthGuard } from '../auth.guard';
import { SiloProjectComponent } from './silo-project/silo-project.component';
import { AllProjectsComponent } from './all-projects/all-projects.component';
import { SendMailComponent } from '../shared/modules/misc/send-mail/send-mail.component';

const routes: Routes = [
  {
    path: '',
    component: AllProjectsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':id',
    component: SiloProjectComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'silo-layout',
    component: SiloLayoutComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':id/send-mail',
    component: SendMailComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProjectsRoutingModule {}
