<!--
  Legend:
    partDetails3D.params.dx.x === length or diameter
    partDetails3D.params.dx.y === height
    partDetails3D.params.dx.z === width
-->
<div *ngIf="equipment.type !== partTypeEnum.INTAKE">
  <mat-expansion-panel [expanded]="isCardExpanded(equipment)">
    <mat-expansion-panel-header>
      <mat-panel-title
        (mouseover)="mouseOverOn($event)"
        (mouseout)="mouseOverOff($event)"
      >
        {{ equipment.partDetails3D.params.name }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <!-- Silo bin -->
    <div *ngIf="equipment.type === partTypeEnum.SILO_BIN">
      <mat-form-field appearance="outline">
        <mat-label>Volume (m^3)</mat-label>
        <input
          #FirstEquipmentParameter
          matInput
          class="input-content"
          type="number"
          step="0.01"
          [(ngModel)]="equipment.partDetails3D.params.volume"
          (blur)="emitValueChange()"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Diameter (m)</mat-label>
        <input
          #SecondEquipmentParameter
          matInput
          class="input-content"
          type="number"
          step="0.01"
          [(ngModel)]="equipment.partDetails3D.params.dx.x"
          (blur)="emitValueChange()"
        />
      </mat-form-field>
    </div>

    <!-- Buffere bin -->
    <div *ngIf="equipment.type === partTypeEnum.BUFFER_BIN">
      <mat-form-field appearance="outline">
        <mat-label>Volume (m^3)</mat-label>
        <input
          #FirstEquipmentParameter
          matInput
          class="input-content"
          type="number"
          step="0.01"
          [(ngModel)]="equipment.partDetails3D.params.volume"
          (blur)="emitValueChange()"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Diameter (m)</mat-label>
        <input
          #SecondEquipmentParameter
          matInput
          step="0.01"
          class="input-content"
          type="number"
          [(ngModel)]="equipment.partDetails3D.params.dx.x"
          (blur)="emitValueChange()"
        />
      </mat-form-field>
    </div>

    <!-- Delivery bin -->
    <div *ngIf="equipment.type === partTypeEnum.DELIVERY">
      <mat-form-field appearance="outline">
        <mat-label>Volume (m^3)</mat-label>
        <input
          #FirstEquipmentParameter
          matInput
          step="0.01"
          class="input-content"
          type="number"
          [(ngModel)]="equipment.partDetails3D.params.volume"
          (blur)="emitValueChange()"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Diameter (m)</mat-label>
        <input
          #SecondEquipmentParameter
          matInput
          step="0.01"
          class="input-content"
          type="number"
          [(ngModel)]="equipment.partDetails3D.params.dx.x"
          (blur)="emitValueChange()"
        />
      </mat-form-field>
    </div>

    <!-- Elevator -->
    <div *ngIf="equipment.type === partTypeEnum.ELEVATOR">
      <mat-form-field appearance="outline">
        <mat-label>Capacity (t/h)</mat-label>
        <input
          #FirstEquipmentParameter
          matInput
          step="0.01"
          class="input-content"
          type="number"
          [(ngModel)]="equipment.partDetails3D.params.capacity"
          (blur)="emitValueChange()"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Height (m)</mat-label>
        <input
          #SecondEquipmentParameter
          matInput
          step="0.01"
          class="input-content"
          type="number"
          [(ngModel)]="equipment.partDetails3D.params.dx.y"
          (blur)="emitValueChange()"
        />
      </mat-form-field>
    </div>

    <!-- Cleaner -->
    <div *ngIf="equipment.type === partTypeEnum.CLEANER">
      <mat-form-field appearance="outline">
        <mat-label>Capacity (t/h)</mat-label>
        <input
          #FirstEquipmentParameter
          matInput
          step="0.01"
          class="input-content"
          type="number"
          [(ngModel)]="equipment.partDetails3D.params.capacity"
          (blur)="emitValueChange()"
        />
      </mat-form-field>
    </div>

    <!-- Chain conveyor -->
    <div *ngIf="equipment.type === partTypeEnum.CHAIN_CONVEYOR">
      <mat-form-field appearance="outline">
        <mat-label>Capacity (t/h)</mat-label>
        <input
          #FirstEquipmentParameter
          matInput
          step="0.01"
          class="input-content"
          type="number"
          [(ngModel)]="equipment.partDetails3D.params.capacity"
          (blur)="emitValueChange()"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Length (m)</mat-label>
        <input
          #SecondEquipmentParameter
          matInput
          step="0.01"
          class="input-content"
          type="number"
          [(ngModel)]="equipment.partDetails3D.params.dx.x"
          (blur)="emitValueChange()"
        />
      </mat-form-field>
    </div>

    <!-- Belt conveyor -->
    <div *ngIf="equipment.type === partTypeEnum.BELT_CONVEYOR">
      <mat-form-field floatLabel="auto" appearance="outline">
        <mat-label>Capacity (t/h)</mat-label>
        <input
          #FirstEquipmentParameter
          matInput
          step="0.01"
          class="input-content"
          type="number"
          [(ngModel)]="equipment.partDetails3D.params.capacity"
          (blur)="emitValueChange()"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Length (m)</mat-label>
        <input
          #SecondEquipmentParameter
          matInput
          step="0.01"
          class="input-content"
          type="number"
          [(ngModel)]="equipment.partDetails3D.params.dx.x"
          (blur)="emitValueChange()"
        />
      </mat-form-field>
    </div>

    <!-- Dryer -->
    <div *ngIf="equipment.type === partTypeEnum.DRYER">
      <mat-form-field appearance="outline">
        <mat-label>Capacity (t/h)</mat-label>
        <input
          #FirstEquipmentParameter
          matInput
          step="0.01"
          class="input-content"
          type="number"
          [(ngModel)]="equipment.partDetails3D.params.capacity"
          (blur)="emitValueChange()"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Moisture Reduction (%)</mat-label>
        <input
          #SecondEquipmentParameter
          matInput
          step="0.01"
          class="input-content"
          type="number"
          [(ngModel)]="equipment.partDetails3D.params.moistureReduction"
          (blur)="emitValueChange()"
        />
      </mat-form-field>
    </div>
  </mat-expansion-panel>
</div>
