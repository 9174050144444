import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuotationStatusEnum } from 'src/app/shared/models/enums';

@Component({
  selector: 'app-generic-card',
  templateUrl: './generic-card.component.html',
  styleUrls: ['./generic-card.component.scss'],
})
export class GenericCardComponent implements OnInit {
  @Input() cardDetails;
  @Input() isAdminUser;
  @Input() isPlantSelect;
  @Output() sendQuotationRequestClicked = new EventEmitter();
  @Output() editProjectClicked = new EventEmitter();
  @Output() deleteProjectClicked = new EventEmitter();
  @Output() downloadProjectClicked = new EventEmitter();
  @Output() newSiloFromTemplateClicked = new EventEmitter();
  @Output() viewQuotationDetailsClicked = new EventEmitter();
  public imageUrl: string;
  enumStatusProject = QuotationStatusEnum;
  constructor() {}
  ngOnInit(): void {
    this.imageUrl = this.cardDetails.imageUrl;
  }
  sendQuotationRequestClickedEvent(cardDetails: any): void {
    this.sendQuotationRequestClicked.emit(cardDetails._id);
  }
  editProjectClickedEvent(cardDetails: any): void {
    this.editProjectClicked.emit(cardDetails._id);
  }
  downloadProjectClickedEvent(cardDetails: any): void {
    this.downloadProjectClicked.emit(cardDetails._id);
  }
  deleteProjectClickedEvent(cardDetails: any): void {
    this.deleteProjectClicked.emit(cardDetails._id);
  }
  newSiloFromTemplateClickedEvent(cardDetails: any): void {
    this.newSiloFromTemplateClicked.emit(cardDetails._id);
  }
  viewQuotationDetailsClickedEvent(cardDetails: any): void {
    this.viewQuotationDetailsClicked.emit(cardDetails.parentId);
  }
}
