<div class="container">
  <h3>Duplicate equipment</h3>
  <mat-form-field appearance="fill" class="full-input">
    <mat-label>Equipments</mat-label>
    <mat-select [formControl]="equipments" required multiple>
      <mat-option
        *ngFor="let node of filteredPart3DNodes"
        [value]="node.id"
        (mouseover)="mouseOverOn($event)"
        (mouseout)="mouseOverOff($event)"
      >
        {{ node.id }}. {{ node.partDetails3D.params.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="layout-button">
    <button mat-button class="white-button" (click)="closeDialog()">
      Close
    </button>
    <button mat-button class="green-button" (click)="duplicateEquipment()">
      Duplicate
    </button>
  </div>
</div>
