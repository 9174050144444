import {
  GrainsEnum,
  PaymentAcceptedEnum,
  ProjectTypeEnum,
  QuotationStatusEnum,
  SyncStatesEnum,
} from './enums';
import { Part3DNode, SiloGroupOrientation } from './part';

export class ProjectBase {
  _id: string;
  parentId: string;
  name: string;
  lastUpdatedAt: Date | string;
  type: ProjectTypeEnum;
  imageUrl: string;

  constructor(
    name: string,
    lastUpdatedAt: Date | string,
    type: ProjectTypeEnum,
    imageUrl: string
  ) {
    this.name = name;
    this.lastUpdatedAt = lastUpdatedAt;
    this.type = type;
    this.imageUrl = imageUrl;
  }
}

export class Project extends ProjectBase {
  userId: string | number;
  producerId: string | number;
  nodes: Part3DNode[];
  extras: Part3DNode[];
  grainsType: GrainsEnum;
  completed?: boolean;
  status?: QuotationStatusEnum;
  paymentAccepted?: PaymentAcceptedEnum;
  storageCapacity?: number;
  handlingCapacity?: number;
  dryingCapacity?: number;
  mainElevatorCapacity?: number;
  orientation?: SiloGroupOrientation;
  sync?: SyncStatesEnum;

  constructor(
    name?: string,
    lastUpdated?: Date | string,
    type?: ProjectTypeEnum,
    userId?: string | number,
    imageUrl?: string,
    extras?: Part3DNode[],
    nodes?: Part3DNode[],
    status?: QuotationStatusEnum,
    grainsType?: GrainsEnum
  ) {
    super(name, lastUpdated, type, imageUrl);
    this.userId = userId;
    if (nodes) {
      this.nodes = nodes;
    } else {
      this.nodes = [];
    }
    if (extras) {
      this.extras = extras;
    } else {
      this.extras = [];
    }
    this.grainsType = grainsType;
    this.status = status;
  }

  nameValid(): boolean {
    const nameT = this.name.trim().replace(' ', '').toLowerCase();
    if (nameT.length <= 0 || nameT.startsWith('untitledproject')) {
      return false;
    }
    return true;
  }
}
