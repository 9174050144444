import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-silo-project',
  templateUrl: './silo-project.component.html',
  styleUrls: ['./silo-project.component.scss']
})
export class SiloProjectComponent implements OnInit, AfterViewInit {
  constructor(private cdr: ChangeDetectorRef) {}
  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
}
