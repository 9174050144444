import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Company } from 'src/app/shared/models/company';
import {
  Countries,
  citiesMap,
  countriesList,
} from 'src/app/shared/models/countries';
import {
  CompanyTypeEnum,
  UserHelperServiceEnum,
} from 'src/app/shared/models/enums';
import { OperationResult } from 'src/app/shared/models/helper-service-events';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CompanyHelperService } from 'src/app/shared/services/company-helper.service';
import { GenericSnackBarService } from 'src/app/shared/services/generic-snack-bar.service';

@Component({
  selector: 'app-createcompany',
  templateUrl: './createcompany.component.html',
  styleUrls: ['./createcompany.components.scss'],
})
export class CreateCompanyComponent implements OnInit, AfterViewInit {
  error: any[];
  loading = false;
  cities: string[] = [];
  @Input() showCreateCompany;
  createCompanyForm: FormGroup;
  companyTypeEnum = CompanyTypeEnum;
  countries: Countries[] = countriesList;
  private companyService$: Subject<OperationResult> =
    new Subject<OperationResult>();

  constructor(
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private api: ApiService,
    private auth: AuthService,
    private snackBar: GenericSnackBarService,
    private companyHelper: CompanyHelperService
  ) {
    this.api.http = this.http;
  }

  ngOnInit(): void {
    this.createCompanyForm = new FormGroup({
      phone: new FormControl(null, [
        Validators.required,
        Validators.minLength(7),
        Validators.pattern('[- +()0-9]+'),
      ]),
      country: new FormControl(null, [Validators.required]),
      companyName: new FormControl(null, [
        Validators.required,
        Validators.minLength(3),
      ]),
      companyCity: new FormControl(null, [Validators.required]),
      companyZIP: new FormControl(null, [
        Validators.required,
        Validators.minLength(5),
      ]),
      companyAddress: new FormControl(null, [
        Validators.required,
        Validators.minLength(6),
      ]),
      companyType: new FormControl(CompanyTypeEnum.SELLER, [
        Validators.required,
      ]),
    });
    this.companyService$.subscribe((r: OperationResult) => {
      if (r.status === UserHelperServiceEnum.CREATED) {
        const type: CompanyTypeEnum = r.data.type;
        this.loading = false;
        this.auth.redirect();
        this.auth.setCompanyType(type);
        this.auth.setCompanyId(r.data._id);
        this.snackBar.showSuccess(
          'Your company have been registered successfully! You can start using the application now'
        );
      }
      if (r.status === UserHelperServiceEnum.ERROR) {
        this.showError(r.data);
      }
    });
    const selectedCountryCode = countriesList[0].code;
    this.cities = citiesMap.get(selectedCountryCode) ?? [];
  }
  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
  onCountryChange(form: FormGroup): void {
    setTimeout(() => {
      const selectedCountryCode = form.value.country;
      this.cities = citiesMap.get(selectedCountryCode) ?? [];
      form.get('companyCity').reset();
    }, 100);
  }
  private showError(e): void {
    this.loading = false;
    if (e.error.message instanceof Array) {
      this.error = e.error.message;
    } else {
      const nl = [];
      nl.push(e.error.message);
      this.error = nl;
    }
  }
  async submitData(form: FormGroup): Promise<void> {
    try {
      let formValue = form.value;
      if (this.createCompanyForm.invalid) {
        this.snackBar.showError(
          'Invalid form! Please fill all the required fields with the correct data.'
        );
        return;
      }
      this.loading = true;
      const companyData: Company = {
        address: formValue.companyAddress,
        city: formValue.companyCity.trim(),
        country: formValue.country,
        name: formValue.companyName.trim(),
        phone: formValue.phone,
        type: formValue.companyType,
        zip: formValue.companyZIP.trim(),
      };
      this.companyHelper.createCompany(this.companyService$, companyData);
    } catch (e) {
      this.showError(e);
      return;
    }
  }
}
