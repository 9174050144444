import { CompanyTypeEnum } from "./enums";

export class Company {
  _id?: string;
  phone: string;
  country: string;
  name: string;
  address: string;
  city: string;
  zip: string;
  type: CompanyTypeEnum;
}
