<mat-progress-bar
  *ngIf="loading"
  mode="determinate"
  [value]="progress"
  style="height: 25px"
>
</mat-progress-bar>
<div *ngIf="loading" class="pending-spinner">
  <p class="pending-text">
    Please be patient... We are currently generating your flow diagram...
  </p>
  <app-spinner [isSmall]="isSmall" [loading]="loading"></app-spinner>
</div>
<div class="container">
  <div class="head-container">
    <div class="content-title">
      <h2>Flow Diagram</h2>
    </div>
    <div class="content-top-buttons">
      <button
        mat-flat-button
        [disabled]="loading"
        class="green-button"
        [matMenuTriggerFor]="actionMenu"
      >
        Actions
      </button>
      <mat-menu #actionMenu="matMenu" yPosition="above">
        <button
          mat-menu-item
          class="button-menu"
          [matMenuTriggerFor]="customizeMenu"
        >
          Customize
        </button>
        <mat-menu #customizeMenu="matMenu" yPosition="above">
          <button
            mat-menu-item
            *ngFor="let element of menuOptions"
            (click)="customizeDialog(element)"
            class="button-menu"
          >
            {{ element }}
          </button>
        </mat-menu>
        <button mat-menu-item class="button-menu" (click)="showEquipment()">
          Show equipments
        </button>
        <!--
        <button
          mat-menu-item
          class="button-menu"
          (click)="submitQuotationRequest()"
          *ngIf="showQuotation"
        >
          + Quotation
        </button>
        -->
        <button mat-menu-item class="button-menu" (click)="sendProjectFiles()">
          Share Project
        </button>
        <button
          mat-menu-item
          class="button-menu"
          (click)="downloadProjectSpecs()"
        >
          Download project specs
        </button>
        <button
          [disabled]="loading"
          mat-menu-item
          class="button-menu"
          (click)="saveProgress()"
        >
          Save
        </button>
        <!--
        <button
          mat-menu-item
          class="button-menu"
          (click)="acceptRfp()"
          *ngIf="showAcceptReject"
        >
          Accept Offer
        </button>
        <button
          mat-menu-item
          class="button-menu"
          (click)="rejectRfp()"
          *ngIf="showAcceptReject"
        >
          Reject Offer
        </button>
        -->
      </mat-menu>
    </div>
  </div>
  <div class="parent-grid">
    <div class="layout-grid">
      <canvas id="flow-diagram" height="512" width="844"></canvas>
    </div>
    <!-- Equipment list -->
    <div class="equipment-list" [hidden]="!showEquipmentList">
      <!-- <div class="equipment-list-header">
        <span class="equipment-list-header-child"
          >Total number of equipments {{ currentProject.nodes.length }}</span
        >
      </div> -->
      <div class="equipment-list-header">
        <span class="equipment-list-header-child"
          >Total number of equipments
          {{ this.currentProject.nodes.length }}</span
        >
        <div class="button-spacer"></div>
        <button
          class="button-space-left secondary-button"
          (click)="saveProgress()"
        >
          Apply changes
        </button>
        <button
          class="button-space-left secondary-button"
          (click)="customizeClosed()"
        >
          Close
        </button>
      </div>
      <div class="mat-elevation-z2">
        <mat-accordion multi>
          <app-equipment-card
            *ngFor="let equipment of currentProject.nodes"
            [equipment]="equipment"
            [editEquipmentCard]="false"
            [deleteEquipmentCard]="true"
            [cardExpanded]="false"
            (deleteEquipment)="deleteEquipment($event)"
            (typingEvent)="handleCapacityChange($event)"
          >
          </app-equipment-card>
        </mat-accordion>
      </div>
    </div>
    <!-- Add new equipment -->
    <div class="equipment-list" [hidden]="!showAddEquipmentDialog">
      <div class="equipment-list-header">
        <span class="equipment-list-header-child"
          >Total number of equipments {{ currentProject.nodes.length }}</span
        >
      </div>
      <app-add-equipment-dialog
        [part3DNodes]="currentProject.nodes"
        (closeClicked)="customizeClosed()"
        (addClicked)="addEquipment($event)"
        (mouseOn)="mouseOn($event)"
        (mouseOff)="mouseOff($event)"
      >
      </app-add-equipment-dialog>
    </div>
    <!-- Delete equipments -->
    <div class="equipment-list" [hidden]="!showDeleteEquipmentDialog">
      <div class="equipment-list-header">
        <span class="equipment-list-header-child"
          >Total number of equipments {{ currentProject.nodes.length }}</span
        >
      </div>
      <app-delete-equipment-dialog
        [part3DNodes]="currentProject.nodes"
        (closeClicked)="customizeClosed()"
        (deleteClicked)="deleteEquipment($event)"
        (mouseOn)="mouseOn($event)"
        (mouseOff)="mouseOff($event)"
      >
      </app-delete-equipment-dialog>
    </div>
    <!--Equipment specifications-->
    <div class="equipment-list" [hidden]="!showEquipmentSpecificationsDialog">
      <div class="equipment-list-header">
        <span class="equipment-list-header-child"
          >Total number of equipments {{ currentProject.nodes.length }}</span
        >
      </div>
      <app-equipment-specifications-dialog
        [part3DNodes]="currentProject.nodes"
        (closeClicked)="customizeClosed()"
        (editClick)="editEquipmentSpecifications($event)"
        (mouseOn)="mouseOn($event)"
        (mouseOff)="mouseOff($event)"
      >
      </app-equipment-specifications-dialog>
    </div>
    <div class="layout-grid-button-item">
      <button
        mat-button
        class="white-button button-left-margin button-cancel"
        (click)="back()"
      >
        Back
      </button>
      <button
        mat-button
        class="white-button button-space-left"
        (click)="cancel()"
      >
        Cancel
      </button>
      <div class="button-spacer"></div>
      <button
        mat-button
        [disabled]="loading"
        class="green-button button-right-margin button-download-mobile"
        (click)="saveProgress()"
      >
        Save
      </button>
    </div>
    <div class="layout-grid-button-item-save">
      <span class="button-spacer"></span>
      <button
        mat-button
        [disabled]="loading"
        class="green-button button-right-margin button-download"
        (click)="saveProgress()"
      >
        Save
      </button>
    </div>
  </div>
  <app-notify-download></app-notify-download>
</div>
