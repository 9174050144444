<div class="head-container">
  <h2>Contact us</h2>
</div>
<div class="container">
  <div class="container-text-contact">
    <p class="general-subtitle">
      Thank you for getting in touch with us. For any queries you can send us a
      message using the form below.
    </p>
  </div>
  <br />
  <!-- Contact us form -->
  <form class="contact-details-form" [formGroup]="contactForm">
    <label class="form-label-contact">Your message</label>
    <br />
    <mat-form-field class="details-full-input-contact" appearance="outline">
      <textarea
        matInput
        type="text"
        formControlName="message"
        required
      ></textarea>
    </mat-form-field>
    <br />
    <button mat-button type="submit" class="button-submit" (click)="submit()">
      Submit
    </button>
  </form>
</div>
