<div class="head-container">
  <div>
    <button
      mat-flat-button
      [ngClass]="title == 'Users' ? 'button-selected' : 'button-deselected'"
      (click)="viewAll()"
    >
      Users
    </button>
    <button
      mat-flat-button
      [ngClass]="title == 'Sellers' ? 'button-selected' : 'button-deselected'"
      (click)="viewSellers()"
    >
      Seller
    </button>
    <!--
    <button
      mat-flat-button
      [ngClass]="title == 'Buyers' ? 'button-selected' : 'button-deselected'"
      (click)="viewBuyers()"
    >
      Buyer
    </button>
    <button
      mat-flat-button
      [ngClass]="title == 'Martketplace' ? 'button-selected' : 'button-deselected'"
      (click)="viewMarketplace()"
    >
      MarketPlace
    </button>
    -->
  </div>
  <div class="content-top-buttons">
    <button
      mat-flat-button
      class="button-equipment-offers"
      [matMenuTriggerFor]="actionMenu"
    >
      Actions
    </button>
    <mat-menu #actionMenu="matMenu" yPosition="above">
      <button mat-menu-item class="button-menu" (click)="activateUsers()">
        Activate
      </button>
      <button mat-menu-item class="button-menu" (click)="deactivateUsers()">
        Disable
      </button>
    </mat-menu>
  </div>
</div>
<div class="container">
  <div class="producer-table-container">
    <div>
      <mat-panel-title>
        <h1>{{ title }}</h1>
      </mat-panel-title>
      <div>
        <div class="admin-users-table-filters">
          <mat-form-field
            *ngFor="let filter of filterSelectObj"
            class="account-details-half-input"
            appearance="outline"
            style="margin-left: 15px"
          >
            <mat-label>Filter {{ filter.name }}</mat-label>
            <select
              matNativeControl
              name="{{ filter.columnProp }}"
              [(ngModel)]="filter.modelValue"
              (change)="filterChange(filter, $event)"
            >
              <option value="">Select {{ filter.name }}</option>
              <option [value]="item" *ngFor="let item of filter.options">
                {{ item }}
              </option>
            </select>
          </mat-form-field>
          &nbsp;
          <button
            mat-flat-button
            color="warn"
            (click)="resetFilters()"
            style="margin-left: 15px; height: 50px"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
    <div class="mat-elevation-z8">
      <mat-table matSort [dataSource]="usersTableData">
        <ng-container matColumnDef="selected">
          <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            fxLayoutAlign="center center"
          >
            <mat-checkbox
              [checked]="unchecked"
              [(ngModel)]="element.isChecked"
            ></mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            ><strong>Name</strong></mat-header-cell
          >
          <mat-cell *matCellDef="let element" class="capitalize-input">
            {{ element.firstName }}
            {{ element.lastName }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            ><strong>Email</strong></mat-header-cell
          >
          <mat-cell *matCellDef="let element" class="capitalize-input">
            {{ element.email }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="createdAt">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            ><strong>Created at</strong></mat-header-cell
          >
          <mat-cell *matCellDef="let element" class="capitalize-input">
            {{ element.createdAt && element.createdAt.split(".")[0].replace("T", " ") }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="lastLoggedIn">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            ><strong>Last Logged In</strong></mat-header-cell
          >
          <mat-cell *matCellDef="let element" class="capitalize-input">
            {{ element.lastLoggedIn && element.lastLoggedIn.split(".")[0].replace("T", " ") }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="company">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            ><strong>Company</strong></mat-header-cell
          >
          <mat-cell *matCellDef="let element" class="capitalize-input">
            {{ element.company?.name }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="companyType">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            ><strong>Company Type</strong></mat-header-cell
          >
          <mat-cell *matCellDef="let element" class="capitalize-input">
            {{ element.company?.type }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="companyPhone">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            ><strong>Company Phone</strong></mat-header-cell
          >
          <mat-cell *matCellDef="let element" class="capitalize-input">
            {{ element.company?.phone }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            ><strong>Type</strong></mat-header-cell
          >
          <mat-cell *matCellDef="let element" class="capitalize-input">
            {{ element.type }}</mat-cell
          >
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            ><strong>Status</strong></mat-header-cell
          >
          <mat-cell *matCellDef="let element" class="capitalize-input">
            {{ getStatusFromNumber(element.status) }}
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
  </div>
</div>
