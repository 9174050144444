import { Component, OnInit, ViewChild } from '@angular/core';
import { Quotation, QuotationsTableRow } from 'src/app/shared/models/quotation';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ApiService } from '../shared/services/api.service';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GenericSnackBarService } from '../shared/services/generic-snack-bar.service';
import {
  CompanyTypeEnum,
  PartTypeEnum,
  PaymentAcceptedEnum,
  QuotationHelperServiceEnum,
  QuotationStatusEnum,
  QuotationTypeEnum,
  QuotationViewBy,
  UserTypeEnum,
} from '../shared/models/enums';
import { AuthService } from '../shared/services/auth.service';
import { PaymentAcceptedEvent } from '../shared/models/dialog-events';
import { QuotationHelperService } from '../shared/services/quotation-helper.service';
import { OperationResult } from '../shared/models/helper-service-events';
import { Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { SiloPlantHelperService } from '../shared/services/silo-plant-helper.service';

@Component({
  selector: 'app-rfps',
  templateUrl: './quotations.component.html',
  styleUrls: ['./quotations.component.scss'],
})
export class QuotationsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<QuotationsTableRow>;
  quotationStatusEnum = QuotationStatusEnum;
  siloPlantQuotation = QuotationTypeEnum.SILO_PLANT;
  singleEquipmentQuotation = QuotationTypeEnum.SINGLE_MACHINES;
  loading = true;
  showReminder = false;
  showProducer = false;
  isSingleMachine = false;
  clickedPaymentRequired = false;
  quotationId: string;
  producerAccount = false;
  quotations: QuotationsTableRow[];
  quotationType: QuotationTypeEnum;
  private routes = environment.routes;
  filterCountry = [];
  filterProducer = [];
  filterMainProduct = [];
  filterSelect = ['accepted', 'rejected', 'not_send', 'pending'];
  filterNumberOfBins = ['0-2', '2-4', '4-6', '6-8', '> 8'];
  filterTotalCapacity = [
    '0-2000',
    '2000-4000',
    '4000-6000',
    '6000-10000',
    '> 10000',
  ];
  filterDryingCapacity = [
    '0-20 t/h',
    '20-40 t/h',
    '40-60 t/h',
    '60-80 t/h',
    '> 80 t/h',
  ];
  filterTransferCapacity = [
    '30-50 t/h',
    '50-100 t/h',
    '100-150 t/h',
    '150-200 t/h',
    '> 200 t/h',
  ];
  private currentQuotation: QuotationsTableRow;
  private quotationService$: Subject<OperationResult> =
    new Subject<OperationResult>();
  products: FormControl = new FormControl('');
  statuses: FormControl = new FormControl('');
  transfers: FormControl = new FormControl('');
  dryings: FormControl = new FormControl('');
  captransfers: FormControl = new FormControl('');
  country: FormControl = new FormControl('');
  producers: FormControl = new FormControl('');
  numberofbins: FormControl = new FormControl('');

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private api: ApiService,
    private snackBar: GenericSnackBarService,
    private auth: AuthService,
    public quotationHelper: QuotationHelperService,
    public siloplant: SiloPlantHelperService
  ) {
    this.api.http = this.http;
  }

  ngOnInit(): void {
    this.isSingleMachine = false;
    /*
    this.showReminder = this.auth.getCompanyType() === CompanyTypeEnum.BUYER;
    this.showProducer = this.auth.getCompanyType() === CompanyTypeEnum.BUYER;
    this.producerAccount = this.auth.getCompanyType() === CompanyTypeEnum.BUYER || this.auth.getCompanyType() === CompanyTypeEnum.MARKETPLACE;
    */
    this.showReminder = false;
    this.showProducer = false;
    this.producerAccount = false;
    this.quotationService$.subscribe((event: OperationResult) => {
      if (event.status === QuotationHelperServiceEnum.LOADED) {
        this.loading = false;
        let countrylist = [];
        let productslist = [];
        let producerslist = [];
        this.quotations = event.data.quotations;
        this.quotations = this.quotations.filter(
          (data: Quotation) => data.quotationType === this.quotationType
        );
        this.quotations.forEach((quotation: Quotation) => {
          let c_data = quotation.country;
          let producer_data = quotation.producerCompany;
          if (producer_data == undefined) producer_data = 'N/A';
          let p_data = quotation.mainProductType;
          if (c_data !== undefined && !countrylist.includes(c_data)) {
            countrylist.push(c_data);
          }
          if (!productslist.includes(p_data)) {
            productslist.push(p_data);
          }
          if (!producerslist.includes(producer_data)) {
            producerslist.push(producer_data);
          }
        });
        this.quotations.reverse();
        this.filterCountry = countrylist;
        this.filterProducer = producerslist;
        this.filterMainProduct = productslist;
        this.dataSource = new MatTableDataSource(this.quotations);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = this.createFilter();
        this.loading = false;
        this.filterData();
      }
      if (event.status === QuotationHelperServiceEnum.ERROR) {
        this.loading = false;
      }
      if (event.status === QuotationHelperServiceEnum.OPEN_PROJECT) {
        this.loading = false;
        this.router
          .navigate([this.routes.quotations + '/individual/', this.quotationId])
          .then(() => {});
      }
    });
    this.quotationType = QuotationTypeEnum.SILO_PLANT;
    this.route.queryParams.subscribe((params) => {
      const viewBy = params.viewBy;
      const qt = params.quotationType;
      if (!qt) {
        this.quotationType = this.siloPlantQuotation;
      }
      switch (qt) {
        case '0': {
          this.quotationType = this.siloPlantQuotation;
          break;
        }
        case '1': {
          this.isSingleMachine = true;
          this.quotationType = this.singleEquipmentQuotation;
          break;
        }
      }
      const requestParams = {
        userId: this.auth.getUserId(),
        quotationType: this.quotationType,
      };
      if (viewBy) {
        requestParams['viewBy'] = viewBy;
      }
      this.quotationHelper.getQuotations(this.quotationService$, requestParams);
    });
  }
  isEmpty(el: string) {
    return el === undefined || el.length <= 0 || el === '';
  }
  isEmptyReturnZero(el: any) {
    if (el === undefined || el == null || el.length <= 0) {
      return 0;
    }
    return el;
  }
  tableColumns(): string[] {
    if (this.isSingleMachine) {
      if (this.showProducer) {
        return [
          'projectId',
          'producerCompany',
          'status',
          'country',
          'minimumCapacity',
          'maximumCapacity',
          'mainProductType',
          'sendReminder',
        ];
      } else {
        return [
          'projectId',
          'userCompany',
          'status',
          'country',
          'minimumCapacity',
          'maximumCapacity',
          'mainProductType',
          'sendReminder',
        ];
      }
    } else {
      if (this.showProducer) {
        return [
          'projectId',
          'producerCompany',
          'status',
          'country',
          'storageCapacity',
          'handlingCapacity',
          'mainElevatorCapacity',
          'dryingCapacity',
          'minimumCapacity',
          'maximumCapacity',
          'mainProductType',
          'sendReminder',
        ];
      } else {
        return [
          'projectId',
          'userCompany',
          'status',
          'country',
          'storageCapacity',
          'handlingCapacity',
          'mainElevatorCapacity',
          'dryingCapacity',
          'minimumCapacity',
          'maximumCapacity',
          'mainProductType',
          'sendReminder',
        ];
      }
    }
  }
  createFilter() {
    let filterFunction = function (data: Quotation, filter: string): boolean {
      const mapParams = {};
      let isFilterSet = false;
      let searchTerms = JSON.parse(filter);
      for (const col in searchTerms) {
        if (searchTerms[col].toString() !== '') {
          isFilterSet = true;
        } else {
          delete searchTerms[col];
        }
      }
      let nameSearch = () => {
        let found = false;
        if (isFilterSet) {
          for (const col in searchTerms) {
            let val: string = searchTerms[col];
            if (val.includes('-')) {
              let datas: string[] = val.split('-');
              let min = parseInt(datas[0]);
              let max = 0;
              if (datas[1].includes(' ')) {
                max = parseInt(datas[1].split(' ')[0]);
              } else {
                max = parseInt(datas[1]);
              }
              if (col === 'bins') {
                let bins = !data.equipments
                  ? 0
                  : data.equipments.filter(
                      (x) =>
                        x.type === PartTypeEnum.SILO_BIN ||
                        x.type === PartTypeEnum.BUFFER_BIN
                    ).length;
                if (bins <= max && bins >= min) {
                  mapParams[col] = true;
                } else {
                  mapParams[col] = false;
                }
              } else {
                let value = data[col]?.toString();
                value = parseInt(value);
                if (value <= max && value >= min) {
                  mapParams[col] = true;
                } else {
                  mapParams[col] = false;
                }
              }
            } else if (val.includes('>')) {
              let min;
              let datas: string[] = val.split('>');
              if (datas[0].includes(' ')) {
                min = parseInt(datas[0].split(' ')[0]);
              } else {
                min = parseInt(datas[0].trim());
              }
              if (col === 'bins') {
                let bins = !data.equipments
                  ? 0
                  : data.equipments.filter(
                      (x) =>
                        x.type === PartTypeEnum.SILO_BIN ||
                        x.type === PartTypeEnum.BUFFER_BIN
                    ).length;
                if (bins >= min) {
                  mapParams[col] = true;
                } else {
                  mapParams[col] = false;
                }
              } else {
                let value = data[col]?.toString();
                value = parseInt(value);
                if (value >= min) {
                  mapParams[col] = true;
                } else {
                  mapParams[col] = false;
                }
              }
            } else {
              if (!data[col] && val === 'N/A') {
                mapParams[col] = true;
              } else if (!data[col] && val.length > 0) {
                mapParams[col] = false;
              } else if (
                data[col]
                  ?.toString()
                  .toLowerCase()
                  .indexOf(val.toLowerCase()) != -1 &&
                isFilterSet
              ) {
                mapParams[col] = true;
              } else {
                mapParams[col] = false;
              }
            }
          }
          const map = new Map(Object.entries(mapParams));
          found = Array.from(map.values()).every((value) => value);
          return found;
        } else {
          return true;
        }
      };
      return nameSearch();
    };
    return filterFunction;
  }
  filterData(): void {
    let status_value = '';
    const data = this.statuses.value;
    const data3 = this.country.value;
    const data1 = this.numberofbins.value;
    const data2 = this.transfers.value;
    const data4 = this.dryings.value;
    const data6 = this.captransfers.value;
    const data5 = this.products.value;
    const data7 = this.producers.value;
    switch (data) {
      case 'pending':
        status_value = QuotationStatusEnum.PENDING;
        break;
      case 'not_sent':
        status_value = QuotationStatusEnum.NOT_SENT;
        break;
      case 'accepted':
        status_value = QuotationStatusEnum.ACCEPTED;
        break;
      case 'rejected':
        status_value = QuotationStatusEnum.REJECTED;
        break;
      default:
        break;
    }
    const map = {
      status: status_value,
      bins: data1,
      storageCapacity: data2,
      country: data3,
      dryingCapacity: data4,
      maxHandlingCapacity: data6,
      mainProductType: data5,
    };
    if (this.showProducer) {
      map['producerCompany'] = data7;
    } else {
      map['userCompany'] = data7;
    }
    this.dataSource.filter = JSON.stringify(map);
  }
  getQuotationId(quotation: QuotationsTableRow): void {
    this.currentQuotation = quotation;
    this.quotationId = quotation._id;
    this.openProject(this.currentQuotation);
  }
  openProject(quotation: QuotationsTableRow): void {
    this.quotationId = quotation._id;
    if (
      this.currentQuotation.paymentAccepted === PaymentAcceptedEnum.ACCEPTED
    ) {
      this.loading = true;
      this.router
        .navigate([this.routes.quotations + '/individual/', this.quotationId])
        .then(() => {});
      this.loading = false;
    } else {
      this.clickedPaymentRequired = true;
    }
  }
  reminder(quotationId: string): void {
    this.quotationHelper.sendReminder(quotationId);
  }
  acceptedPayment(event: PaymentAcceptedEvent): void {
    this.currentQuotation.paymentAccepted = event.action;
    this.clickedPaymentRequired = false;
    if (event.action === PaymentAcceptedEnum.ACCEPTED) {
      this.openProject(this.currentQuotation);
    } else {
      this.router.navigate([this.routes.quotations]).then(() => {});
    }
  }
  viewSiloOffers(): void {
    this.isSingleMachine = false;
    this.router.navigate([this.routes.quotations]);
  }
  viewPersonalSingleEquipmentQuotations(): void {
    this.isSingleMachine = true;
    this.router.navigate([this.routes.quotations], {
      queryParams: {
        quotationType: QuotationTypeEnum.SINGLE_MACHINES,
        viewBy: QuotationViewBy.PRODUCER,
      },
    });
  }
  viewSingleEquipmentsQuotations(): void {
    const navigationLink = `${this.routes.quotations}/new`;
    this.router.navigate([navigationLink], {
      queryParams: {
        quotationType: QuotationTypeEnum.SINGLE_MACHINES,
        viewBy: QuotationViewBy.PRODUCER,
      },
    });
  }
}
