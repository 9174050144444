<div class="container" *ngIf="!loading">
  <div>
    <h2>New Quotation request</h2>
    <p class="general-subtitle">
      You are about to submit a new quotation request for
      <span class="title-project">{{
        currentProject.name ? currentProject.name : "single machines"
      }}</span
      >.
    </p>
    <p class="general-subtitle">
      Please fill in the details below so we can make sure your quotation has
      the highest chances of success.
    </p>
  </div>
  <div class="details-form">
    <div class="details-form-producers-and-products">
      <!-- Producers and product types -->
      <div>
        <p class="general-title"><strong>Producers & Product Types</strong></p>
      </div>

      <!-- Producers dropdown list -->
      <label class="form-label">Producers</label>
      <div class="div-producers">
        <mat-form-field
          class="rfp-details-half-input-producers"
          appearance="fill"
          floatLabel="always"
        >
          <mat-label>Producers</mat-label>
          <mat-select [formControl]="selectedProducers" required multiple  (selectionChange)="onProducersChange($event)">
            <mat-option
              *ngFor="let producer of producersList()"
              [value]="producer._id"
            >
              {{ producer.firstName + " " + producer.lastName }}
            </mat-option>
            <button
              mat-button
              class="show-more"
              *ngIf="canSeeMoreProducers"
              (click)="showMoreProducersBtn()"
            >
              {{ showMoreProducers ? "Show less" : "Show more" }}
            </button>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="label-rfp">
        <label class="form-label rfp-details-half-input-label"
          >Main Product Type</label
        >
        <label class="form-label rfp-details-half-input-no-margin-label"
          >Other Product Types</label
        >
      </div>

      <!-- Product types dropdown list -->
      <app-dropdown-search [options]="mainProdType" label="Main product type" [_formControl]="selectedMainProductTypes" 
      [formFieldClass]="'rfp-details-half-input rfp-details-half-input-mainprodtype'" (onSelectionChangeEvent)="onMainProductTypesChange($event)">
      </app-dropdown-search>

      <!-- Product types dropdown list -->
      <label class="form-label rfp-details-half-input-no-margin rfp-mobile"
        >Other Product Types</label
      >
      
      <app-dropdown-search [options]="otherProdType" label="Other product type" [_formControl]="selectedProductTypes" 
      [formFieldClass]="'rfp-details-half-input-no-margin rfp-details-half-input-otherprodtype'" (onSelectionChangeEvent)="onOtherProductTypesChange($event)"
      [multipleOption]="true">
      </app-dropdown-search>

      <!-- Additional message -->
      <div class="title-container">
        <p class="general-title"><strong>Additional Details</strong></p>
      </div>

      <label class="form-label"
        >Please add any relevant information about your inquiry ...</label
      >
      <mat-form-field class="full-input" appearance="fill">
        <textarea matInput type="text" [formControl]="message"> </textarea>
      </mat-form-field>
    </div>

    <!-- Cards with equipments (for silo plant quotations) -->
    <div
      class="details-form-equipments"
      *ngIf="quotationType == siloPlantQuotationType"
    >
      <div>
        <p class="general-title-total">
          <strong
            >Total number of equipments
            {{ currentProject.nodes.length }}</strong
          >
        </p>
      </div>
      <div class="equipment-list">
        <div class="mat-elevation-z2">
          <mat-accordion multi>
            <app-equipment-card
              *ngFor="let equipment of currentProject.nodes"
              [equipment]="equipment"
              [editEquipmentCard]="false"
              [deleteEquipmentCard]="false"
              [cardExpanded]="true"              
              (typingEvent)="handleCapacityChange($event)"
            >
            </app-equipment-card>
          </mat-accordion>
        </div>
      </div>
    </div>

    <!-- Cards with additional equipments (for single equipment quotation) -->
    <div
      class="details-form-equipments"
      *ngIf="quotationType == singleMachinesQuotationType"
    >
      <div class="add-individual-equipment">
        <p class="general-title-total"><strong>New single machine</strong></p>
        <button mat-button [matMenuTriggerFor]="first" class="white-button">
          + Add
        </button>
        <mat-menu #first="matMenu">
          <button
            mat-menu-item
            *ngFor="let group of individualEquipmentsGroups"
            [matMenuTriggerFor]="second"
          >
            {{ group.name }}
            <mat-menu #second="matMenu">
              <button
                mat-menu-item
                title="{{ group.name }}"
                (click)="addEquipment(equipment.id, group.id)"
                *ngFor="let equipment of findEquipmentsInGroup(group.id)"
              >
                {{ equipment.partDetails3D.params.name }}
              </button>
            </mat-menu>
          </button>
        </mat-menu>
      </div>
      <div class="equipment-list">
        <div class="mat-elevation-z2">
          <mat-accordion multi>
            <app-individual-equipment-card
              *ngFor="let equipment of selectedIndividualEquipmentsList"
              [equipment]="equipment"
              [editEquipmentCard]="true"
              [deleteEquipmentCard]="true"
              [cardExpanded]="true"
              [showDeleteButton]="true"
              (deleteEquipment)="deleteEquipment($event)"
            >
            </app-individual-equipment-card>
          </mat-accordion>
        </div>
      </div>
    </div>

    <!-- User navigation -->
    <button mat-button class="white-button button-back" (click)="back()">
      Back
    </button>
    <div class="button-container">
      <button
        mat-button
        class="white-button button-back-mobile"
        (click)="back()"
      >
        Back
      </button>
      <span class="button-spacer"></span>
      <button mat-button class="green-button" (click)="submitQuotation()">
        Request quotation
      </button>
    </div>
  </div>
</div>
<div class="spinner-loading">
  <app-spinner [loading]="loading"></app-spinner>
</div>
