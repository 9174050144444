import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  PartTypeEnum,
  PaymentAcceptedEnum,
  ProjectHelperServiceEnum,
  ProjectTypeEnum,
  QuotationStatusEnum,
} from '../models/enums';
import { OperationResult } from '../models/helper-service-events';
import { Project } from '../models/project';
import { Quotation } from '../models/quotation';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { GenericSnackBarService } from './generic-snack-bar.service';
import { ProjectFilesService } from './projectfiles.service';
import { SiloPlantHelperService } from './silo-plant-helper.service';
import { SendProjectEmail } from '../models/projectfile';
import { CloneObjectService } from './clone-object.service';
import { Part3DNode } from '../models/part';

@Injectable()
export class ProjectHelperService {
  private endpoint = environment.endpoint;
  constructor(
    private api: ApiService,
    private auth: AuthService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private projectFiles: ProjectFilesService,
    private siloPlantHelper: SiloPlantHelperService,
    private snackBar: GenericSnackBarService,
    private cloner: CloneObjectService
  ) {
    this.api.http = this.http;
  }

  createProject(
    projectService$: Subject<OperationResult>,
    projectType: ProjectTypeEnum,
    gridSnapshot: string
  ): void {
    const currentProject = new Project(
      'Untitled project',
      new Date().toTimeString(),
      projectType,
      this.auth.getUserId(),
      gridSnapshot,
      [],
      [],
      QuotationStatusEnum.NOT_SENT
    );
    currentProject.paymentAccepted = PaymentAcceptedEnum.REJECTED;
    currentProject.completed = false;
    this.api
      .post<Project, any>(this.endpoint.projects, currentProject)
      .subscribe(
        (newProject) => {
          this.snackBar.showSuccess(
            `Project ${currentProject.name} successfully created.`
          );
          currentProject._id = newProject._id;
          const result = new OperationResult(
            ProjectHelperServiceEnum.CREATED,
            currentProject
          );
          projectService$.next(result);
        },
        (err) => {
          this.snackBar.showError(err.message);
          const result = new OperationResult(ProjectHelperServiceEnum.ERROR);
          projectService$.next(result);
        }
      );
  }

  downloadProject(projectId: string, downloadService$) {
    this.api.getById<Project>(this.endpoint.projectById, projectId).subscribe(
      (project) => {
        this.projectFiles.downloadProjectFiles(project, downloadService$);
      },
      (err) => {
        this.snackBar.showError(err.message);
      }
    );
  }

  sendProjectFilesEmail(
    projectId: string,
    emailData: SendProjectEmail,
    downloadService$: Subject<OperationResult>
  ) {
    this.api
      .post<SendProjectEmail, any>(
        this.endpoint.sendProjectFilesEmail.replace(':id', projectId),
        emailData
      )
      .subscribe(
        (response) => {
          const operationResult = new OperationResult(
            ProjectHelperServiceEnum.SUCCESS,
            response
          );
          downloadService$.next(operationResult);
        },
        (err) => {
          console.log(err);
          const operationResult = new OperationResult(
            ProjectHelperServiceEnum.ERROR,
            err
          );
          downloadService$.next(operationResult);
          return false;
        }
      );
  }

  insertProject(
    project: Project,
    quotation: Quotation,
    projectService$: Subject<OperationResult>
  ): void {
    this.api
      .post<Project, any>(this.endpoint.projects, project)
      .subscribe((newProject) => {
        const operationResult = new OperationResult(
          ProjectHelperServiceEnum.CREATED,
          { id: newProject._id, quotation: quotation }
        );
        projectService$.next(operationResult);
      });
  }

  getProjects(projectService$: Subject<OperationResult>): void {
    this.api.get(this.endpoint.projects).subscribe(
      (projects) => {
        const result = new OperationResult(
          ProjectHelperServiceEnum.LOADED,
          projects
        );
        this.snackBar.showSuccess('Projects loaded successfully.');
        projectService$.next(result);
      },
      (err) => {
        this.snackBar.showError(
          `Failed to load projects. Cause: ${err.message}`
        );
        const result = new OperationResult(ProjectHelperServiceEnum.ERROR);
        projectService$.next(result);
      }
    );
  }

  getProject(
    projectService$: Subject<OperationResult>,
    projectId: string
  ): void {
    if (projectId) {
      this.api
        .getById<Project>(this.endpoint.projectById, projectId)
        .subscribe((project) => {
          const result = new OperationResult(
            ProjectHelperServiceEnum.LOADED,
            project
          );
          projectService$.next(result);
        });
    } else {
      const result = new OperationResult(ProjectHelperServiceEnum.NO_DATA);
      projectService$.next(result);
    }
  }

  editProject(
    projectService$: Subject<OperationResult>,
    project: Project,
    params?: any
  ): void {
    if (params.hasOwnProperty('name')) {
      project.name = params.name;
    }
    if (params.hasOwnProperty('imageUrl')) {
      project.imageUrl = params.imageUrl;
    }
    if (params.hasOwnProperty('plantCapacity')) {
      project = this.siloPlantHelper.getPlantCapacityDetails(project);
    }
    if (params.hasOwnProperty('status')) {
      project.status = params.status;
    }
    const projectCopy = this.cloner.deepCopyProject(project, project.name, false);

    projectCopy.nodes.forEach((node) => (node.part3D = node.physicsBody = undefined));
    this.api
      .put<Project>(this.endpoint.projectById, project._id, projectCopy)
      .subscribe((r) => {
        const result = new OperationResult(ProjectHelperServiceEnum.EDITED);
        result.data = {};
        if (params.hasOwnProperty('navigateTo') && params.navigateTo) {
          result.status = ProjectHelperServiceEnum.EDITED;
          result.data = { navigateTo: params.navigateTo };
        }
        projectService$.next(result);
      });
  }

  deleteProject(
    projectService$: Subject<OperationResult>,
    projectId: string
  ): void {
    this.api.delete(this.endpoint.projectById, projectId).subscribe(
      (_) => {
        const result = new OperationResult(ProjectHelperServiceEnum.SUCCESS);
        this.snackBar.showSuccess(`Project ${projectId} successfully deleted.`);
        projectService$.next(result);
      },
      (err) => {
        const result = new OperationResult(ProjectHelperServiceEnum.ERROR);
        this.snackBar.showError(
          `Failed to delete project ${projectId}. Cause: ${err.message}`
        );
        projectService$.next(result);
      }
    );
  }

  isEquipmentValid(equipment: Part3DNode): boolean {
    if (
      equipment.type === PartTypeEnum.BUFFER_BIN ||
      equipment.type === PartTypeEnum.SILO_BIN ||
      equipment.type === PartTypeEnum.DELIVERY
    ) {
      if (this.isInvalidNumber(equipment.partDetails3D.params["dx"]["x"]) || this.isInvalidNumber(equipment.partDetails3D.params["volume"])) {
        return false;
      }
    } else if (equipment.type === PartTypeEnum.INTAKE) {
      if (this.isInvalidNumber(equipment.partDetails3D.params["dx"]["x"]) || this.isInvalidNumber(equipment.partDetails3D.params["dx"]["z"])) {
        return false;
      }
    } else if (equipment.type === PartTypeEnum.ELEVATOR) {
      if (this.isInvalidNumber(equipment.partDetails3D.params["capacity"]) || this.isInvalidNumber(equipment.partDetails3D.params["dx"]["h"])) {
        return false;
      }
    } else if (equipment.type === PartTypeEnum.CLEANER) {
      if (this.isInvalidNumber(equipment.partDetails3D.params["capacity"])) {
        return false;
      }
    } else if (
      equipment.type === PartTypeEnum.BELT_CONVEYOR ||
      equipment.type === PartTypeEnum.CHAIN_CONVEYOR
    ) {
      if (this.isInvalidNumber(equipment.partDetails3D.params["dx"]["x"]) || this.isInvalidNumber(equipment.partDetails3D.params["capacity"])) {
        return false;
      }
    } else if (equipment.type === PartTypeEnum.DRYER) {
      if (this.isInvalidNumber(equipment.partDetails3D.params["capacity"]) || this.isInvalidNumber(equipment.partDetails3D.params["moistureReduction"])) {
        return false;
      }
    }

    return true;
  }

  isProjectEquipmentValid(
    project: Project,
    snackBar: GenericSnackBarService
  ): boolean {
    let totalEquimentsList = [...project.nodes, ...project.extras];
    for (const equipment of totalEquimentsList) {
      if (
        equipment.type === PartTypeEnum.BUFFER_BIN ||
        equipment.type === PartTypeEnum.SILO_BIN ||
        equipment.type === PartTypeEnum.DELIVERY
      ) {
        if (this.isInvalidNumber(equipment.partDetails3D.params["dx"]["x"]) || this.isInvalidNumber(equipment.partDetails3D.params["volume"])) {
          snackBar.showError(
            'Missing required information. Please check your bins configuration. Make sure the values are above 0.'
          );
          return false;
        }
      } else if (equipment.type === PartTypeEnum.INTAKE) {
        if (this.isInvalidNumber(equipment.partDetails3D.params["dx"]["x"]) || this.isInvalidNumber(equipment.partDetails3D.params["dx"]["z"])) {
          snackBar.showError(
            'Missing required information. Please check your intake configuration. Make sure the values are above 0.'
          );
          return false;
        }
      } else if (equipment.type === PartTypeEnum.ELEVATOR) {
        if (this.isInvalidNumber(equipment.partDetails3D.params["capacity"]) || this.isInvalidNumber(equipment.partDetails3D.params["dx"]["y"])) {
          snackBar.showError(
            'Missing required information. Please check your elevator configuration. Make sure the values are above 0.'
          );
          return false;
        }
      } else if (equipment.type === PartTypeEnum.CLEANER) {
        if (this.isInvalidNumber(equipment.partDetails3D.params["capacity"])) {
          snackBar.showError(
            'Missing required information. Please check your cleaner configuration. Make sure the values are above 0.'
          );
          return false;
        }
      } else if (
        equipment.type === PartTypeEnum.BELT_CONVEYOR ||
        equipment.type === PartTypeEnum.CHAIN_CONVEYOR
      ) {
        if (this.isInvalidNumber(equipment.partDetails3D.params["dx"]["x"]) || this.isInvalidNumber(equipment.partDetails3D.params["capacity"])) {
          snackBar.showError(
            'Missing required information. Please check your conveyors configuration. Make sure the values are above 0.'
          );
          return false;
        }
      } else if (equipment.type === PartTypeEnum.DRYER) {
        if (this.isInvalidNumber(equipment.partDetails3D.params["capacity"]) || this.isInvalidNumber(equipment.partDetails3D.params["moistureReduction"])) {
          snackBar.showError(
            'Missing required information. Please check your dryers configuration. Make sure the values are above 0.'
          );
          return false;
        }
      }
    }
    return true;
  }

  isInvalidNumber(value: number): boolean {
    if (value === undefined || value === null || isNaN(value)) {
      return true;
    }
    if (value <= 0) {
      return true;
    }
    return false;
  }

}
