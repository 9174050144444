<div class="container" *ngIf="!loading">
  <!-- Logo -->
  <div class="box-content">
    <img class="image" [src]="'./assets/siloapp_logo.svg'" alt="" />
    <!-- Message -->
    <div>
      <p class="general-text">Create an account</p>
      <p class="general-text" style="color: black">Account informations</p>
    </div>
    <!-- Sign-up form -->
    <form
      class="signup-form"
      [formGroup]="signupForm"
      (submit)="registerUser(signupForm)"
    >
      <mat-form-field class="signup-input" appearance="outline">
        <mat-label class="form-label">First name</mat-label>
        <input matInput type="text" formControlName="firstName" />
      </mat-form-field>
      <mat-form-field class="signup-input" appearance="outline">
        <mat-label class="form-label">Last name</mat-label>
        <input matInput type="text" formControlName="lastName" />
      </mat-form-field>
      <mat-form-field class="signup-input" appearance="outline">
        <mat-label class="form-label">E-mail address</mat-label>
        <input matInput type="email" formControlName="email" />
      </mat-form-field>
      <mat-form-field class="signup-input" appearance="outline">
        <mat-label class="form-label">Password</mat-label>
        <input
          matInput
          [type]="showPassword ? 'text' : 'password'"
          formControlName="firstPassword"
        />
        <label mat-icon-button matSuffix (click)="togglePasswordVisibility()">
          <mat-icon>{{
            showPassword ? "visibility_off" : "visibility"
          }}</mat-icon>
        </label>
      </mat-form-field>
      <mat-form-field class="signup-input" appearance="outline">
        <mat-label class="form-label">Re-enter password</mat-label>
        <input
          matInput
          [type]="showRePassword ? 'text' : 'password'"
          formControlName="secondPassword"
        />
        <label mat-icon-button matSuffix (click)="toggleRePasswordVisibility()">
          <mat-icon>{{
            showRePassword ? "visibility_off" : "visibility"
          }}</mat-icon>
        </label>
      </mat-form-field>
      <mat-checkbox
        appearance="outline"
        class="signup-checkbox"
        formControlName="privacy"
      >
        <a href="/signup" style="color: black">Accept T&C and privacy policy</a>
      </mat-checkbox>
      <button
        mat-button
        type="submit"
        class="button-default-dimension button-access"
      >
        Register
      </button>
    </form>
    <div
      *ngIf="error && error.length > 0 && signupForm.touched"
      style="display: flex; flex-direction: column; gap: 4px; margin-top: 1rem"
    >
      <label *ngFor="let item of error" style="color: red; text-align: center">
        {{ item }}
      </label>
    </div>
    <div>
      <p class="general-black-text">
        Already have an account?
        <a class="general-text" href="" routerLink="/sign-in">Sign in</a>
      </p>
    </div>
  </div>
</div>
<div class="spinner-loading">
  <app-spinner [loading]="loading"></app-spinner>
</div>
